import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { updateFTUEStatus } from "./commonService";
import { FTUEPayload, FTUEResponse } from "./types";

type APIResponse = FTUEResponse & FailedAPIResponse;

const useUpdateFTUEStatus = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, FTUEPayload>({
    mutationFn: updateFTUEStatus,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useUpdateFTUEStatus;
