import { Box, Button, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import FTUELayout from "components/FTUELayout";
import { LoadingButton, Modal } from "components/common";
import { TitleField } from "components/ui";
import { ROUTES } from "config/app";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth } from "hooks";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDeleteAccount, useGetDeleteAccountOTP } from "services/common";
import { DeleteAccountOTPResponse } from "services/common/types";
import triggerGAEvent from "utils/gaEvents";
import EditEmail from "./subComponents/EditEmail";
import EmailForm from "./subComponents/EmailForm";
import OTPForm from "./subComponents/OTPForm";

type FormStep = "enterEmail" | "verifyOTP";

const { PAGES } = Resources;

const label: Record<FormStep, string> = {
  enterEmail: Resources.PAGES.DELETE_ACCOUNT.enterEmail,
  verifyOTP: Resources.PAGES.DELETE_ACCOUNT.verifyOTPDetails,
};

function DeleteAccount() {
  const [currentStep, setCurrentStep] = useState<FormStep>("enterEmail");
  const [userEmail, setUserEmail] = useState("");
  const [otpError, setOtpError] = useState("");
  const [emailOTP, setEmailOTP] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [OTPResendAttemptsLeft, setOTPResendAttemptsLeft] = useState(0);

  const { setGlobalAlert, user } = useAuth();
  const navigate = useNavigate();

  const showError = (message: string) =>
    setGlobalAlert({ message, messageType: "error" });

  const onEmailOTPSendSuccess = (res: DeleteAccountOTPResponse) => {
    setOTPResendAttemptsLeft(res.result.resendEmailOtpBalance);
    setCurrentStep("verifyOTP");
  };

  const handleError = (error: FailedAPIStatus) => {
    showError(error.errorMessage);
  };

  const handleOTPError = (error: FailedAPIStatus) => {
    setOtpError(error.errorMessage);
    showError(error.errorMessage);
  };

  const onDeleteAccountSuccess = () => {
    // send account delete event to GA
    triggerGAEvent("account_delete", user?.userId as string);
    setGlobalAlert({
      message: PAGES.DELETE_ACCOUNT.deleteSuccess,
      messageType: "success",
    });
    navigate(ROUTES.PUBLIC.LOGIN, { replace: true });
  };

  // APIs
  const { mutateAsync: getEmailOTP, isLoading: isSendingOTP } =
    useGetDeleteAccountOTP({
      onSuccess: onEmailOTPSendSuccess,
      onError: handleError,
    });

  const { mutateAsync: deleteAccount, isLoading: isDeleting } =
    useDeleteAccount({
      onSuccess: onDeleteAccountSuccess,
      onError: handleOTPError,
    });

  const updateStep = (step: FormStep) => {
    setCurrentStep(step);
  };

  const onEmailUpdate = async (email: string) => {
    setUserEmail(email);
    await getEmailOTP({ email });
  };

  const onOTPValidate = (otp: string) => {
    setOtpError("");
    setEmailOTP(otp);
    setShowConfirmation(true);
  };

  const dismissAlert = () => setShowConfirmation(false);

  const onDeleteConfirmation = async () => {
    await deleteAccount({ email: userEmail, otp: emailOTP });
    dismissAlert();
  };

  return (
    <>
      <FTUELayout containerStyle={{ maxWidth: { xl: "80%" } }}>
        <Box>
          <TitleField
            title1={PAGES.DELETE_ACCOUNT.title}
            title2={PAGES.DELETE_ACCOUNT.account}
            sx={{ mb: { xl: "2.5rem", sm: "1.5rem", xs: "1.5rem" } }}
          />
          <Typography
            sx={{
              mb: "2.5rem",
              color: "var(--color-secondary)",
              fontFamily: "var(--font-primary-700)",
            }}
          >
            {PAGES.DELETE_ACCOUNT.developerName}
          </Typography>
          <Box sx={{ mb: { md: "3rem" } }}>
            <Box
              sx={{
                mb: "3rem",
                width: { sm: "30.667rem", md: "36.667rem" },
                lineHeight: "1.68rem",
                marginBottom: "30px",
              }}
            >
              <Typography
                component="p"
                sx={{
                  fontSize: "1.4rem !important",
                  fontFamily: "var(--font-primary-500)",
                  color: "var(--colorTextSecondary)",
                  fontWeight: "500",
                  marginBottom: "3rem",
                }}
              >
                {label[currentStep]}
              </Typography>

              {currentStep === "enterEmail" && (
                <EmailForm
                  isLoading={isSendingOTP}
                  email={userEmail}
                  onComplete={onEmailUpdate}
                />
              )}

              {currentStep === "verifyOTP" && (
                <>
                  <EditEmail
                    email={userEmail}
                    onEdit={() => updateStep("enterEmail")}
                  />
                  <OTPForm
                    email={userEmail}
                    otpAttemptsLeft={OTPResendAttemptsLeft}
                    otpError={otpError ?? ""}
                    onValidateOTP={onOTPValidate}
                  />
                </>
              )}

              <Box className="app-l-secondary-button-block">
                <Button
                  type="button"
                  variant="outlined"
                  color="inherit"
                  component={Link}
                  fullWidth
                  to={ROUTES.PUBLIC.LOGIN}
                  sx={{ textTransform: "capitalize" }}
                >
                  {Resources.COMMON.BUTTONS.BACK_TO_LOGIN}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </FTUELayout>
      <Modal open={showConfirmation} onClose={dismissAlert} disableClickAway>
        <Box
          sx={{
            backgroundColor: "var(--colorWhite)",
            p: "3.5rem",
            minWidth: { xs: "95vw", md: "35vw" },
            maxWidth: { md: "35vw" },
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: { xs: "1.8rem !important", sm: "2.4rem !important" },
                fontFamily: "var(--font-primary-500)",
                color: "var(--colorTextPrimary) !important",
              }}
            >
              {PAGES.DELETE_ACCOUNT.confirmationTitle}
            </Typography>
            <Typography sx={{ mt: "1.5rem", color: "var(--text-input-label)" }}>
              {PAGES.ACCOUNT_PROFILE.DELETE_ACCOUNT_DESCRIPTION}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              rowGap: { xs: "1.5rem" },
              justifyContent: "space-between",
              mt: { xs: "1.5rem", sm: "3rem" },
              "&>button": {
                minWidth: "33%",
              },
            }}
          >
            <LoadingButton
              loading={isDeleting}
              label="Yes, Delete"
              onClick={onDeleteConfirmation}
              variant="outlined"
            />
            <Button onClick={dismissAlert} variant="contained">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default DeleteAccount;
