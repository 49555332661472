import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormHelperText, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import { ConfirmDialog } from "components";
import CountryPhoneBlock from "components/CountryPhoneBlock";
import { OTPCount, OTPInput } from "components/ui";
import {
  DEFAULT_OTP_LENGTH,
  HINT_MESSAGE,
  OTP_COUNT,
  OTP_WAITING_TIME,
  ROUTES,
} from "config/app";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth } from "hooks";
import { ChangeEventHandler, useMemo, useReducer, useState } from "react";
import {
  Controller,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";

import OtpResentSuccess from "components/ui/OtpResentSuccess";
import useTimer from "hooks/useTimer";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  useUpdatePhone,
  useVerifyPhoneOtp,
} from "services/accountProfileService";
import { ChangePhoneResponse } from "services/accountProfileService/types";
import { useGetCountryCode } from "services/common";
import { isValidMobileNumber } from "utils";
import { OTPReducer, initialOTPState } from "utils/otpReducer";
import { changePhoneNumber } from "utils/validationSchema";
import { ChangePhoneNumberDataType, ChangePhoneType } from "./config";

export type FormField = keyof typeof changePhoneNumber.fields;

type ChangePhoneFormProps = {
  onCancel: () => void;
  onAbort: () => void;
};

type ErrorType = {
  errorMessage: string;
};

const {
  COMMON: {
    BUTTONS: { CODE_LABEL },
    MESSAGE: { VALID_CODE, VALIDATE_MOBILE },
  },
} = Resources;

function ChangePhoneForm(props: ChangePhoneFormProps) {
  const { onCancel, onAbort } = props;

  const [OTPState, dispatchOTPAction] = useReducer(OTPReducer, initialOTPState);
  const [helperText, setHelperText] = useState<string>("");
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [canSentOtp, setCanSentOtp] = useState<Boolean>(true);
  const [isShowResentMsg, setIsShowResentMsg] = useState<Boolean>(false);
  const [resentMsg, setResentMsg] = useState<string>("");
  const [otpBalance, setOtpBalance] = useState(OTP_COUNT);
  const [isFirstTime, setIsFirstTime] = useState<Boolean>(true);
  const { setGlobalAlert, setUserPhone } = useAuth();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    trigger,
    getValues,
  } = useForm<ChangePhoneNumberDataType>({
    resolver: yupResolver(changePhoneNumber),
    defaultValues: { isdCode: "", phoneNumber: "", isdCountry: "" },
    mode: "onTouched",
  });

  const location = useLocation();
  const navigate = useNavigate();

  const changeDetails = location.state as ChangePhoneType;

  const onOtpTimerComplete = () => {
    setCanSentOtp(true);
  };

  const { timeLeft: otpTimer } = useTimer({
    time: OTP_WAITING_TIME,
    onComplete: onOtpTimerComplete,
    start: !canSentOtp,
  });

  const watchPhoneNumber = watch("phoneNumber");
  const watchIsdCode = watch("isdCode");

  const showError = (message: string) => {
    setGlobalAlert({ messageType: "error", message });
  };

  const handleAPIError = (error: FailedAPIStatus) => {
    if (error.errorKey === "PWB_INVALID_OLD_OTP") {
      navigate(-1);
    }
    setHelperText(error.errorMessage);
    showError(error.errorMessage);
  };

  const onPhoneOTPSuccess = (response: ChangePhoneResponse) => {
    dispatchOTPAction({ type: "SET_IS_PHONE_OTP_RESEND", payload: true });
    setOtpBalance(+response.result.resendPhoneOtpBalance);
    setResentMsg(response?.result?.hintMessageCode);
    if (!showOTPInput) {
      setShowOTPInput(true);
    }
  };

  const onPhoneVerifySuccess = () => {
    setGlobalAlert({
      messageType: "success",
      message: "Mobile number verified",
    });
    navigate(ROUTES.PRIVATE.ACCOUNT_PROFILE);
  };

  const handlePhoneNumberError = (error: ErrorType) => {
    const message = error?.errorMessage;
    showError(message as string);
  };

  const { mutateAsync: updatePhoneNumber, isLoading } = useUpdatePhone({
    onError: handlePhoneNumberError,
    onSuccess: onPhoneOTPSuccess,
  });

  const { mutateAsync: verifyPhone, isLoading: isPhoneVerifying } =
    useVerifyPhoneOtp({
      onSuccess: onPhoneVerifySuccess,
      onError: handleAPIError,
    });

  const { data: allCountryList } = useGetCountryCode();

  const countryList = useMemo(() => {
    const list = allCountryList?.result?.countryList?.map((country) => ({
      isdCode: country.isdCode,
      countryCode: country.shortName,
    }));
    return list;
  }, [allCountryList]);

  const handlePhoneNumberChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setShowOTPInput(false);
    const { name, value } = event.target;
    if (name === "isdCountry") {
      const country = countryList?.find((code) => code.countryCode === value);
      setValue("isdCode", country?.isdCode?.substring(1).toString() ?? "USA");
      setValue("isdCountry", value);
      trigger("isdCode");
      trigger("isdCountry");
      return;
    }
    const field = name as FormField;
    setValue(field, value);
    trigger(field);
  };

  const handleErrors: SubmitErrorHandler<FieldValues> = (error) => {
    const message = error.phoneNumber?.message ?? error.isdCode?.message;
    showError(message as string);
  };

  const closeOTPAlert = () => {
    dispatchOTPAction({ type: "SET_IS_PHONE_OTP_RESEND", payload: false });
  };

  const onOTPComplete = (otp: string) => {
    dispatchOTPAction({ type: "SET_PHONE_OTP", payload: otp });
  };

  const handleCancel = () => {
    if (showOTPInput) {
      onAbort();
      return;
    }
    onCancel();
  };

  const isSavedNumber = (): boolean => {
    const { isdCode, phoneNumber } = changeDetails;

    const newISDCode = getValues("isdCode");
    const newPhoneNumber = getValues("phoneNumber");

    if (isdCode === `${newISDCode}` && phoneNumber === newPhoneNumber) {
      showError("This mobile number is already verified");
      return true;
    }
    return false;
  };

  const handlePhoneValidate = async () => {
    const isdCode = getValues("isdCode");
    const phoneNumber = getValues("phoneNumber");
    const isdCountry = getValues("isdCountry");
    const number = `+${isdCode}${phoneNumber}`;

    const isOldNumber = isSavedNumber();

    if (isOldNumber) {
      return;
    }

    if (!isdCode || !phoneNumber) {
      showError("Please fill mobile number");
      return;
    }
    setIsShowResentMsg(true);

    if (!isValidMobileNumber(number)) {
      showError(Resources.ERRORS.MOBILE_NUMBER_NOT_MATCHING);
      return;
    }

    if (!isValidMobileNumber(number)) {
      showError(Resources.ERRORS.MOBILE_NUMBER_NOT_MATCHING);
      return;
    }

    const res = await updatePhoneNumber({
      isdCode,
      phoneNumber,
      isdCountry,
    });

    setOtpBalance(+res.result.resendPhoneOtpBalance);
    setResentMsg(res?.result?.hintMessageCode);

    if (!res?.result?.success) {
      return;
    }
    setUserPhone({ isdCode, phoneNumber });
  };

  const handleValidate = () => {
    setIsFirstTime(true);
    handlePhoneValidate();
  };

  const handleResentOtp = () => {
    setIsFirstTime(false);
    if (+otpBalance === 0) {
      showError(Resources.ERRORS.NO_RESEND_LEFT);
      return;
    }
    setIsShowResentMsg(true);
    handlePhoneValidate();
    setCanSentOtp(false);
  };

  const onSubmit: SubmitHandler<FieldValues> = async (formData) => {
    const { phoneOTP } = OTPState;
    const { isdCode, phoneNumber, isdCountry } = formData;

    const isOldNumber = isSavedNumber();

    if (isOldNumber) {
      return;
    }

    if (!phoneOTP.length && !showOTPInput) {
      const errorMessage = VALIDATE_MOBILE;
      setHelperText(errorMessage);
      showError(errorMessage);
      return;
    }

    if (phoneOTP.length !== DEFAULT_OTP_LENGTH) {
      const errorMessage = VALID_CODE;
      setHelperText(errorMessage);
      showError(errorMessage);
      return;
    }

    await verifyPhone({
      otp: phoneOTP,
      oldOTP: changeDetails?.oldOtp,
      isFromAccountProfile: true,
      isdCode,
      phoneNumber,
      isdCountry,
    });
  };

  const isValidPhone = !!(
    watchPhoneNumber &&
    watchIsdCode &&
    !errors.phoneNumber?.message &&
    !errors.isdCode?.message
  );

  const hideResentMessage = () => {
    setIsShowResentMsg(false);
  };

  const onOTPFocus = () => {
    setHelperText("");
  };

  if (!changeDetails) {
    return <Navigate to={ROUTES.PRIVATE.ACCOUNT_PROFILE} />;
  }

  return (
    <ConfirmDialog
      heading="Change Mobile Number"
      onCancel={handleCancel}
      onConfirm={handleSubmit(onSubmit, handleErrors)}
      primaryButtonProps={{ disabled: isPhoneVerifying || !showOTPInput }}
    >
      <Box sx={{ maxWidth: "40rem" }}>
        <Controller
          control={control}
          name="isdCountry"
          render={({ field }) => (
            <CountryPhoneBlock
              control={control}
              fieldErrors={{
                isdCode: errors.isdCode?.message,
                phoneNumber: errors.phoneNumber?.message,
              }}
              {...field}
              onChange={handlePhoneNumberChange}
            />
          )}
        />
        {!showOTPInput && isValidPhone && (
          <>
            <OTPCount
              sx={{ justifyContent: "flex-end", mb: "3rem", marginTop: "1rem" }}
              resendLabel="Validate"
              onResend={handleValidate}
            />

            <FormHelperText sx={{ fontSize: "1.4rem" }}>
              Please validate mobile number
            </FormHelperText>
          </>
        )}
      </Box>
      {showOTPInput && isValidPhone && (
        <>
          <Box sx={{ maxWidth: "64rem", mt: "3rem" }}>
            <Typography>
              Your mobile number needs to be validated. You should have received
              an SMS text message to your new mobile number which will contain a
              6-digit code. Please enter the code below.
            </Typography>
          </Box>

          <Box sx={{ maxWidth: "40rem", mt: "3.2rem" }}>
            <>
              <OTPInput
                otpLength={DEFAULT_OTP_LENGTH}
                legend={CODE_LABEL}
                onComplete={onOTPComplete}
                showAlert={OTPState.isPhoneOTPResend}
                onFocus={onOTPFocus}
                helpText={helperText ?? ""}
                error={!!helperText}
                alertMessage={
                  isFirstTime
                    ? "Code sent successfully!"
                    : "Code resent successfully!"
                }
                onAlertClose={closeOTPAlert}
                sx={{ mb: "1.2rem" }}
              />
              <OTPCount
                sx={{
                  justifyContent: "flex-end",
                  mb: "3rem",
                }}
                resendLabel={
                  canSentOtp ? "Resend code" : `Resend code in ${otpTimer}s`
                }
                onResend={handleResentOtp}
                attemptsLeft={otpBalance}
                isAttemptEnabled
                attemptsLabel={Resources.COMMON.BUTTONS.ATTEMPT_LEFT}
                disabled={!canSentOtp}
                loading={isLoading}
              />
            </>
            {isShowResentMsg && resentMsg && !isFirstTime && (
              <OtpResentSuccess
                message={HINT_MESSAGE[resentMsg]}
                onCancel={hideResentMessage}
              />
            )}
          </Box>
        </>
      )}
    </ConfirmDialog>
  );
}

export default ChangePhoneForm;
