import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { TimeZonesDetailsResponseType, TimezonesDetailsPayload } from "./types";
import { getTimeZoneDetails } from "./commonService";

type APIResponse = TimeZonesDetailsResponseType & FailedAPIResponse;

const useSetTimeZoneDetails = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, TimezonesDetailsPayload>({
    mutationFn: getTimeZoneDetails,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useSetTimeZoneDetails;
