/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import Resources from "assets/json/Resources";
import CountryPhoneBlock from "components/CountryPhoneBlock";
import { LoadingButton } from "components/common";
import { MobileNumber } from "constants/sharedTypes";
import { useAuth } from "hooks";
import { FormField } from "pages/AccountProfile/ChangePhone";
import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import useGetCountryCode from "services/common/useGetCountryCode";
import { isValidMobileNumber } from "utils";
import { mobileVerificationSchema } from "./config";

function MobileVerificationForm(props: {
  sendPhoneOTPHandler: (mobileData: MobileNumber) => void;
  isCodeSendLoading: boolean;
  currentPhone: MobileNumber;
  onBack: () => void;
}) {
  const { sendPhoneOTPHandler, isCodeSendLoading, currentPhone, onBack } =
    props;
  const {
    COMMON: {
      BUTTONS: { SEND_VERIFICATION_CODE, BACK },
    },
  } = Resources;

  const { setGlobalAlert } = useAuth();
  const [phoneData, setPhoneData] = useState<MobileNumber | null>(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    setError,
    getValues,
    clearErrors,
  } = useForm<MobileNumber>({
    resolver: yupResolver(mobileVerificationSchema),
    defaultValues: {
      isdCode: phoneData?.isdCode ?? "",
      isdCountry: phoneData?.isdCountry ?? "",
      phoneNumber: phoneData?.phoneNumber ?? "",
    },
  });

  const countryCode = getValues("isdCode");

  const { data: allCountryList } = useGetCountryCode();

  const countryList = useMemo(() => {
    const list = allCountryList?.result?.countryList?.map((country) => ({
      isdCode: country.isdCode,
      countryCode: country.shortName,
    }));
    return list;
  }, [allCountryList]);

  useEffect(() => {
    setValue("phoneNumber", currentPhone?.phoneNumber);
    setValue("isdCountry", currentPhone?.isdCountry);
    const country = countryList?.find(
      (code) => code.countryCode === currentPhone?.isdCountry,
    );
    setValue("isdCode", country?.isdCode ?? "");
    if (countryCode.length) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    const { phoneNumber, isdCountry, isdCode } = data;
    const country = countryList?.find(
      (code) => code.countryCode === isdCountry,
    );
    const number = `${country?.isdCode}${phoneNumber}`;
    if (!isValidMobileNumber(number)) {
      setGlobalAlert({
        messageType: "error",
        message: Resources.ERRORS.MOBILE_NUMBER_NOT_MATCHING,
      });
      return;
    }

    const errorMessage = Object.values(errors)?.[0]?.message;
    if (errorMessage) {
      setGlobalAlert({
        message: errorMessage,
        messageType: "error",
      });
      setError("isdCountry", { message: errorMessage });
      return;
    }

    sendPhoneOTPHandler({ isdCode, isdCountry, phoneNumber });
    setPhoneData(data as MobileNumber);
  };

  const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    const field = name as FormField;
    if (name === "isdCountry") {
      const country = countryList?.find((code) => code.countryCode === value);
      setValue("isdCode", country?.isdCode?.substring(1) ?? "USA");
      setValue("isdCountry", value);
      trigger("isdCode");
      trigger("isdCountry");
      return;
    }

    setValue(field, value);
    trigger(field);
    clearErrors("phoneNumber");
  };

  return (
    <form name="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="formGroup">
        <Controller
          control={control}
          name="isdCountry"
          render={({ field }) => (
            <CountryPhoneBlock
              control={control}
              fieldErrors={{
                isdCode: errors?.isdCode?.message,
                phoneNumber: errors?.phoneNumber?.message,
              }}
              {...field}
              onChange={handlePhoneChange}
            />
          )}
        />
      </div>
      <div className="button-block">
        <div className="app-l-primary-button-block">
          <LoadingButton
            type="submit"
            variant="contained"
            label={SEND_VERIFICATION_CODE}
            fullWidth
            loading={isCodeSendLoading}
            sx={{ fontWeight: "500" }}
          />
        </div>
        <div className="app-l-secondary-button-block">
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            fullWidth
            onClick={onBack}
            sx={{ textTransform: "capitalize" }}
          >
            {BACK}
          </Button>
        </div>
      </div>
    </form>
  );
}
export default MobileVerificationForm;
