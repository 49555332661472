import { Box, Typography } from "@mui/material";
import { getCardNameAndImage } from "utils";
import styles from "./styles.module.scss";

type CardNamePropType = {
  nickName: string;
};

function CardName(props: CardNamePropType) {
  const { nickName } = props;
  const { name, imgPath } = getCardNameAndImage(nickName);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <img src={imgPath} alt={name} className={styles.cardLogo} />
      <Typography variant="body2" className={styles.cardName}>
        {name}
      </Typography>
    </Box>
  );
}

export default CardName;
