import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TypographyProps,
} from "@mui/material";
import { Location } from "components";
import FTUELayout from "components/FTUELayout";
import { LoadingButton } from "components/common";
import CheckBoxLabel from "components/ui/CheckBoxLabel";
import {
  REACT_QUERY_KEYS,
  ROUTES,
  coachingSessionMandatoryItems,
} from "config/app";
import {
  CareerPhase,
  CareerPhaseOptionsText,
  FailedAPIStatus,
  HelpOption,
  HowCanWeHelpOptions,
  LocationInfo,
} from "constants/sharedTypes";
import { useAuth } from "hooks";
import { ChangeEventHandler, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetWelcome } from "services/accountProfileService";
import { WelcomeResponse } from "services/accountProfileService/types";
import { useGetConfig } from "services/common";
import useGetTalentHomeDetails from "services/useGetTalentHomeDetails";
import triggerGAEvent, { GAEvents, GA_USER_FLOW } from "utils/gaEvents";

function Title(props: TypographyProps) {
  return (
    <Typography
      sx={{
        fontSize: {
          xl: "2.2rem !important",
          md: "2rem !important",
          sm: "1.6rem !important",
        },
        fontFamily: "var(--font-primary-500)",
        fontWeight: 400,
        mb: "2rem",
        lineHeight: "1",
        color: "var(--colorTextPrimary)",
      }}
      {...props}
    />
  );
}

function KnowMore() {
  const { logout, setGlobalAlert, login, user, getUserId } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [chosenOptions, setChosenOptions] = useState({
    howCanWeHelp: "",
    careerPhase: "",
  });
  const [location, setLocation] = useState<LocationInfo>({} as LocationInfo);

  const userId = getUserId();

  const { data, isLoading } = useGetConfig("ACCOUNT_PROFILE");
  const { refetch: getUserInfo, data: userDetails } = useGetTalentHomeDetails();

  const helpOptions: HelpOption =
    data?.result?.configurations?.HOW_CAN_WE_HELP_OPTIONS ?? ({} as HelpOption);

  const careerPhaseOptions: CareerPhaseOptionsText =
    data?.result?.configurations?.CAREER_PHASE_OPTIONS_TEXT ??
    ({} as CareerPhaseOptionsText);

  const showError = (message: string) =>
    setGlobalAlert({ message, messageType: "error" });

  const sendAnalyticsData = async (event: GAEvents, step: string) => {
    try {
      triggerGAEvent(event, userId as string, {
        step,
        age_selection: userDetails?.home?.age,
        how_we_help:
          helpOptions[chosenOptions.howCanWeHelp as keyof HelpOption],
        career_phase: chosenOptions.careerPhase,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleAPIError = (error: FailedAPIStatus) => {
    sendAnalyticsData("error", GA_USER_FLOW.REGISTER.ERROR.WHY_ARE_U_HERE);
    showError(error.errorMessage);
  };

  const handleSuccess = async (res: WelcomeResponse) => {
    // trigger GA event on welcome details saved
    sendAnalyticsData(
      "create_account",
      GA_USER_FLOW.REGISTER.SUCCESS.WHY_ARE_U_HERE,
    );
    sendAnalyticsData(
      "create_account",
      GA_USER_FLOW.REGISTER.SUCCESS.SIGNUP_SUCCESS,
    );

    await getUserInfo();
    if (res.result.incompleteSection) {
      const nextSection = coachingSessionMandatoryItems.includes(
        res.result.incompleteSection,
      )
        ? "COACHING_SESSION_BLOCKER"
        : res.result.incompleteSection;

      navigate(ROUTES.PUBLIC.SIGN_UP, {
        state: { section: nextSection },
      });
      return;
    }
    login({ ...user, email: user?.email as string });
    navigate(ROUTES.PRIVATE.HOME, { replace: true });
  };

  const { mutateAsync: setWelcomeOptions, isLoading: isSubmitting } =
    useSetWelcome({
      onSuccess: handleSuccess,
      onError: handleAPIError,
    });

  const handleOptionChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setChosenOptions((prev) => ({ ...prev, [name]: value }));
  };

  const handleCitySelect = (locationInfo: LocationInfo) => {
    setLocation(locationInfo);
  };

  const validateInputs = (): boolean => {
    const { howCanWeHelp, careerPhase } = chosenOptions;
    const { city, state, zipCode, country } = location;

    if (!howCanWeHelp && !careerPhase && !zipCode && !city && !state) {
      showError("Please fill all fields.");
      return false;
    }

    if (!howCanWeHelp) {
      showError(
        'Choose an option from "What do you want pepelwerk to help you do?"',
      );
      return false;
    }

    if (!careerPhase) {
      showError('Choose an option from "How long have you been working?"');
      return false;
    }

    if (!country) {
      showError("Choose a country based on your current location.");
      return false;
    }

    if (!zipCode) {
      showError("Enter the zipcode of your current location.");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const { howCanWeHelp, careerPhase } = chosenOptions;
    const isValidInputs = validateInputs();
    queryClient.invalidateQueries(REACT_QUERY_KEYS.ACCOUNT_PROFILE);

    if (!isValidInputs) return;
    await setWelcomeOptions({ careerPhase, howCanWeHelp, ...location });
  };

  return (
    <FTUELayout
      title1="Tell us a little bit"
      title2="about yourself"
      containerStyle={{ maxWidth: "51rem !important" }}
    >
      {isLoading ? (
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <CircularProgress size={20} />
        </Box>
      ) : (
        <Box sx={{ gap: "3rem", display: "flex", flexDirection: "column" }}>
          <Box>
            <Title className="required">
              What do you want pepelwerk to help you do?
            </Title>
            <FormControl aria-label="Help Options">
              <RadioGroup
                name="howCanWeHelp"
                sx={{ gap: "1.5rem" }}
                onChange={handleOptionChange}
              >
                {Object.keys(helpOptions).map((key: string) => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    sx={{ ml: 0 }}
                    control={<Radio size="small" />}
                    label={helpOptions[key as HowCanWeHelpOptions]}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            <Title className="required">How long have you been working?</Title>
            <FormControl aria-label="Career Phase Options">
              <RadioGroup
                name="careerPhase"
                sx={{ gap: "1.5rem" }}
                onChange={handleOptionChange}
              >
                {Object.keys(careerPhaseOptions).map((key: string) => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    sx={{ ml: 0 }}
                    control={<Radio size="small" />}
                    label={
                      <CheckBoxLabel
                        label={careerPhaseOptions[key as CareerPhase].value}
                        subText={
                          careerPhaseOptions[key as CareerPhase]
                            .talentHelperText
                        }
                        sx={{ marginBottom: "0", alignItems: "flex-start" }}
                      />
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            <Title>Where are you located?</Title>
            <Box sx={{ maxWidth: "36.8rem" }}>
              <Location onCitySelect={handleCitySelect} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              maxWidth: "36.8rem",
            }}
          >
            <LoadingButton
              loading={isSubmitting}
              label="Submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            />

            <Button variant="outlined" color="primary" onClick={logout}>
              Logout
            </Button>
          </Box>
          <Box />
        </Box>
      )}
    </FTUELayout>
  );
}

export default KnowMore;
