import Resources from "assets/json/Resources";
import requests from "config/api";
import { LOCAL_STORAGE_KEYS, ROUTES, SESSION_KEYS } from "config/app";
import API_ENDPOINTS from "constants/api";
import { userInitial } from "context/auth/authContext";
import { GlobalAlert, UserType } from "context/auth/types";
import { useAuth } from "hooks";
import useTimeout from "hooks/useTimeout";
import { useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCampaignInfo,
  getParsedLSItem,
  navigateToIncompleteSession,
  setToSessionStorage,
} from "utils";
import signOutApiHandler from "./signOutAPIHandler";
import useSendCampaignInfo from "./useSendCampaignInfo";

export const getTalentHomeDetails = async () => {
  const url = API_ENDPOINTS.HOME_DETAILS;
  return requests.post(url);
};

type FunctionParam = {
  setUser: (user: UserType) => void;
  setLoading: (isLoading: boolean) => void;
  setGlobalAlert: (alertData: GlobalAlert) => void;
  nextRoute: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
const [_, campaignUrl] = ROUTES.PUBLIC.SIGN_UP_WITH_CAMPAIGN.split("/");

const useGetTalentDetails = (params: FunctionParam) => {
  const { setUser, setGlobalAlert, nextRoute, setLoading } = params;
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const queryClient = useQueryClient();
  const { mutateAsync: sendCampaignInfo } = useSendCampaignInfo();

  const { pathname } = location;

  const {
    PUBLIC: { LOGIN, TERMS_AND_CONDITIONS },
  } = ROUTES;

  // handle error message
  const { reset } = useTimeout(() => {
    setGlobalAlert({ message: "", messageType: "error" });
  }, 4000);
  const timerRef = useRef<number | undefined>();
  const goToNextStep = () => {
    timerRef.current = window.setTimeout(() => {
      if (pathname !== TERMS_AND_CONDITIONS)
        navigate(nextRoute, { replace: true });
      setLoading(false);
    }, 3000);
  };

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return useMutation(
    async () => {
      if (pathname === TERMS_AND_CONDITIONS) setLoading(false);
      const response = await getTalentHomeDetails();
      return response;
    },
    {
      onSuccess: async (response) => {
        if (
          response &&
          response.status === 200 &&
          response.result?.home?.email
        ) {
          const userDetails = getParsedLSItem(LOCAL_STORAGE_KEYS.USER_DETAILS);
          if (userDetails?.email === response.result.home.email) {
            setUser({ ...userDetails, navigate: true });
            goToNextStep();
          } else {
            setUser({ ...userInitial });
            await signOutApiHandler();
            queryClient.clear();
            if (pathname !== TERMS_AND_CONDITIONS)
              navigate(LOGIN, { replace: true });
            setLoading(false);
          }
        } else {
          const privateRoutes = Object.values(ROUTES.PRIVATE);
          const isPrivateRoute = privateRoutes.some((x) => x === pathname);
          if (
            response?.status === 440 &&
            response.result?.timeOut === "session"
          ) {
            await signOutApiHandler();
            queryClient.clear();
            setUser({ ...userInitial });
            // session timeout - signout and redirect to login page
            logout();
            if (isPrivateRoute) {
              navigate(LOGIN, { replace: true });
            }
          } else if (response.result.incompleteSection) {
            if (pathname !== TERMS_AND_CONDITIONS) {
              setUser({
                ...response.result,
                email: response.result.home.email,
              });

              // * Check whether the user is navigated from campaign url to other page
              // * if so, save campaign details to session and tag with user
              // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-shadow, @typescript-eslint/naming-convention
              const [_, url, token] = pathname.split("/");
              if (url === campaignUrl && token) {
                setToSessionStorage(SESSION_KEYS.CAMPAIGN, token);
                const campaignInfo = getCampaignInfo();
                if (campaignInfo) {
                  await sendCampaignInfo({
                    accessCode: campaignInfo.code,
                    campaignId: campaignInfo.campaign_id,
                  });
                }
              }

              navigateToIncompleteSession({
                userData: {
                  ...response.result,
                  incompleteSection: response.result.incompleteSection,
                },
                nextRoute: nextRoute || LOGIN,

                navigate,
              });
            }
          } else if (isPrivateRoute) {
            if (pathname !== TERMS_AND_CONDITIONS)
              navigate(LOGIN, { replace: true });
          }
          setLoading(false);
        }
      },
      onError: async (err: { data: any }) => {
        if (err) {
          let message = Resources.ERRORS.SOMETHING;
          if (err.data?.message) {
            message = err.data.message;
          }
          setUser({ ...userInitial, navigate: true });
          setGlobalAlert({ message, messageType: "error" });
          setLoading(false);
          reset();
        }
      },
    },
  );
};

export default useGetTalentDetails;
