import { Box } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { LoadingButton } from "components/common";

interface PrimaryButtonRequiredProps {
  primaryButtonLabel: string;
  onPrimaryButtonClick: () => void;
  primaryButtonProps?: ButtonProps;
}
interface SecondaryButtonRequiredProps {
  secondaryButtonLabel: string;
  onSecondaryButtonClick: () => void;
  secondaryButtonProps?: ButtonProps;
}

interface SecondaryButtonNotProps {
  secondaryButtonLabel?: never;
  onSecondaryButtonClick?: undefined;
  secondaryButtonProps?: undefined;
}

interface PrimaryButtonNotProps {
  primaryButtonLabel?: never;
  onPrimaryButtonClick?: undefined;
  primaryButtonProps?: undefined;
}

export type ActionControlProps = (
  | PrimaryButtonRequiredProps
  | PrimaryButtonNotProps
) &
  (SecondaryButtonRequiredProps | SecondaryButtonNotProps) & {
    loading?: boolean;
  };

function ActionControl(props: ActionControlProps) {
  const {
    onSecondaryButtonClick,
    onPrimaryButtonClick,
    primaryButtonLabel,
    secondaryButtonLabel,
    primaryButtonProps,
    secondaryButtonProps,
    loading = false,
  } = props;
  return (
    <Box
      width="100%"
      sx={{
        marginTop: "auto",
        position: "sticky",
        bottom: 0,
        zIndex: 1,
        backgroundColor: "var(--colorWhite)",
        boxShadow: "0px -4px 12px 0px #00000040",
        // borderRadius: "1.125rem 1.125rem 0px 0px",
        padding: {
          xs: "1.563rem",
          sm: "1.875rem 2.4375rem",
          xl: "3.625rem 1.563rem",
        },
        marginLeft: "-6rem",
        marginRight: "-6rem",
        width: "auto",
        paddingLeft: "6rem !important ",
        paddingRight: "6rem !important",
        gap: { xs: "1rem", sm: "2.188rem", md: "3.125rem", lg: "7.5rem" },
        flexDirection: { xs: "column-reverse", sm: "row" },
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {onSecondaryButtonClick && secondaryButtonLabel && (
        <Box
          sx={{
            display: "flex",
            flexGrow: "1",
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{ width: "100%", maxWidth: { sm: "23rem" } }}
            onClick={onSecondaryButtonClick}
            {...secondaryButtonProps}
          >
            {secondaryButtonLabel}
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexGrow: "1",
          justifyContent: { xs: "center", sm: "flex-end" },
        }}
      >
        {onPrimaryButtonClick && primaryButtonLabel && (
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            sx={{ width: "100%", maxWidth: { sm: "23rem" } }}
            onClick={onPrimaryButtonClick}
            {...primaryButtonProps}
            label={primaryButtonLabel}
            loading={loading}
          />
        )}
      </Box>
    </Box>
  );
}

export default ActionControl;
