import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { downgradeSubscription } from "./featureCenterService";
import { DowngradeSubResponse } from "./types";

type APIResponse = DowngradeSubResponse & FailedAPIResponse;

const useDowngradePlan = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, null>({
    mutationFn: downgradeSubscription,
    handler,
    resource: Resources.ERRORS,
  });
};

export default useDowngradePlan;
