import {
  isdCountrySchema,
  isdSchema,
  otpSchema,
  passwordSchema,
  phoneNumberSchema,
} from "utils/validationSchema";
import * as yup from "yup";
import Resources from "assets/json/Resources";

const {
  PAGES: {
    FORGOT_PASSWORD: { EMAIL_VERIFICATION, PHONE_NUMBER_VERIFICATION },
  },
} = Resources;

// eslint-disable-next-line import/prefer-default-export
export const resetPasswordSchema = yup.object({
  password: passwordSchema,
  confirmPassword: passwordSchema,
});

export const otpValidation = yup.object({
  otp: otpSchema,
});

export const HINT_MESSAGE: Record<string, any> = {
  PWB_TALENT_RESEND_COUNT_CHECK_SPAM:
    "Please check the spam/junk folder. Sometimes the message may end up in these folders.",
  PWB_TALENT_RESEND_COUNT_CHECK_QUARANTINE:
    "If this is a company email, please check your quarantine folder or contact your administrator.",
  PWB_TALENT_RESEND_COUNT_CLICK_CONTACT_SUPPORT:
    "Click on Contact Support and use the chat on our website for more assistance.",
  PWB_CHECK_IF_THE_EMAIL_IS_INCORRECT: "Please check if the email is correct.",
  PWB_CHECK_IF_THE_MOBILE_NUMBER_IS_INCORRECT:
    "Please check if the mobile number is correct.",
};

export const verificationTypeSchema = yup.object({
  chooseType: yup
    .string()
    .required("Please choose the type of verification to reset password "),
});

export const mobileVerificationSchema = yup.object({
  isdCountry: isdCountrySchema,
  isdCode: isdSchema,
  phoneNumber: phoneNumberSchema,
});

export type ChooseVerificationType = yup.InferType<
  typeof verificationTypeSchema
>;

export const OPTION_TYPE = {
  EMAIL: "email",
  MOBILE: "mobile",
};

export const verificationOption = [
  {
    label: PHONE_NUMBER_VERIFICATION,
    value: OPTION_TYPE.MOBILE,
  },
  {
    label: EMAIL_VERIFICATION,
    value: OPTION_TYPE.EMAIL,
  },
];
