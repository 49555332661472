/* eslint-disable react/jsx-props-no-spreading */
import Resources from "assets/json/Resources";
import { AlertDialog, Snackbar } from "components/common";
import { PAGE_KEYS, ROUTES } from "config/app";
import {
  Email,
  FailedAPIStatus,
  MobileNumber,
  SnackbarType,
} from "constants/sharedTypes";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import FTUELayout from "components/FTUELayout";
import { CircularButton, TitleField } from "components/ui";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useForgotPassword, {
  ForgotPasswordResponseType,
} from "services/useForgotPassword";
import useForgotPhoneOtp from "services/authServices/useForgetPhoneOtp";
import { ForgotPasswordPhoneResponseType } from "services/authServices/type";
import { useAuth } from "hooks";
import ForgotPasswordForm from "./forgotPasswordForm";
import ResetPasswordForm from "./resetPasswordForm";
import VerifyPassword from "./verifyPassword";
import MobileVerificationForm from "./mobileVerificationForm";
import ChooseVerification from "./VerificationPage/ChooseVerification";

const {
  CHOOSE_VERIFICATION,
  FORGOT_PASSWORD_EMAIL,
  FORGOT_PASSWORD_PHONE,
  RESET_PASSWORD,
  VERIFY_PASSWORD,
} = PAGE_KEYS;

function ForgotPassword() {
  const [currentForm, setCurrentForm] = useState(CHOOSE_VERIFICATION);
  const [isAccountLocked, setAccountLocked] = useState(false);
  const [currentData, setCurrentData] = useState(""); // email is required for reset password api
  const [otp, setOtp] = useState("");
  const [resendAttemptsLeft, setResendAttemptsLeft] = useState(0);
  const [resendPhoneOtp, setResendPhoneOtp] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [pageKey, setPageKey] = useState("");
  const [phoneEmail, setPhoneEmail] = useState("");
  const [isPhoneValidate, setIsPhoneValidate] = useState(false);

  const [currentPhone, setCurrentPhone] = useState<MobileNumber | null>(null);

  const { setGlobalAlert } = useAuth();

  const {
    ERRORS: { ACCOUNT_LOCKED, ACCOUNT_LOCKED_DESC },
    COMMON: {
      BUTTONS: { OK },
      TEXT: { FORGOT, PASSWORD, CREATE, NEW },
    },
    PAGES: {
      FORGOT_PASSWORD: {
        FORGOT_PASSWORD_SUBTEXT,
        FORGOT_PASSWORD_SUBTEXT_PHONE,
        RESET_PASSWORD_SUBTEXT_EMAIL,
        RESET_PASSWORD_SUBTEXT_PHONE,
        ENTER,
        CODE,
        EMAIL,
        PHONE_NUMBER,
      },
    },
  } = Resources;

  const navigate = useNavigate();

  // form initialization
  const methods = useForm({
    mode: "all",
  });

  const [snackbar, setSnackbar] = useState<SnackbarType>({
    open: false,
    message: "",
    type: "error",
  });

  const closeSnackbar = () =>
    setSnackbar({ ...snackbar, open: false, message: "" });

  const setErrorPopUp = (message: any) => {
    setSnackbar({ open: true, type: "error", message });
  };

  const handleAttemptLeft = (balance: number) => {
    setResendAttemptsLeft(balance);
  };

  const forgotPasswordOnSuccess = (result: ForgotPasswordResponseType) => {
    if (result.resendEmailOtpBalance === 0) {
      navigate(ROUTES.PRIVATE.COOL_DOWN);
    }
    setCurrentForm(RESET_PASSWORD);
  };

  const forgotPasswordPhoneOnSuccess = (
    response: ForgotPasswordPhoneResponseType,
  ) => {
    if (response?.result?.resendPhoneOtpBalance === 0) {
      navigate(ROUTES.PRIVATE.COOL_DOWN);
    }
    setCurrentForm(RESET_PASSWORD);
    setResendPhoneOtp(response?.result?.resendPhoneOtpBalance);
  };

  const forgotPassWordOnPhoneError = (error: FailedAPIStatus) => {
    if (error?.errorKey === "OTP_LIMIT_REACHED") {
      navigate(ROUTES.PRIVATE.COOL_DOWN);
    }

    setGlobalAlert({ message: error?.errorMessage, messageType: "error" });
  };

  const forgotPassWordOnError = (error: string) => {
    if (error === "OTP_LIMIT_REACHED") {
      navigate(ROUTES.PRIVATE.COOL_DOWN);
    }
  };

  const closeAlert = () => setAccountLocked(false);
  const { mutate: mutateForgotPassword, isLoading } = useForgotPassword({
    setErrorPopUp,
    setAccountLocked,
    setCurrentForm,
    currentForm,
    resendCountUpdate: handleAttemptLeft,
    onSuccess: forgotPasswordOnSuccess,
    onError: forgotPassWordOnError,
  });
  const { mutateAsync: forgotPhoneOtp } = useForgotPhoneOtp({
    onSuccess: forgotPasswordPhoneOnSuccess,
    onError: forgotPassWordOnPhoneError,
  });
  const sendEmailOTPHandler = (userData: Email) => {
    const { email } = userData;
    setCurrentData(email);
    mutateForgotPassword({ email });
  };

  const sendPhoneOTPHandler = async (userPhoneData: MobileNumber) => {
    const { phoneNumber, isdCode, isdCountry } = userPhoneData;
    setCurrentPhone(userPhoneData as MobileNumber);

    await forgotPhoneOtp({ isdCode, isdCountry, phoneNumber });
  };

  const onOtpVerified = (OTP: string) => {
    setOtp(OTP);
    setCurrentForm(VERIFY_PASSWORD);
  };

  const handleEditClick = () => {
    if (selectedOption === "email") {
      setCurrentForm(FORGOT_PASSWORD_EMAIL);
    } else if (selectedOption === "mobile") {
      setCurrentForm(FORGOT_PASSWORD_PHONE);
    }
  };

  const handleSelectOption = (option: string) => {
    setSelectedOption(option);
    if (option === "email") {
      setPageKey("FORGOT_PASSWORD_EMAIL");
    } else if (option === "mobile") {
      setPageKey("FORGOT_PASSWORD_PHONE");
    }
  };

  const setNextPage = (form: string) => {
    if (form === "email") {
      setCurrentForm(FORGOT_PASSWORD_EMAIL);
    } else if (form === "mobile") {
      setCurrentForm(FORGOT_PASSWORD_PHONE);
    }
  };

  const handleBack = () => {
    setCurrentForm(CHOOSE_VERIFICATION);
    setCurrentPhone(null);
    methods.setValue("email", "");
  };

  const renderForm = () => {
    switch (currentForm) {
      case CHOOSE_VERIFICATION: {
        return (
          <ChooseVerification
            onSelectOption={handleSelectOption}
            setCurrentForm={setNextPage}
            pageKey={pageKey}
          />
        );
      }

      case FORGOT_PASSWORD_EMAIL:
        return (
          <ForgotPasswordForm
            sendOTPHandler={sendEmailOTPHandler}
            isCodeSendLoading={isLoading}
            onBack={handleBack}
          />
        );
      case FORGOT_PASSWORD_PHONE:
        return (
          <MobileVerificationForm
            sendPhoneOTPHandler={sendPhoneOTPHandler}
            isCodeSendLoading={isLoading}
            currentPhone={currentPhone as MobileNumber}
            onBack={handleBack}
          />
        );
      case RESET_PASSWORD:
        return (
          <ResetPasswordForm
            setErrorPopUp={setErrorPopUp}
            currentEmail={currentData}
            currentPhone={currentPhone as MobileNumber}
            onVerifiedOtp={onOtpVerified}
            setAccountLocked={setAccountLocked}
            setCurrentForm={setCurrentForm}
            currentForm={currentForm}
            resendAttemptsLeft={resendAttemptsLeft}
            resendAttemptsLeftPhone={resendPhoneOtp}
            setPhoneEmail={setPhoneEmail}
            setIsPhoneValidate={setIsPhoneValidate}
          />
        );
      case VERIFY_PASSWORD:
        return (
          <VerifyPassword
            setErrorPopUp={setErrorPopUp}
            currentEmail={currentData}
            otp={otp}
            setAccountLocked={setAccountLocked}
            phoneEmail={phoneEmail}
            isPhoneValidated={isPhoneValidate}
          />
        );

      default:
        return null;
    }
  };

  return (
    <FTUELayout containerStyle={{ maxWidth: { xl: "80%" } }}>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.type}
        onClose={closeSnackbar}
        disableClickAway
      />
      <Box>
        {currentForm === FORGOT_PASSWORD_EMAIL && (
          <TitleField title1={FORGOT} title2={PASSWORD} />
        )}
        {currentForm === FORGOT_PASSWORD_PHONE && (
          <TitleField title1={FORGOT} title2={PASSWORD} />
        )}
        {currentForm === CHOOSE_VERIFICATION && (
          <TitleField title1={FORGOT} title2={PASSWORD} />
        )}
        {currentForm === RESET_PASSWORD && (
          <TitleField title1={ENTER} title2={CODE} />
        )}
        {currentForm === VERIFY_PASSWORD && (
          <TitleField title1={CREATE} title2={NEW} />
        )}
        <Box sx={{ mb: { md: "3rem" } }}>
          <Box
            sx={{
              mb: "3rem",
              width: "30.667rem",
              lineHeight: "1.68rem",
              marginBottom: "30px",
            }}
          >
            <Typography>
              {currentForm === FORGOT_PASSWORD_EMAIL && (
                <Typography
                  component="p"
                  sx={{
                    fontSize: "1.4rem !important",
                    fontFamily: "var(--font-primary-500)",
                    color: "var(--colorTextSecondary)",
                    fontWeight: "500",
                    marginBottom: "3rem",
                  }}
                >
                  {FORGOT_PASSWORD_SUBTEXT}
                </Typography>
              )}
              {currentForm === FORGOT_PASSWORD_PHONE && (
                <Typography
                  component="p"
                  sx={{
                    fontSize: "1.4rem !important",
                    fontFamily: "var(--font-primary-500)",
                    color: "var(--colorTextSecondary)",
                    fontWeight: "500",
                    marginBottom: "3rem",
                  }}
                >
                  {FORGOT_PASSWORD_SUBTEXT_PHONE}
                </Typography>
              )}
            </Typography>
            {currentForm === RESET_PASSWORD && (
              <>
                <Typography
                  component="p"
                  sx={{
                    fontSize: "1.4rem !important",
                    fontFamily: "var(--font-primary-500)",
                    color: "var(--colorTextSecondary)",
                    fontWeight: "400",
                    lineHeight: "1.2",
                    marginBottom: "3rem",
                  }}
                >
                  {selectedOption === "email" && RESET_PASSWORD_SUBTEXT_EMAIL}
                  {selectedOption === "mobile" && RESET_PASSWORD_SUBTEXT_PHONE}
                </Typography>
                <Box>
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "1.4rem !important",
                      fontFamily: "var(--font-primary-500)",
                      color: "var(--colorTextSecondary)",
                      fontWeight: "400",
                      lineHeight: "1.2",
                      marginBottom: "1.5rem",
                    }}
                  >
                    {selectedOption === "email" && EMAIL}
                    {selectedOption === "mobile" && PHONE_NUMBER}
                  </Typography>
                  <Box
                    sx={{
                      maxWidth: "36.7rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1.5rem",
                        marginBottom: "4rem",
                      }}
                    >
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "2.2rem !important",
                          fontFamily: "var(--font-primary-500)",
                          color: "var(--colorTextPrimary)",
                          fontWeight: "500",
                          lineHeight: "1.2",
                        }}
                      >
                        {selectedOption === "email" &&
                          currentData.toLowerCase()}

                        {selectedOption === "mobile" &&
                          `+${currentPhone?.isdCode.replace("+", "")} - ${
                            currentPhone?.phoneNumber
                          }`}
                      </Typography>
                      <CircularButton
                        iconName="edit"
                        onClick={handleEditClick}
                        disabled={isLoading}
                        sx={{
                          minWidth: "2.4rem",
                          minHeight: "2.4rem",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>

          <AlertDialog
            open={isAccountLocked}
            title={ACCOUNT_LOCKED}
            content={ACCOUNT_LOCKED_DESC}
            handleClose={closeAlert}
            primaryButton={OK}
            primaryButtonAction={closeAlert}
          />
          <Box sx={{ maxWidth: "36rem" }}>
            <FormProvider {...methods}>{renderForm()}</FormProvider>
          </Box>
        </Box>
      </Box>
    </FTUELayout>
  );
}

export default ForgotPassword;
