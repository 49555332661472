import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SVGIcon from "components/icons";
import { Link } from "react-router-dom";
import { SupportNavProps } from "./types";

function SidebarItem(props: SupportNavProps) {
  const {
    IconComponent,
    text,
    activeClassName = "",
    textProps,
    disabled,
    warning,
    onLinkClick,
    id,
  } = props;
  return (
    <ListItem
      disablePadding
      className={`${activeClassName} sidebar_link`}
      onClick={onLinkClick}
      data-tutorial-item={id}
    >
      <ListItemButton disabled={disabled}>
        <ListItemIcon
          {...(!text && {
            sx: {
              width: "13.5rem !important",
              height: "3.5rem !important",
            },
          })}
        >
          {IconComponent}
        </ListItemIcon>
        <ListItemText disableTypography primary={text} {...textProps} />
        <Box
          sx={{
            width: "4rem",
            height: "4rem",
            alignSelf: "center",
            marginRight: "3rem",
            "& svg": {
              width: "100%",
              height: "100%",
            },
          }}
        >
          {warning && <SVGIcon name="alert" />}
        </Box>
      </ListItemButton>
    </ListItem>
  );
}

function SidebarLinkItem(props: SupportNavProps) {
  const { link = "", external, onLinkClick, ...rest } = props;

  return (
    <Link
      to={link}
      target={external ? "_blank" : ""}
      onClick={onLinkClick}
      rel="noopener noreferrer"
      className="sidebar_link"
    >
      <SidebarItem {...rest} />
    </Link>
  );
}

function SidebarLink(props: SupportNavProps) {
  const { link, ...rest } = props;

  return link ? (
    <SidebarLinkItem link={link} {...rest} />
  ) : (
    <SidebarItem {...rest} />
  );
}

export default SidebarLink;
