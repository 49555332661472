import Resources from "assets/json/Resources";
import requests from "config/api";
import API_ENDPOINTS from "constants/api";
import useAuth from "hooks/useAuth";
import { useMutation } from "react-query";
import { ResetPasswordKeys } from "../constants/sharedTypes";

type ResetPassword = {
  setErrorPopUp: (message: string) => void;
  setAccountLocked: (locked: boolean) => void;
};

export const resetPasswordRequest = async (payload: { email: string }) => {
  return requests.post(API_ENDPOINTS.RESET_PASSWORD, payload);
};

const useResetPassword = ({
  setErrorPopUp,
  setAccountLocked,
}: ResetPassword) => {
  const { logout, setGlobalAlert } = useAuth();
  const { ERRORS } = Resources;
  const { PWB_INVALID_OTP_EMAIL, SOMETHING } = ERRORS;
  return useMutation(
    async (payload: ResetPasswordKeys) => {
      const response = await resetPasswordRequest(payload);
      if (
        response &&
        response.status === 428 &&
        response.result &&
        response.result.success
      ) {
        setGlobalAlert({
          message: Resources.PAGES.FORGOT_PASSWORD.RESET_SUCCESSFUL,
          messageType: "success",
        });
        logout();
      } else if (!response.success && response.accountLocked) {
        setAccountLocked(true);
      } else {
        setErrorPopUp(PWB_INVALID_OTP_EMAIL);
      }
      return response;
    },
    {
      onError: () => {
        setErrorPopUp(SOMETHING);
      },
    },
  );
};

export default useResetPassword;
