import { CircularProgress } from "@mui/material";
import Button, { ButtonProps as MuiBtnProps } from "@mui/material/Button";
import styles from "./styles.module.scss";

type ButtonProps = {
  label: string;
  loading: boolean;
  disabled?: boolean;
} & MuiBtnProps;

function LoadingButton(props: ButtonProps) {
  const { label, loading, disabled, ...rest } = props;

  return (
    <Button disabled={loading || disabled} {...rest}>
      {label}
      {loading && (
        <div className={`${styles.loader} input-loader`}>
          <CircularProgress size={20} />
        </div>
      )}
    </Button>
  );
}

export default LoadingButton;
LoadingButton.defaultProps = {
  disabled: false,
};
