export const featureOptionConfig: Record<string, string> = {
  coachingSession: "Guidance Sessions",
  events: "Events",
  oadSurvey: "AI Attribute Assessment by Survey ",
  faceCode: "AI Attribute Assessment by Photo",
  membershipLevel: "Preferred Membership",
};

export const HOW_CAN_WE_HELP_OPTIONS: Record<string, string> = {
  OPTION1: "Get matched with jobs as soon as possible",
  OPTION2: "Reach your career and income goals",
  OPTION3: "Launch your work-life for the first time.",
};

export const accessCodeDiscountItemLabel: Record<string, string> = {
  FACE_CODE: "Face Code",
  OAD_SURVEY: "OAD Survey",
  ATTRIBUTE_ASSESSMENT: "Attribute Assessment",
  BACKGROUND_CHECK: "Background Check",
  COACHING_SESSION: "Coaching Session",
  DRIVING_LICENSE_VALIDATION: "Driver License Validation",
  PREFERRED_PROFILE_FEATURES: "Preferred Profile Features",
  SKILL_ASSESSMENT: "Skill Assessments",
  ACCESS_CODE_ACTIVATION: "Access Code Redemption",
  VIRTUAL_REALITY_JOB_TOUR: "Virtual Reality Job Tour",
  ON_DEMAND_ADVICE_CHAT: "On-Demand Advice Chat",
  EVENTS: "Events",
  CO_WORKING_SPACE: "Co-working Office Space",
  TRANSPORTATION_STIPEND: "Transportation Stipend",
  WORK_READINESS_CONTENT: "Work Readiness Content",
  SPECIFIC_EDUCATOR: "Specific Educator",
};
