import { Box, Typography } from "@mui/material";
import SVGIcon from "components/icons";
import { APP_LINKS } from "config/app";
import { Link } from "react-router-dom";

type TitleProps = {
  text: string;
};

export function AppLinks() {
  return (
    <Box sx={{ display: "flex", gap: "3rem", mt: "auto" }}>
      {APP_LINKS.map((link) => (
        <Link to={link.link} key={link.key} target="_blank">
          <SVGIcon name={link.icon} />
        </Link>
      ))}
    </Box>
  );
}

export function Title(props: TitleProps) {
  const { text } = props;
  return (
    <Typography
      variant="body2"
      sx={{
        fontSize: { xs: "1.4rem !important", lg: "1.8rem !important" },
      }}
      fontWeight="bold"
    >
      {text}
    </Typography>
  );
}
