import defaultImage from "assets/images/defaultImage.png";
import { SIDEBAR_TOP_MENUS, SubmenuType } from "config/app";
import { useAuth } from "hooks";
import useSidebar from "hooks/useSidebar";
import { useMemo } from "react";
import useUserProfileDetails from "services/useGetUserProfileDetails";
import triggerGAEvent from "utils/gaEvents";
import { useGetAccountProfile } from "services/accountProfileService";
import Sidebar from ".";

const ON_BEHALF_PARENT_INDEX = 2;
const ACCOUNT_PARENT_INDEX = 0;
const DELETE_COUNT = 1;

function SidebarContent() {
  const { logout, user } = useAuth();
  const { data } = useUserProfileDetails();
  const { setActiveMenu } = useSidebar();
  const { data: userInfo } = useGetAccountProfile();

  const userName = data?.name ?? "";
  const profileImg = data?.profileImageURL ?? defaultImage;
  const isOnBehalfEnabled = data?.canUseOnBehalf ?? false;
  const hasZipCode = data?.zipCode ?? true;
  const isProfileCompleted = userInfo?.result?.home?.accountProfile ?? true;

  const menus = useMemo(() => {
    const mainMenu = [...SIDEBAR_TOP_MENUS];

    // remove onBehalf
    if (!isOnBehalfEnabled) {
      const services = mainMenu[ON_BEHALF_PARENT_INDEX];

      const updatedServices = services.subMenu.filter(
        (menu) => menu.key !== "onBehalf",
      );

      // replace onBehalf menus back to parent array in the same index
      mainMenu.splice(ON_BEHALF_PARENT_INDEX, DELETE_COUNT, {
        ...services,
        subMenu: updatedServices,
      });
    }

    // if no zip code added, then show a warning
    if (!hasZipCode || !isProfileCompleted) {
      const parent = mainMenu[ACCOUNT_PARENT_INDEX];

      const accountSettings = parent.subMenu.map((menu) => {
        if (menu.key === "account") {
          return { ...menu, warning: true };
        }
        return menu;
      });

      // replace account menus back to parent array in the same index
      mainMenu.splice(ACCOUNT_PARENT_INDEX, DELETE_COUNT, {
        ...parent,
        subMenu: accountSettings,
      });
    }

    if (!isOnBehalfEnabled || !hasZipCode || !isProfileCompleted) {
      return mainMenu;
    }

    return SIDEBAR_TOP_MENUS;
  }, [isOnBehalfEnabled, hasZipCode, isProfileCompleted]);

  const onLinkClick = (item: SubmenuType) => {
    if (item.key === "logout") {
      logout();
      triggerGAEvent("logout", user?.userId as string);
    }

    if (
      item.key !== "appStore" &&
      item.key !== "playStore" &&
      item.key !== "logout"
    ) {
      setActiveMenu(item.key);
    }
  };

  return (
    <Sidebar
      menuList={menus}
      onLinkItemClick={onLinkClick}
      userInfo={{ image: profileImg, name: userName }}
    />
  );
}

export default SidebarContent;
