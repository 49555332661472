import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Resources from "assets/json/Resources";
import { Breadcrumb } from "components/common";
import SVGIcon from "components/icons";
import { ROUTES } from "config/app";
import { useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import usePaymentUsage, { PurchaseDetailType } from "services/usePaymentUsage";
import { formatCurrency, transformDate } from "utils";

const breadcrumbs = {
  subscriptionInfo: [
    {
      text: "Payment Center",
      active: true,
      pageLink: ROUTES.PRIVATE.PAYMENT_CENTER,
    },
    { text: "Usage" },
  ],
};

const {
  PAGES: { PAYMENT_CENTER },
} = Resources;

function PaymentUsage() {
  const navigate = useNavigate();
  const { paymentId } = useParams();
  const [usageSummary, setUsageSummary] = useState<PurchaseDetailType[]>([]);

  const onViewUsageSuccess = (details: PurchaseDetailType[]) => {
    setUsageSummary(details);
  };

  const { mutateAsync: getCardUsage, isLoading: isUsageLoading } =
    usePaymentUsage({
      onSuccess: onViewUsageSuccess,
    });

  const totalAmount = useMemo(() => {
    return usageSummary?.reduce((acc, curValue) => acc + curValue.amount, 0);
  }, [usageSummary]);

  useEffect(() => {
    if (!usageSummary.length && paymentId) getCardUsage(paymentId);
  }, []);

  if (!paymentId) {
    return <Navigate to={ROUTES.PRIVATE.PAYMENT_CENTER} />;
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box className="app-l-container" sx={{ paddingBottom: "6rem !important" }}>
      {isUsageLoading ? (
        <Box sx={{ display: "grid", placeItems: "center", flexGrow: 1 }}>
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          <Breadcrumb crumbs={breadcrumbs.subscriptionInfo} />
          <Box
            sx={{
              marginTop: "4.8rem",
            }}
          >
            <Card
              sx={{
                padding: "2.4rem 3rem",
                background: "var(--colorWhite)",
                boxShadow: "0px 3px 20px #0000001a",
                borderRadius: "1.8rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2.6rem",
                }}
              >
                <Box
                  sx={{
                    flexShrink: 0,
                    marginRight: "1.2rem",
                  }}
                >
                  <IconButton
                    size="small"
                    aria-label="close"
                    sx={{ color: "var(--color-secondary)" }}
                    onClick={handleBack}
                  >
                    <SVGIcon name="angle-left" />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    minWidth: 0,
                    flexGrow: 1,
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      fontSize: {
                        xs: "1.6rem !important",
                        md: "2rem !important",
                      },
                      marginBottom: "0",
                      color: "var(--colorTextPrimary)",
                      fontFamily: "var( --font-primary-700)",
                    }}
                  >
                    {PAYMENT_CENTER.USAGE_TITLE}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <TableContainer
                  sx={{
                    maxHeight: {
                      xs: "60svh",
                      md: "70svh",
                    },
                  }}
                >
                  <Table
                    className="app-c-grid--primary app-c-grid--primary-large"
                    stickyHeader
                    sx={{ minWidth: "80rem", overflowY: "auto" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>{PAYMENT_CENTER.USAGE_TYPE}</TableCell>
                        <TableCell>{PAYMENT_CENTER.NAME}</TableCell>
                        <TableCell>{PAYMENT_CENTER.COUNT}</TableCell>
                        <TableCell>{PAYMENT_CENTER.PURCHASE_DATE}</TableCell>
                        <TableCell align="center">
                          {PAYMENT_CENTER.BILLABLE_AMOUNT}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {usageSummary.length ? (
                      <TableBody>
                        {usageSummary.map((summary) => (
                          <TableRow key={summary.purchaseddate}>
                            <TableCell>{summary.purchasetype}</TableCell>
                            <TableCell>{summary.featuretitle}</TableCell>
                            <TableCell width={150}>{summary.count}</TableCell>
                            <TableCell>
                              {transformDate(summary.purchaseddate, {
                                month: "short",
                                day: "numeric",
                                year: "numeric",
                              })}
                            </TableCell>
                            <TableCell width={150} align="center">
                              {formatCurrency(summary.amount, 2)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={5} sx={{ border: "none" }}>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "1.6rem !important",
                                  md: "2rem !important",
                                },
                                fontFamily: "var(--font-primary-700)",
                                color: "var(--color-secondary)",
                                textAlign: "center",
                                marginTop: "3rem",
                              }}
                            >
                              No data available
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
              {usageSummary.length ? (
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.6rem !important",
                      md: "2rem !important",
                    },
                    fontFamily: "var(--font-primary-700)",
                    color: "var(--color-secondary)",
                    textAlign: "right",
                    marginTop: "3rem",
                    paddingRight: "3rem",
                  }}
                >
                  {Resources.PAGES.PAYMENT_CENTER.TOTAL_AMOUNT_BILLED} :{" "}
                  {formatCurrency(totalAmount, 2)}
                </Typography>
              ) : null}
            </Card>
          </Box>
        </>
      )}
    </Box>
  );
}

export default PaymentUsage;
