import { Box, Fade } from "@mui/material";
import { ConfirmDialog } from "components";
import { Breadcrumb } from "components/common";
import { ROUTES } from "config/app";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { changePhoneNumber } from "utils/validationSchema";
import ChangePhoneForm from "./ChangePhoneForm";

export type FormField = keyof typeof changePhoneNumber.fields;

const breadcrumbs = [
  {
    text: "Account Settings",
    active: true,
    pageLink: ROUTES.PRIVATE.ACCOUNT_PROFILE,
  },
  { text: "Change Mobile Number" },
];

function ChangePhone() {
  const navigate = useNavigate();

  const [abortPhoneVerification, setAbortPhoneVerification] = useState(false);

  const handleBack = () => navigate(ROUTES.PRIVATE.ACCOUNT_PROFILE);

  const continuePhoneVerification = () => setAbortPhoneVerification(false);
  const terminatePhoneVerification = () => setAbortPhoneVerification(true);

  return (
    <Box
      className="app-l-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: "3rem !important",
        gap: "4.5rem",
      }}
    >
      <Breadcrumb crumbs={breadcrumbs} />
      {abortPhoneVerification && (
        <ConfirmDialog
          heading="Are you sure you want to cancel?"
          onCancel={continuePhoneVerification}
          onConfirm={handleBack}
          primaryButtonLabel="Yes"
          secondaryButtonLabel="No"
        />
      )}
      <Fade in={!abortPhoneVerification} appear={false} exit={false} easing="0">
        <Box
          sx={{ display: abortPhoneVerification ? "none" : "flex", flex: 1 }}
        >
          <ChangePhoneForm
            onCancel={handleBack}
            onAbort={terminatePhoneVerification}
          />
        </Box>
      </Fade>
    </Box>
  );
}

export default ChangePhone;
