import { REACT_QUERY_KEYS } from "config/app";
import { useQuery } from "react-query";
import { getPaymentMethods } from "./PaymentCenterService";

export type PaymentType = {
  paymentMethodId: string;
  status: string | null;
  nickName: string;
  paymentMethod: string;
};

export type PlaidType = {
  publicKey: string;
  env: string;
  clientName: string;
  productType: string;
  method: string;
};

type StripeType = {
  pApiKey: string;
  sApiKey: string;
  method: string;
};

type PaymentDetailsType = {
  payments: PaymentType[];
  paymentCenter: {
    plaid: PlaidType;
    stripe: StripeType;
  };
};

export type PaymentMethodResponseType = {
  status: number;
  result: {
    userid: string;
    paymentDetails: PaymentDetailsType;
  };
};

type QueryOptions = {
  onSuccess?: (data: PaymentMethodResponseType) => void;
  onError?: () => void;
  enabled?: boolean;
};

const useGetPaymentMethods = (options: QueryOptions) =>
  useQuery<PaymentMethodResponseType, Error>(
    REACT_QUERY_KEYS.PAYMENT_METHODS,
    getPaymentMethods,
    {
      onSuccess: options?.onSuccess,
      onError: options?.onError,
      enabled: options.enabled,
      // refetchOnWindowFocus: false,
      // staleTime: 10 * 1000,
    },
  );

export default useGetPaymentMethods;
