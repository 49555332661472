import Resources from "assets/json/Resources";
import { ErrorKeysType } from "constants/sharedTypes";
import { useMutation } from "react-query";
import { setGuardianConsent } from "./signUpServices";

type HandlerType = {
  onSuccess?: (data: SuccessResponseType) => void;
  onError: (errorMsg: string) => void;
};

type SuccessResponseType = {
  status: number;
  result: {
    success: boolean;
  };
};

type FailedResponse = {
  status: number;
  inputErrors?: {
    exists: boolean;
  };
  appErrors?: {
    exists: boolean;
    errors: string[];
  };
};

type APIResponseType = SuccessResponseType & FailedResponse;

const useGuardianConsent = (handlers?: HandlerType) => {
  const handleError = (error: FailedResponse) => {
    let errorMessage = Resources.ERRORS.SOMETHING;
    if (error.appErrors?.errors[0]) {
      errorMessage =
        Resources.ERRORS[error.appErrors?.errors[0] as ErrorKeysType] ??
        Resources.ERRORS.REGISTER_ERROR;
    }

    handlers?.onError(errorMessage);
  };

  const handleSuccess = (data: APIResponseType) => {
    if (data?.inputErrors?.exists || data?.appErrors?.exists) {
      handleError(data);
      return;
    }
    handlers?.onSuccess?.(data);
  };

  return useMutation(setGuardianConsent, {
    onSuccess: handleSuccess,
    onError: handleError,
  });
};

export default useGuardianConsent;
