import resources from "assets/json/Resources";
import { REACT_QUERY_KEYS, TimeConfig } from "config/app";
import { useAPIErrorHandler } from "hooks";
import { useQuery } from "react-query";
import { FailedAPIResponse, HandlerType } from "../commonTypes";
import { getAccessCodes } from "./accessCodeService";
import { AccessCodeResponseType } from "./types";

const useGetAccessCodesList = (handler?: HandlerType) => {
  const { checkError, getErrorInfo } = useAPIErrorHandler(resources.ERRORS);

  const onRejected = (errorResponse: FailedAPIResponse) => {
    const { errorMessage, errorField, errorKey } = getErrorInfo(errorResponse);
    handler?.onError?.({ errorMessage, errorField, errorKey });
  };

  const onFulFilled = (
    response: AccessCodeResponseType & FailedAPIResponse,
  ) => {
    const hasError = checkError(response);
    if (hasError || response?.status !== 200) {
      onRejected(response);
    }
    handler?.onSuccess?.(response);
  };

  return useQuery(REACT_QUERY_KEYS.ACCESS_CODES, getAccessCodes, {
    cacheTime: TimeConfig.ONE_MINUTE,
    staleTime: TimeConfig.ONE_HOUR,
    onSuccess: onFulFilled,
    onError: onRejected,
  });
};

export default useGetAccessCodesList;
