/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TableHeaderType } from "constants/sharedTypes";
import { Children, ReactNode } from "react";
import styles from "./styles.module.scss";

type TableProps = {
  headerData: TableHeaderType[];
  children: ReactNode;
  loading?: boolean;
  noDataMessage?: ReactNode;
};

function PWTable(props: TableProps) {
  const { headerData, children, noDataMessage, loading } = props;

  const renderNoContent = () => (
    <TableRow>
      <TableCell colSpan={headerData.length} align="center">
        <Typography variant="body2">{noDataMessage}</Typography>
      </TableCell>
    </TableRow>
  );

  const renderLoadingRow = () => (
    <TableRow>
      <TableCell colSpan={headerData.length} align="center">
        <CircularProgress size={20} />
      </TableCell>
    </TableRow>
  );

  const renderChildren = () => {
    if (Children.count(children)) {
      return children;
    }
    return renderNoContent();
  };

  return (
    <TableContainer className="app-l-grid-wrap">
      <Table
        className="app-c-grid--primary"
        sx={{ minWidth: 650 }}
        aria-label="simple table"
      >
        <TableHead className={styles.tableHeader}>
          <TableRow>
            {headerData.map((header) => (
              <TableCell
                className={header?.className ? styles[header?.className] : ""}
                width={header.width}
                key={header.key}
                align={header.align ?? "left"}
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{loading ? renderLoadingRow() : renderChildren()}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default PWTable;

PWTable.defaultProps = {
  loading: false,
  noDataMessage: "No data available",
};
