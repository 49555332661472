import { useMutation, UseMutationOptions } from "react-query";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import useAPIErrorHandler from "./useAPIErrorHandler";

type APIResponse<T> = T & FailedAPIResponse;
type QueryOptions = Omit<
  UseMutationOptions,
  "onSuccess" | "onError" | "mutationFn"
>;

type Options<T, U> = {
  mutationFn: (payload: U) => Promise<APIResponse<T>>;
  resource: Record<string, any>;
  handler?: HandlerType;
  successCodes?: number[];
  queryOptions?: QueryOptions;
  defaultErrorMessage?: string;
};

const useMutationWithHandler = <T, U = {}>(options: Options<T, U>) => {
  const {
    mutationFn,
    resource,
    handler,
    queryOptions,
    defaultErrorMessage,
    successCodes = [200],
  } = options;
  const { checkError, getErrorInfo } = useAPIErrorHandler(
    resource,
    defaultErrorMessage,
  );

  const onRejected = (error: FailedAPIResponse) => {
    const { errorMessage, errorField, errorKey } = getErrorInfo(error);
    handler?.onError?.({ errorMessage, errorField, errorKey });
  };

  const onFulfilled = (res: APIResponse<T>) => {
    const hasError = checkError(res);
    if (hasError || !successCodes.includes(res?.status as number)) {
      onRejected(res);
      return;
    }
    handler?.onSuccess?.(res);
  };

  return useMutation(mutationFn, {
    onSuccess: onFulfilled,
    onError: onRejected,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(queryOptions as any),
  });
};

export default useMutationWithHandler;
