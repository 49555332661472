import { Box, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import { ConfirmDialog } from "components";
import { Breadcrumb } from "components/common";
import { ROUTES, coachingSessionMandatoryItems } from "config/app";
import { useAuth } from "hooks";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useRedeemAccessCode from "services/useRedeemAccessCode";

function AccessCodeConfirmation() {
  const { setGlobalAlert } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const breadCrumbs = useMemo(() => {
    return [
      {
        text: location?.state?.text,
        active: true,
        pageLink: location?.state?.pageLink,
      },
    ];
  }, [location?.state?.text, location?.state?.pageLink]);

  const accessCode = useMemo(() => {
    return location?.state?.accessCode as string;
  }, [location?.state?.accessCode]);

  const handleBack = () => {
    navigate(-1);
  };

  const onRedeemAccessCodeError = (error: string) => {
    setGlobalAlert({
      message: error,
      messageType: "error",
    });
    handleBack();
  };

  const onRedeemAccessCodeSuccess = (incompleteSection: string) => {
    setGlobalAlert({
      message: Resources.PAGES.PAYMENT_CENTER.ACCESS_CODE.ADD_SUCCESS,
      messageType: "success",
    });
    if (coachingSessionMandatoryItems.includes(incompleteSection)) {
      window.location.replace(ROUTES.PUBLIC.SIGN_UP);
    }
    handleBack();
  };

  const { mutateAsync: redeemAccessCodeData } = useRedeemAccessCode({
    onError: onRedeemAccessCodeError,
    onSuccess: onRedeemAccessCodeSuccess,
  });

  const redeemAccessCode = async () => {
    await redeemAccessCodeData(accessCode);
  };

  useEffect(() => {
    if (!location?.state) {
      navigate(ROUTES.PRIVATE.HOME);
    }
  }, [location]);

  return (
    <Box
      className="app-l-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: "3rem !important",
        gap: "4.5rem",
      }}
    >
      <Breadcrumb
        crumbs={[...breadCrumbs, { text: "Activate Access Code" || "" }]}
      />
      <ConfirmDialog
        heading={`Confirm Access Code Activation [${accessCode}] `}
        onCancel={handleBack}
        onConfirm={redeemAccessCode}
        primaryButtonLabel="Proceed"
        secondaryButtonLabel="Cancel"
      >
        <Typography>
          {Resources.PAGES.HOME.ACCESS_CODE_CONFIRMATION_DESC}
        </Typography>
      </ConfirmDialog>
    </Box>
  );
}

export default AccessCodeConfirmation;
