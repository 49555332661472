import { TableHeaderType } from "constants/sharedTypes";
import { addPublicAccessCodeSchema } from "utils/validationSchema";
import * as yup from "yup";

export const breadcrumbs = [{ text: "Access Code" }];

export type DataType = {
  accessCodeName: string;
  sponsorName: string;
  accessCodeStatus: string;
  assignedDate: string;
  redeemedDate: string;
  expiryDate: string;
  description?: string;
};

export const accessCodeTableHeaders: TableHeaderType[] = [
  { key: "name", label: "Name", className: "tableHeading" },
  { key: "sponsor", label: "Sponsor", className: "tableHeading" },
  { key: "status", label: "Status", className: "tableHeading" },
  {
    key: "assignedDate",
    label: "Assigned date",
    className: "tableHeadingCenter",
  },
  {
    key: "actionDate",
    label: "Activation date",
    className: "tableHeadingCenter",
  },
  {
    key: "expiryDate",
    label: "Expiry date",
    className: "tableHeadingCenter",
  },
  { key: "action", label: "Action", className: "tableHeadingCenter" },
];

export const featureTableHeaders: TableHeaderType[] = [
  {
    key: "feature",
    label: "Feature",
    className: "detailTableHeading",
    align: "left",
  },
  {
    key: "count",
    label: "Count",
    className: "detailTableHeading",
    align: "left",
  },
  {
    key: "used",
    label: "Used",
    className: "detailTableHeading",
    align: "left",
  },
  {
    key: "available",
    label: "Available to use",
    className: "detailTableHeading",
    align: "left",
  },
];

export type TalentAccessCodeStatusType =
  | "ACTIVE"
  | "AVAILABLE_TO_USE"
  | "UTILIZED"
  | "EXPIRED"
  | "IN_ACTIVE"
  | "REQUESTED"
  | "REJECTED";

export const talentAccessCodeKey = {
  ACTIVE: "ACTIVE",
  AVAILABLE_TO_USE: "AVAILABLE_TO_USE",
  UTILIZED: "UTILIZED",
  EXPIRED: "EXPIRED",
  IN_ACTIVE: "IN_ACTIVE",
  REQUESTED: "REQUESTED",
  REJECTED: "REJECTED",
};

export const talentAccessCodeStatus = {
  ACTIVE: "Active and in use",
  AVAILABLE_TO_USE: "Available to use",
  UTILIZED: "Utilized",
  EXPIRED: "Expired",
  IN_ACTIVE: "Inactive",
  REQUESTED: "Approval pending",
  REJECTED: "Access denied",
};

export const talentAccessCodeStatusDesc = {
  ACTIVE: "Active",
  AVAILABLE_TO_USE: "Available for use",
  UTILIZED: "This access code has been utilized",
  EXPIRED: "This access code has expired",
  IN_ACTIVE: "This access code is inactive",
  REQUESTED: "Awaiting approval from the access code provider.",
  REJECTED: "N/A",
};

export type AddPublicAccessCodeFormType = yup.InferType<
  typeof addPublicAccessCodeSchema
>;
