import { Box, Typography } from "@mui/material";
import SVGIcon from "components/icons";
import {
  APP_LINKS,
  INTERVIEW,
  INTERVIEW_TYPE,
  INTERVIEW_TYPE_BUTTON,
  ROUTES,
  SESSION_TYPE,
} from "config/app";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useSetTimeZoneDetails from "services/common/useSetTimeZoneDetails";
import useGetYourActivites from "services/home/useGetYourActivites";
import { openURL } from "utils";
import { formatUTCDateToLocal, getTimeZone } from "utils/dateUtils";
import ActivitiesCardOuter from "./ActivitiesCardOuter";
import TextValue from "./TextValue";
import styles from "./styles.module.scss";

function Activities() {
  const navigate = useNavigate();
  const [timeZoneAbbr, setTimeZoneAbbr] = useState<string>("");

  const timeZone = getTimeZone();

  const { mutateAsync: getTimeZoneInfo } = useSetTimeZoneDetails({});

  const getTimeZoneData = async () => {
    if (timeZone) {
      const response = await getTimeZoneInfo({
        timeZoneId: timeZone,
      });
      if (response?.result?.timezoneDetails?.timeZoneAbbr) {
        setTimeZoneAbbr(response?.result?.timezoneDetails.timeZoneAbbr);
      }
    }
  };

  useEffect(() => {
    getTimeZoneData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZone]);

  const { data, isLoading } = useGetYourActivites({ limit: 1 });
  const interviewDetails = data?.result?.interviews[0]?.interviewDetails;
  const coachingSessionDetails = data?.result?.coachingSessions[0];

  const onSeeMoreClick = (type: string) => {
    navigate(ROUTES.PRIVATE.YOUR_ACTIVITY, {
      state: { type, timeZoneData: `(${timeZone}, ${timeZoneAbbr})` },
    });
  };

  const onButtonAction = (url: string) => {
    if (url) {
      openURL(url);
    }
  };

  const hideInterviewButton = () => {
    return (
      interviewDetails?.interviewmode === "ONSITE" ||
      interviewDetails?.interviewmode === "PHONE_CALL" ||
      (data !== undefined && !data?.result?.interviews?.length)
    );
  };

  return (
    <Box
      className="app-c-card--default"
      sx={{
        padding: "0 !important",
        flexGrow: 1,
        flexBasis: "100%",
      }}
    >
      <Box
        sx={{
          background: "var(--color-primary)",
          borderRadius: "1.2rem 1.2rem 0 0",
          padding: "3rem 1.5rem",
        }}
      >
        <Typography
          component="h5"
          sx={{
            fontSize: "2rem !important",
            color: "var(--color-secondary)",
            fontFamily: "var(--font-primary-700)",
            lineHeight: 1,
            textAlign: "center",
          }}
        >
          Your Activities
        </Typography>
      </Box>
      {!isLoading && (
        <Box
          sx={{
            padding: "3rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "90%",
          }}
        >
          <Box
            sx={{
              overflowY: "auto",
              height: "37.75rem",
              scrollbarWidth: "thin",
            }}
          >
            <ActivitiesCardOuter
              title="Work & Earn Center"
              subtitle={
                `${
                  INTERVIEW[
                    data?.result?.interviews[0]?.interviewStatus ?? ""
                  ] ?? ""
                } - ${data?.result?.interviews[0]?.jobName} (${
                  data?.result?.interviews[0]?.companyName
                })` ?? ""
              }
              hideSubtitle={
                data !== undefined && !data?.result?.interviews[0]?.jobName
              }
              hideButton={hideInterviewButton()}
              buttonText={
                INTERVIEW_TYPE_BUTTON[interviewDetails?.interviewmode ?? ""] ??
                ""
              }
              icon="work-earn"
              buttonAction={() => onButtonAction(interviewDetails?.link ?? "")}
              seeMoreAction={() => onSeeMoreClick("interview")}
              hideSeeMore={
                data !== undefined && !data?.result?.interviews?.length
              }
            >
              {data !== undefined && data?.result?.interviews?.length > 0 ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "3.1rem",
                      marginBottom: "1.25rem",
                      flex: 1,
                    }}
                  >
                    <Box sx={{ flex: 0.5 }}>
                      <TextValue
                        label="Interview Method"
                        value={
                          INTERVIEW_TYPE[
                            interviewDetails?.interviewmode ?? ""
                          ] ?? ""
                        }
                      />
                    </Box>
                    <Box sx={{ flex: 0.5 }}>
                      <TextValue
                        label="Date"
                        value={
                          formatUTCDateToLocal(
                            data?.result?.interviews[0]?.interviewStartDate,
                            "dd LLL, yyyy",
                          ) ?? ""
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "3.1rem",
                      marginBottom: "1.25rem",
                      flex: 1,
                    }}
                  >
                    <Box sx={{ flex: 0.5 }}>
                      <TextValue
                        label="Time"
                        value={`${
                          formatUTCDateToLocal(
                            data?.result?.interviews[0]?.interviewStartDate,
                            "hh:mm a",
                          ) ?? ""
                        } (${timeZone}, ${timeZoneAbbr})`}
                      />
                    </Box>
                  </Box>
                  {interviewDetails?.interviewmode === "ONSITE" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "3.1rem",
                        marginBottom: "1.25rem",
                        flex: 1,
                      }}
                    >
                      <TextValue
                        label="Address"
                        value={
                          `${interviewDetails?.buildingname}, ${interviewDetails?.city}, ${interviewDetails?.landmark}, ${interviewDetails?.city}, ${interviewDetails?.state}, ${interviewDetails?.zipcode}` ??
                          ""
                        }
                      />
                    </Box>
                  )}
                  {interviewDetails?.interviewmode === "PHONE_CALL" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "3.1rem",
                        marginBottom: "1.25rem",
                        flex: 1,
                      }}
                    >
                      <TextValue
                        label="Phone Number"
                        value={
                          `+${interviewDetails?.isdcode} ${interviewDetails?.phone}` ??
                          ""
                        }
                      />
                    </Box>
                  )}
                </>
              ) : (
                <Typography
                  component="p"
                  sx={{
                    fontSize: "2.4rem !important",
                    fontFamily: "var(--font-primary-700)",
                    color: "var(--color-secondary)",
                    marginLeft: "3.1rem",
                    marginBottom: "1.45rem",
                  }}
                >
                  No interviews scheduled
                </Typography>
              )}
            </ActivitiesCardOuter>
            <Box
              sx={{
                height: "0.08rem",
                backgroundColor: "var(--background)",
                marginBottom: "2.5rem",
                marginTop: "1.25rem",
              }}
            />
            <ActivitiesCardOuter
              title="Guidance Center"
              subtitle={`${
                SESSION_TYPE[coachingSessionDetails?.sessionType ?? ""] ?? ""
              } session with ${coachingSessionDetails?.coachName?.firstname}`}
              buttonText="Join Zoom"
              hideSubtitle={
                data !== undefined &&
                !coachingSessionDetails?.coachName?.firstname
              }
              hideButton={
                data !== undefined && !data?.result?.coachingSessions?.length
              }
              icon="guidance"
              buttonAction={() =>
                onButtonAction(
                  coachingSessionDetails?.sessionRoomLocation ?? "",
                )
              }
              seeMoreAction={() => onSeeMoreClick("coachingSession")}
              hideSeeMore={
                data !== undefined && !data?.result?.coachingSessions?.length
              }
            >
              {data !== undefined &&
              data?.result?.coachingSessions?.length > 0 ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "3.1rem",
                      marginBottom: "1.25rem",
                      flex: 1,
                    }}
                  >
                    <Box sx={{ flex: 0.5 }}>
                      <TextValue
                        label="Session Method"
                        value="Digital Conference"
                      />
                    </Box>
                    <Box sx={{ flex: 0.5 }}>
                      <TextValue
                        label="Date"
                        value={
                          formatUTCDateToLocal(
                            coachingSessionDetails?.scheduleTime ?? "",
                            "dd LLL, yyyy",
                          ) ?? ""
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "3.1rem",
                      marginBottom: "1.25rem",
                      flex: 1,
                    }}
                  >
                    <Box sx={{ flex: 0.5 }}>
                      <TextValue
                        label="Time"
                        value={`${
                          formatUTCDateToLocal(
                            coachingSessionDetails?.scheduleTime ?? "",
                            "hh:mm a",
                          ) ?? ""
                        } (${timeZone}, ${timeZoneAbbr})`}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <Typography
                  component="p"
                  sx={{
                    fontSize: "2.4rem !important",
                    fontFamily: "var(--font-primary-700)",
                    color: "var(--color-secondary)",
                    marginLeft: "3.1rem",
                    marginBottom: "1.45rem",
                  }}
                >
                  No sessions scheduled
                </Typography>
              )}
            </ActivitiesCardOuter>
          </Box>
          <Box
            sx={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Typography variant="h4" className={styles.containerHeading2}>
              To view the latest updates, open your mobile application
            </Typography>
            <Box className={styles.storeGroup}>
              {APP_LINKS.map((link) => (
                <Link to={link?.link} key={link?.key} target="_blank">
                  <SVGIcon name={link.icon} />
                </Link>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Activities;
