import { Box, Typography } from "@mui/material";

type TextValueProps = {
  label: string;
  value: number | string;
};

function TextValue(props: TextValueProps) {
  const { label, value } = props;
  return (
    <Box>
      <Typography
        component="span"
        sx={{
          display: "block",
          fontSize: "1.4rem",
          fontWeight: "500",
          color: "var(--colorTextSecondary)",
          lineHeight: 1,
          marginBottom: "0.8rem",
        }}
      >
        {label}
      </Typography>

      <Typography
        component="span"
        sx={{
          display: "block",
          fontSize: "1.4rem",
          fontWeight: "500",
          color: "var(--colorTextPrimary)",
          lineHeight: 1,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
}

export default TextValue;
