import { Box, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import SVGIcon from "components/icons";
import { Title } from "./helperComponents";
import Layout from "./layout";

type RedeemSuccessProps = {
  onClose: () => void;
};

const labels = Resources.PAGES.PURCHASE_CENTER.CAMPAIGN_MODAL.SUCCESS;

function RedeemSuccess(props: RedeemSuccessProps) {
  const { onClose } = props;
  return (
    <Layout onClose={onClose} title={labels.ACTIVATE_SUCCESS}>
      <Title text={labels.VISIT_PURCHASE_CENTER} />
      <Box
        sx={{
          mt: "3rem",
          display: "flex",
          gap: "1.5rem",
          pl: "1rem",
          alignItems: "center",
        }}
      >
        <SVGIcon name="feature-center" />
        <Typography
          variant="body2"
          sx={{
            fontSize: { xs: "1.4rem !important", lg: "1.8rem !important" },
          }}
        >
          {labels.PURCHASE_CENTER}
        </Typography>
      </Box>
      <Box sx={{ mt: "3rem", mb: "3rem" }}>
        <Title text={labels.OR} />
      </Box>
      <Box sx={{ mb: "1.5rem" }}>
        <Title text={labels.BUILD_TALENT_PROFILE} />
      </Box>
    </Layout>
  );
}

export default RedeemSuccess;
