import { Box, Typography } from "@mui/material";
import { ConfirmDialog } from "components";
import { Breadcrumb } from "components/common";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth } from "hooks";
import { useNavigate } from "react-router-dom";
import { useDowngradePlan } from "services/featureCenter";
import triggerGAEvent, { freePlanInfo } from "utils/gaEvents";
import { ROUTES } from "config/app";

const breadcrumbs = [
  {
    text: "Feature Center",
    active: true,
    pageLink: ROUTES.PRIVATE.PURCHASE_CENTER,
  },
  { text: "Downgrade Subscription" },
];

function DowngradePlan() {
  const navigate = useNavigate();
  const { setGlobalAlert, user } = useAuth();

  const userId = user?.userId as string;

  const handleBack = () => navigate(-1);

  const onDowngradeSuccess = () => {
    // Trigger GA event on successful downgrade
    triggerGAEvent("purchase", userId, freePlanInfo);

    setGlobalAlert({
      message:
        "You will be downgraded to free plan after the expiration of subscription plan",
      messageType: "success",
    });
    handleBack();
  };

  const onDowngradeError = (error: FailedAPIStatus) => {
    setGlobalAlert({ message: error.errorMessage, messageType: "error" });
  };

  const { mutateAsync: downgradePlan, isLoading } = useDowngradePlan({
    onSuccess: onDowngradeSuccess,
    onError: onDowngradeError,
  });

  const downgradeSubscription = async () => {
    await downgradePlan(null);
  };

  return (
    <Box
      className="app-l-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: "3rem !important",
        gap: "4.5rem",
      }}
    >
      <Breadcrumb crumbs={breadcrumbs} />
      <ConfirmDialog
        heading="Downgrade Subscription"
        onCancel={handleBack}
        onConfirm={downgradeSubscription}
        primaryButtonLabel="Yes"
        secondaryButtonLabel="No"
        loading={isLoading}
      >
        <Typography>
          Are you sure want to downgrade your subscription to free?
        </Typography>
      </ConfirmDialog>
    </Box>
  );
}

export default DowngradePlan;
