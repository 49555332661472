import Resources from "assets/json/Resources";
import requests from "config/api";
import { REACT_QUERY_KEYS } from "config/app";
import API_ENDPOINTS from "constants/api";
import useAuth from "hooks/useAuth";
import useTimeout from "hooks/useTimeout";
import { useQuery, UseQueryResult } from "react-query";
import { getResponseErrorMessage } from "utils";

async function fetchProfileData() {
  return requests.get(API_ENDPOINTS.PROFILE_DETAILS);
}

type Config = {
  enabled?: boolean;
};

const useUserProfileDetails = (
  config?: Config,
): UseQueryResult<{
  name?: string;
  profileImageURL?: string;
  canUseOnBehalf?: boolean;
  zipCode?: boolean;
  userId?: string;
  isFirsttimeProfileComplete?: boolean;
}> => {
  const { setGlobalAlert, setGlobalLoader } = useAuth();
  const { reset } = useTimeout(() => {
    setGlobalAlert({ message: "", messageType: "error" });
  }, 4000);
  return useQuery(
    REACT_QUERY_KEYS.USER_PROFILE_DETAILS,
    async () => {
      const response = await fetchProfileData();
      if (response.status === 200 && response.result) {
        const {
          name: { firstname, middlename, lastname },
          matchprofile: {
            profileData: { photoUrl },
          },
          accountData: { zipCode, userId },
          isEligibleForLoginAsTalent,
          isFirsttimeProfileComplete,
        } = response.result;

        const profileDetails = {
          name: `${`${firstname ?? ""} `}${`${middlename ?? ""} `}${
            lastname || ""
          }`,
          profileImageURL: photoUrl,
          canUseOnBehalf: isEligibleForLoginAsTalent,
          zipCode: zipCode !== null,
          userId,
          isFirsttimeProfileComplete,
        };
        return profileDetails;
      }
      const message = getResponseErrorMessage(response);
      setGlobalAlert({
        message,
        messageType: "error",
      });
      reset();
      return {};
    },
    {
      onSettled: () => setGlobalLoader(false),

      onError: () => {
        setGlobalAlert({
          message: Resources.ERRORS.SOMETHING,
          messageType: "error",
        });
        reset();
      },
      staleTime: 10000,
      refetchOnWindowFocus: true,
      enabled: config?.enabled ?? true,
    },
  );
};

export default useUserProfileDetails;
