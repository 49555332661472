import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { updatePhone } from "./accountProfileService";
import { ChangePhonePayload, ChangePhoneResponse } from "./types";

type APIResponse = ChangePhoneResponse & FailedAPIResponse;

const useChangePhone = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, ChangePhonePayload>({
    mutationFn: updatePhone,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useChangePhone;
