import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { getDeleteAccountOTP } from "./commonService";
import { DeleteAccountOTPPayload, DeleteAccountOTPResponse } from "./types";

type APIResponse = DeleteAccountOTPResponse & FailedAPIResponse;

const useGetDeleteAccountOTP = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, DeleteAccountOTPPayload>({
    mutationFn: getDeleteAccountOTP,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useGetDeleteAccountOTP;
