import { Box, Fade } from "@mui/material";
import { Breadcrumb } from "components/common";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from "components";
import { ROUTES } from "config/app";
import NewEmailVerification from "./NewEmailVerification";
import OldEmailVerification from "./OldEmailVerification";

type Pages = "oldEmailVerification" | "newEmailVerification";
type VerificationInfo = {
  newEmail: string;
  oldEmailOTP: string;
};

const initialState: VerificationInfo = {
  newEmail: "",
  oldEmailOTP: "",
};

const breadcrumbs = [
  {
    text: "Account Settings",
    active: true,
    pageLink: ROUTES.PRIVATE.ACCOUNT_PROFILE,
  },
  { text: "Change Email" },
];

function ChangeEmail() {
  const [currentPage, setCurrentPage] = useState<Pages>("oldEmailVerification");
  const [verificationInfo, setVerificationInfo] =
    useState<VerificationInfo>(initialState);
  const [abortPhoneVerification, setAbortPhoneVerification] = useState(false);

  const navigate = useNavigate();

  const onOldEmailVerified = (emailInfo: VerificationInfo) => {
    setVerificationInfo(emailInfo);
    setCurrentPage("newEmailVerification");
  };

  const onNewEmailVerified = () => navigate(-1);
  const continuePhoneVerification = () => setAbortPhoneVerification(false);
  const terminatePhoneVerification = () => setAbortPhoneVerification(true);

  const handleBack = () => navigate(ROUTES.PRIVATE.ACCOUNT_PROFILE);

  const renderForms = () => {
    switch (currentPage) {
      case "oldEmailVerification":
        return (
          <OldEmailVerification
            onVerified={onOldEmailVerified}
            onAbort={terminatePhoneVerification}
          />
        );
      case "newEmailVerification":
        return (
          <NewEmailVerification
            newEmail={verificationInfo.newEmail}
            oldEmailOTP={verificationInfo.oldEmailOTP}
            onVerified={onNewEmailVerified}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      className="app-l-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: "3rem !important",
        gap: "4.5rem",
      }}
    >
      <Breadcrumb crumbs={breadcrumbs} />
      {abortPhoneVerification && (
        <ConfirmDialog
          heading="Are you sure you want to cancel?"
          onCancel={continuePhoneVerification}
          onConfirm={handleBack}
          primaryButtonLabel="Yes"
          secondaryButtonLabel="No"
        />
      )}
      <Fade in={!abortPhoneVerification} appear={false} exit={false} easing="0">
        <Box
          sx={{ display: abortPhoneVerification ? "none" : "flex", flex: 1 }}
        >
          {renderForms()}
        </Box>
      </Fade>
    </Box>
  );
}

export default ChangeEmail;
