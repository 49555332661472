import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { CheckoutButtons } from "components/ui";
import { CheckoutProps } from "components/ui/CheckoutButtons";
import styles from "../style.module.scss";

type BuyFeatureProps = {
  isInCart: boolean;
  onAdd: () => void;
} & CheckoutProps;

function AddBuyUp(props: BuyFeatureProps) {
  const { isInCart, onAdd, ...checkoutProps } = props;

  return (
    <Box className={styles.bottomCounter}>
      {isInCart ? (
        <CheckoutButtons {...checkoutProps} />
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Button variant="contained" fullWidth onClick={onAdd}>
            Add
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default AddBuyUp;
