import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { validateOldEmail } from "./accountProfileService";
import { EmailOTPSendResponse, ValidateOldEmailPayload } from "./types";

type APIResponse = EmailOTPSendResponse & FailedAPIResponse;

const useValidateOldEmail = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, ValidateOldEmailPayload>({
    mutationFn: validateOldEmail,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useValidateOldEmail;
