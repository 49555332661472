import { Box, Typography } from "@mui/material";
import { ConfirmDialog } from "components";
import { OTPCount, OTPInput } from "components/ui";
import { DEFAULT_OTP_LENGTH } from "config/app";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth } from "hooks";
import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import {
  useChangeEmail,
  useValidateOldEmail,
} from "services/accountProfileService";
import { OTPReducer, initialOTPState } from "utils/otpReducer";

type Props = {
  newEmail: string;
  oldEmailOTP: string;
  onVerified: () => void;
};

function NewEmailVerification(props: Props) {
  const { onVerified, newEmail, oldEmailOTP } = props;
  const { setGlobalAlert } = useAuth();

  const navigate = useNavigate();

  const [OTPState, dispatchOTPAction] = useReducer(OTPReducer, initialOTPState);

  const showError = (message: string) =>
    setGlobalAlert({ message, messageType: "error" });

  const handleAPIError = (error: FailedAPIStatus) => {
    showError(error.errorMessage);
  };

  const handleBack = () => navigate(-1);

  const onEmailOTPResendSuccess = () => {
    dispatchOTPAction({ type: "SET_IS_EMAIL_OTP_RESEND", payload: true });
  };

  const handleEmailVerifySuccess = () => {
    setGlobalAlert({
      message: "Email updated successfully",
      messageType: "success",
    });
    onVerified();
  };

  const { mutateAsync: resendOTP } = useValidateOldEmail({
    onError: handleAPIError,
    onSuccess: onEmailOTPResendSuccess,
  });

  const { mutateAsync: verifyEmail, isLoading } = useChangeEmail({
    onError: handleAPIError,
    onSuccess: handleEmailVerifySuccess,
  });

  const closeEmailOTPAlert = () => {
    dispatchOTPAction({ type: "SET_IS_EMAIL_OTP_RESEND", payload: false });
  };

  const onOldEmailOTPComplete = (otp: string) => {
    dispatchOTPAction({ type: "SET_EMAIL_OTP", payload: otp });
  };

  const handleOTPResend = async () => {
    closeEmailOTPAlert();
    await resendOTP({ newEmail, otp: oldEmailOTP });
  };

  const onSubmit = async () => {
    if (OTPState.emailOTP.length !== DEFAULT_OTP_LENGTH) {
      showError("Please enter valid 6-digit code");
      return;
    }

    await verifyEmail({
      newEmail,
      newEmailOtp: OTPState.emailOTP,
      oldEmailOTP,
    });
  };

  return (
    <ConfirmDialog
      heading="New Email Verification"
      onCancel={handleBack}
      onConfirm={onSubmit}
      primaryButtonProps={{ disabled: isLoading }}
    >
      <Typography>
        To complete the process, we sent a code to your new email address&nbsp;
        <Typography component="span" sx={{ fontWeight: 700 }}>
          {newEmail}
        </Typography>
      </Typography>
      <Typography sx={{ mt: "1.5rem" }}>
        <Typography component="span" sx={{ fontWeight: 700 }}>
          NOTE:
        </Typography>
        Check your spam folder if you don&apos;t see it.
      </Typography>
      <Box sx={{ maxWidth: "40rem", mt: "3.2rem" }}>
        <>
          <OTPInput
            otpLength={DEFAULT_OTP_LENGTH}
            legend="6-Digit-code"
            onComplete={onOldEmailOTPComplete}
            showAlert={OTPState.isEmailOTPResend}
            alertMessage="Code resent successfully!"
            onAlertClose={closeEmailOTPAlert}
            sx={{ mb: "1.2rem" }}
          />
          <OTPCount
            sx={{ justifyContent: "flex-end", mb: "3rem" }}
            resendLabel="Resend code again"
            onResend={handleOTPResend}
          />
        </>
      </Box>
    </ConfirmDialog>
  );
}

export default NewEmailVerification;
