import { Box, Button, Divider, Typography } from "@mui/material";
import SVGIcon from "components/icons";
import { APP_LINKS, ROUTES } from "config/app";
import useSidebar from "hooks/useSidebar";
import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  HowCanWeHelpType,
  UsernameType,
} from "services/accountProfileService/types";
import { FeaturesType, OtherFeaturesType } from "services/home/types";
import HelpOption from "../Features/HelpOption";
import { accessCodeDiscountItemLabel, featureOptionConfig } from "../config";
import { FeatureUsage, Paragraph, Title } from "../subComponents";
import NoOtherFeature from "./NoOtherFeature";

type AccessCodeFeaturesProps = {
  features?: FeaturesType;
  otherFeatures?: OtherFeaturesType[];
  userName?: UsernameType;
  howCanWeHelpOption?: HowCanWeHelpType;
};
function AccessCodeFeatures(props: AccessCodeFeaturesProps) {
  const { features, otherFeatures, howCanWeHelpOption, userName } = props;
  const navigate = useNavigate();
  const { setActiveMenu } = useSidebar();

  const filteredOtherUsage = useMemo(() => {
    return otherFeatures?.filter(
      (feature) => feature.key !== "BACKGROUND_CHECK",
    );
  }, [otherFeatures]);

  const navigateToFeatureCenter = () => {
    setActiveMenu("featureCenter");
    navigate(ROUTES.PRIVATE.PURCHASE_CENTER);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        minWidth: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        className="app-c-card--default"
        sx={{
          flexGrow: otherFeatures?.length ? 1 : "initial",
        }}
      >
        <Box>
          <Title
            title={`Welcome ${userName?.firstname ? userName.firstname : " "}!`}
            sx={{
              marginBottom: "1.0rem",
            }}
          />

          <Box>
            {howCanWeHelpOption && (
              <HelpOption helpOption={howCanWeHelpOption} />
            )}
            <Title
              title="Premium Features"
              sx={{
                marginTop: "3rem",
              }}
            />
            <Paragraph text="You have the following features available for you" />
            {features ? (
              <Box
                sx={{
                  marginTop: { xl: "3rem", lg: "1.5rem", xs: "1rem" },
                  display: "grid",
                  gridTemplateColumns: {
                    xl: "1fr 1fr",
                    sm: "repeat(auto-fit, minmax(30rem, 1fr))",
                    xs: "1fr",
                  },
                  rowGap: { xl: "4rem", lg: "1.5rem", xs: "2.5rem" },
                  columnGap: "2rem",
                }}
              >
                {Object.entries(features).map(([key, value]) => {
                  const count =
                    // eslint-disable-next-line no-nested-ternary
                    key === "membershipLevel"
                      ? value?.available
                        ? "Active"
                        : "In-Active"
                      : value?.count;
                  return (
                    <FeatureUsage
                      key={key}
                      isColorRed={count === "In-Active" || !count}
                      count={count}
                      text={featureOptionConfig[key] as string}
                    />
                  );
                })}
              </Box>
            ) : (
              <Typography>No features available</Typography>
            )}
            <Box
              sx={{
                marginTop: { xl: "5rem", lg: "3.5rem", xs: "3rem" },
                display: "flex",
                gap: { xs: "3rem", sm: "2rem" },
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  flexShrink: 0,
                  width: { xs: "100%", sm: "auto" },
                  alignSelf: "center",
                  maxWidth: { sm: "36.8rem" },
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={navigateToFeatureCenter}
                >
                  Buy More Features
                </Button>
              </Box>
              <Box
                sx={{
                  marginLeft: { sm: "auto" },
                  maxWidth: { xl: "none", sm: "27.7rem" },
                }}
              >
                <Typography
                  component="h4"
                  sx={{
                    fontSize: "1.6rem !important",
                    fontFamily: "var(--font-primary-700)",
                    color: "var(--color-secondary)",
                    lineHeight: "normal",
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  Start using your features and build your profile
                </Typography>
                <Box
                  sx={{
                    marginTop: "1.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "space-between", sm: "flex-start" },
                    gap: "1.5rem",
                    img: {
                      maxWidth: "100%",
                      display: "block",
                    },
                  }}
                >
                  {APP_LINKS.map((link) => (
                    <Box
                      sx={{
                        maxWidth: "13.6rem",
                        a: {
                          maxWidth: "100%",
                          display: "block",

                          svg: {
                            width: "100%",
                            height: "100%",
                          },
                        },
                      }}
                    >
                      <Link to={link.link} key={link.key} target="_blank">
                        <SVGIcon name={link.icon} />
                      </Link>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {otherFeatures?.length ? (
          <>
            <Divider
              variant="middle"
              sx={{
                borderColor: "var(--colorLightGrey)",
                margin: "3rem auto",
                maxWidth: "95%",
              }}
            />
            <Box>
              <Title
                title="Other Features"
                sx={{
                  marginBottom: "1.0rem",
                }}
              />
              <Paragraph text="These are the other features available with us and soon this tracking will be automated too!" />
              <Box
                sx={{
                  marginTop: { xl: "3.9rem", lg: "1.5rem", xs: "1rem" },
                  display: "grid",
                  gridTemplateColumns: {
                    xl: "1fr 1fr 1fr",
                    xs: "repeat(auto-fit, minmax(22rem, 1fr))",
                  },
                  rowGap: { xl: "4rem", lg: "2rem", xs: "1.5rem" },
                  columnGap: "2rem",
                }}
              >
                {filteredOtherUsage?.map((value, index) => (
                  <FeatureUsage
                    key={`feature${index + 1}`}
                    count={+value.totalCount}
                    text={accessCodeDiscountItemLabel[value.key]}
                  />
                ))}
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
      {!otherFeatures?.length && <NoOtherFeature />}
    </Box>
  );
}

export default AccessCodeFeatures;
