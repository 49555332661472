import { Button } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

type OTPCountProps = {
  attemptsLeft?: number | string;
  onResend: () => void;
  resendLabel: ReactNode;
  attemptsLabel?: string;
  isAttemptEnabled?: boolean;
  loading?: boolean;
  disabled?: boolean;
} & BoxProps;

function OtpCount(props: OTPCountProps) {
  const {
    sx,
    attemptsLeft,
    onResend,
    resendLabel,
    attemptsLabel,
    isAttemptEnabled,
    disabled,
    loading,
    ...rest
  } = props;
  return (
    <Box sx={{ display: "flex", ...sx }} {...rest}>
      {isAttemptEnabled && (
        <Typography
          component="span"
          sx={{
            color: "var(--colorTextSecondary)",
            lineHeight: "1",
            fontSize: "1.1rem",
            fontWeight: "500",
            paddingRight: "0.5rem",
            marginRight: "0.5rem",
            borderRight: "var(--colorTextSecondary) 0.063rem solid",
          }}
        >
          {attemptsLeft || 0} {attemptsLabel}
        </Typography>
      )}
      {disabled ? (
        <Typography
          component="span"
          sx={{
            color: "var(--colorTextSecondary)",
            lineHeight: "1",
            fontSize: "1.1rem",
            fontWeight: "500",
            paddingRight: "0.5rem",
            marginRight: "0.5rem",
          }}
        >
          {resendLabel}
        </Typography>
      ) : (
        <Button
          variant="text"
          sx={{ fontSize: "1.1rem", height: "auto" }}
          onClick={onResend}
          disabled={disabled && !loading}
          aria-label="Resend OTP"
        >
          {resendLabel}
        </Button>
      )}
    </Box>
  );
}
export default OtpCount;
