import { Box, Grid } from "@mui/material";
import { SidebarContent } from "components";
import { ReactChildrenProps } from "constants/sharedTypes";
import { SidebarProvider } from "context/sidebar/SidebarProvider";
import Header from "./Header";

declare global {
  interface Window {
    opera: any;
  }
}

export default function MenusAndNavigation(props: ReactChildrenProps) {
  const { children } = props;

  return (
    <SidebarProvider>
      <Header />
      <Grid
        container
        spacing={0}
        sx={{ flexWrap: "nowrap", height: "100%", minHeight: "0" }}
      >
        <Grid
          item
          sx={{
            display: { xs: "none", lg: "block" },
            borderRightWidth: "0.6rem",
            borderRightStyle: "solid",
            borderRightColor: "var(--color-primary)",
            width: "100%",
            maxWidth: { xl: "32.9rem", xs: "30.5rem" },
            bgcolor: "var(--color-secondary)",
          }}
        >
          <SidebarContent />
        </Grid>
        <Grid
          item
          sx={{
            minWidth: 0,
            width: "100%",
            flexGrow: "1",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "var(--color-primary4)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: "1",
              minHeight: "0",
              overflow: "auto",
            }}
          >
            {children}
          </Box>
        </Grid>
      </Grid>
    </SidebarProvider>
  );
}
