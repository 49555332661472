import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import Resources from "assets/json/Resources";
import { ChangeAgeResponse, UpdateAgePayload } from "./types";
import { updateAge } from "./accountProfileService";

type APIResponse = ChangeAgeResponse & FailedAPIResponse;

const useUpdateAge = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, UpdateAgePayload>({
    mutationFn: updateAge,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useUpdateAge;
