import { CircularProgress } from "@mui/material";
import styles from "./styles.module.scss";

// add position relative css property in parent element
export default function SectionLoader({ loading }: { loading: boolean }) {
  return loading ? (
    <div className={styles.loader}>
      <CircularProgress size={20} />
    </div>
  ) : null;
}
