import SVGIcon from "components/icons";
import { LogoBlackText } from "assets/images";
import Resources from "assets/json/Resources";
import styles from "./styles.module.scss";

const { CONTACT_US_LINK } = Resources.COMMON.LINKS;

export default function GuardianSuccess() {
  return (
    <div className={styles.EntryScreenMainSection}>
      <div className={styles.EntryScreenBlock}>
        <div className={styles.EntryScreenSection}>
          <div className={styles.center}>
            <div className={styles.inner}>
              <SVGIcon name="success" />
              <b style={{ marginTop: 10, fontSize: 20 }}>
                {Resources.PAGES.SIGN_UP.GUARDIAN_CONSENT.THANKS}
              </b>
              <p className={styles.successmsg}>
                {Resources.PAGES.SIGN_UP.GUARDIAN_CONSENT.SUCCESS_MSG}
              </p>
            </div>
          </div>

          <div className={styles.EntryScreenleftSection}>
            <SVGIcon name="chat" />
            <p className={styles.alreadyAccountText}>
              Need help?
              <a target="_blank" href={CONTACT_US_LINK} rel="noreferrer">
                Contact Support.
              </a>
            </p>
          </div>

          <div className={styles.EntryScreenRightSections}>
            <img src={LogoBlackText} alt="pepelwerk" />
          </div>
        </div>
      </div>
    </div>
  );
}
