import { Box, Typography } from "@mui/material";
import SVGIcon from "components/icons";
import { APP_LINKS } from "config/app";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils";
import { PaymentSuccessImg } from "../../../assets/images";
import styles from "./styles.module.scss";

type PaymentSuccessProps = {
  amount: number;
};

function PaymentSuccessful(props: PaymentSuccessProps) {
  const { amount } = props;
  return (
    <Box className={styles.PaymentContainer}>
      <Box className={styles.PaymentTextBox}>
        <Typography component="h3" className={styles.PaymentHeading}>
          Payment Successful!
        </Typography>
        <Typography component="p" className={styles.PaymentDescription}>
          The payment of {formatCurrency(amount, 2)} (Including Tax) is
          successful!
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={PaymentSuccessImg}
          alt="payment success"
          className={styles.PaymentSuccessImg}
        />
      </Box>
      <Box className={styles.PaymentTextBox}>
        <Typography component="h3" className={styles.PaymentHeading}>
          What’s next?
        </Typography>
        <Typography component="p" className={styles.PaymentDescription}>
          Open your app to start using the features!
        </Typography>
      </Box>
      <Box className={styles.storeGroup}>
        {APP_LINKS.map((app) => (
          <Link key={app.key} to={app.link} target="_blank">
            <SVGIcon name={app.icon} />
          </Link>
        ))}
      </Box>
    </Box>
  );
}

export default PaymentSuccessful;
