import { emailErrorMsg } from "pages/SignUp/RegisterForm/config";
import { changePhoneNumber, emailSchema } from "utils/validationSchema";
import * as yup from "yup";

export const guardianConsentRequestFormSchema = yup
  .object({
    email: emailSchema(emailErrorMsg),
  })
  .concat(changePhoneNumber);

export type GuardianConsentFormType = yup.InferType<
  typeof guardianConsentRequestFormSchema
>;

export type FormField = keyof typeof guardianConsentRequestFormSchema.fields;
