/* eslint-disable import/prefer-default-export */
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import { transformDate } from "utils";
import styles from "./styles.module.scss";

const { PURCHASE_CENTER } = Resources.PAGES;

type PlanInfo = {
  expiryDate: string;
};

export function CurrentPlanInfo(props: PlanInfo) {
  const { expiryDate } = props;

  return (
    <div className={styles.action}>
      <div>
        <Typography variant="body2">
          {PURCHASE_CENTER.PLANS.CURRENT_PLAN}
        </Typography>
        <Typography variant="body2">
          {PURCHASE_CENTER.PLANS.EXPIRY}&nbsp;
          <span>
            {transformDate(expiryDate, {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </span>
        </Typography>
      </div>
    </div>
  );
}

type FeatureProps = { name: string; available: boolean };

export function FeatureListItem(props: FeatureProps) {
  const { name, available } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: "1rem",
        "&:not(:last-child)": { mb: "1.7rem" },
      }}
    >
      {available ? (
        <CheckIcon
          fontSize="small"
          sx={{
            width: "1.8rem",
            height: "1.8rem",
            fontSize: "1.2rem",
            padding: "0.3rem",
            color: "var(--color-secondary)",
            backgroundColor: "var(--color-primary)",
            borderRadius: "50%",
          }}
        />
      ) : (
        <CloseIcon
          fontSize="small"
          sx={{
            width: "1.8rem",
            height: "1.8rem",
            fontSize: "1.2rem",
            padding: "0.3rem",
            color: "var(--button-text1)",
            backgroundColor: "var(--danger)",
            borderRadius: "50%",
          }}
        />
      )}

      <Typography
        variant="body2"
        sx={{
          fontSize: "1.4rem",
          fontFamily: "var(--font-primary-500)",
          color: "var(--colorTextPrimary)",
        }}
      >
        {name}
      </Typography>
    </Box>
  );
}
