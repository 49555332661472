import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth, useCreateCardToken } from "hooks";
import { ChangeEvent } from "react";
import { FieldValues } from "react-hook-form";
import { useSaveCC } from "services/paymentService";
import styles from "../style.module.scss";
import PaymentForm from "./PaymentForm";
import PaymentList, { PaymentListProps } from "./PaymentList";

type PaymentProps = {
  onPaymentSelect: (paymentId: string) => void;
  loading?: boolean;
  stripeKey: string;
  onCardSave: (paymentId: string) => void;
  disabled?: boolean;
  onPaymentProcessing?: (status: boolean) => void;
} & PaymentListProps;

function Payments(props: PaymentProps) {
  const {
    onPaymentSelect,
    creditCards,
    selectedPaymentId,
    loading,
    stripeKey,
    disabled,
    onCardSave,
    onPaymentProcessing,
  } = props;
  const { setGlobalAlert } = useAuth();

  const { createToken } = useCreateCardToken(stripeKey);

  const showError = (message: string) => {
    setGlobalAlert({ messageType: "error", message });
  };

  const onAddCardError = (error: FailedAPIStatus) => {
    showError(error.errorMessage);
  };

  const { mutateAsync: saveCreditCard, isLoading: isPaymentAdding } = useSaveCC(
    { onError: onAddCardError },
  );

  const addCreditCard = async (values: FieldValues) => {
    const { cardNumber, cvv, name, zipCode, expMonth, expYear, country } =
      values;
    const payload = {
      number: cardNumber,
      expMonth,
      expYear,
      cvc: cvv,
      addressZip: zipCode,
      name,
      addressCountry: country,
    };

    try {
      onPaymentProcessing?.(true);
      const res = await createToken(payload);
      if (res.status === "error") {
        showError(res.errorMsg);
        return;
      }
      const response = await saveCreditCard({
        nickName: res.nickName,
        tokenId: res.tokenId,
      });
      if (response?.result?.success && response?.result?.paymentMethodId) {
        onCardSave(response?.result?.paymentMethodId as string);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error in creating token", error);
    } finally {
      onPaymentProcessing?.(false);
    }
  };

  const handlePaymentSelect = (e: ChangeEvent<HTMLInputElement>) => {
    onPaymentSelect(e.target.value);
  };

  return (
    <>
      <RadioGroup
        name="radio-buttons-group"
        className={styles.cardGroup}
        onChange={handlePaymentSelect}
      >
        <FormControlLabel
          className={styles.cardOptions}
          value="ADD_CARD"
          // control={<Radio {...(!creditCards.length && { checked: true })} />}
          control={<Radio checked={selectedPaymentId === "ADD_CARD"} />}
          label={<Typography variant="h4">Add new card</Typography>}
        />
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <PaymentList
            creditCards={creditCards}
            selectedPaymentId={selectedPaymentId}
          />
        )}
      </RadioGroup>
      {(selectedPaymentId === "ADD_CARD" || !creditCards.length) && (
        <PaymentForm
          disabled={isPaymentAdding || disabled}
          formId="addCredit-card"
          onFormValid={addCreditCard}
        />
      )}
    </>
  );
}

export default Payments;
