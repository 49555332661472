import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { onBehalfOfLogin } from "./accountProfileService";
import { OnBehalfPayload, OnBehalfSuccess } from "./types";

type APIResponse = OnBehalfSuccess & FailedAPIResponse;

const useOnBehalf = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, OnBehalfPayload>({
    mutationFn: onBehalfOfLogin,
    resource: Resources.API_ERRORS.ON_BEHALF,
    handler,
  });
};

export default useOnBehalf;
