import API_ENDPOINTS from "constants/api";
import requests from "config/api";
import { CCDetailsTypePayload, DeleteCardPayload } from "./types";

export const addNewCard = async (payload: CCDetailsTypePayload) => {
  return requests.post(API_ENDPOINTS.ADD_NEW_CC, payload);
};

export const deletePaymentCard = async (payload: DeleteCardPayload) => {
  return requests.post(API_ENDPOINTS.DELETE_PAYMENT, payload);
};
