import {
  Box,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Resources from "assets/json/Resources";
import SVGIcon from "components/icons";
import { ROUTES } from "config/app";
import { useAuth } from "hooks";
import useSidebar from "hooks/useSidebar";
import {
  talentAccessCodeKey,
  TalentAccessCodeStatusType,
} from "pages/AccessCode/config";
import { Link, useNavigate } from "react-router-dom";
import { AccessCodeDetailType } from "services/accessCode/types";
import { encryptString } from "utils";

type AccessCodeListProps = {
  list: AccessCodeDetailType[] | null;
};

function AccessCodeList(props: AccessCodeListProps) {
  const { list } = props;

  const navigate = useNavigate();
  const { setActiveMenu } = useSidebar();

  const { setGlobalAlert } = useAuth();

  const showConfirmation = (
    accessCode: string,
    status: TalentAccessCodeStatusType,
  ) => {
    const breadCrumb = {
      accessCode,
      text: "Home",
      pageLink: ROUTES.PRIVATE.HOME,
    };
    if (status === "ACTIVE") {
      setGlobalAlert({
        message: Resources.PAGES.HOME.ACCESS_CODE_CANNOT_DEACTIVATE,
        messageType: "error",
      });
      return;
    }

    navigate(ROUTES.PRIVATE.ACCESS_CODE_ACTIVATION, {
      state: breadCrumb,
    });
  };

  const onAccessCodeClick = () => setActiveMenu("accessCode");

  return (
    <Box>
      <TableContainer
      //  sx={{ maxHeight: { md: "16rem" } }} // disabled because max row count is set
      >
        <Table
          stickyHeader
          className="app-c-grid--primary"
          sx={{ minWidth: "20rem" }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center" width="40%">
                Features <br /> Available
              </TableCell>
              <TableCell width={110}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((accessCode, index) => {
              const talentACStatus =
                accessCode?.talentAccessCodeStatus as Extract<
                  TalentAccessCodeStatusType,
                  "ACTIVE" | "AVAILABLE_TO_USE" | "REQUESTED"
                >;
              return (
                <TableRow key={`accesCode${index + 1}`}>
                  <TableCell>
                    <Typography
                      component="span"
                      sx={{
                        fontFamily: "var(--font-primary-700)",
                        textDecoration: "underline",
                      }}
                    >
                      <Link
                        to={`${ROUTES.PRIVATE.ACCESS_CODE_DETAILS.replace(
                          ":code",
                          encryptString(accessCode.accessCode),
                        )}/`}
                        state={{
                          text: "Home",
                          active: true,
                          pageLink: ROUTES.PRIVATE.HOME,
                        }}
                        style={{ color: "var(--colorTextPrimary)" }}
                        onClick={onAccessCodeClick}
                      >
                        {accessCode.accessCode}
                      </Link>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {accessCode.featuresAvailableCount}
                  </TableCell>
                  <TableCell>
                    <Switch
                      className="app-c-switch--primary"
                      onClick={() => {
                        showConfirmation(
                          accessCode?.accessCode,
                          accessCode.talentAccessCodeStatus,
                        );
                      }}
                      checked={talentACStatus === talentAccessCodeKey.ACTIVE}
                      disabled={
                        talentACStatus === talentAccessCodeKey.REQUESTED
                      }
                    />
                    <Tooltip
                      title={
                        Resources.PAGES.ACCESS_CODE.AC_TOOLTIP[talentACStatus]
                      }
                      placement="bottom"
                    >
                      <IconButton
                        size="small"
                        aria-label="close"
                        sx={{ marginLeft: "1.2rem" }}
                      >
                        <SVGIcon name="question" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default AccessCodeList;
