import { DateTime } from "luxon";

export const timeLeftForNextUTCDay = (): number => {
  const now = DateTime.utc();
  const nextUTCDay = now
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .plus({ days: 1 });
  return nextUTCDay.diff(now).as("milliseconds");
};

export const formatUTCDateToLocal = (
  utcDate: string,
  format = "LLL dd, yyyy",
) => {
  const parsedDate = DateTime.fromISO(utcDate);
  return parsedDate.toFormat(format);
};

export const getTimeZone = () => {
  const now = DateTime.now();
  const timeZone = now.zoneName;
  return timeZone;
};
