interface State {
  emailOTP: string;
  phoneOTP: string;
  isEmailOTPResend: boolean;
  isPhoneOTPResend: boolean;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
}

type Action =
  | { type: "SET_EMAIL_OTP"; payload: string }
  | { type: "SET_PHONE_OTP"; payload: string }
  | { type: "SET_IS_EMAIL_OTP_RESEND"; payload: boolean }
  | { type: "SET_IS_PHONE_OTP_RESEND"; payload: boolean }
  | { type: "SET_PHONE_VERIFIED"; payload: boolean }
  | { type: "SET_EMAIL_VERIFIED"; payload: boolean };

export const initialOTPState: State = {
  emailOTP: "",
  phoneOTP: "",
  isEmailOTPResend: false,
  isEmailVerified: false,
  isPhoneOTPResend: false,
  isPhoneVerified: false,
};

export const OTPReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_EMAIL_OTP":
      return { ...state, emailOTP: action.payload };
    case "SET_PHONE_OTP":
      return { ...state, phoneOTP: action.payload };
    case "SET_IS_EMAIL_OTP_RESEND":
      return { ...state, isEmailOTPResend: action.payload };
    case "SET_IS_PHONE_OTP_RESEND":
      return { ...state, isPhoneOTPResend: action.payload };
    case "SET_EMAIL_VERIFIED":
      return { ...state, isEmailVerified: action.payload };
    case "SET_PHONE_VERIFIED":
      return { ...state, isPhoneVerified: action.payload };
    default:
      return state;
  }
};
