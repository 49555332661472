import Resources from "assets/json/Resources";
import REGEX from "constants/regex";
import * as yup from "yup";

export const cvvMaxLength = 4;

export const dateMaxLength = 2;

export const cardNumberMaxLength = 23;

export const zipCodeMaxLength = 20;

const today = new Date();

const {
  PAGES: {
    PAYMENT_CENTER: {
      ADD_CREDIT_CARD: {
        ENTER_CVV_NUMBER,
        ENTER_VALID_CVV,
        ENTER_CC_NUMBER,
        ENTER_NAME_OF_CARD_HOLDER,
        ENTER_EXPIRY_MONTH,
        ENTER_EXPIRY_YEAR,
        ENTER_VALID_ZIP_CODE,
        INVALID_EXP_YEAR_FORMAT,
        INVALID_EXP_MONTH_FORMAT,
        ENTER_VALID_CARD_HOLDER,
        ENTER_VALID_EXPIRY_MONTH,
        SELECT_COUNTRY,
      },
    },
  },
} = Resources;
export const addCreditCardSchema = yup.object({
  cardNumber: yup.string().required(ENTER_CC_NUMBER),
  name: yup
    .string()
    .required(ENTER_NAME_OF_CARD_HOLDER)
    .matches(REGEX.CARD_NAME, ENTER_VALID_CARD_HOLDER)
    .trim(),
  expMonth: yup
    .string()
    .required(ENTER_EXPIRY_MONTH)
    .min(dateMaxLength, INVALID_EXP_MONTH_FORMAT)
    .test(
      "is-greater-than-current-month",
      ENTER_VALID_EXPIRY_MONTH,
      // eslint-disable-next-line func-names
      function (value) {
        const year: string = this.resolve(yup.ref("expYear"));
        const currentYear = +today.getFullYear().toString().substring(2);
        const currentMonth = today.getMonth() + 1;
        const enteredYear = +year;
        const enteredMonth = +value;
        if (enteredYear <= currentYear) {
          return enteredMonth >= currentMonth;
        }

        return true;
      },
    ),
  expYear: yup
    .string()
    .required(ENTER_EXPIRY_YEAR)
    .min(dateMaxLength, INVALID_EXP_YEAR_FORMAT),

  cvv: yup
    .string()
    .required(ENTER_CVV_NUMBER)
    .matches(REGEX.CVV, ENTER_VALID_CVV),
  country: yup.string().required(SELECT_COUNTRY),
  zipCode: yup.string().required(ENTER_VALID_ZIP_CODE),
});

export type AddCreditCardFormType = yup.InferType<typeof addCreditCardSchema>;

export type CreateTokenPayload = {
  number: string;
  expMonth: string;
  expYear: string;
  cvc: string;
  addressZip: string;
  name: string;
  country: string;
};

type CardDetails = {
  last4: string;
  name: string;
  brand: string;
};
export type CCSuccessResponse = {
  type: string;
  id: string;
  card: CardDetails;
  error: ErrorDetails;
};

type ErrorDetails = {
  code: string;
  doc_url: string;
  message: string;
  param: string;
  type: string;
};
