import {
  APIResponse,
  AddOnKeys,
  CommonFeatures,
  FeaturePurchaseInfo,
  FeatureTypes,
  MembershipLevels,
  PlanDetails,
  Subscriptions,
} from "constants/sharedTypes";
import { AddOnAPIKeys } from "pages/PurchaseCenter/NewBuyUpFeatures/config";
import { CartItem } from "pages/PurchaseCenter/types";

export type FeaturesAndPurchaseInfo = FeaturePurchaseInfo & CommonFeatures;

// TODO - MOVE
export const isPurchasedInfo = (
  obj: CommonFeatures | FeaturesAndPurchaseInfo,
): obj is FeaturesAndPurchaseInfo => {
  try {
    return "isPurchased" in obj;
  } catch (error) {
    return false;
  }
};

export type BuyUpResponse = {
  result: {
    memberShipLevel: MembershipLevels;
    featuresEligibleForUse: {
      [key in FeatureTypes]: boolean;
    };
    accessCodeDetails: Record<AddOnAPIKeys, boolean> & {
      accessCode: string;
      isPreferedProfile: boolean;
    };
    buyUpFeatures: Record<AddOnAPIKeys, FeaturesAndPurchaseInfo> & {
      coachingSession: CommonFeatures;
    };
  };
} & APIResponse;

export type PlanResponse = {
  result: {
    success: boolean;
    freeMemberLevel: "1";
    subscriptionList: Subscriptions[];
    membershipInfo: {
      current: PlanDetails;
      future: PlanDetails;
    };
  };
} & APIResponse;

export type DowngradeSubResponse = {
  result: { success: boolean };
} & APIResponse;

export type PaymentResponse = {
  result: {
    isSuccess: boolean;
    transactionId: string;
  };
} & APIResponse;

type BuyUpType = {
  featureKey: string;
  count: number;
  price: number;
};

export type ViewCartResponse = {
  result: {
    success: boolean;
    userCart: {
      totalCartCount: number;
      cartItems: CartItem[];
    };
  };
} & APIResponse;

export type CartUpdateResponse = {
  result: {
    success: boolean;
    cartCount: number;
  };
} & APIResponse;

export type CheckoutSuccessResponse = {
  result: { isSuccess: boolean; transactionId: string };
} & APIResponse;

export type PaymentPayload = {
  paymentMethodId: string;
  subscriptionId?: string;
  buyUpFeatures?: BuyUpType[];
};

export type CartItemPayload = {
  count?: number;
  feature: AddOnKeys | string;
};

export type CheckoutPayload = {
  isCartCheckout: boolean;
  paymentMethodId: string;
  subscriptionId?: string;
  buyUpFeatures?: BuyUpType[];
  amountWithTax: number;
  taxCode?: string;
  isTaxCodeApplicable?: boolean;
  taxRate: string;
};
