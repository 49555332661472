import { Box, FormHelperText, MenuItem } from "@mui/material";
import Resources from "assets/json/Resources";
import { Select } from "components/common";
import InputField from "components/ui/InputField";
import { ALLOWED_COUNTRIES } from "config/app";
import { FailedAPIStatus, LocationInfo } from "constants/sharedTypes";
import {
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useGetCountryList, useGetGeoLocation } from "services/common";
import { GeoLocationResponse } from "services/common/types";
import { zipCodeValidation as validateZip } from "utils";

const initialLocationState: LocationInfo = {
  city: "",
  state: "",
  country: "",
  zipCode: "",
};

const APIinputFields: Record<string, string> = {
  zipCode: "zip code",
};

type LocationProps = {
  onCitySelect: (location: LocationInfo) => void;
  defaultZipCode?: string;
  defaultCity?: string;
};

const MIN_ZIP_LENGTH = 5;

function Location(props: LocationProps) {
  const { onCitySelect, defaultZipCode, defaultCity } = props;
  const [location, setLocation] = useState(initialLocationState);
  const [cityList, setCityList] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState("");

  const handleAPIError = (error: FailedAPIStatus) => {
    let { errorMessage } = error;
    if (error.errorField) {
      errorMessage = `Invalid ${APIinputFields[error.errorField]}`;
    }
    setErrorMsg(errorMessage);
  };

  const { mutateAsync: fetchLocationInfo, isLoading } = useGetGeoLocation({
    onError: handleAPIError,
  });

  const { data: countryListResponse } = useGetCountryList();

  const countryList = useMemo(() => {
    const list = countryListResponse?.result?.countryList?.map((country) => ({
      label: country?.name,
      value: country?.code,
    }));
    return list;
  }, [countryListResponse]);

  const handleResponse = (
    response: GeoLocationResponse,
  ): LocationInfo | null => {
    const {
      cityList: cities = [],
      country = "",
      state = "",
      zipCode = "",
    } = response.result;

    if (!ALLOWED_COUNTRIES.includes(country.toUpperCase())) {
      setErrorMsg(Resources.PAGES.ACCOUNT_PROFILE.ZIP_CODE_NOT_APPLICABLE);
      return null;
    }

    setCityList(cities);

    const hasCity = defaultCity ? cities.includes(defaultCity) : false;
    const city = hasCity ? (defaultCity as string) : cities[0];

    return { city, zipCode, state, country };
  };

  useEffect(() => {
    if (!defaultZipCode) return;

    (async () => {
      const res = await fetchLocationInfo({
        zipCode: defaultZipCode,
        isSpecificToCountry: true,
        country: location?.country,
      });
      if (res?.result?.success && res?.result?.cityList) {
        const locationDetails = handleResponse(res);
        if (!locationDetails) return;
        setLocation(locationDetails);
        onCitySelect(locationDetails);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultZipCode]);

  const onCityChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const selectedLocation = { ...location, city: event.target.value };
    setLocation(selectedLocation);
    onCitySelect(selectedLocation);
  };

  const onCountryChange = (event: string | number) => {
    const selectedLocation = {
      country: event as string,
      city: "",
      state: "",
      zipCode: "",
    };
    setErrorMsg("");
    setCityList([]);
    setLocation(selectedLocation);
    onCitySelect(selectedLocation);
  };

  const handleZipCodeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onCitySelect(initialLocationState);
    setCityList([]);
    setErrorMsg("");
    const selectedLocation = {
      ...location,
      zipCode: event.target.value,
      city: "",
      state: "",
    };
    setLocation(selectedLocation);
  };

  const handleZipCodeBlur: FocusEventHandler<HTMLInputElement> = async (
    event,
  ) => {
    const { value } = event.target;
    if (!value) return;

    if (value.length < MIN_ZIP_LENGTH) {
      setErrorMsg(Resources.PAGES.ACCOUNT_PROFILE.MINIMUM_FIVE_DIGITS);
      return;
    }

    const isValidZip = validateZip(value);
    if (!isValidZip) {
      setErrorMsg("Invalid zip code");
      return;
    }
    const response = await fetchLocationInfo({
      zipCode: value,
      isSpecificToCountry: true,
      country: location?.country,
    });
    if (response?.result?.success && response?.result?.cityList) {
      const locationDetails = handleResponse(response);

      if (!locationDetails) return;

      setLocation(locationDetails);
      onCitySelect(locationDetails);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.2rem",
      }}
    >
      <Select
        required
        label={Resources.PAGES.ACCOUNT_PROFILE.FIELDS.COUNTRY}
        placeholder={Resources.PAGES.ACCOUNT_PROFILE.FIELDS.COUNTRY_PLACEHOLDER}
        placeholderDisabled
        options={countryList ?? []}
        onChange={onCountryChange}
      />
      <Box
        sx={{
          display: "flex",
          gap: "1.2rem",
        }}
      >
        <Box
          sx={{
            flexBasis: "9rem",
          }}
        >
          <InputField
            required
            label="Zip code"
            onChange={handleZipCodeChange}
            disabled={isLoading || !location.country}
            value={location.zipCode}
            autoComplete="off"
            onBlur={handleZipCodeBlur}
            placeholder="Eg: 78005"
            error={!!errorMsg.length}
          />
        </Box>
        <Box sx={{ flexGrow: 1, minWidth: "0" }}>
          <InputField
            required
            select
            disabled={!cityList.length}
            label="City"
            name="city"
            onChange={onCityChange}
            value={location.city}
          >
            {cityList.map((city) => (
              <MenuItem
                key={city}
                value={city}
                sx={{ backgroundColor: "var(--colorWhite)" }}
              >
                {city}
              </MenuItem>
            ))}
          </InputField>
        </Box>
        <Box sx={{ flexBasis: "8.3rem" }}>
          <InputField required disabled label="State" value={location.state} />
        </Box>
      </Box>
      <FormHelperText error>{errorMsg}</FormHelperText>
    </Box>
  );
}

export default Location;
