import { Box, List, Typography } from "@mui/material";
import Logo from "assets/images/pepelwerk-logo-white.svg";
import Resources from "assets/json/Resources";
import { ProfilePicture } from "components/common";
import SVGIcon from "components/icons";
import { SidebarList, SubmenuType } from "config/app";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import SideMenuList from "./SideMenuList";

interface ISideBarProps {
  menuList: SidebarList[];
  onLinkItemClick: (item: SubmenuType) => void;
  userInfo: { image: string; name: string };
}

function NewSidebar(props: ISideBarProps) {
  const { menuList, onLinkItemClick, userInfo } = props;
  return (
    <Box
      className="app-l-sidebar"
      sx={{ overflow: "auto" }}
      data-tutorial-item="sidebar"
    >
      <Box
        sx={{
          padding: "0 2.5rem",
          display: "flex",
          mb: { xl: "4rem", xs: "3rem" },
        }}
      >
        <Box sx={{ flexShrink: 0, mr: "1.5rem" }}>
          <ProfilePicture
            imageUrl={userInfo.image}
            alt="user"
            width="4.5rem"
            height="6rem"
            borderRadius="0.8rem"
          />
        </Box>
        <Box
          sx={{
            minWidth: 0,
            width: "100%",
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-start",
          }}
        >
          <Box sx={{ minWidth: 0 }}>
            <Typography
              component="h4"
              sx={{
                fontSize: { xl: "2rem !important", xs: "1.6rem !important" },
                fontWeight: "800",
                color: "var(--color-primary4)",
                lineHeight: { xl: " 2.4rem", xs: "1.9rem" },
                wordBreak: "break-word",
              }}
            >
              {userInfo.name}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <List sx={{ backgroundColor: "var(--color-secondary)" }}>
          {menuList.map((menu) => (
            <Fragment key={menu.mainMenu.key}>
              <Box
                className="app-l-sidebar__section"
                sx={{
                  ":not(:last-child)": { mb: "1.5rem" },
                }}
                data-sidebar-item={menu.mainMenu.key}
              >
                <Typography className="app-l-sidebar__title">
                  {menu.mainMenu.label}
                </Typography>
                {menu.subMenu && (
                  <SideMenuList
                    menus={menu.subMenu}
                    onLinkItemClick={onLinkItemClick}
                    IconComponent={SVGIcon}
                  />
                )}
              </Box>
            </Fragment>
          ))}
        </List>
      </Box>

      <Box
        sx={{ p: "1.9rem 0 3.5rem 2.5rem", maxWidth: "15.8rem", mt: "auto" }}
      >
        <Link target="_blank" to={Resources.COMMON.LINKS.PEPELWERK_URL}>
          <img alt="Pepelwerk Logo" src={Logo} width="100%" />
        </Link>
      </Box>
    </Box>
  );
}

export default NewSidebar;
