import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { getGeneralDocs } from "./docCenterService";
import { GeneralDocsRes } from "./types";

type APIResponse = GeneralDocsRes & FailedAPIResponse;

const useGetGeneralDocs = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse>({
    mutationFn: getGeneralDocs,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useGetGeneralDocs;
