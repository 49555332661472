/* eslint-disable react/require-default-props */
import { ExpandMore } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
} from "@mui/material";
import { ReactNode, useState } from "react";
import "./styles.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: "#FAFAFA",
      fontSize: "14px",
    },
  },
};

type CustomSelectPropType = {
  label: ReactNode;
  options: {
    label: ReactNode;
    value: string | number;
  }[];
  onChange: (value: string | number) => void;
  onPlaceholderClick?: () => void;
  placeholder?: ReactNode;
  disabled?: boolean;
  placeholderDisabled?: boolean;
  initialValue?: string;
  required?: boolean;
  error?: boolean;
};

function Select(props: CustomSelectPropType) {
  const {
    label,
    options,
    placeholder,
    onChange,
    disabled,
    placeholderDisabled,
    onPlaceholderClick,
    initialValue,
    required,
    error,
  } = props;

  const [selectedValue, setSelectedValue] = useState<string | number>(
    initialValue ?? "",
  );

  const onSelectChange = (e: SelectChangeEvent<typeof selectedValue>) => {
    const { value } = e.target;
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <FormControl sx={{ width: { md: "100%", xs: "100%" } }}>
      <InputLabel
        shrink
        required={required}
        className="select_label"
        sx={{
          fontSize: "1.4rem !important",
          backgroundColor: "transparent !important",
        }}
      >
        {label}
      </InputLabel>
      <MuiSelect
        label={label}
        variant="outlined"
        value={selectedValue}
        MenuProps={MenuProps}
        IconComponent={ExpandMore}
        onChange={onSelectChange}
        displayEmpty
        disabled={disabled}
        error={error}
        sx={{
          height: 33,
          color: selectedValue ? "" : "var(--colorLightGrey) !important",
        }}
        className="select_input"
      >
        {placeholder && (
          <MenuItem
            disabled={placeholderDisabled}
            value=""
            onClick={onPlaceholderClick}
          >
            {placeholder}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

export default Select;
