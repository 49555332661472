import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import Resources from "assets/json/Resources";
import * as React from "react";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type CustomSnackbarProps = {
  open: boolean;
  message: React.ReactNode;
  severity: "error" | "info" | "success" | "warning";
  // eslint-disable-next-line react/require-default-props
  snackbarProps?: SnackbarProps;
  autoHideDuration?: number;
  onClose?: () => void;
  disableClickAway?: boolean;
  anchorOrigin?: {
    vertical: "bottom" | "top";
    horizontal: "center" | "left" | "right";
  };
  icon?: React.ReactNode;
};

function CustomSnackbar(props: CustomSnackbarProps) {
  const {
    severity,
    open,
    message,
    snackbarProps,
    autoHideDuration,
    onClose,
    anchorOrigin,
    disableClickAway,
    icon,
  } = props;
  const keyProp = React.useId();
  let errorMessage = message;

  const handleClose = (
    event: React.SyntheticEvent<any> | Event,
    reason: string,
  ) => {
    if (disableClickAway && reason === "clickaway") {
      return;
    }
    onClose?.();
  };

  errorMessage = React.useMemo(() => {
    if (
      errorMessage === Resources.ERRORS.OTP_LIMIT_REACHED ||
      errorMessage === Resources.ERRORS.BLOCKED_PHONE_NUMBER
    ) {
      const errorText: string = errorMessage.toString();
      let textArray = [];

      const firstIndex = errorText?.toLowerCase().indexOf("contact us", 0); // find link start
      const linkEnd = errorText?.indexOf(" for", firstIndex); // find the end of link
      const firstTextSection = errorText.slice(0, firstIndex);
      const linkSection = errorText.slice(firstIndex, linkEnd);
      const secondSection = errorText.slice(linkEnd);
      textArray = [firstTextSection, linkSection, secondSection];

      return (
        <span>
          {textArray[0]}
          <a
            href={Resources.COMMON.LINKS.CONTACT_US_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline" }}
          >
            {textArray[1]}
          </a>
          {textArray[2]}
        </span>
      );
    }
    return errorMessage;
  }, [message]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      key={keyProp + errorMessage}
      {...snackbarProps}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{
          width: "100%",
        }}
        icon={icon ?? false}
        variant="standard"
      >
        {errorMessage}
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackbar;

CustomSnackbar.defaultProps = {
  autoHideDuration: 5000,
  anchorOrigin: { vertical: "top", horizontal: "center" },
  disableClickAway: false,
};
