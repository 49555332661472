import { Box, FormControlLabel, Radio, Typography } from "@mui/material";
import { PaymentType } from "services/common/types";
import { getCardNameAndImage } from "utils";
import styles from "../style.module.scss";

export type PaymentListProps = {
  creditCards: PaymentType[];
  selectedPaymentId?: string;
};

function PaymentList(props: PaymentListProps) {
  const { creditCards, selectedPaymentId } = props;

  return (
    <>
      {creditCards.map((card) => {
        const { name, imgPath } = getCardNameAndImage(card.nickName);
        return (
          <FormControlLabel
            key={card.paymentMethodId}
            className={styles.cardOptions}
            value={card.paymentMethodId}
            control={
              <Radio checked={card.paymentMethodId === selectedPaymentId} />
            }
            label={
              <Box sx={{ display: "flex" }}>
                <img
                  src={imgPath}
                  alt="creditCard"
                  className={styles.masterCard}
                />
                <Typography
                  variant="h4"
                  style={{ marginLeft: "10px", textTransform: "uppercase" }}
                >
                  {name}
                </Typography>
              </Box>
            }
          />
        );
      })}
    </>
  );
}

export default PaymentList;
