import { REACT_QUERY_KEYS, TimeConfig } from "config/app";
import { useQuery } from "react-query";
import { getAccountProfile } from "./accountProfileService";
import { AccountProfileResponseType } from "./types";

const useGetAccountProfile = () => {
  return useQuery<AccountProfileResponseType, Error>(
    REACT_QUERY_KEYS.ACCOUNT_PROFILE,
    getAccountProfile,
    {
      cacheTime: TimeConfig.ONE_MINUTE,
      staleTime: TimeConfig.ONE_HOUR,
    },
  );
};

export default useGetAccountProfile;
