import REGEX from "constants/regex";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SVGIcon from "../../../assets/icons";

const KEYS = {
  ARROW_LEFT: "ArrowLeft",
  ARROW_RIGHT: "ArrowRight",
  ARROW_UP: "ArrowUp",
  ARROW_DOWN: "ArrowDown",
  BACKSPACE: "Backspace",
};

type OtpInputProps = {
  legend: string;
  otpLength: number;
  regex?: RegExp;
  onComplete: (otp: string) => void;
  error?: boolean;
  helpText?: string;
  showAlert?: boolean;
  alertMessage?: string;
  disabled?: boolean;
  isResetOtpValues?: boolean;
  setIsResetOtpValues?: (value: boolean) => void;
} & BoxProps;

const DIGITS_PER_INPUT = 1;

function OtpInput(props: OtpInputProps) {
  const {
    onComplete,
    legend,
    otpLength,
    regex = REGEX.NUMBERS_ONLY,
    error,
    helpText,
    sx,
    showAlert,
    disabled,
    alertMessage,
    isResetOtpValues,
    setIsResetOtpValues,
    ...restBoxProps
  } = props;

  const [otpValue, setOtpValue] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [openAlert, setOpenAlert] = useState(showAlert);

  const inputRef = useRef<HTMLInputElement>(null);

  // Effect running for closing alert message
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (showAlert) {
      setOpenAlert(true);
      timeout = setTimeout(() => {
        setOpenAlert(false);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [showAlert]);

  useEffect(() => {
    if (isResetOtpValues) {
      setOtpValue("");
      setIsResetOtpValues?.(false);
    }
  }, [isResetOtpValues]);

  // split values into arrays
  const valueItems: string[] = useMemo(() => {
    const valueArray = otpValue.split("");
    const items: string[] = [];
    for (let i = 0; i < otpLength; i += 1) {
      const char = valueArray?.[i] ?? "";
      if (regex.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }
    return items;
  }, [otpValue, otpLength]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeIndex]);

  useEffect(() => {
    // removes whitespace
    onComplete(otpValue.replace(REGEX.WHITESPACE, ""));
  }, [valueItems]);

  const focusNextInput = () => {
    const nextIndex = activeIndex + 1;
    if (nextIndex > otpLength) return;
    setActiveIndex(nextIndex);
  };

  const focusPreviousInput = () => {
    const nextIndex = activeIndex - 1;
    if (nextIndex < 0) return;
    setActiveIndex(nextIndex);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ): void => {
    const { target } = e;

    let targetValue = target.value.trim();
    const isDigitValue = regex.test(targetValue);

    if (!isDigitValue && targetValue !== "") return;

    targetValue = isDigitValue ? targetValue : " ";
    const targetValueLength = targetValue.length;

    if (targetValueLength <= DIGITS_PER_INPUT) {
      const newValue =
        otpValue.substring(0, index) +
        targetValue +
        otpValue.substring(index + 1);

      setOtpValue(newValue);
      if (!isDigitValue) return;
      focusNextInput();
    } else {
      // handling paste event

      setOtpValue(targetValue);

      let indexToFocus = 0;
      if (targetValueLength === otpLength || targetValueLength > otpLength) {
        // focus to last input
        indexToFocus = otpLength - 1;
      } else if (targetValueLength < otpLength) {
        // focus to next input of last filled input
        indexToFocus = targetValueLength;
      }

      setActiveIndex(indexToFocus);
    }

    if (targetValueLength === DIGITS_PER_INPUT) {
      focusNextInput();
    }
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;
    const { value } = target;

    if (key === KEYS.ARROW_RIGHT || key === KEYS.ARROW_DOWN) {
      e.preventDefault();
      focusNextInput();
    }

    if (key === KEYS.ARROW_LEFT || key === KEYS.ARROW_UP) {
      e.preventDefault();
      focusPreviousInput();
    }

    target.setSelectionRange(0, value.length);

    if (e.key !== KEYS.BACKSPACE || value !== "") {
      return;
    }
    // Change focus
    focusPreviousInput();
  };

  const handleInputFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    setActiveIndex(index);
    const { target } = e;
    target.setSelectionRange(0, target.value.length);
  };

  return (
    <Box
      className="app-c-otp__field"
      sx={{ ...sx }}
      {...restBoxProps}
      position="relative"
    >
      <fieldset
        className={`app-c-otp__fieldset ${
          error && "app-c-otp__fieldset__error"
        }`}
      >
        <legend className="app-c-otp__legend">{legend}</legend>
        <Grid container spacing={0} sx={{ flexWrap: "nowrap" }}>
          {valueItems.map((digit, index) => (
            <Grid item key={`otp-input-${index + 1}`}>
              <TextField
                className="otp-input-textfield"
                id={`${legend} ${index + 1}-digit`}
                inputRef={index === activeIndex ? inputRef : null}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                onChange={(e) => handleInputChange(e, index)}
                autoComplete="off"
                value={digit}
                onKeyDown={handleInputKeyDown}
                onFocus={(e) => handleInputFocus(e, index)}
                disabled={disabled}
                aria-label={`otp digit ${index + 1}`}
              />
            </Grid>
          ))}
        </Grid>
      </fieldset>
      <Typography
        component="span"
        sx={{
          position: "absolute",
          lineHeight: "1",
          fontSize: "0.875rem",
          top: "3.688rem",
          left: "0.938rem",
          color: "var(--danger)",
        }}
      >
        {helpText}
      </Typography>
      <Box className={`app-l-otp__info success ${openAlert ? "open" : ""}`}>
        <Box className="app-l-otp__icon">
          <SVGIcon name="tick-circle" />
        </Box>
        <Box className="app-l-otp__text">
          <Typography>{alertMessage}</Typography>
        </Box>
        <Box className="app-l-otp__close">
          <IconButton size="small" aria-label="close" sx={{ color: "inherit" }}>
            <SVGIcon name="cross-circle" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default OtpInput;
