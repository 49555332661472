import { useEffect } from "react";

export type TokenPayload = {
  number: string;
  expMonth: string;
  expYear: string;
  cvc: string;
  addressZip: string;
  name: string;
  addressCountry: string;
};
type CardDetails = {
  last4: string;
  name: string;
  brand: string;
};

type ErrorDetails = {
  code: string;
  doc_url: string;
  message: string;
  param: string;
  type: string;
};

export type CCSuccessResponse = {
  type: string;
  id: string;
  card: CardDetails;
  error: ErrorDetails;
};

type Response =
  | { status: "error"; errorMsg: string }
  | { status: "success"; tokenId: string; nickName: string };

const useCreateCardToken = (stripeKey: string) => {
  useEffect(() => {
    try {
      window?.Stripe?.setPublishableKey(stripeKey);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Stripe failed", error);
    }
  }, [stripeKey]);

  const handleStripeResponse = (data: CCSuccessResponse): Promise<Response> => {
    return new Promise((resolve) => {
      if (data.error) {
        const { message } = data.error;

        resolve({ status: "error", errorMsg: message });
      }

      const cardNickname = `${data.card.brand} ending ${data.card.last4}`;

      resolve({ status: "success", nickName: cardNickname, tokenId: data.id });
    });
  };

  const createToken = async (payload: TokenPayload): Promise<Response> => {
    return new Promise((resolve, reject) => {
      try {
        window?.Stripe?.createToken(
          payload,
          (status: number, data: CCSuccessResponse) => {
            const result = handleStripeResponse(data);
            resolve(result);
          },
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Stripe failed", error);
        reject(error);
      }
    });
  };

  return { createToken };
};

export default useCreateCardToken;
