import { CssBaseline } from "@mui/material";
import { LOCAL_STORAGE_KEYS, SESSION_KEYS } from "config/app";
import { TalentRegistrationStepTypes } from "constants/sharedTypes";
import Guardian from "pages/Guardian";
import { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { isReferralInfo, setToLocalStorage, setToSessionStorage } from "utils";
import VerificationTime from "pages/VerificationTime";

import CoachingSessionMandatory from "./CoachingSessionMandatory";
import EmailVerification from "./EmailVerification";
import KnowMore from "./KnowMore";
import PhoneNumberVerification from "./PhoneNumber";
import RegisterForm from "./RegisterForm";

type FormSelectorProps = {
  currentStep?: TalentRegistrationStepTypes;
};
interface CustomizedState {
  section?: TalentRegistrationStepTypes;
}

function SignUp(props: FormSelectorProps) {
  const { currentStep } = props;
  const [step, setStep] = useState<TalentRegistrationStepTypes>(
    currentStep ?? "NEW_USER",
  );
  const [emailOTPBalance, setEmailOTPBalance] = useState(0);

  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const state = location.state as CustomizedState;

  useEffect(() => {
    const encryptedInfo =
      searchParams.get("referral_code") ?? params?.campaignInfo;

    if (encryptedInfo) {
      if (isReferralInfo(encryptedInfo)) {
        setToLocalStorage(LOCAL_STORAGE_KEYS.REFERRAL_INFO, encryptedInfo);
      } else {
        setToSessionStorage(SESSION_KEYS.CAMPAIGN, encryptedInfo);
      }
    }
  }, [params]);

  useLayoutEffect(() => {
    setStep(state?.section ?? "NEW_USER");
  }, [location, state?.section]);

  const onComplete = (nextStep: TalentRegistrationStepTypes) => {
    setStep(nextStep);
  };

  const handleAttemptsLeft = (attempsLeftCound: number) => {
    setEmailOTPBalance(attempsLeftCound);
  };

  const renderForm = (): ReactNode => {
    switch (step) {
      case "NEW_USER":
        return <RegisterForm onComplete={onComplete} />;
      case "VERIFY_PHONE":
        return (
          <PhoneNumberVerification
            onComplete={onComplete}
            handleAttemptsLeft={handleAttemptsLeft}
          />
        );
      // case "ACCESS_CODE":
      //   return <AccessCode onComplete={onComplete} />;
      case "VERIFY_EMAIL":
        return (
          <EmailVerification
            onComplete={onComplete}
            attemptLeft={emailOTPBalance}
          />
        );
      case "WELCOME":
        return <KnowMore />;
      case "GUARDIAN_APPROVAL":
        return <Guardian />;
      case "COACHING_SESSION_BLOCKER":
        return <CoachingSessionMandatory />;
      case "PHONE_COOL_DOWN_PERIOD":
        return <VerificationTime />;
      case "EMAIL_COOL_DOWN_PERIOD":
        return <VerificationTime />;

      default:
        return <RegisterForm onComplete={onComplete} />;
    }
  };

  return (
    <>
      <CssBaseline />
      {renderForm()}
    </>
  );
}

export default SignUp;

SignUp.defaultProps = {
  currentStep: "NEW_USER",
};
