import Resources from "assets/json/Resources";
import requests from "config/api";
import { REACT_QUERY_KEYS } from "config/app";
import API_ENDPOINTS from "constants/api";
import useAuth from "hooks/useAuth";
import useSessionTimeOut from "hooks/useSessionTimeOut";
import useTimeout from "hooks/useTimeout";
import { useQuery } from "react-query";
import { getResponseErrorMessage } from "utils";

async function fetchProfileData() {
  return requests.post(API_ENDPOINTS.HOME_DETAILS);
}

const useGetTalentHomeDetails = () => {
  const { setGlobalAlert, setGlobalLoader } = useAuth();
  const sessionCheck = useSessionTimeOut();
  const { reset } = useTimeout(() => {
    setGlobalAlert({ message: "", messageType: "error" });
  }, 4000);
  return useQuery(
    REACT_QUERY_KEYS.USER_HOME_DETAILS,
    async () => {
      const response = await fetchProfileData();
      sessionCheck(response);
      if (response.result) {
        return response.result;
      }
      const message = getResponseErrorMessage(response);
      setGlobalAlert({
        message,
        messageType: "error",
      });
      reset();
      return {};
    },

    {
      onSettled: () => setGlobalLoader(false),
      onError: () => {
        setGlobalAlert({
          message: Resources.ERRORS.SOMETHING,
          messageType: "error",
        });
        reset();
      },
      refetchOnWindowFocus: true,
    },
  );
};

export default useGetTalentHomeDetails;
