import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { checkoutItems } from "./featureCenterService";
import { CheckoutPayload, CheckoutSuccessResponse } from "./types";

type Response = CheckoutSuccessResponse & FailedAPIResponse;

const useCheckout = (handler?: HandlerType<CheckoutSuccessResponse>) => {
  return useMutationWithHandler<Response, CheckoutPayload>({
    mutationFn: checkoutItems,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useCheckout;
