import Button, { ButtonProps } from "@mui/material/Button";
import SvgIcon, { SVGIconType } from "components/icons";

type CircularButtonProps = {
  iconName: SVGIconType;
} & ButtonProps;

function CircularButton(props: CircularButtonProps) {
  const { sx, iconName, ...buttonProps } = props;

  return (
    <Button
      variant="contained"
      sx={{
        borderRadius: "50%",
        width: "2.4rem",
        height: "2.4rem",
        padding: "0.5rem",
        minWidth: "0",
        minHeight: "0",
        boxShadow: "0px 3px 6px #00000029",
        svg: { width: "100%", height: "100%" },
        "&[disabled]": {
          color: "var(--colorTextPrimary)",
        },
        ...sx,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
    >
      <SvgIcon name={iconName} />
    </Button>
  );
}

export default CircularButton;
