import { Box } from "@mui/material";
import { LogoBlack } from "assets/images";

function NoOtherFeature() {
  return (
    <Box
      className="app-c-card--default"
      sx={{
        marginTop: "1.5rem",
        background: "var(--color-primary) !important",
        position: "relative",
        padding: "3rem",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        height: { xs: "15rem", sm: "25rem", md: "30rem", lg: "auto" },
        overflow: "hidden",
        flexGrow: 1,

        "&:before, &:after": {
          content: '""',
          width: { sm: "10rem", xs: "6rem" },
          height: { sm: "10rem", xs: "6rem" },
          borderRadius: "50%",
          background: "var(--colorWhite)",
          opacity: "0.3",
          position: "absolute",
        },
        "&:before": {
          left: { xs: "2.9rem", sm: "15%" },
          top: { xs: " 0.7rem", sm: "2.7rem" },
        },
        "&:after": {
          left: { xs: "-2.5rem", sm: "-1.5rem" },
          bottom: { xs: "1.5rem", sm: "-1.5rem" },
        },
        " img": {
          maxWidth: { lg: "100%", sm: "20rem", xs: " 14rem" },
        },
      }}
    >
      <img src={LogoBlack} alt="logo" />
    </Box>
  );
}

export default NoOtherFeature;
