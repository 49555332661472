import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import Resources from "assets/json/Resources";
import FTUELayout from "components/FTUELayout";
import { LoadingButton, Snackbar } from "components/common";
import { ROUTES } from "config/app";
import REGEX from "constants/regex";
import { InputEventType, SnackbarType } from "constants/sharedTypes";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Link,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useGuardianConsent from "services/useGuardianConsent";
import useValidateGuardianToken, {
  ConsentRecord,
  TokenType,
} from "services/useValidateToken";
import { InvalidOuter, InvalidView } from "components/ui";
import CountryPhoneBlock from "components/CountryPhoneBlock";
import { useAuth } from "hooks";
import FormField from "./FormField";
import { FormInputs } from "./types";

const { GUARDIAN_CONSENT } = Resources.PAGES.SIGN_UP;

const MIN_AGE = 18;

const validationMessages = {
  firstName: {
    required: "Please fill first name",
    regex: "First name cannot contain special characters",
  },
  lastName: {
    required: "Please fill last name",
    regex: "Last name cannot contain special characters",
  },
  age: {
    required: "Please fill age",
    regex: "Age can contain numbers only",
    maxLength: "Age cannot exceed 3 digits",
    minValue: "Age must be greater than 18",
  },
  relation: {
    required: "Please fill relationship",
    regex: "Relationship cannot contain special characters",
  },
};

export default function Guardian() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [acceptTnC, setAcceptTnC] = useState(false);
  const [acceptConsent, setAcceptConsent] = useState(false);
  const [talentName, setTalentName] = useState({
    firstName: "",
    lastName: "",
  });
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    open: false,
    message: "",
    type: "error",
  });
  const [invalid, setInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setGlobalAlert } = useAuth();
  const { pathname } = useLocation();
  const tokenType: TokenType = matchPath(
    { path: ROUTES.PUBLIC.GUARDIAN_SHORT },
    pathname,
  )
    ? "sms"
    : "email";

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm<FormInputs>({
    mode: "all",
    shouldFocusError: true,
    defaultValues: {
      guardianEmail: "",
      firstName: "",
      lastName: "",
      relation: "",
      talentId: "",
      consentId: "",
      phoneNumber: "",
      isdCountry: "",
      isdCode: "",
    },
  });

  const handleError = (errorMsg: string) => {
    setSnackbar({ open: true, type: "error", message: errorMsg });
  };

  const onValidateSuccess = (data: ConsentRecord) => {
    if (data) {
      setValue("guardianEmail", data?.guardianEmail);
      setValue("isdCode", data?.guardianIsdCode);
      setValue("isdCountry", data?.guardianIsdCountry);
      setValue("phoneNumber", data?.guardianPhoneNumber);
      setValue("talentId", data?.talentId);
      setValue("consentId", data?.consentId);
      setTalentName({
        firstName: data?.talentFirstName,
        lastName: data?.talentLastName,
      });
      setValue("firstName", data?.guardianFirstName ?? "");
      setValue("lastName", data?.guardianLastName ?? "");
      setValue("age", data?.guardianAge?.toString() ?? "");
      setValue("relation", data?.guardianRelation ?? "");
    }
  };

  const handleTokenError = (error: string) => {
    setInvalid(true);
    setErrorMessage(error);
    setGlobalAlert({ messageType: "error", message: error });
  };

  const {
    data: details,
    mutateAsync: validateGuardianToken,
    isLoading: loading,
  } = useValidateGuardianToken(tokenType, {
    onError: handleTokenError,
    onSuccess: onValidateSuccess,
  });

  const { mutateAsync: setGuardianConsent, isLoading } = useGuardianConsent({
    onError: handleError,
  });

  useEffect(() => {
    if (token) {
      validateGuardianToken(token);
    }
  }, []);

  const onSubmit = async (formData: FormInputs) => {
    const { ACCEPT_TNC, ACCEPT_CONSENT } = GUARDIAN_CONSENT.ERRORS;
    if (!acceptTnC || !acceptConsent) {
      setSnackbar({
        open: true,
        type: "error",
        message: !acceptTnC ? ACCEPT_TNC : ACCEPT_CONSENT,
      });
      return;
    }
    const formDataCopy = { ...formData };
    const { isdCode, isdCountry, phoneNumber, ...restPayload } = formDataCopy;
    const response = await setGuardianConsent({
      ...restPayload,
      ...(isdCode &&
        isdCountry &&
        phoneNumber && {
          guardianIsdCode: isdCode,
          guardianIsdCountry: isdCountry,
          guardianPhoneNumber: phoneNumber,
        }),
      isTncAccepted: acceptTnC,
      isConsentDone: acceptConsent,
    });
    if (!response.appErrors?.exists) {
      navigate(ROUTES.PUBLIC.GUARDIAN_SUCCESS);
    }
  };

  const onFormError = () => {
    setSnackbar({
      open: true,
      type: "error",
      message: GUARDIAN_CONSENT.ERRORS.INVALID_FORM,
    });
  };

  const handleTnCChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setAcceptTnC(checked);
  };

  const handleConsentChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setAcceptConsent(checked);
  };

  const closeSnackbar = () =>
    setSnackbar({ ...snackbar, open: false, message: "" });

  const handleNumberOnlyInputs = (e: InputEventType) => {
    if (!REGEX.ONLY_NUMBERS.test(e.target.value)) {
      setValue("age", e.target.value.replace(/\D/g, ""));
    }
  };
  const validateAge = (value: number): boolean | string => {
    return value <= MIN_AGE ? validationMessages.age.minValue : true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(REGEX.RELATION_TO_TALENT, "");
    setValue("relation", newValue);
  };

  const guardianRecord = details?.result?.guardianConsentRecord;
  const talentFullName = `${talentName.firstName} ${talentName.lastName}`;

  return (
    <Box sx={{ flex: 1 }}>
      {loading && (
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={24} />
        </Box>
      )}

      {invalid ? (
        <InvalidOuter>
          <InvalidView erroeMessage={errorMessage} />
        </InvalidOuter>
      ) : (
        <Box sx={{ height: "100%" }}>
          {!loading && (
            <FTUELayout
              title1={GUARDIAN_CONSENT.DESC}
              title2={GUARDIAN_CONSENT.GUARDIAN}
              containerStyle={{ maxWidth: "none" }}
              ftueWrapperStyle={{ height: "100%" }}
            >
              <Snackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.type}
                onClose={closeSnackbar}
                disableClickAway
              />
              <Box sx={{ maxWidth: "51rem", pt: { xs: "1.5rem", sm: "3rem" } }}>
                <Typography
                  display="inline"
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: "bold !important",
                    lineHeight: "1.68rem",
                  }}
                >
                  {talentFullName}&nbsp;
                </Typography>
                <Typography
                  display="inline"
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: "50rem !important",
                    lineHeight: "1.68rem",
                  }}
                >
                  {GUARDIAN_CONSENT.GUARDIAN_INFO_1}
                  <MuiLink
                    component={Link}
                    style={{
                      color: "inherit",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                    to={Resources.COMMON.LINKS.AI_CAREER_ASSISTANT}
                    target="_blank"
                  >
                    {GUARDIAN_CONSENT.PEPELWERK_CAREER_ASSISTANT}
                  </MuiLink>
                  {GUARDIAN_CONSENT.GUARDIAN_INFO_2.replace(
                    "{talentName}",
                    talentName.firstName,
                  )}
                </Typography>
              </Box>

              <Box sx={{ maxWidth: "51rem", pt: { xs: "1.5rem", sm: "3rem" } }}>
                <Typography
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: "50rem !important",
                    lineHeight: "1.68rem",
                  }}
                >
                  {GUARDIAN_CONSENT.APP_SUCCESS}
                </Typography>
              </Box>
              <Box sx={{ maxWidth: "51rem", pt: { xs: "1.5rem", sm: "3rem" } }}>
                <Typography
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: "50rem !important",
                    lineHeight: "1.68rem",
                  }}
                >
                  {GUARDIAN_CONSENT.INFO_SUBMIT}
                </Typography>
              </Box>
              <Box
                sx={{
                  paddingTop: "1.5em",
                  paddingBottom: "3rem",
                  maxWidth: "90rem",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(auto-fit, minmax(250px, 1fr))",
                        sm: "1fr 1fr",
                      },
                      columnGap: "2rem",
                    }}
                  >
                    <Box sx={{ paddingTop: { sm: "3rem", xs: "1.5rem" } }}>
                      <Controller
                        name="guardianEmail"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...register("guardianEmail")}
                            {...field}
                            fullWidth
                            label={GUARDIAN_CONSENT.FIELDS.GUARDIAN_MAIL}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            disabled
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ paddingTop: { sm: "3rem", xs: "1.5rem" } }}>
                      <Controller
                        control={control}
                        name="isdCountry"
                        render={({ field }) => (
                          <CountryPhoneBlock
                            fieldErrors={{
                              isdCode: errors.isdCode?.message,
                              phoneNumber: errors.phoneNumber?.message,
                            }}
                            control={control}
                            disabled
                            label={GUARDIAN_CONSENT.FIELDS.GUARDIAN_MOBILE}
                            {...field}
                          />
                        )}
                      />
                    </Box>

                    <Box sx={{ paddingTop: { sm: "3rem", xs: "1.5rem" } }}>
                      <FormField
                        name="firstName"
                        label={GUARDIAN_CONSENT.FIELDS.F_NAME}
                        control={control}
                        register={register}
                        disabled={!!guardianRecord?.guardianFirstName}
                        error={!!errors?.firstName}
                        placeHolder="Enter your first name"
                        rules={{
                          required: {
                            value: true,
                            message: validationMessages.firstName.required,
                          },
                          pattern: {
                            value: REGEX.ALPHABETS_AND_SPACE,
                            message: validationMessages.firstName.regex,
                          },
                        }}
                        helperText={errors.firstName?.message}
                      />
                    </Box>
                    <Box sx={{ paddingTop: { sm: "3rem", xs: "1.5rem" } }}>
                      <FormField
                        name="lastName"
                        label={GUARDIAN_CONSENT.FIELDS.L_NAME}
                        control={control}
                        disabled={!!guardianRecord?.guardianLastName}
                        register={register}
                        error={!!errors?.lastName}
                        placeHolder="Enter your last name"
                        rules={{
                          required: {
                            value: true,
                            message: validationMessages.lastName.required,
                          },
                          pattern: {
                            value: REGEX.ALPHABETS_AND_SPACE,
                            message: validationMessages.lastName.regex,
                          },
                        }}
                        helperText={errors.lastName?.message}
                      />
                    </Box>
                    <Box sx={{ paddingTop: { sm: "3rem", xs: "1.5rem" } }}>
                      <FormField
                        name="age"
                        label={GUARDIAN_CONSENT.FIELDS.AGE}
                        control={control}
                        register={register}
                        error={!!errors?.age}
                        placeHolder="Enter your age"
                        rules={{
                          required: {
                            value: true,
                            message: validationMessages.age.required,
                          },
                          validate: validateAge,
                          maxLength: {
                            value: 3,
                            message: validationMessages.age.maxLength,
                          },
                          onChange: handleNumberOnlyInputs,
                        }}
                        inputProps={{
                          inputMode: "numeric",
                          maxLength: 3,
                          pattern: "[0-9]*",
                          type: "text",
                        }}
                        helperText={errors.age?.message}
                      />
                    </Box>
                    <Box sx={{ paddingTop: { sm: "3rem", xs: "1.5rem" } }}>
                      <FormField
                        name="relation"
                        label={GUARDIAN_CONSENT.FIELDS.TALENT_RELATION}
                        control={control}
                        register={register}
                        disabled={!!guardianRecord?.guardianRelation}
                        error={!!errors?.relation}
                        placeHolder="Enter your relationship to minor"
                        rules={{
                          required: {
                            value: true,
                            message: validationMessages.relation.required,
                          },
                          pattern: {
                            value: REGEX.ALPHABETS_AND_SPACE,
                            message: validationMessages.relation.regex,
                          },
                          onChange: handleChange,
                        }}
                        helperText={errors.relation?.message}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      paddingTop: { sm: "3rem", xs: "1.5rem" },
                      fontSize: "1.1rem",
                      fontWeight: 500,
                    }}
                  >
                    <FormControlLabel
                      sx={{ ml: 0, mr: 0, gap: "0.9rem" }}
                      control={
                        <Checkbox
                          id="acceptConsent"
                          value={acceptConsent}
                          onChange={handleConsentChange}
                        />
                      }
                      label={
                        <Typography
                          sx={{ color: "var(--color-secondary)" }}
                          display="inline"
                        >
                          {`${GUARDIAN_CONSENT.CONSENT_DESC_2} `}
                          <Typography
                            component="span"
                            sx={{ fontWeight: "bold", display: "inline-block" }}
                          >
                            {talentFullName}&nbsp;
                          </Typography>
                          {`${GUARDIAN_CONSENT.CONSENT_DESC_3}`}
                        </Typography>
                      }
                    />
                  </Box>

                  <Box
                    sx={{
                      paddingTop: { sm: "2rem", xs: "1.5rem" },
                      fontSize: "1.1rem",
                      fontWeight: 500,
                    }}
                  >
                    <FormControlLabel
                      sx={{ ml: 0, mr: 0, gap: "0.9rem" }}
                      control={
                        <Checkbox
                          id="acceptTnC"
                          checked={acceptTnC}
                          onChange={handleTnCChange}
                        />
                      }
                      label={
                        <Typography
                          display="inline"
                          style={{ color: "var(list-primary) !important" }}
                        >
                          I accept these{" "}
                          <Link
                            to={ROUTES.PUBLIC.TERMS_AND_CONDITIONS}
                            target="_blank"
                            className="tc-link"
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              textDecoration: "underline",
                            }}
                          >
                            Terms & Conditions
                          </Link>
                        </Typography>
                      }
                    />
                  </Box>

                  <Box
                    sx={{
                      maxWidth: "36.8rem",
                      paddingTop: { sm: "2rem", xs: "1.5rem" },
                    }}
                  >
                    <LoadingButton
                      loading={isLoading}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleSubmit(onSubmit, onFormError)}
                      label={Resources.COMMON.BUTTONS.SUBMIT}
                    />
                  </Box>
                </Box>
              </Box>
            </FTUELayout>
          )}
        </Box>
      )}
    </Box>
  );
}
