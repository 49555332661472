import { Box, List } from "@mui/material";
import SVGIcon, { SVGIconProps } from "components/icons";
import useSidebar from "hooks/useSidebar";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import SidebarLink from "./SidebarLink";
import { SideMenuListProps } from "./types";

function SmallIcon(props: SVGIconProps) {
  const { name } = props;
  return (
    <Box
      sx={{
        width: "2rem",
        height: "2rem",
        alignSelf: "center",
        "& svg": { width: "100%", height: "100%" },
      }}
    >
      <SVGIcon name={name} />
    </Box>
  );
}

function SideMenuList(props: SideMenuListProps) {
  const { menus, onLinkItemClick, IconComponent, textProps } = props;
  const { pathname } = useLocation();
  const { activeMenu } = useSidebar();

  return (
    <>
      {menus.map((menu) => (
        <Fragment key={menu.key}>
          <>
            <SidebarLink
              id={menu.key}
              onLinkClick={() => onLinkItemClick(menu)}
              activeClassName={
                activeMenu === menu.key || menu.link === pathname
                  ? "active"
                  : ""
              }
              IconComponent={<IconComponent name={menu.icon} />}
              text={menu.name}
              link={menu.link}
              textProps={textProps}
              external={menu.externalLink}
              warning={menu.warning}
            />
            {menu.submenu && (
              <List className="app-l-nav__sub" component="div" disablePadding>
                <SideMenuList
                  menus={menu.submenu}
                  IconComponent={SmallIcon}
                  onLinkItemClick={onLinkItemClick}
                  textProps={{
                    sx: {
                      fontSize: "1.4rem",
                      fontWeight: "500",
                      transition: "color ease 0.2s",
                      textTransform: "capitalize",
                    },
                  }}
                />
              </List>
            )}
          </>
        </Fragment>
      ))}
    </>
  );
}

export default SideMenuList;
