import requests from "config/api";
import API_ENDPOINTS from "constants/api";

export const accountProfile = async () => {
  return requests.get(API_ENDPOINTS.HOME_DETAILS);
};

export const updateName = async (payload: any) => {
  return requests.post(API_ENDPOINTS.UPDATE_NAME, payload);
};

export const getGeoLocation = async (payload: any) => {
  return requests.post(API_ENDPOINTS.GEO_LOCATION, payload);
};

export const setLocation = async (payload: any) => {
  return requests.post(API_ENDPOINTS.SET_LOCATION, payload);
};

export const onBehalfOfLogin = async (payload: any) => {
  return requests.post(API_ENDPOINTS.ON_BEHALF_OF_LOGIN, payload);
};

export const deactivateAccount = async () => {
  return requests.post(API_ENDPOINTS.DEACTIVATE_ACCOUNT);
};

export const getHowCanWeHelp = async () => {
  return requests.get(API_ENDPOINTS.GETHOWCANWEHELP);
};

export const saveHelpOption = async (payload: any) => {
  return requests.post(API_ENDPOINTS.SAVEHELPOPTION, payload);
};

export const getCareerPhaseOptions = async (config: any) => {
  return requests.get(`${API_ENDPOINTS.CAREERPHASEOPTIONS}?type=${config}`);
};

export const saveCareerPhaseOptions = async (payload: any) => {
  return requests.post(API_ENDPOINTS.SAVECAREEROPTIONS, payload);
};

export const getEmailOTP = async () => {
  return requests.get(API_ENDPOINTS.EMAIL_OTP);
};

export const validateOldEmail = async (payload: any) => {
  return requests.post(API_ENDPOINTS.OLD_EMAIL_VERIFY, payload);
};

export const validateNewEmail = async (payload: any) => {
  return requests.post(API_ENDPOINTS.NEW_EMAIL_VERIFY, payload);
};

export const changePassword = async (payload: any) => {
  return requests.post(API_ENDPOINTS.CHANGE_PASSWORD, payload);
};

export const updatePhone = async (payload: any) => {
  return requests.post(API_ENDPOINTS.UPDATE_PHONE_NUMBER, payload);
};

export const getPhoneValidationOtp = async () => {
  return requests.get(API_ENDPOINTS.TRIGGER_OTP_SMS);
};

export const verifyPhone = async (payload: any) => {
  return requests.post(API_ENDPOINTS.VERIFY_PHONE, payload);
};
