import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { setWelcomeOptions } from "./accountProfileService";
import { SetWelcomePayload, WelcomeResponse } from "./types";

type APIResponse = WelcomeResponse & FailedAPIResponse;

const useSetWelcome = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, SetWelcomePayload>({
    mutationFn: setWelcomeOptions,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useSetWelcome;
