import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import Resources from "assets/json/Resources";
import { verifyPhoneOtp } from "./authServices";
import { VerifyPhonePayload, VerifyPhoneResponse } from "./type";

type APIResponse = VerifyPhoneResponse & FailedAPIResponse;

const useVerifyForgotPhone = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, VerifyPhonePayload>({
    mutationFn: verifyPhoneOtp,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useVerifyForgotPhone;
