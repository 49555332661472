import {
  emailSchema,
  isdCountrySchema,
  isdSchema,
  phoneNumberSchema,
} from "utils/validationSchema";
import * as yup from "yup";
import { ROUTES } from "config/app";

export const ageRange = {
  below18: "BELOW_18",
  between18and21: "BETWEEN_18_AND_21",
  above21: "ABOVE_21",
};

export const ageOptions = [
  { label: "< 18", value: ageRange.below18 },
  { label: "18 - 21", value: ageRange.between18and21 },
  { label: "> 21", value: ageRange.above21 },
];

const emailErrorMsg = {
  required: "Please fill email",
  regex:
    "Double check the email address you have entered. Looks like something is off!",
};

export const AGE: Record<string, string> = {
  BELOW_18: "< 18",
  BETWEEN_18_AND_21: "18 - 21",
  ABOVE_21: "> 21",
};

export type ChangeAgeFormType = yup.InferType<typeof changeAgeSchema>;
export type FormField = keyof typeof changeAgeSchema.fields;

export const changeAgeSchema = yup.object({
  ageRange: yup
    .mixed()
    .oneOf(Object.values(ageRange), "Age is required")
    .required("Age is required"),

  guardianEmail: yup.string().when("ageRange", {
    is: (value: string) => value === ageRange.below18,
    then: (schema) =>
      schema.concat(
        emailSchema({
          required: "Please fill guardian email",
          regex: emailErrorMsg.regex,
        }),
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
  isdCode: yup.string().when("ageRange", {
    is: (value: string) => value === ageRange.below18,
    then: () => isdSchema,
    otherwise: (schema) => schema.notRequired(),
  }),
  isdCountry: yup.string().when("ageRange", {
    is: (value: string) => value === ageRange.below18,
    then: () => isdCountrySchema,
    otherwise: (schema) => schema.notRequired(),
  }),
  phoneNumber: yup.string().when("ageRange", {
    is: (value: string) => value === ageRange.below18,
    then: () => phoneNumberSchema,
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const breadcrumbs = [
  {
    text: "Account Settings",
    active: true,
    pageLink: ROUTES.PRIVATE.ACCOUNT_PROFILE,
  },
  { text: "Change Your Age" },
];
