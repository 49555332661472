import {
  Box,
  BoxProps,
  IconButton,
  SxProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import ConfirmDialog, { ConfirmProps } from "components/ConfirmDialog";
import { Breadcrumb } from "components/common";
import { BreadcrumbProps } from "components/common/Breadcrumb";
import SVGIcon from "components/icons";
import { ReactNode } from "react";
import { formatCurrency } from "utils";

type CheckoutLayoutProps = {
  breadCrumbs: BreadcrumbProps["crumbs"];
  children: ReactNode;
} & ConfirmProps;

type CartTitleProps = {
  cartItemCount?: number;
  onButtonClick: () => void;
  title: string;
  containerStyle?: SxProps;
};

type ContainerProps = { children: ReactNode } & BoxProps;
type TitleProps = { title: ReactNode } & TypographyProps;
type TotalProps = { amount: number; label: string } & TypographyProps;

function Title(props: TitleProps) {
  const { title, sx, ...typographyProps } = props;
  return (
    <Typography
      component="h3"
      sx={{
        fontSize: "2rem !important",
        fontFamily: "var(--font-primary-700)",
        color: "var(--colorTextPrimary)",
        margin: "0 0 0",
        lineHeight: "1",
        ...sx,
      }}
      {...(typographyProps as any)}
    >
      {title}
    </Typography>
  );
}

function Total(props: TotalProps) {
  const { amount, label, sx, ...typographyProps } = props;
  return (
    <Typography
      component="h3"
      sx={{
        fontSize: "2rem !important",
        fontFamily: "var(--font-primary-700)",
        color: "var(--color-secondary)",
        textAlign: "right",
        ...sx,
      }}
      {...(typographyProps as any)}
    >
      {label} {formatCurrency(amount, 2)}
    </Typography>
  );
}

function ItemContainer(props: ContainerProps) {
  const { children, sx, ...boxProps } = props;
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", mb: "3rem", ...sx }}
      {...boxProps}
    >
      {children}
    </Box>
  );
}

function CheckoutHeader(props: CartTitleProps) {
  const { cartItemCount, title, containerStyle, onButtonClick } = props;
  return (
    <Box sx={{ display: "flex", ...containerStyle }}>
      <Box sx={{ flexShrink: 0, marginRight: "1.5rem", marginLeft: "-1rem" }}>
        <IconButton sx={{ padding: 0 }} onClick={onButtonClick}>
          <SVGIcon name="angle-left" />
        </IconButton>
      </Box>
      <Box>
        <Title title={title} />
        {/* don't skip 0 cart item */}
        {cartItemCount !== undefined && (
          <Typography
            component="p"
            sx={{
              fontSize: "1.4rem !important",
              fontFamily: "var(--font-primary-500)",
              color: "var(--color-secondary)",
              mb: "3rem",
            }}
          >
            {cartItemCount} item(s) in your cart
          </Typography>
        )}
      </Box>
    </Box>
  );
}

function ContentContainer(props: ContainerProps) {
  const { children, sx, ...boxProps } = props;
  return (
    <Box
      sx={{
        mb: "3rem",
        maxWidth: { xs: "none", sm: "75%", md: "75%", lg: "62.4rem" },
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
}

function CheckoutLayout(props: CheckoutLayoutProps) {
  const { breadCrumbs, children, ...confirmDialogProps } = props;
  return (
    <Box
      className="app-l-container"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Breadcrumb crumbs={breadCrumbs} />
      <Box
        sx={{
          marginTop: "4.8rem",
          flexGrow: "1",
          minHeight: "0",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ConfirmDialog {...confirmDialogProps}>{children}</ConfirmDialog>
      </Box>
    </Box>
  );
}

CheckoutLayout.Title = Title;
CheckoutLayout.Price = Total;
CheckoutLayout.CheckoutHeader = CheckoutHeader;
CheckoutLayout.ItemContainer = ItemContainer;
CheckoutLayout.ContentContainer = ContentContainer;
export default CheckoutLayout;
