import { Box, FormLabel, Typography } from "@mui/material";
import { CircularButton } from "components/ui";

type EditPhoneProps = {
  phoneNumber: string;
  onEdit?: () => void;
};
function EditPhone(props: EditPhoneProps) {
  const { phoneNumber, onEdit } = props;
  return (
    <>
      <FormLabel
        component="legend"
        sx={{ fontSize: "1.4rem", color: "var(--list-primary)" }}
      >
        Mobile number
      </FormLabel>
      <Box
        sx={{
          mt: "1.5rem",
          display: "flex",
          alignItems: "center",
          gap: "1.5rem",
        }}
      >
        <Typography sx={{ fontSize: "2.4rem !important", fontWeight: 700 }}>
          {phoneNumber}
        </Typography>
        <CircularButton iconName="edit" onClick={onEdit} />
      </Box>
    </>
  );
}

export default EditPhone;
