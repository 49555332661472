import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import Resources from "assets/json/Resources";
import { ChangePhonePayload, ChangePhoneResponse } from "./types";
import { changePhoneNumber } from "./accountProfileService";

type APIResponse = ChangePhoneResponse & FailedAPIResponse;

const useUpdatePhone = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, ChangePhonePayload>({
    mutationFn: changePhoneNumber,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useUpdatePhone;
