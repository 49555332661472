import { Box, CircularProgress } from "@mui/material";
import { Breadcrumb } from "components/common";
import HomeTutorial from "pages/Tutorial/HomePage";
import { useEffect, useState } from "react";
import useGetAccessCodesList from "services/accessCode/useGetAccessCodesList";
import { useGetAccountProfile } from "services/accountProfileService";
import { useUpdateFTUEStatus } from "services/common";
import { useGetFeaturesCount } from "services/home";
import useGetTalentHomeDetails from "services/useGetTalentHomeDetails";
import AccessCode from "./AccessCode";
import AccessCodeFeatures from "./AccessCodeFeatures";
import Activities from "./Activites";

const breadcrumbs = {
  subscriptionInfo: [{ text: "Home" }],
};

function Home() {
  const { data: featuresCount, isLoading } = useGetFeaturesCount();
  const { data: accessCodeData } = useGetAccessCodesList();
  const { data: userInfo } = useGetAccountProfile();
  const { data: userDetails, isFetching } = useGetTalentHomeDetails();
  const { mutateAsync: updateFTUE } = useUpdateFTUEStatus();

  const [isWelcomeTutorialComplete, setIsWelcomeTutorialComplete] =
    useState(true);
  const accessCodes = accessCodeData?.result?.accessCodeList ?? [];

  useEffect(() => {
    if (userDetails?.ftueVisitedStatus && !isFetching)
      setIsWelcomeTutorialComplete(
        userDetails?.ftueVisitedStatus?.WEB_WELCOME_TUTORIAL,
      );
  }, [userDetails]);

  const onTutorialComplete = async () => {
    setIsWelcomeTutorialComplete(true);
    await updateFTUE({ page: "WEB_WELCOME_TUTORIAL" });
  };

  return (
    <>
      <Box
        className="app-l-container"
        sx={{
          paddingBottom: "3rem !important",
          paddingTop: "3rem !important",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isLoading && (
          <CircularProgress
            size={20}
            sx={{ position: "absolute", inset: 0, margin: "auto" }}
          />
        )}
        <Breadcrumb crumbs={breadcrumbs.subscriptionInfo} />

        <Box
          sx={{
            display: "flex",
            gap: "1.5rem",
            marginTop: "3rem",
            flexGrow: "1",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <AccessCodeFeatures
            features={featuresCount?.result?.features}
            otherFeatures={featuresCount?.result?.otherFeatures}
            userName={userInfo?.result?.home?.userName}
            howCanWeHelpOption={userInfo?.result?.home?.howCanWeHelp}
          />
          <Box
            sx={{
              flexBasis: { lg: "40%", sm: "30rem" },
              flexShrink: 0,
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              minWidth: 0,
            }}
          >
            <AccessCode accessCodeList={accessCodes} />
            <Activities />
          </Box>
        </Box>
      </Box>
      {/* if user is not completed welcome tutorial, then show tutorial */}
      {!isWelcomeTutorialComplete && (
        <HomeTutorial onComplete={onTutorialComplete} />
      )}
    </>
  );
}

export default Home;
