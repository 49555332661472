import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { LoadingButton } from "components/common";
import { InputField } from "components/ui";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { ChangeEmailFormType, deleteAccountForm } from "./config";

type EmailFormProps = {
  onComplete: (email: string) => void;
  email: string;
  isLoading: boolean;
};

function EmailForm(props: EmailFormProps) {
  const { onComplete, email, isLoading } = props;

  const { control, handleSubmit } = useForm<ChangeEmailFormType>({
    resolver: yupResolver(deleteAccountForm),
    defaultValues: { email },
    mode: "onChange",
  });

  const handleFormSubmit: SubmitHandler<FieldValues> = (formData) => {
    onComplete(formData.email);
  };

  return (
    <>
      <Box
        component="form"
        id="delete-account-form"
        onSubmit={handleSubmit(handleFormSubmit)}
        noValidate
        className="formGroup"
      >
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <InputField
              {...field}
              placeholder="Enter registered email address"
              type="text"
              label="Email :"
              autoComplete="off"
              className="custom_form_control"
              inputProps={{ maxLength: "50" }}
              variant="outlined"
              FormHelperTextProps={{ style: { color: "red" } }}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              required
            />
          )}
        />
      </Box>
      <Box sx={{ mt: "3.8rem" }}>
        <Box className="app-l-primary-button-block">
          <LoadingButton
            variant="contained"
            label="Delete Account"
            fullWidth
            loading={isLoading}
            sx={{ fontWeight: "500" }}
            form="delete-account-form"
            type="submit"
          />
        </Box>
      </Box>
    </>
  );
}

export default EmailForm;
