import { Button } from "@mui/material";
import useAuth from "hooks/useAuth";

function Dashboard() {
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <div>
      Dashboard
      <p>{user?.email}</p>
      <Button onClick={handleLogout}>Logout</Button>
    </div>
  );
}

export default Dashboard;
