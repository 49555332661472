import { Box, Button } from "@mui/material";

type BreadcrumbProps = {
  label: string;
  onClick: Function;
};

function PrimaryButtonMedium(props: BreadcrumbProps) {
  const { label, onClick } = props;
  return (
    <Box className="app-l-primary-button-medium-block">
      <Button variant="contained" onClick={() => onClick()}>
        {label}
      </Button>
    </Box>
  );
}

export default PrimaryButtonMedium;
