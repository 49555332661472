import { CSSProperties } from "react";
import Joyride, { Step } from "react-joyride";

export type TourStep = Step;

export type PageTourProps = {
  steps: TourStep[];
  stepIndex?: number;
  start?: boolean;
  styles?: {
    tooltip?: CSSProperties;
    tooltipContent?: CSSProperties;
  };
};

export function PageTour(props: PageTourProps) {
  const { steps, stepIndex, styles, start } = props;

  return (
    <Joyride
      run={start}
      steps={steps}
      disableCloseOnEsc
      disableOverlayClose
      hideCloseButton
      floaterProps={{ hideArrow: true }}
      stepIndex={stepIndex}
      styles={{
        tooltip: styles?.tooltip,
        tooltipContent: styles?.tooltipContent,
        overlay: { zIndex: 1200 },
        options: { zIndex: 1201 },
      }}
    />
  );
}
