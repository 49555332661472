/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@mui/material";
import Resources from "assets/json/Resources";
import { LoadingButton } from "components/common";
import CountryPhoneBlock from "components/CountryPhoneBlock";
import REGEX from "constants/regex";
import { useAuth } from "hooks";
import { ChangeEventHandler, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useGetCountryCode } from "services/common";
import {
  FormField,
  GuardianConsentFormType,
  guardianConsentRequestFormSchema,
} from "./config";

function GuardianForm(props: {
  sendEmailHandler: (userData: GuardianConsentFormType) => void;
  isCodeSendLoading: boolean;
  details: any;
}) {
  const { sendEmailHandler, isCodeSendLoading, details } = props;
  const {
    ERRORS: { ENTER_EMAIL_ADDRESS, ENTER_VALID_EMAIL_ADDRESS },
    COMMON: {
      BUTTONS: { BACK_TO_LOGIN, REQUEST_CONCERN },
    },
    PAGES: {
      GUARDIAN: {
        GUARDIAN_MOBILE_PLACEHOLDER,
        GUARDIAN_EMAIL_PLACEHOLDER,
        GUARDIAN_MOBILE_LABEL,
      },
    },
  } = Resources;

  const { data: allCountryList } = useGetCountryCode();

  const countryList = useMemo(() => {
    const list = allCountryList?.result?.countryList?.map((country) => ({
      isdCode: country.isdCode,
      countryCode: country.shortName,
    }));
    return list;
  }, [allCountryList]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<GuardianConsentFormType>({
    resolver: yupResolver(guardianConsentRequestFormSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
      isdCode: "",
      phoneNumber: "",
      isdCountry: "",
    },
  });

  useEffect(() => {
    setValue("email", details?.home?.guardianEmail ?? "");
    setValue("isdCountry", details?.home?.guardianIsdCountry ?? "");
    setValue("isdCode", details?.home?.guardianIsdCode ?? "");
    setValue("phoneNumber", details?.home?.guardianPhoneNumber ?? "");
  }, [details]);

  const onSubmit = handleSubmit((data) => sendEmailHandler(data));
  const { logout } = useAuth();

  const handleLogout = async () => {
    logout();
  };

  const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    const field = name as FormField;
    if (name === "isdCountry") {
      const country = countryList?.find((code) => code.countryCode === value);
      if (country) {
        setValue("isdCode", country?.isdCode);
        setValue("isdCountry", value);
      }
      trigger("isdCode");
      trigger("isdCountry");
      return;
    }
    setValue(field, value);
    trigger(field);
  };

  return (
    <form
      name="guardianConsentRequestForm"
      id="guardianConsentRequestForm"
      autoComplete="off"
      noValidate
      onSubmit={onSubmit}
    >
      <div className="formGroup">
        <Controller
          control={control}
          name="isdCountry"
          render={({ field }) => (
            <CountryPhoneBlock
              fieldErrors={{
                isdCode: errors.isdCode?.message,
                phoneNumber: errors.phoneNumber?.message,
              }}
              control={control}
              {...field}
              onChange={handlePhoneChange}
              label={GUARDIAN_MOBILE_LABEL}
              placeholder={GUARDIAN_MOBILE_PLACEHOLDER}
              disabled={
                details?.home?.isGuardianApproved &&
                details?.home?.guardianIsdCountry &&
                details?.home?.guardianIsdCode &&
                details?.home?.guardianPhoneNumber
              }
            />
          )}
        />
      </div>
      <div className="formGroup">
        <Controller
          name="email"
          control={control}
          rules={{
            required: ENTER_EMAIL_ADDRESS,
            pattern: {
              value: REGEX.EMAIL,
              message: ENTER_VALID_EMAIL_ADDRESS,
            },
          }}
          render={({ field }) => {
            return (
              <TextField
                placeholder={GUARDIAN_EMAIL_PLACEHOLDER}
                type="text"
                label="Guardian email"
                autoComplete="off"
                disabled={details?.home?.isGuardianApproved ?? false}
                className="custom_form_control"
                inputProps={{ maxLength: "50" }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                FormHelperTextProps={{ style: { color: "red" } }}
                error={!!errors.email?.message}
                helperText={errors.email?.message}
                required
                {...field}
              />
            );
          }}
        />
      </div>
      <Box sx={{ my: "3rem" }}>
        <div className="resend-button-block">
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            label={REQUEST_CONCERN}
            loading={isCodeSendLoading}
          />
        </div>
        <div className="app-l-secondary-button-block">
          <Button
            type="button"
            variant="outlined"
            fullWidth
            color="inherit"
            onClick={handleLogout}
            sx={{ textTransform: "none !important" }}
          >
            {BACK_TO_LOGIN}
          </Button>
        </div>
      </Box>
    </form>
  );
}
export default GuardianForm;
