import { REACT_QUERY_KEYS, TimeConfig } from "config/app";
import { useGETRequestHandler } from "hooks";
import { HandlerType } from "services/commonTypes";
import { getFeatures } from "./featureCenterService";
import { BuyUpResponse } from "./types";

const useGetFeatures = (handler?: HandlerType<BuyUpResponse>) => {
  return useGETRequestHandler<BuyUpResponse>({
    queryKey: REACT_QUERY_KEYS.BUY_UP_FEATURES,
    queryFn: getFeatures,
    handler,
    queryOptions: {
      staleTime: TimeConfig.ONE_MINUTE,
    },
  });
};
export default useGetFeatures;
