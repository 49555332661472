import { Box, CircularProgress, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import styles from "./styles.module.scss";

type ContainerProps = {
  children: ReactNode;
  loading?: boolean;
  backgroundColor?: string;
  gradient?: boolean;
  // eslint-disable-next-line react/require-default-props
  styleProps?: React.CSSProperties;
  sx?: SxProps<Theme> | undefined;
};

function Container(props: ContainerProps) {
  const { children, loading, backgroundColor, styleProps, gradient, sx } =
    props;
  return (
    <Box
      className={`${styles.container} ${gradient ? styles.gradient : ""} `}
      sx={{
        backgroundColor,
        padding: { xs: "0.5rem 0.5rem 0 0.5rem", md: "3rem 3rem 0 3rem" },
        ...sx,
      }}
      style={styleProps}
    >
      {loading ? (
        <Box className={styles.center}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Box className={styles.wrapper}>{children}</Box>
      )}
    </Box>
  );
}

export default Container;

Container.defaultProps = {
  loading: false,
  backgroundColor: "transparent",
  gradient: false,
  sx: undefined,
};
