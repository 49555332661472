import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ROUTES } from "config/app";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import { useAuth } from "hooks";
import {
  ChooseVerificationType,
  verificationOption,
  verificationTypeSchema,
} from "../config";

interface ChooseVerificationProps {
  setCurrentForm: (form: string) => void;
  onSelectOption: (option: string) => void;
  pageKey: string;
}

function ChooseVerification({
  setCurrentForm,
  onSelectOption,
}: ChooseVerificationProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ChooseVerificationType>({
    resolver: yupResolver(verificationTypeSchema),
    mode: "onChange",
  });

  const { setGlobalAlert } = useAuth();

  const showError = (message: string) =>
    setGlobalAlert({ message, messageType: "error" });

  const handleClick = () => {
    handleSubmit((data) => {
      if (!data.chooseType) {
        showError("Please choose a verification type.");
        return;
      }
      if (typeof data.chooseType === "string") {
        setCurrentForm(data.chooseType);
        onSelectOption(data.chooseType);
      }
    })();
  };

  return (
    <Box sx={{ maxWidth: "40rem" }}>
      <Box>
        <FormControl component="fieldset">
          <FormLabel
            error={!!errors.chooseType?.message}
            component="label"
            sx={{
              fontSize: "1.3rem",
              fontWeight: "800",
              color: "var(--color-secondary) !important",
              fontFamily: "metropolismedium",
              "&.Mui-focused": {
                color: "inherit",
              },
            }}
          >
            Choose the type of verification to reset password
          </FormLabel>
          <Controller
            control={control}
            name="chooseType"
            render={({ field }) => (
              <RadioGroup
                aria-label="chooseType"
                row
                {...field}
                sx={{ mt: "1.5rem", ml: "1.3rem", gap: "1.5rem" }}
              >
                {verificationOption.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    control={
                      <Radio
                        checkedIcon={<CheckCircleIcon fontSize="large" />}
                      />
                    }
                  />
                ))}
              </RadioGroup>
            )}
          />
          {errors.chooseType && (
            <FormHelperText error>{errors.chooseType.message}</FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box
        sx={{
          marginTop: "3rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleClick}
        >
          Ok
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="inherit"
          component={Link}
          fullWidth
          to={ROUTES.PUBLIC.LOGIN}
          sx={{ textTransform: "capitalize" }}
        >
          Back to Login
        </Button>
      </Box>
    </Box>
  );
}

export default ChooseVerification;
