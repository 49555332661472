import {
  Box,
  IconButton,
  Typography,
  TextField,
  Divider,
  Button,
} from "@mui/material";
import FTUELayout from "components/FTUELayout";
import { Controller, useForm } from "react-hook-form";
import SVGIcon from "components/icons";
import Resources from "assets/json/Resources";

function EmailVerification(props: { currentEmail?: string }) {
  const { control } = useForm({
    defaultValues: { username: "", password: "" },
    mode: "all",
  });
  const { currentEmail } = props;
  const {
    PAGES: {
      FORGOT_PASSWORD: { RESET_PASSWORD_SUBTEXT_EMAIL, EMAIL },
    },
  } = Resources;

  return (
    <FTUELayout
      title1="Enter"
      title2="6 digit code"
      containerStyle={{ maxWidth: { xl: "80%" } }}
    >
      <Box>
        <Typography
          component="p"
          sx={{
            fontSize: "1.4rem !important",
            fontFamily: "var(--font-primary-500)",
            color: "var(--colorTextSecondary)",
            fontWeight: "400",
            lineHeight: "1.2",
            marginBottom: "3rem",
          }}
        >
          {RESET_PASSWORD_SUBTEXT_EMAIL}
        </Typography>
        <Box>
          <Typography
            component="p"
            sx={{
              fontSize: "1.4rem !important",
              fontFamily: "var(--font-primary-500)",
              color: "var(--colorTextSecondary)",
              fontWeight: "400",
              lineHeight: "1.2",
              marginBottom: "1.5rem",
            }}
          >
            {EMAIL}
          </Typography>
          <Box
            sx={{
              maxWidth: "36.7rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1.5rem",
                marginBottom: "4rem",
              }}
            >
              <Typography
                component="p"
                sx={{
                  fontSize: "2.2rem !important",
                  fontFamily: "var(--font-primary-500)",
                  color: "var(--colorTextPrimary)",
                  fontWeight: "400",
                  lineHeight: "1.2",
                }}
              >
                {currentEmail}
              </Typography>
              <IconButton
                aria-label="edit"
                color="primary"
                sx={{ padding: "0" }}
              >
                <SVGIcon name="edit-pencil" />
              </IconButton>
            </Box>
            <Box>
              <Controller
                name="username"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      required
                      id="login-username"
                      label="6 digit code"
                      placeholder="Enter 6 digit code"
                      autoComplete="off"
                      inputProps={{ maxLength: "50" }}
                      {...field}
                      InputLabelProps={{ shrink: true }}
                    />
                  );
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "1rem",
                  marginTop: "0.7rem",
                }}
              >
                <Typography
                  component="p"
                  sx={{
                    fontSize: "1.1rem !important",
                    fontFamily: "var(--font-primary-500)",
                    color: "var(--colorTextSecondary)",
                    fontWeight: "400",
                    lineHeight: "1.2",
                  }}
                >
                  5 attempts left
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={{
                    width: "0.1rem",
                    height: "1rem",
                    background: "var(--colorTextSecondary)",
                  }}
                />
                <Button
                  variant="text"
                  sx={{
                    height: "auto",
                  }}
                >
                  Resend Code
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "3rem",
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
              }}
            >
              <Button variant="contained" color="primary">
                Verify
              </Button>
              <Button variant="outlined" color="primary">
                Back to Login
              </Button>
            </Box>
            <Box
              sx={{
                background: "#E5F6FD",
                padding: "1.6rem",
                borderRadius: "0.4rem",
                display: "flex",
                alignItems: "center",
                marginTop: "3rem",
              }}
            >
              <Box
                sx={{ color: "#03989E", flexShrink: "0", marginRight: "1rem" }}
              >
                <SVGIcon name="exclamation" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "1.4rem !important",
                    fontWeight: "400",
                    fontFamily: "var(--font-primary-500)",
                    lineHeight: "1.3",
                    color: "#014361",
                  }}
                >
                  Please check the spam/junk folder. Sometimes the message may
                  end up in these folders.
                </Typography>
              </Box>
              <Box
                sx={{
                  color: "#03989E",
                  flexShrink: "0",
                  marginLeft: "2rem",
                  marginRight: "1rem",
                }}
              >
                <IconButton
                  aria-label="delete"
                  size="small"
                  color="secondary"
                  sx={{
                    padding: "0",
                    width: " 1.7rem",
                    height: "1.7rem",
                  }}
                >
                  <SVGIcon name="cross-circle" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </FTUELayout>
  );
}

export default EmailVerification;
