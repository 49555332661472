import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASE_URL } from "./app";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

const addCustomHeader = (config: AxiosRequestConfig) => {
  try {
    if (config.headers) {
      // Add the device-type header to the request
      // eslint-disable-next-line no-param-reassign
      config.headers["device-type"] = "web";
    }
    return config;
  } catch (error) {
    return Promise.reject(error);
  }
};

// add custom headers to api before request
axiosInstance.interceptors.request.use(addCustomHeader, (error) => {
  return Promise.reject(error);
});

const responseBody = (response: AxiosResponse) => response.data;
const errorBody = async (error: AxiosError) => Promise.reject(error.response);

const requests = {
  get: (url: string) =>
    axiosInstance.get(url).then(responseBody).catch(errorBody),
  post: (url: string, body?: {}, config?: AxiosRequestConfig) =>
    axiosInstance.post(url, body, config).then(responseBody).catch(errorBody),
  put: (url: string, body: {}) =>
    axiosInstance.put(url, body).then(responseBody).catch(errorBody),
  del: (url: string) =>
    axiosInstance.delete(url).then(responseBody).catch(errorBody),
  patch: (url: string, data?: {}) =>
    axiosInstance.patch(url, data).then(responseBody).catch(errorBody),
};

export default requests;
