import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import SVGIcon from "assets/icons";
import Resources from "assets/json/Resources";
import PWTable from "components/common/PWTable";
import { Switch } from "components/ui";
import { ROUTES } from "config/app";
import { useAuth } from "hooks";
import { AccessCodeDetailType } from "pages/PaymentCenter/types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { encryptString, formatDate } from "utils";
import {
  TalentAccessCodeStatusType,
  accessCodeTableHeaders,
  talentAccessCodeKey,
  talentAccessCodeStatus,
  talentAccessCodeStatusDesc,
} from "../config";
import LinkSection from "./LinkSection";
import NoDataComponent from "./NoDataComponent";
import styles from "./styles.module.scss";

interface AccessCodeListingProps {
  accessCodeList: AccessCodeDetailType[];
  loading: boolean;
}

function AccessCodeListing(props: AccessCodeListingProps): JSX.Element {
  const { accessCodeList, loading } = props;
  const { AVAILABLE_ACCESS_CODE, AC_TOOLTIP, CANNOT_DEACTIVATE_AC } =
    Resources.PAGES.ACCESS_CODE;
  const { ACTIVE, AVAILABLE_TO_USE, REQUESTED, REJECTED } = talentAccessCodeKey;
  const { setGlobalAlert } = useAuth();
  const navigate = useNavigate();

  const toggleAccessCode = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    accessCode: string,
    status: TalentAccessCodeStatusType,
  ) => {
    const breadCrumb = {
      accessCode,
      text: "Access Code",
      pageLink: ROUTES.PRIVATE.ACCESS_CODE,
    };
    if (status === ACTIVE) {
      setGlobalAlert({ message: CANNOT_DEACTIVATE_AC, messageType: "error" });
      return;
    }

    if (checked && status === AVAILABLE_TO_USE) {
      navigate(ROUTES.PRIVATE.ACCESS_CODE_ACTIVATION, {
        state: breadCrumb,
      });
    }
  };

  const renderActionColumn = (
    accessCode: string,
    status: TalentAccessCodeStatusType,
  ) => {
    if (
      status === ACTIVE ||
      status === AVAILABLE_TO_USE ||
      status === REQUESTED
    ) {
      const talentACStatus = status as Extract<
        TalentAccessCodeStatusType,
        "ACTIVE" | "AVAILABLE_TO_USE" | "REQUESTED"
      >;
      return (
        <Box
          sx={{
            display: "flex",
            direction: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <>
            <Switch
              className="app-c-switch--primary"
              checked={status === ACTIVE}
              disabled={status === REQUESTED}
              onChange={(e, checked) =>
                toggleAccessCode(e, checked, accessCode, status)
              }
            />
            <Tooltip title={AC_TOOLTIP[talentACStatus]} placement="top">
              <IconButton>
                <SVGIcon name="question" />
              </IconButton>
            </Tooltip>
          </>
        </Box>
      );
    }
    return talentAccessCodeStatusDesc[status] ?? status;
  };

  return (
    <Box className={styles.availableAccessCode}>
      {accessCodeList?.length > 0 && (
        <Box>
          <Typography variant="h3" className={styles.containerHeading}>
            {AVAILABLE_ACCESS_CODE}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "6.5rem" }}>
            <PWTable
              headerData={accessCodeTableHeaders}
              noDataMessage="No records found"
              loading={loading}
            >
              {accessCodeList?.map((item: AccessCodeDetailType) => {
                return (
                  <TableRow
                    key={item?.accessCode}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell className={styles.nameCell}>
                      <RouterLink
                        to={`${ROUTES.PRIVATE.ACCESS_CODE_DETAILS.replace(
                          ":code",
                          encryptString(item?.accessCode),
                        )}/`}
                        state={{
                          text: "Access Code",
                          active: true,
                          pageLink: ROUTES.PRIVATE.ACCESS_CODE,
                        }}
                        className={styles.nameCell}
                      >
                        {item?.accessCode ? item.accessCode : "-"}
                      </RouterLink>
                    </TableCell>
                    <TableCell className={styles.cellContent}>
                      {item?.sponsorName ? item.sponsorName : "-"}
                    </TableCell>
                    <TableCell className={styles.cellContent}>
                      {talentAccessCodeStatus[item?.talentAccessCodeStatus]}
                    </TableCell>
                    <TableCell className={styles.cellContentCenter}>
                      {item?.assignedDate &&
                      item?.talentAccessCodeStatus !== REQUESTED &&
                      item?.talentAccessCodeStatus !== REJECTED
                        ? formatDate(item?.assignedDate)
                        : "-"}
                    </TableCell>
                    <TableCell className={styles.cellContentCenter}>
                      {item?.redeemedDate &&
                      item?.talentAccessCodeStatus !== REJECTED
                        ? formatDate(item?.redeemedDate)
                        : "-"}
                    </TableCell>
                    <TableCell className={styles.cellContentCenter}>
                      {item?.expiryDate ? formatDate(item?.expiryDate) : "-"}
                    </TableCell>
                    <TableCell className={styles.cellContentCenter}>
                      {renderActionColumn(
                        item?.accessCode,
                        item?.talentAccessCodeStatus,
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </PWTable>
            <LinkSection />
          </Box>
        </Box>
      )}
      {!loading && !accessCodeList?.length && <NoDataComponent />}
    </Box>
  );
}

export default AccessCodeListing;
