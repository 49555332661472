import { Wifi, WifiOffRounded } from "@mui/icons-material";
import Resources from "assets/json/Resources";
import { Snackbar } from "components/common";
import useNetworkInfo from "hooks/useNetworkInfo";
import { useEffect, useState } from "react";

function NetworkStatus() {
  const isOnline = useNetworkInfo();
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    setShowSnackbar(true);
  }, [isOnline]);

  // hide toast on first load
  useEffect(() => {
    setShowSnackbar(false);
  }, []);

  const onClose = () => setShowSnackbar(false);

  return (
    <Snackbar
      open={showSnackbar}
      onClose={onClose}
      message={
        isOnline
          ? Resources.ERRORS.CONNECTION_RESTORE
          : Resources.ERRORS.CONNECTION_LOST
      }
      icon={
        isOnline ? (
          <Wifi fontSize="small" />
        ) : (
          <WifiOffRounded fontSize="small" />
        )
      }
      severity={isOnline ? "success" : "error"}
      disableClickAway
    />
  );
}

export default NetworkStatus;
