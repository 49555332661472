import {
  Logo,
  Logout,
  PaymentCenter,
  PurchaseCenter,
  DocumentCenter,
} from "assets/images";
import Resources from "assets/json/Resources";
import { ROUTES } from "config/app";

type MenuItemsType = {
  key: string;
  icon: any;
  to: string;
  label: string;
};

const drawerWidth = 240;
const drawerClosedWidth = 110;
const {
  PRIVATE: {
    PURCHASE_CENTER,
    PAYMENT_CENTER,
    DOCUMENT_CENTER,
    ACCOUNT_PROFILE,
  },
} = ROUTES;
const menuList: MenuItemsType[] = [
  {
    label: "Purchase Center",
    key: "purchase-center",
    icon: PurchaseCenter,
    to: PURCHASE_CENTER,
  },
  {
    label: "Payment Center",
    key: "payment-center",
    icon: PaymentCenter,
    to: PAYMENT_CENTER,
  },
  {
    label: "Account Profile",
    key: "account-profile",
    icon: PaymentCenter,
    to: ACCOUNT_PROFILE,
  },
  {
    label: "Document Center",
    key: "document-center",
    icon: DocumentCenter,
    to: DOCUMENT_CENTER,
  },
  {
    label: "Account Profile",
    key: "account-profile",
    icon: PaymentCenter,
    to: ACCOUNT_PROFILE,
  },
  // { label: "Receipts", key: "receipts", icon: Receipts, to: RECEIPTS },
  { label: "Logout", key: "logout", icon: Logout, to: "" },
];

function PepelwerkCorporateLink() {
  return (
    <a
      href={Resources.COMMON.LINKS.PEPELWERK_URL}
      target="_blank"
      className="logo_a"
      rel="noreferrer"
    >
      <img width="100%" height="100%" src={Logo} alt="logo" />
    </a>
  );
}

export { drawerWidth, drawerClosedWidth, PepelwerkCorporateLink, menuList };
