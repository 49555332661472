import { Box, Button, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import { Plan } from "components/common";
import { Link } from "react-router-dom";
import styles from "../style.module.scss";

const { LINKS } = Resources.COMMON;

function Events() {
  return (
    <Plan className={`${styles.planCard} ${styles.bgPrimary}`}>
      <Box className={styles.sliderContent}>
        <Box className={styles.sliderTop}>
          <Box className={styles.sliderHeading}>
            <Typography
              variant="body2"
              className={styles.sliderItemTitle}
              sx={{ color: "var(--color-secondary) !important" }}
            >
              Events
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="body2"
          className={styles.sliderSubText}
          sx={{ color: "var(--color-secondary) !important" }}
        >
          Check the site for upcoming events or open the Connect Center in your
          app.
        </Typography>

        <Box className={styles.sliderItemBottom}>
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            target="_blank"
            rel="noopener,noreferrer"
            to={LINKS.EVENTS}
            sx={{ border: "none" }}
          >
            Events
          </Button>
        </Box>
      </Box>
    </Plan>
  );
}

export default Events;
