import Resources from "assets/json/Resources";
import { REACT_QUERY_KEYS } from "config/app";
import { useAPIErrorHandler } from "hooks";
import { useQuery } from "react-query";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { getReceiptsList } from "./docCenterService";
import { ReceiptsListType } from "./types";

type APIResponse = ReceiptsListType & FailedAPIResponse;

const useGetReceipts = (handler?: HandlerType) => {
  const { checkError, getErrorInfo } = useAPIErrorHandler(Resources.ERRORS);

  const onRejected = (error: FailedAPIResponse) => {
    const { errorMessage } = getErrorInfo(error);
    handler?.onError?.(errorMessage);
  };

  const onFulfilled = (res: APIResponse) => {
    const hasError = checkError(res);
    if (hasError || res?.status !== 200) {
      onRejected(res);
      return;
    }

    handler?.onSuccess?.();
  };

  return useQuery(REACT_QUERY_KEYS.RECEIPTS, getReceiptsList, {
    onSuccess: onFulfilled,
    onError: onRejected,
    staleTime: 50000,
  });
};

export default useGetReceipts;
