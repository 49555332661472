import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { verifyPhone } from "./accountProfileService";
import { VerifyPhonePayload, VerifyPhoneResponse } from "./types";

type APIResponse = VerifyPhoneResponse & FailedAPIResponse;

const useVerifyPhone = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, VerifyPhonePayload>({
    mutationFn: verifyPhone,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useVerifyPhone;
