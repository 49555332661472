/* eslint-disable react/no-danger */
import { Box, CircularProgress, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import { useState } from "react";
import useGetTC, { TCResponseType } from "services/useGetTnC";
import styles from "./style.module.scss";

const { TERMS_AND_CONDITIONS } = Resources.PAGES;

function TermsAndConditions() {
  const [html, setHtml] = useState("");

  const saveTnC = (data: TCResponseType) => {
    setHtml(data.tncDetails);
  };

  const { isLoading, isError } = useGetTC({ onSuccess: saveTnC });

  function createMarkup(htmlString: string) {
    return { __html: htmlString };
  }

  return (
    <div className="app-l-inner-wrap app-l-tc-inner-wrap">
      <div className="app-l-tc-main-wrap">
        <div className="app-l-tc-content-section">
          <h2 className="app-l-tc-main-heading">Terms and Conditions</h2>
          {isLoading && (
            <Box alignItems="center" display="flex" justifyContent="center">
              <CircularProgress size={20} />
            </Box>
          )}
          {isError ? (
            <Typography variant="subtitle2" align="center">
              {TERMS_AND_CONDITIONS.FAILED_TO_LOAD}
            </Typography>
          ) : (
            html && (
              <div
                className={styles.tnc}
                dangerouslySetInnerHTML={createMarkup(html)}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
