import { Box, Button, Typography } from "@mui/material";
import SVGIcon, { SVGIconType } from "components/icons";
import { ReactNode } from "react";

type ActivitiesCardProps = {
  title: string;
  subtitle: string;
  buttonText: string;
  children: ReactNode;
  icon: SVGIconType;
  hideSubtitle: boolean;
  hideButton: boolean;
  buttonAction: () => void;
  seeMoreAction: () => void;
  hideSeeMore: boolean;
};

function ActivitiesCardOuter(props: ActivitiesCardProps) {
  const {
    title,
    subtitle,
    buttonText,
    children,
    icon,
    buttonAction,
    hideButton,
    hideSubtitle,
    seeMoreAction,
    hideSeeMore,
  } = props;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "1.25rem",
        }}
      >
        <SVGIcon name={icon} />
        <Typography
          component="h4"
          sx={{
            fontSize: "2.4rem !important",
            fontFamily: "var(--font-primary-500)",
            color: "var(--color-secondary)",
            marginLeft: "1rem",
          }}
        >
          {title}
        </Typography>
      </Box>
      {!hideSubtitle && (
        <Typography
          component="p"
          sx={{
            fontSize: "1.6rem !important",
            fontFamily: "var(--font-primary-500)",
            color: "var(--color-secondary)",
            marginLeft: "3rem",
            marginBottom: "1.25rem",
          }}
        >
          {subtitle}
        </Typography>
      )}
      {children}
      {!hideButton && (
        <Box sx={{ marginLeft: "3.1rem", marginRight: "3.3rem" }}>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "transparent",
              borderColor: "var(--color-primary)",
              width: "100%",
              marginTop: "1.25rem",
              marginBottom: "1.25rem",
            }}
            onClick={buttonAction}
          >
            {buttonText}
          </Button>
        </Box>
      )}
      {!hideSeeMore && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button onClick={seeMoreAction}>See more</Button>
        </Box>
      )}
    </Box>
  );
}

export default ActivitiesCardOuter;
