import requests from "config/api";
import API_ENDPOINTS from "constants/api";

type RegisterType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isdCode: string;
  phoneNumber: string;
  tncVersion: string;
  isdCountry: string;
};

type ValidateAccessCodeType = {
  email: string;
  accessCode: string;
};

type VerifyEmailType = {
  otp: string;
};

type ResendOtpType = {
  email: string;
};

type GuardianData = {
  guardianEmail: string;
  guardianIsdCode?: string;
  guardianIsdCountry?: string;
  guardianPhoneNumber?: string;
  firstName: string;
  lastName: string;
  age: string;
  relation: string;
  isTncAccepted: boolean;
  isConsentDone: boolean;
  talentId: string;
  consentId: string;
};

export const getTermsAndConditions = () => {
  return requests.get(API_ENDPOINTS.TERMS_AND_CONDITIONS);
};

export const registerTalent = async (data: RegisterType) => {
  return requests.post(API_ENDPOINTS.REGISTER_TALENT, {
    ...data,
    userType: "Talent",
  });
};

export const validateAccessCode = async (data: ValidateAccessCodeType) => {
  return requests.post(API_ENDPOINTS.VALIDATE_ACCESS_CODE, data);
};

export const verifyEmail = async (data: VerifyEmailType) => {
  return requests.post(API_ENDPOINTS.VERIFY_EMAIL, data);
};

export const resendOtp = async (data: ResendOtpType) => {
  return requests.post(API_ENDPOINTS.RESEND_OTP, data);
};

export const autoRedeemOrEmailVerify = async () => {
  return requests.get(API_ENDPOINTS.AUTO_REDEEM_ACCESS_CODE);
};

export const validateGuardianToken = async (token: string) => {
  return requests.post(API_ENDPOINTS.VALIDATE_GUARDIAN_TOKEN, { token });
};

export const validateGuardianShortToken = async (token: string) => {
  return requests.get(
    API_ENDPOINTS.VALIDATE_GUARDIAN_SHORT_TOKEN.replace(":token", token),
  );
};

export const setGuardianConsent = async (data: GuardianData) => {
  return requests.post(API_ENDPOINTS.GUARDIAN_CONSENT, data);
};
