import { ReactNode } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import styles from "./styles.module.scss";

type ScrollbarProp = {
  children: ReactNode;
  height: string | number;
  width?: string | number;
  className?: string;
  styleProps?: object;
};

function Scrollbar(props: ScrollbarProp) {
  const { children, height, width, className, styleProps } = props;
  return (
    <Scrollbars
      style={{ ...styleProps, height } || { height, width }}
      className={`${styles.custom_scrollbar} ${styles[className || ""]}`}
    >
      {children}
    </Scrollbars>
  );
}

export default Scrollbar;

Scrollbar.defaultProps = {
  width: "100%",
};
