import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { validatePhone } from "./accountProfileService";
import { ValidatePhonePayload, ValidatePhoneResponse } from "./types";

type APIResponse = ValidatePhoneResponse & FailedAPIResponse;

const useValidatePhone = (handler: HandlerType) => {
  return useMutationWithHandler<APIResponse, ValidatePhonePayload>({
    mutationFn: validatePhone,
    resource: Resources.API_ERRORS.VERIFY_PHONE,
    handler,
  });
};

export default useValidatePhone;
