import { Box, Button, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import FTUELayout from "components/FTUELayout";
import CountDown from "components/ui/CountDown";
import { ROUTES } from "config/app";
import { useAuth } from "hooks";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { timeLeftForNextUTCDay } from "utils/dateUtils";

function VerificationTime() {
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);
  const { logout } = useAuth();

  const navigateToLogin = () => {
    logout();
    navigate(ROUTES.PUBLIC.LOGIN);
  };

  const timeLeft: number = useMemo(() => timeLeftForNextUTCDay(), []);
  return (
    <FTUELayout
      title1={Resources.PAGES.VERIFICATION_TIME.ENTER_COOL_DOWN}
      title2={Resources.PAGES.VERIFICATION_TIME.PERIOD_VALIDATION}
      containerStyle={{ maxWidth: { xl: "80%" } }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "1.4rem !important",
            fontFamily: "var(--font-primary-500)",
            color: "var(--colorTextSecondary)",
            lineHeight: "1.4",
          }}
        >
          {Resources.PAGES.VERIFICATION_TIME.TITLE}
        </Typography>
      </Box>
      <CountDown remainingTime={timeLeft} onComplete={handleBack} />
      <Box
        sx={{
          marginTop: { xs: "3rem", lg: "10.5rem" },
          maxWidth: { sm: "36.9rem" },
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={navigateToLogin}
        >
          {Resources.PAGES.VERIFICATION_TIME.LOGOUT_AND}
        </Button>
      </Box>
    </FTUELayout>
  );
}
export default VerificationTime;
