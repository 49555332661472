import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { addItemToCart } from "./featureCenterService";
import { CartItemPayload, CartUpdateResponse } from "./types";

type APIResponse = CartUpdateResponse & FailedAPIResponse;

const useAddCartItem = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, CartItemPayload>({
    mutationFn: addItemToCart,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useAddCartItem;
