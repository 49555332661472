import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch disableRipple {...props} size="medium" />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,

  "&.app-c-switch": {
    ".Mui-checked": {
      transform: "translateX(14px) !important",
    },
  },

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",

    "&.Mui-checked": {
      color: "var(--colorWhite)",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--color-primary)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },

    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },

    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },

  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },

  "& .MuiSwitch-track": {
    border: "2px solid var(--color-primary)",
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "light" ? "#B7CBCB" : "var(--color-primary)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default IOSSwitch;
