import requests from "config/api";
import API_ENDPOINTS from "constants/api";
import { AccessCodeDetailsPayload } from "./types";

export const getAccessCodeDetails = async (
  payload: AccessCodeDetailsPayload,
) => {
  return requests.post(API_ENDPOINTS.ACCESS_CODE_DETAIL, payload);
};

export const getAccessCodes = async () => {
  return requests.get(API_ENDPOINTS.ACCESS_CODES);
};
