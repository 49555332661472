import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import Resources from "assets/json/Resources";
import { forgetPhoneOtp } from "./authServices";
import { ForgetPhoneOtpPayload, ForgotPhoneOtpResponse } from "./type";

type APIResponse = ForgotPhoneOtpResponse & FailedAPIResponse;

const useForgotPhoneOtp = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, ForgetPhoneOtpPayload>({
    mutationFn: forgetPhoneOtp,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useForgotPhoneOtp;
