import Resources from "assets/json/Resources";
import { REACT_QUERY_KEYS } from "config/app";
import { useMutationWithHandler } from "hooks";
import { useQueryClient } from "react-query";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { addPublicAccessCode } from "./homeService";
import { AddAccessCodePayload, AddAccessCodeSuccessType } from "./types";

type APIResponse = AddAccessCodeSuccessType & FailedAPIResponse;

const useAddPublicAccessCode = (handler?: HandlerType) => {
  const queryClient = useQueryClient();
  const onSuccess = (res: APIResponse) => {
    queryClient.invalidateQueries(REACT_QUERY_KEYS.ACCESS_CODES);

    queryClient.invalidateQueries(REACT_QUERY_KEYS.FEATURES_COUNT);

    handler?.onSuccess?.(res);
  };
  return useMutationWithHandler<APIResponse, AddAccessCodePayload>({
    mutationFn: addPublicAccessCode,
    resource: Resources.ERRORS,
    handler: { onSuccess, onError: handler?.onError },
  });
};

export default useAddPublicAccessCode;
