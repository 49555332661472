import Resources from "assets/json/Resources";
import requests from "config/api";
import { PAGE_KEYS } from "config/app";
import API_ENDPOINTS from "constants/api";
import { useMutation } from "react-query";

export const forgotPasswordRequest = async (payload: { email: string }) => {
  return requests.post(API_ENDPOINTS.FORGOT_PASSWORD_OTP, payload);
};

export type ForgotPasswordResponseType = {
  otp: string;
  resendEmailOtpBalance: number;
  hintMessageCode: string;
};
type ForgotPassword = {
  setErrorPopUp: (message: string) => void;
  setAccountLocked: (locked: boolean) => void;
  setCurrentForm: (page: string) => void;
  currentForm: string;
  resendCountUpdate?: (otpBalance: number) => void;
  onSuccess?: (result: ForgotPasswordResponseType) => void;
  onError?: (error: string) => void;
};

const useForgotPassword = ({
  setErrorPopUp,
  setAccountLocked,
  setCurrentForm,
  currentForm,
  resendCountUpdate,
  onSuccess,
  onError,
}: ForgotPassword) => {
  const { FORGOT_PASSWORD_EMAIL, RESET_PASSWORD } = PAGE_KEYS;

  return useMutation(
    async (payload: { email: string }) => {
      const response = await forgotPasswordRequest(payload);
      if (response && response.status === 200 && response.result) {
        const { resendEmailOtpBalance } = response.result;
        if (resendEmailOtpBalance >= 0) {
          resendCountUpdate?.(resendEmailOtpBalance);
        }
        onSuccess?.(response?.result);
        // set page to reset password page
        if (currentForm === FORGOT_PASSWORD_EMAIL)
          setCurrentForm(RESET_PASSWORD);
      } else if (!response.success && response.accountLocked) {
        setAccountLocked(true);
      } else if (response?.appErrors?.errors?.length > 0) {
        onError?.(response?.appErrors?.errors[0]);
        const apiErrorKey: string = response.appErrors.errors[0];
        const errorMessage =
          Resources.ERRORS[apiErrorKey] || Resources.ERRORS.SOMETHING;
        setErrorPopUp(errorMessage);
      }
      return response;
    },
    {
      onError: () => {
        setErrorPopUp(Resources.ERRORS.SOMETHING);
      },
    },
  );
};

export default useForgotPassword;
