import requests from "config/api";
import API_ENDPOINTS from "constants/api";
import useAuth from "hooks/useAuth";
import { useMutation } from "react-query";
import triggerGAEvent from "utils/gaEvents";
import { UserDetails } from "../constants/sharedTypes";

export const loginRequest = async (loginDetails: UserDetails) => {
  const url = API_ENDPOINTS.LOGIN;
  return requests.post(url, loginDetails);
};

const useUserLogin = () => {
  const { login } = useAuth();

  return useMutation(
    async ({ loginDetails }: { loginDetails: UserDetails }) => {
      const response = await loginRequest(loginDetails);

      if (
        response &&
        response.status === 200 &&
        response.result &&
        response.result.firstname
      ) {
        // sending login event to analytics
        triggerGAEvent("login", response?.result?.userId);

        login({
          ...response.result,
          email: loginDetails.email,
        });
      }
      return response;
    },
  );
};

export default useUserLogin;
