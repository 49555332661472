import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormHelperText, InputAdornment } from "@mui/material";
import Resources from "assets/json/Resources";
import { Select } from "components/common";
import InputField from "components/ui/InputField";
import REGEX from "constants/regex";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth, useGetTaxExcludedCountries } from "hooks";
import { ChangeEventHandler, FocusEventHandler, useMemo, useRef } from "react";
import {
  Controller,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useGetGeoLocation } from "services/common";
import { GeoLocationResponse } from "services/common/types";
import styles from "../style.module.scss";
import {
  AddCreditCardFormType,
  addCreditCardSchema,
  cardNumberMaxLength,
  cvvMaxLength,
  dateMaxLength,
  zipCodeMaxLength,
} from "./config";
import { formatCreditCard } from "./helper";

type PaymentFormPropsType = {
  formId: string;
  onFormValid: (formData: FieldValues | object) => void;
  disabled?: boolean;
};

const btnClasses = "app-c-expiry";
const {
  PAGES: {
    PAYMENT_CENTER: {
      ADD_CREDIT_CARD: { ENTER_VALID_EXPIRY_MONTH },
    },
  },
} = Resources;

const TODAY = new Date();

function PaymentForm(props: PaymentFormPropsType) {
  const { formId, onFormValid, disabled } = props;
  const { setGlobalAlert } = useAuth();
  const yearFieldRef = useRef<HTMLInputElement>();

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    setError,
    getValues,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<AddCreditCardFormType>({
    resolver: yupResolver(addCreditCardSchema),
    mode: "all",
  });

  const handleZipCodeError = (errorInfo: FailedAPIStatus) => {
    const { errorMessage } = errorInfo;
    const msg = errorInfo?.errorField ? "Invalid zip code" : errorMessage;

    setError("zipCode", { message: msg, type: "validate" });
    setGlobalAlert({ message: msg, messageType: "error" });
  };

  const { taxExcludedCountries } = useGetTaxExcludedCountries();

  const onZipCodeSuccess = (response: GeoLocationResponse) => {
    if (!response?.result?.success) {
      setError("zipCode", { message: "Invalid Zip code", type: "required" });
    }
  };

  const { mutateAsync: getGeoLocation } = useGetGeoLocation({
    onError: handleZipCodeError,
    onSuccess: onZipCodeSuccess,
  });

  const countryList = useMemo(() => {
    return taxExcludedCountries.map((country) => ({
      label: country?.name,
      value: country?.code,
    }));
  }, [taxExcludedCountries]);

  const onSubmit: SubmitHandler<FieldValues> = (formData) => {
    const errorMessage = Object.values(errors)?.[0]?.message;

    if (errorMessage) {
      setGlobalAlert({ message: errorMessage, messageType: "error" });
      setError("zipCode", { message: errorMessage, type: "validate" });
      return;
    }

    onFormValid({
      ...formData,
      cardNumber: formData.cardNumber.replace(REGEX.SPACE, ""),
    });
  };

  const handleErrors: SubmitErrorHandler<FieldValues> = (FieldErrors) => {
    const errorMsgs = Object.values(FieldErrors).map(
      (formField) => formField?.message,
    );
    setGlobalAlert({
      messageType: "error",
      message: errorMsgs[0] as string,
    });
  };

  const handleCardNumberChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const formattedNumber = formatCreditCard(e.target.value);

    setValue("cardNumber", formattedNumber);
    trigger("cardNumber");
  };

  const handleCVVChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const onlyDigits = value.replace(/\D/g, "");
    setValue("cvv", onlyDigits);
    trigger("cvv");
  };

  const validateDate: FocusEventHandler<HTMLInputElement> = () => {
    const expMonth = getValues("expMonth");
    const expYear = getValues("expYear");
    const currentMonth = TODAY.getMonth() + 1;
    const year = TODAY.getFullYear();
    const currentYear = +year.toString().substring(2);

    if (+expYear === currentYear && +expMonth < currentMonth) {
      setError("expMonth", {
        message: ENTER_VALID_EXPIRY_MONTH,
        type: "value",
      });
    }
  };

  const handleMonthChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const { value } = e.target;
    const onlyDigits = value.replace(/\D/g, "");
    const monthValue = +onlyDigits;
    // month should be between 1 and 12
    if ((onlyDigits.length === 2 && monthValue <= 0) || monthValue > 12) {
      return;
    }
    setValue("expMonth", value);
    const isValid = await trigger("expMonth");

    if (onlyDigits.length === 2 && isValid) {
      // focus year field
      yearFieldRef.current?.focus();
    }
  };

  const handleYearChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const onlyDigits = value.replace(/\D/g, "");
    const yearValue = +onlyDigits;
    const year = TODAY.getFullYear();
    const currentYear = +year.toString().substring(2);
    if (onlyDigits.length === 2 && yearValue < currentYear) {
      return;
    }
    setValue("expYear", value);
    trigger("expYear");
    trigger("expMonth");
  };

  const onCountryChange = (value: string | number) => {
    setValue("country", value as string);
    setValue("zipCode", "");
    clearErrors("zipCode");
    clearErrors("country");
  };

  const handleZipCodeBlur: FocusEventHandler<HTMLInputElement> = async (
    event,
  ) => {
    const { value } = event.target;
    const trimmedValue = value.trim();

    if (!trimmedValue) return;

    const country = getValues("country");
    if (!country) {
      setGlobalAlert({
        message: "Please Select country",
        messageType: "error",
      });
      return;
    }

    await getGeoLocation({
      zipCode: trimmedValue,
      country,
      isSpecificToCountry: true,
    });
  };

  const isCountryAdded = Boolean(watch("country"));

  return (
    <Box className={styles.cardDetails}>
      <Box
        className={styles.splitContainer}
        component="form"
        id={formId}
        onSubmit={handleSubmit(onSubmit, handleErrors)}
        noValidate
      >
        <Controller
          name="cardNumber"
          control={control}
          render={({ field }) => (
            <InputField
              {...field}
              label="Card number"
              placeholder="Enter card number"
              type="number"
              inputProps={{
                inputMode: "numeric",
                maxLength: cardNumberMaxLength,
                type: "text",
              }}
              error={!!errors?.cardNumber}
              helperText={errors?.cardNumber?.message as string}
              onChange={handleCardNumberChange}
              disabled={disabled}
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <InputField
              label="Name of the card holder"
              placeholder="Enter card holder name"
              type="text"
              {...field}
              error={!!errors.name}
              helperText={errors?.name?.message as string}
              disabled={disabled}
            />
          )}
        />
      </Box>

      <Box className={styles.splitContainer}>
        <Box className={styles.innerSplit}>
          <Box>
            <Controller
              name="expMonth"
              control={control}
              render={({ field }) => (
                <InputField
                  disabled={disabled}
                  id="month-year"
                  autoComplete="off"
                  placeholder="MM"
                  label="Expiration date"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: dateMaxLength,
                    type: "text",
                  }}
                  className={`${btnClasses} error`}
                  {...field}
                  onBlur={validateDate}
                  onChange={handleMonthChange}
                  error={!!errors?.expMonth}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" sx={{ width: "100%" }}>
                        <Controller
                          name="expYear"
                          control={control}
                          // eslint-disable-next-line @typescript-eslint/no-shadow
                          render={({ field }) => (
                            <InputField
                              disabled={disabled}
                              placeholder="YY"
                              inputRef={yearFieldRef}
                              sx={{ width: "100%" }}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                maxLength: dateMaxLength,
                              }}
                              {...field}
                              onBlur={validateDate}
                              onChange={handleYearChange}
                              error={!!errors?.expYear}
                            />
                          )}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <FormHelperText error>
              {errors?.expMonth?.message as string}
            </FormHelperText>
            <FormHelperText error>
              {errors?.expYear?.message as string}
            </FormHelperText>
          </Box>
          <Controller
            name="cvv"
            control={control}
            render={({ field }) => (
              <InputField
                label="CVV"
                disabled={disabled}
                placeholder="Enter CVV number"
                type="number"
                inputProps={{
                  maxLength: cvvMaxLength,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  type: "text",
                }}
                {...field}
                error={!!errors.cvv}
                helperText={errors?.cvv?.message as string}
                onChange={handleCVVChange}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <Select
                options={countryList}
                {...field}
                label={Resources.PAGES.ACCOUNT_PROFILE.FIELDS.COUNTRY}
                placeholder={
                  Resources.PAGES.ACCOUNT_PROFILE.FIELDS.COUNTRY_PLACEHOLDER
                }
                placeholderDisabled
                error={!!errors?.country?.message}
                onChange={onCountryChange}
              />
            )}
          />
          {!!errors?.country?.message && (
            <FormHelperText error>{errors?.country?.message}</FormHelperText>
          )}
        </Box>
      </Box>

      <Box className={styles.splitContainer}>
        <Controller
          name="zipCode"
          control={control}
          render={({ field }) => (
            <InputField
              disabled={disabled || !isCountryAdded}
              label="Zip code of the billing address"
              placeholder="Enter ZIP code"
              type="text"
              {...field}
              error={!!errors.zipCode}
              helperText={errors?.zipCode?.message as string}
              onBlur={handleZipCodeBlur}
              inputProps={{
                maxLength: zipCodeMaxLength,
                type: "text",
              }}
            />
          )}
        />
        <Box />
      </Box>
    </Box>
  );
}
export default PaymentForm;
