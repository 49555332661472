import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { ConfirmDialog } from "components";
import { Breadcrumb } from "components/common";
import InputField from "components/ui/InputField";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth } from "hooks";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useOnBehalf } from "services/accountProfileService";
import { OnBehalfSuccess } from "services/accountProfileService/types";
import { changeEmail } from "utils/validationSchema";
import * as yup from "yup";

type ChangeEmailDataType = yup.InferType<typeof changeEmail>;

const breadcrumbs = [{ text: "Login On Behalf" }];

function OnBehalf() {
  const { setGlobalAlert } = useAuth();
  const navigate = useNavigate();
  const { login } = useAuth();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ChangeEmailDataType>({
    resolver: yupResolver(changeEmail),
    defaultValues: { email: "" },
    mode: "onChange",
  });

  const handleBack = () => navigate(-1);

  const onLoginSuccess = (response: OnBehalfSuccess) => {
    login({
      ...response.result,
      email: getValues("email").toLowerCase(),
    });
    window.location.reload();
  };

  const handleAPIError = (error: FailedAPIStatus) => {
    setGlobalAlert({ message: error.errorMessage, messageType: "error" });
  };

  const { mutateAsync: loginOnBehalf } = useOnBehalf({
    onSuccess: onLoginSuccess,
    onError: handleAPIError,
  });

  const onSubmit: SubmitHandler<FieldValues> = async (formData) => {
    await loginOnBehalf({ email: formData.email });
  };

  return (
    <Box
      className="app-l-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: "3rem !important",
        gap: "4.5rem",
      }}
    >
      <Breadcrumb crumbs={breadcrumbs} />

      <ConfirmDialog
        heading="Login On Behalf"
        onCancel={handleBack}
        onConfirm={handleSubmit(onSubmit)}
        primaryButtonLabel="Submit"
        secondaryButtonLabel="Cancel"
      >
        <Typography>Please enter an email address to continue.</Typography>
        <Box sx={{ maxWidth: "40rem", mt: "3.2rem" }}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <InputField
                {...field}
                label="Email"
                placeholder="Enter email address"
                type="email"
                error={!!errors?.email?.message}
                helperText={errors?.email?.message}
              />
            )}
          />
        </Box>
      </ConfirmDialog>
    </Box>
  );
}

export default OnBehalf;
