import { Box } from "@mui/material";
import Resources from "assets/json/Resources";
import { Breadcrumb, SectionLoader, Snackbar } from "components/common";
import { PaymentCard, PrimaryButtonMedium } from "components/ui";
import { ROUTES } from "config/app";
import { SnackbarType } from "constants/sharedTypes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useGetPaymentMethods, {
  PaymentMethodResponseType,
  PaymentType,
  // PlaidType,
} from "services/useGetPaymentMethods";
import NoData from "./NoData";
// import AddPaymentMethod from "./addPaymentMethod";
import styles from "./styles.module.scss";
// import { PaymentTypes } from "./types";

const { PAYMENT_CENTER } = Resources.PAGES;

const breadcrumbs = {
  paymentDetails: [
    {
      text: "Payment Center",
    },
  ],
  accessCode: [
    { text: "Access code", active: true },
    {
      text: "Manage your access code here",
    },
  ],
};

function PaymentCenter() {
  const [activePayments, setActivePayments] = useState<PaymentType[]>([]);
  // const [isAddPaymentSection, setAddPaymentSection] = useState(false);
  // const [defaultActiveTab, setDefaultActiveTab] =
  // useState<PaymentTypes>("creditCard");
  // const [plaidInfo, setPlaidInfo] = useState<PlaidType>({} as PlaidType);
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    open: false,
    message: "",
    type: "success",
  });

  const navigate = useNavigate();

  const filterAndSavePaymentMethod = (payments: PaymentMethodResponseType) => {
    const availablePayments =
      payments?.result?.paymentDetails?.payments?.filter(
        (payment) => payment.status === "Active",
      ) ?? [];
    // setPlaidInfo(payments?.result?.paymentDetails?.paymentCenter?.plaid);
    setActivePayments(availablePayments);
  };

  const { isLoading: isPaymentsLoading, isFetching: isPaymentFetching } =
    useGetPaymentMethods({ onSuccess: filterAndSavePaymentMethod });

  // const toggleAddPaymentSection = () => setAddPaymentSection((prev) => !prev);

  const handleAddPaymentMethodClick = () => {
    // setDefaultActiveTab(tab);
    // toggleAddPaymentSection();
    navigate(ROUTES.PRIVATE.ADD_PAYMENT);
  };

  const onPaymentDelete = (payment: PaymentType) => {
    navigate(ROUTES.PRIVATE.DELETE_PAYMENT, { state: payment });
  };

  const onViewUsage = (payment: PaymentType) => {
    navigate(
      ROUTES.PRIVATE.PAYMENT_USAGE.replace(
        ":paymentId",
        payment.paymentMethodId,
      ),
    );
  };

  const onSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const renderSnackbar = () => (
    <Snackbar
      severity={snackbar.type}
      open={snackbar.open}
      message={snackbar.message}
      onClose={onSnackbarClose}
    />
  );
  // console.log(toggleAddPaymentSection, plaidInfo, defaultActiveTab);

  return (
    <Box
      className="app-l-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "3rem !important",
      }}
    >
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        {renderSnackbar()}
        {/* {isAddPaymentSection ? (
          <AddPaymentMethod
            toggleSection={toggleAddPaymentSection}
            plaidInfo={plaidInfo}
            defaultActiveTab={defaultActiveTab}
          />
        ) : ( */}
        <Box className={styles.pageLayout}>
          <Box className={styles.spaceBetween}>
            <Breadcrumb crumbs={breadcrumbs.paymentDetails} />
            {activePayments.length ? (
              <Box className={styles.paymentButton}>
                <PrimaryButtonMedium
                  label={PAYMENT_CENTER.ADD_CREDIT_CARD_BUTTON}
                  onClick={handleAddPaymentMethodClick}
                />
              </Box>
            ) : null}
          </Box>
          <Box className={styles.scrollContainer}>
            <SectionLoader loading={isPaymentsLoading || isPaymentFetching} />
            {!isPaymentsLoading && activePayments.length === 0 && (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <NoData onActionClick={handleAddPaymentMethodClick} />
              </Box>
            )}
            {activePayments
              .filter((card) => card.paymentMethod === "CC")
              .map((payment) => (
                <PaymentCard
                  paymentMethodInfo={payment}
                  onCardDelete={onPaymentDelete}
                  viewUsage={onViewUsage}
                />
              ))}
          </Box>
        </Box>
        {/* )} */}
      </Box>
    </Box>
  );
}

export default PaymentCenter;
