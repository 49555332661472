import { Box, TextField } from "@mui/material";
import Resources from "assets/json/Resources";
import { ConfirmDialog } from "components";
import { OTPCount, OTPInput } from "components/ui";
import OtpResentSuccess from "components/ui/OtpResentSuccess";
import { DEFAULT_OTP_LENGTH, OTP_WAITING_TIME, ROUTES } from "config/app";
import REGEX from "constants/regex";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth } from "hooks";
import useTimer from "hooks/useTimer";
import { HINT_MESSAGE } from "pages/ForgotPassword/config";
import { ChangeEvent, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useChangeEmail } from "services/accountProfileService";
import { ValidateEmailResponse } from "services/accountProfileService/types";
import useValidateEmail from "services/accountProfileService/useValidateEmail";
import useGetTalentHomeDetails from "services/useGetTalentHomeDetails";
import { ChangePhoneType } from "../ChangePhone/config";

type Props = {
  onVerified: (emailInfo: { newEmail: string; oldEmailOTP: string }) => void;
  onAbort: () => void;
};

type EmailFormValues = {
  email: string;
};

const {
  COMMON: {
    BUTTONS: { RESEND, ATTEMPT_LEFT, CODE_LABEL },
    FIELDS: { SHOULD_DIFFERENT },
    MESSAGE: { VALID_CODE },
  },
} = Resources;

function OldEmailVerification(props: Props) {
  const { onAbort } = props;

  const navigate = useNavigate();
  const { setGlobalAlert } = useAuth();

  const [helperText, setHelperText] = useState<string>("");
  const [otpBalance, setOtpBalance] = useState(0);
  const [canSentOtp, setCanSentOtp] = useState<Boolean>(true);
  const [isShowResentMsg, setIsShowResentMsg] = useState<Boolean>(true);
  const [resentMsg, setResentMsg] = useState<string>("");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isOTPSent, setIsOTPSent] = useState(true);
  const [OTP, setOTP] = useState("");
  const [showOTPInput, setShowOTPInput] = useState(false);

  const location = useLocation();
  const phoneOTP = location.state as ChangePhoneType;

  const { data: userInfo } = useGetTalentHomeDetails();

  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<{ email: string }>({
    mode: "all",
    shouldFocusError: true,
    defaultValues: { email: "" },
  });

  const newEmail = getValues("email");

  const showError = (message: string) => {
    setGlobalAlert({ messageType: "error", message });
  };

  const handleAPIError = (error: FailedAPIStatus) => {
    showError(error.errorMessage);
  };

  const handleBack = () => {
    onAbort();
  };

  const onOtpTimerComplete = () => {
    setCanSentOtp(true);
  };

  const { timeLeft: otpTimer } = useTimer({
    time: OTP_WAITING_TIME,
    onComplete: onOtpTimerComplete,
    start: !canSentOtp,
  });

  const hideResentMessage = () => {
    setIsShowResentMsg(false);
  };

  const handleEmailVerifySuccess = () => {
    setGlobalAlert({
      message: "Email updated successfully",
      messageType: "success",
    });
    navigate(ROUTES.PRIVATE.ACCOUNT_PROFILE);
  };

  const handleVerifyEmailAPIError = (error: FailedAPIStatus) => {
    if (error.errorKey === "PWB_INVALID_OLD_OTP") {
      navigate(-1);
    }
    setHelperText(error.errorMessage);
    showError(error.errorMessage);
  };

  const { mutateAsync: changeEmail, isLoading } = useChangeEmail({
    onSuccess: handleEmailVerifySuccess,
    onError: handleVerifyEmailAPIError,
  });

  const handleSucess = (response: ValidateEmailResponse) => {
    setShowOTPInput(true);
    setOtpBalance(response?.result?.resendEmailOtpBalance);
    setResentMsg(response?.result?.hintMessageCode);
    if (response?.result?.resendEmailOtpBalance > 0) {
      setIsShowResentMsg(true);
    } else {
      setIsShowResentMsg(false);
      setIsFirstTime(false);
    }
  };

  const { mutateAsync: validateEmailOtp, isLoading: isValidating } =
    useValidateEmail({
      onSuccess: handleSucess,
      onError: handleAPIError,
    });

  const handleOTPResend = async () => {
    if (!otpBalance) {
      showError("No resend attempts left. Try again later.");
    } else {
      await validateEmailOtp({ email: newEmail });
      setIsShowResentMsg(true);
      setCanSentOtp(false);
      setIsFirstTime(false);
      setIsOTPSent(true);
    }
  };

  const handleAlertClose = () => setIsOTPSent(false);

  const onSubmit = async () => {
    if (OTP?.length !== DEFAULT_OTP_LENGTH) {
      const errorMessage = VALID_CODE;
      setHelperText(errorMessage);
      showError(errorMessage);
      return;
    }

    await changeEmail({
      newEmail,
      newEmailOtp: OTP,
      oldEmailOTP: phoneOTP?.oldOtp,
    });
  };

  const handleEmailChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: keyof EmailFormValues,
  ) => {
    setShowOTPInput(false);
    const { value } = event.target;

    setValue(fieldName, value);
    trigger(fieldName);
  };

  const onOTPFocus = () => {
    setHelperText("");
  };

  const handleValidate = async () => {
    setIsFirstTime(true);
    if (newEmail === userInfo?.home?.email) {
      showError(SHOULD_DIFFERENT);
    } else {
      await validateEmailOtp({ email: newEmail });
    }
  };

  if (!phoneOTP) {
    navigate(-1);
  }

  return (
    <ConfirmDialog
      heading="Change Email"
      onCancel={handleBack}
      onConfirm={handleSubmit(onSubmit)}
      primaryButtonProps={{ disabled: isLoading || !showOTPInput }}
    >
      <Box sx={{ maxWidth: "40rem" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                label="New Email"
                placeholder="Enter your new email"
                autoComplete="off"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Please enter new email",
                  },
                  pattern: {
                    value: REGEX.EMAIL,
                    message: "Please enter valid email",
                  },
                })}
                {...field}
                error={!!errors?.email}
                helperText={errors.email?.message}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => handleEmailChange(event, "email")}
              />
            )}
          />
          <Box sx={{ alignSelf: "flex-end" }}>
            {!showOTPInput && isValid && (
              <OTPCount
                sx={{
                  justifyContent: "flex-end",
                  mb: "3rem",
                  marginTop: "1rem",
                }}
                resendLabel="Validate"
                onResend={handleValidate}
              />
            )}
          </Box>
        </Box>
        {showOTPInput && isValid && (
          <Box sx={{ maxWidth: "40rem", mt: "3.2rem" }}>
            <>
              <OTPInput
                otpLength={DEFAULT_OTP_LENGTH}
                legend={CODE_LABEL}
                onComplete={setOTP}
                showAlert={isOTPSent}
                onFocus={onOTPFocus}
                helpText={helperText ?? ""}
                error={!!helperText}
                onAlertClose={handleAlertClose}
                alertMessage={
                  isFirstTime
                    ? "Code sent successfully!"
                    : "Code resent successfully!"
                }
              />
              <OTPCount
                attemptsLeft={otpBalance}
                onResend={handleOTPResend}
                resendLabel={
                  canSentOtp ? RESEND : `Resend code in ${otpTimer}s`
                }
                attemptsLabel={ATTEMPT_LEFT}
                sx={{ justifyContent: "flex-end", mb: "3rem" }}
                isAttemptEnabled
                loading={isValidating}
                disabled={!canSentOtp}
              />
            </>
          </Box>
        )}
        {isShowResentMsg && resentMsg && showOTPInput && !isFirstTime && (
          <OtpResentSuccess
            message={HINT_MESSAGE[resentMsg]}
            onCancel={hideResentMessage}
          />
        )}
      </Box>
    </ConfirmDialog>
  );
}

export default OldEmailVerification;
