import { TAX_EXCLUDED_COUNTRIES } from "config/app";
import { useMemo } from "react";
import { useGetCountryList } from "services/common";
import { CountryListResponse } from "services/common/types";

type ReturnType = {
  taxExcludedCountries: CountryListResponse["result"]["countryList"];
};

const useGetTaxExcludedCountries = (): ReturnType => {
  const { data: countryListResponse } = useGetCountryList();

  const taxExcludedCountries = useMemo(() => {
    if (!countryListResponse?.result?.countryList?.length) return [];

    return countryListResponse?.result?.countryList?.filter(
      (country) => !TAX_EXCLUDED_COUNTRIES.includes(country.code),
    );
  }, [countryListResponse]);

  return { taxExcludedCountries };
};

export default useGetTaxExcludedCountries;
