import { Box, Dialog, Theme, useMediaQuery } from "@mui/material";
import { Tutorial } from "components";
import useSidebar from "hooks/useSidebar";
import { PageTour, TourStep } from "libs/pagetour";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getElement } from "utils";
import TutorialItem from "../TutorialItem";
import {
  HOME_MENU_INDEX,
  TourItem,
  commonConfig,
  tourItems,
  tutorials,
} from "./config";

const MODAL_SELECTOR = ".MuiDrawer-modal";

type TutorialProps = { onComplete: () => void };

function HomeTutorial(props: TutorialProps) {
  const { onComplete } = props;
  const {
    open: isSidebarOpen,
    setSidebarVisibility,
    setActiveMenu,
  } = useSidebar();
  const [openWelcomeModal, setOpenWelcomeModal] = useState(true);
  const [stepIndex, setStepIndex] = useState<number | undefined>(0);
  const [startTour, setStartTour] = useState(false);

  // checks whether the sidebar is hidden
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg"),
  );

  // fix for first tour item showing in offscreen
  useEffect(() => {
    if (stepIndex === HOME_MENU_INDEX) {
      const sidebar = getElement("#mob-sidebar-scroll-container");
      sidebar?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [stepIndex]);

  const sidebarTourItems = useMemo<TourItem[]>(() => {
    const items: TourItem[] = tourItems.map((item) => item.sidebarItem);
    // first step is sidebar - which can't be highlighted
    return [null, ...items, "logout"];
  }, []);

  const showWelcomeModal = useCallback(() => {
    setStartTour(false);

    if (isSidebarOpen) setSidebarVisibility({ isVisible: false });
    setOpenWelcomeModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarOpen]);

  const handleTutorialStart = () => {
    setStartTour(true);

    setOpenWelcomeModal(false);
    // if it is mobile screen, open sidebar
    if (isSmallScreen) {
      setSidebarVisibility({ isVisible: true });
    }
    setStepIndex(0);
  };

  const handleTutorialComplete = useCallback(() => {
    setStartTour(false);
    setActiveMenu(null);
    if (openWelcomeModal) setOpenWelcomeModal(false);
    onComplete();
    // if sidebar is open in mobile screen, then close it
    if (isSmallScreen && isSidebarOpen) {
      setSidebarVisibility({ isVisible: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onComplete, openWelcomeModal]);

  const handleBackwardNavigation = useCallback(() => {
    if (typeof stepIndex === "undefined") return;

    const nextIndex = stepIndex - 1;
    if (nextIndex < 0) return;

    if (nextIndex >= HOME_MENU_INDEX) {
      setActiveMenu(sidebarTourItems[nextIndex]);
    }

    setStepIndex(nextIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex]);

  const handleForwardNavigation = useCallback(() => {
    if (typeof stepIndex === "undefined") return;

    const nextIndex = stepIndex + 1;

    if (nextIndex > sidebarTourItems.length) return;

    if (nextIndex >= HOME_MENU_INDEX) {
      setActiveMenu(sidebarTourItems[nextIndex]);
    }
    setStepIndex(nextIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex, sidebarTourItems]);

  // creates the data and UI for the tutorial
  const tourSteps = useMemo<TourStep[]>(() => {
    //
    const sidebarSteps: TourStep[] = tourItems.map((item, index) => {
      return {
        ...commonConfig,
        target: isSmallScreen
          ? `${MODAL_SELECTOR} ${item.selector}`
          : item.selector,
        // fix for first tour item showing in offscreen
        placement:
          // eslint-disable-next-line no-nested-ternary
          index === 0 && isSmallScreen
            ? "center"
            : item.key === "accessCode"
            ? "right-end"
            : "right-start",
        content: (
          <TutorialItem
            tutorialInfo={tutorials[item.key]}
            onClose={handleTutorialComplete}
            onBackClick={handleBackwardNavigation}
            onNextClick={handleForwardNavigation}
          />
        ),
      };
    });

    return [
      {
        ...commonConfig,
        target: isSmallScreen
          ? `${MODAL_SELECTOR} [data-tutorial-item="sidebar"]`
          : '[data-tutorial-item="sidebar"]',
        placement: isSmallScreen ? "center" : "right",
        floaterProps: {
          styles: {
            floaterCentered: {
              transform: "translateX(-50%)",
              bottom: "1.5rem",
              top: "auto",
            },
          },
        },
        content: (
          <TutorialItem
            tutorialInfo={tutorials.sidebar}
            onClose={handleTutorialComplete}
            onBackClick={showWelcomeModal}
            onNextClick={handleForwardNavigation}
          />
        ),
      },
      ...sidebarSteps,
      {
        ...commonConfig,
        target: isSmallScreen
          ? `${MODAL_SELECTOR} [data-tutorial-item="logout"]`
          : '[data-tutorial-item="logout"]',
        placement: isSmallScreen ? "center" : "right-end",
        content: (
          <TutorialItem
            tutorialInfo={tutorials.logout}
            onBackClick={handleBackwardNavigation}
            primaryActionLabel="Let's Get Started"
            onNextClick={handleTutorialComplete}
          />
        ),
      },
    ];
  }, [
    isSmallScreen,
    handleTutorialComplete,
    showWelcomeModal,
    handleForwardNavigation,
    handleBackwardNavigation,
  ]);

  const renderWelcomeModal = () => {
    return (
      <Dialog
        open={openWelcomeModal}
        PaperProps={{
          sx: {
            position: "absolute",
            maxWidth: 1200,
            m: "0",
            bottom: { md: "auto", xs: "1.5rem" },
            width: { md: "65vw", xs: "90vw" },
          },
        }}
      >
        <Tutorial
          onClose={handleTutorialComplete}
          info={
            <Box>
              <Tutorial.Title>{tutorials.welcome?.title}</Tutorial.Title>
              <Tutorial.Description sx={{ mt: 2 }}>
                {tutorials?.welcome?.description}
              </Tutorial.Description>
            </Box>
          }
          action={
            <Tutorial.ButtonWrapper>
              <Tutorial.Button
                onClick={handleTutorialStart}
                variant="contained"
              >
                Get Started
              </Tutorial.Button>
            </Tutorial.ButtonWrapper>
          }
        />
      </Dialog>
    );
  };

  return (
    <Box>
      {renderWelcomeModal()}

      <PageTour
        start={startTour}
        stepIndex={stepIndex}
        steps={tourSteps}
        styles={{
          tooltip: {
            padding: 0,
            width: isSmallScreen ? "90vw" : "65vw",
            maxWidth: 1200,
          },
          tooltipContent: { padding: 0, textAlign: "start" },
        }}
      />
    </Box>
  );
}

export default HomeTutorial;
