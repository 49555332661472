import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { changePassword } from "./accountProfileService";
import { ChangePasswordPayload, ChangePasswordResponse } from "./types";

type APIResponse = ChangePasswordResponse & FailedAPIResponse;

const useChangePassword = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, ChangePasswordPayload>({
    mutationFn: changePassword,
    resource: Resources.API_ERRORS.PASSWORD,
    handler,
  });
};

export default useChangePassword;
