/* eslint-disable */
//@ts-nocheck
import { APP_ENVIRONMENT } from "config/app";
import { useEffect } from "react";

const useDrift = () => {
  // only runs in PROD environment
  useEffect(() => {
    const appEnvKey = process.env.REACT_APP_ENVIRONMENT;
    if (appEnvKey === APP_ENVIRONMENT.PRODUCTION) {
      !(function () {
        let t = (window.driftt = window.drift = window.driftt || []);
        if (!t.init) {
          if (t.invoked)
            return void (
              window.console &&
              console.error &&
              console.error("Drift snippet included twice.")
            );
          (t.invoked = !0),
            (t.methods = [
              "identify",
              "config",
              "track",
              "reset",
              "debug",
              "show",
              "ping",
              "page",
              "hide",
              "off",
              "on",
            ]),
            (t.factory = function (e) {
              return function () {
                let n = Array.prototype.slice.call(arguments);
                return n.unshift(e), t.push(n), t;
              };
            }),
            t.methods.forEach(function (e) {
              t[e] = t.factory(e);
            }),
            (t.load = function (t) {
              let e = 3e5,
                n = Math.ceil(new Date() / e) * e,
                o = document.createElement("script");
              (o.type = "text/javascript"),
                (o.async = !0),
                (o.crossorigin = "anonymous"),
                (o.src =
                  "https://js.driftt.com/include/" + n + "/" + t + ".js");
              let i = document.getElementsByTagName("script")[0];
              i.parentNode.insertBefore(o, i);
            });
        }
      })();
      drift.SNIPPET_VERSION = "0.3.1";
      drift.load("c97gz2i4nzbf");

      // load configurations after load
      drift.on("ready", () => {
        drift.config({ verticalOffset: 100 });
      });
    }
  }, []);
};
export default useDrift;
