import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { removeCartItem } from "./featureCenterService";
import { CartItemPayload, CartUpdateResponse } from "./types";

type APIResponse = CartUpdateResponse & FailedAPIResponse;

const useRemoveCartItem = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, CartItemPayload>({
    mutationFn: removeCartItem,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useRemoveCartItem;
