import Resources from "assets/json/Resources";
import { Menu } from "config/app";

export type TourItem = Menu | null;

export type TutorialItems =
  | "welcome"
  | "sidebar"
  | "home"
  | "account"
  | "appExperience"
  | "featureCenter"
  | "documentCenter"
  | "paymentCenter"
  | "accessCode"
  | "logout";

export type TutorialInfo = { title: string; description: string };
export type TutorialType = Record<TutorialItems, TutorialInfo>;

type TourElement = {
  sidebarItem: TourItem;
  key: TutorialItems;
  selector: string;
};

export const tutorials: TutorialType = Resources.PAGES.FTUE.HOME;

export const tourItems: TourElement[] = [
  { sidebarItem: "home", key: "home", selector: '[data-tutorial-item="home"]' },
  {
    sidebarItem: "account",
    key: "account",
    selector: '[data-tutorial-item="account"]',
  },
  {
    sidebarItem: null,
    key: "appExperience",
    selector: '[data-sidebar-item="appExperience"]',
  },
  {
    sidebarItem: "featureCenter",
    key: "featureCenter",
    selector: '[data-tutorial-item="featureCenter"]',
  },
  {
    sidebarItem: "documentCenter",
    key: "documentCenter",
    selector: '[data-tutorial-item="documentCenter"]',
  },
  {
    sidebarItem: "paymentCenter",
    key: "paymentCenter",
    selector: '[data-tutorial-item="paymentCenter"]',
  },
  {
    sidebarItem: "accessCode",
    key: "accessCode",
    selector: '[data-tutorial-item="accessCode"]',
  },
];

export const commonConfig = {
  hideFooter: true,
  disableBeacon: true,
  offset: 50,
  spotlightPadding: 0,
};

export const HOME_MENU_INDEX = 1;
