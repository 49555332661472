import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { APIResponse, HandlerType } from "services/commonTypes";
import { validatePhoneOtp } from "./accountProfileService";
import { ValidatePhoneOtpPayload } from "./types";

const useValidPhoneOtp = (handler: HandlerType) => {
  return useMutationWithHandler<APIResponse, ValidatePhoneOtpPayload>({
    mutationFn: validatePhoneOtp,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useValidPhoneOtp;
