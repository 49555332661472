import {
  changePhoneNumber,
  emailSchema,
  isdCountrySchema,
  isdSchema,
  nameSchema,
  passwordSchema,
  phoneNumberSchema,
} from "utils/validationSchema";
import * as yup from "yup";

export const ageRange = {
  below18: "BELOW_18",
  between18and21: "BETWEEN_18_AND_21",
  above21: "ABOVE_21",
};

export const ageOptions = [
  { label: "< 18", value: ageRange.below18 },
  { label: "18-21", value: ageRange.between18and21 },
  { label: "> 21", value: ageRange.above21 },
];

export const emailErrorMsg = {
  required: "Please fill email",
  regex:
    "Double check the email address you have entered. Looks like something is off!",
};

export const registerFormSchema = yup
  .object({
    firstName: nameSchema({
      required: "Please fill first name",
      regex: "First name cannot contain special characters",
    }),
    lastName: nameSchema({
      required: "Please fill last name",
      regex: "Last name cannot contain special characters",
    }),
    email: emailSchema(emailErrorMsg),
    password: passwordSchema,
    ageRange: yup
      .mixed()
      .oneOf(Object.values(ageRange), "Age is required")
      .required("Age is required"),

    guardianEmail: yup.string().when("ageRange", {
      is: (value: string) => value === ageRange.below18,
      then: (schema) =>
        schema
          .concat(
            emailSchema({
              required: "Please fill guardian email",
              regex: emailErrorMsg.regex,
            }),
          )
          .test(
            "unique-email",
            "Guardian's email cannot be the same as your email.",
            // eslint-disable-next-line func-names
            function (value) {
              const talentEmail = this.resolve(yup.ref("email"));
              return value !== talentEmail;
            },
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
    guardianIsdCode: yup.string().when("ageRange", {
      is: (value: string) => value === ageRange.below18,
      then: () => isdSchema,
      otherwise: (schema) => schema.notRequired(),
    }),
    guardianPhoneNumber: yup.string().when("ageRange", {
      is: (value: string) => value === ageRange.below18,
      then: () =>
        phoneNumberSchema.test(
          "unique-phone-number",
          "Guardian's phone number cannot be the same as your phone number.",
          // eslint-disable-next-line func-names
          function (value) {
            const talentIsdCode = this.resolve(yup.ref("isdCode"));
            const talentPhoneNumber = this.resolve(yup.ref("phoneNumber"));
            const guardianIsdCode = this.resolve(yup.ref("guardianIsdCode"));
            return (
              !guardianIsdCode ||
              !talentIsdCode ||
              `${guardianIsdCode} ${value}` !==
                `${talentIsdCode} ${talentPhoneNumber}`
            );
          },
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
    guardianIsdCountry: yup.string().when("ageRange", {
      is: (value: string) => value === ageRange.below18,
      then: () => isdCountrySchema,
      otherwise: (schema) => schema.notRequired(),
    }),
  })
  .concat(changePhoneNumber);
