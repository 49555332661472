/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import defaultImage from "assets/images/defaultImage.png";
import Resources from "assets/json/Resources";
import CountryPhoneBlock from "components/CountryPhoneBlock";
import {
  LoadingButton,
  SectionLoader,
  Select,
  Snackbar,
} from "components/common";
import ProfilePicture from "components/common/ProfilePic";
import SVGIcon from "components/icons";
import InputField from "components/ui/InputField";
import { ALLOWED_COUNTRIES, REACT_QUERY_KEYS, ROUTES } from "config/app";
import REGEX from "constants/regex";
import {
  FailedAPIStatus,
  InputEventType,
  SnackbarType,
} from "constants/sharedTypes";
import useAuth from "hooks/useAuth";
import * as React from "react";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  getGeoLocation,
  setLocation,
  updateName,
} from "services/accountProfile";
import { useGetEmailOTP, useSetCountry } from "services/accountProfileService";
import { OtpReasonType } from "services/accountProfileService/types";
import useSetProfilePic from "services/accountProfileService/useSetProfilePic";
import { useGetCountryList } from "services/common";
import useUserProfileDetails from "services/useGetUserProfileDetails";
import { zipCodeValidation } from "utils";
import { AGE } from "./config";
import styles from "./style.module.scss";

const { PROFILE_INFORMATION, FIELDS, VALIDATION_MESSAGES } =
  Resources.PAGES.ACCOUNT_PROFILE;

const MIN_ZIP_LENGTH = 5;

const fileReader = new FileReader();

type PasswordForm = {
  firstName: string;
  lastName: string;
  zipCode: string;
  country: string;
  city: string;
  state: string;
  email: string;
  phoneNumber: number;
  isdCode: string;
  accessCode: string;
  isdCountry: string;
};

type ProfileDetailsPropTypes = {
  profileDetails: any;
};

type LocationDataType = {
  city: string;
  zipCode: string;
  country: string;
  isSpecificToCountry: boolean;
};

function ProfileForm(props: ProfileDetailsPropTypes) {
  const { profileDetails } = props;
  const queryClient = useQueryClient();
  const {
    setGlobalAlert,
    setUserImage,
    user: currentLoggedInUser,
    setUser,
  } = useAuth();

  const [loader, setLoader] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState<SnackbarType>({
    open: false,
    message: "",
    type: "error",
  });

  const [cityDetails, setCityDetails] = React.useState({
    city: profileDetails?.home?.accountProfileLocation?.city,
    cityList: [],
  });

  const setCityList = async (locationData: LocationDataType) => {
    const { city, ...geoData } = locationData;
    const response = await getGeoLocation(geoData);
    setCityDetails({ city, cityList: response?.result?.cityList || [] });
  };

  React.useEffect(() => {
    const locationData = {
      country: profileDetails?.home?.accountProfileLocation?.country,
      zipCode: profileDetails?.home?.accountProfileLocation?.zipCode,
      city: profileDetails?.home?.accountProfileLocation?.city,
      isSpecificToCountry: true,
    };
    setCityList(locationData);
  }, [profileDetails?.home?.accountProfileLocation?.zipCode]);

  const [initialData, setInitialData] = React.useState({
    firstName: profileDetails?.home?.userName?.firstname?.trim(),
    lastName: profileDetails?.home?.userName?.lastname?.trim(),
    zipCode: profileDetails?.home?.accountProfileLocation?.zipCode,
    city: profileDetails?.home?.accountProfileLocation?.city,
    state: profileDetails?.home?.accountProfileLocation?.state,
    country: profileDetails?.home?.accountProfileLocation?.country,
  });

  const { data } = useUserProfileDetails();
  const userImage = data?.profileImageURL ?? defaultImage;

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    register,
    setError,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<PasswordForm>({
    mode: "all",
    shouldFocusError: true,
    defaultValues: {
      firstName: profileDetails?.home?.userName?.firstname?.trim(),
      lastName: profileDetails?.home?.userName?.lastname?.trim(),
      zipCode: profileDetails?.home?.accountProfileLocation?.zipCode,
      country: profileDetails?.home?.accountProfileLocation?.country,
      city: profileDetails?.home?.accountProfileLocation?.city,
      state: profileDetails?.home?.accountProfileLocation?.state,
      email: profileDetails?.home?.email,
      phoneNumber: profileDetails?.home?.phoneNumber,
      isdCode: profileDetails?.home?.isdCode?.replace?.("+", ""), // remove "+" from isd
      isdCountry: profileDetails?.home?.isdCountry,
    },
  });

  const showError = (message: string) => {
    setGlobalAlert({ message, messageType: "error" });
  };

  const navigatePage =
    (navigatedPage: string, otpReason: OtpReasonType) => () => {
      const changeUserDetails = {
        page: navigatedPage,
        phoneNumber: profileDetails?.home?.phoneNumber,
        isdCode: profileDetails?.home?.isdCode,
        otpReason,
      };
      navigate(ROUTES.PRIVATE.VERIFY_PHONE, { state: changeUserDetails });
    };

  const onEmailOTPSendSuccess = () => {
    navigatePage(
      ROUTES.PRIVATE.UPDATE_EMAIL,
      "CHANGE_EMAIL_PHONE_VALIDATION",
    )();
  };

  const handlePhotoUploadError = (error: FailedAPIStatus) => {
    showError(error.errorMessage);
  };

  const { mutateAsync: updateProfilePic, isLoading: isPhotoUploading } =
    useSetProfilePic({
      onError: handlePhotoUploadError,
    });

  const { refetch: getEmailOTP, isLoading: isEmailOTPSending } = useGetEmailOTP(
    {
      onError: showError,
      onSuccess: onEmailOTPSendSuccess,
    },
  );

  const { mutateAsync: setCountry } = useSetCountry({
    onError: showError,
  });

  const { data: countryListResponse } = useGetCountryList();

  const countryList = useMemo(() => {
    const list = countryListResponse?.result?.countryList?.map((country) => ({
      label: country?.name,
      value: country?.code,
    }));
    return list;
  }, [countryListResponse]);

  const successToaster = () => {
    setLoader(false);
    setSnackbar({
      ...snackbar,
      open: true,
      message: Resources.PAGES.ACCOUNT_PROFILE.SAVED_SUCCESSFULLY,
      type: "success",
    });
  };

  const saveLocationInfo = async () => {
    const payload = {
      city: getValues("city"),
      state: getValues("state"),
      country: getValues("country"),
      zipCode: getValues("zipCode"),
    };
    setLoader(true);
    const response = await setLocation(payload);
    if (response?.status !== 200 && response.errorMessage !== "") {
      setLoader(false);
      setError("zipCode", {
        type: "error",
        message: Resources.ERRORS.INVALID_ZIPCODE,
      });
    } else {
      queryClient.invalidateQueries(REACT_QUERY_KEYS.USER_PROFILE_DETAILS);
      setUser({
        ...currentLoggedInUser,
        email: currentLoggedInUser?.email as string,
        zipCode: getValues("zipCode"),
        userid: currentLoggedInUser?.userid as string,
      });
      setInitialData({
        ...initialData,
        city: getValues("city"),
        state: getValues("state"),
        zipCode: getValues("zipCode"),
      });
      successToaster();
    }
  };

  const onCityChange = (e: InputEventType) => {
    const { value } = e.target;
    setCityDetails({ ...cityDetails, city: value });
    setValue("city", value);
    saveLocationInfo();
  };

  const saveProfileDetails = async (formData: any, e: any) => {
    // saving profile details on onBlur
    const { value, name } = e.target;
    switch (name) {
      case "firstName":
      case "lastName":
        if (value !== "") {
          const payload = {
            firstName: formData.firstName,
            lastName: formData.lastName,
          };
          if (
            formData.firstName !== initialData.firstName ||
            formData.lastName !== initialData.lastName
          ) {
            setLoader(true);
            const response = await updateName(payload);
            if (response.status === 200) {
              successToaster();
              setInitialData({
                ...initialData,
                firstName: formData.firstName,
                lastName: formData.lastName,
              });
              queryClient.invalidateQueries(
                REACT_QUERY_KEYS.USER_PROFILE_DETAILS,
              );
            } else {
              setLoader(false);
              setSnackbar({
                ...snackbar,
                open: true,
                message: Resources.ERRORS.SOMETHING,
                type: "error",
              });
            }
          }
        } else {
          reset({
            firstName: initialData.firstName,
            lastName: initialData.lastName,
          });
        }
        break;
      case "zipCode":
        if (value.length) {
          if (value.length < MIN_ZIP_LENGTH) {
            setError("zipCode", {
              type: "error",
              message: Resources.PAGES.ACCOUNT_PROFILE.MINIMUM_FIVE_DIGITS,
            });
            return;
          }
          const formattedZipcode = await zipCodeValidation(value);
          if (formattedZipcode !== null && formattedZipcode !== "") {
            const geoData = {
              country: getValues("country"),
              zipCode: formattedZipcode,
              isSpecificToCountry: true,
            };
            const response = await getGeoLocation(geoData);
            if (
              response.status === 200 &&
              response.result.success &&
              response.result !== "" &&
              response.result.cityList &&
              response?.result?.state &&
              response?.result?.country &&
              response?.result?.zipCode
            ) {
              const { defaultCity = "", cityList = [] } = {
                ...response?.result,
              };
              if (
                !ALLOWED_COUNTRIES.includes(
                  response?.result?.country.toUpperCase(),
                )
              ) {
                setError("zipCode", {
                  type: "error",
                  message:
                    Resources.PAGES.ACCOUNT_PROFILE.ZIP_CODE_NOT_APPLICABLE,
                });
                return;
              }
              setCityDetails({
                ...cityDetails,
                cityList,
                city:
                  (cityList?.includes(defaultCity) && defaultCity) ||
                  cityList?.[0] ||
                  "",
              });
              reset({
                city:
                  response.result.cityList.length > 1
                    ? response?.result?.cityList[0]
                    : response?.result?.cityList.length === 1
                    ? response.result.defaultCity
                    : "",
                state: response?.result?.state || "",
                country: response?.result?.country || "",
                zipCode: response?.result?.zipCode || "",
              });
              saveLocationInfo();
            } else {
              if (
                response?.appErrors?.errors[0] ===
                "PWB_ZIP_CODE_COUNTRY_MISMATCH"
              ) {
                setError("zipCode", {
                  type: "error",
                  message: Resources.ERRORS.PWB_ZIP_CODE_COUNTRY_MISMATCH,
                });
                return;
              }
              setError("zipCode", {
                type: "error",
                message: Resources.ERRORS.INVALID_ZIPCODE,
              });
            }
          } else {
            setError("zipCode", {
              type: "error",
              message: Resources.ERRORS.INVALID_ZIPCODE,
            });
          }
        }
        break;

      default:
        break;
    }
  };

  const cityDetailsUpdate = (e: any) => {
    const { name, value } = e.target;
    if (name === "zipCode") {
      setValue("city", "");
      setValue("state", "");
      setValue("zipCode", value);
      setCityDetails({
        ...cityDetails,
        cityList: [],
      });
    }
  };

  const onCountryChange = async (event: any) => {
    const response = await setCountry({ country: event });
    if (response?.result?.success) {
      reset({
        city: "",
        state: "",
        zipCode: "",
      });
      setCityDetails({
        ...cityDetails,
        cityList: [],
      });
      queryClient.invalidateQueries(REACT_QUERY_KEYS.USER_PROFILE_DETAILS);
    }
    setValue("country", event);
  };

  const closeSnackbar = () =>
    setSnackbar({ ...snackbar, open: false, message: "" });

  const handleImageSelect = (imgDetails: { error: boolean; image?: File }) => {
    if (!imgDetails.image) return;
    if (imgDetails.error) {
      showError("Unable to pick image. Please try again !");
      return;
    }
    try {
      fileReader.readAsDataURL(imgDetails?.image);
      fileReader.onloadend = async () => {
        const res = await updateProfilePic(imgDetails.image as File);
        if (res?.result?.talentMedia?.userMediaUrl) {
          queryClient.invalidateQueries(REACT_QUERY_KEYS.USER_PROFILE_DETAILS);
          setUserImage(res.result.talentMedia.userMediaUrl);
        }
      };
    } catch (error) {
      showError("Failed to upload image");
    }
  };

  const handleChangeEmail = async () => {
    // After successfully sending OTP to old email, navigate to new screen;
    try {
      await getEmailOTP();
    } catch (error) {
      showError(Resources.ERRORS.SOMETHING);
    }
  };
  const handleVerifyEmail = () => {
    try {
      navigate(ROUTES.PRIVATE.VERIFY_EMAIL);
    } catch (error) {
      showError(Resources.ERRORS.SOMETHING);
    }
  };

  const handleEditAgeClick = () => {
    const ageDetails = {
      age: profileDetails?.home?.age ?? "",
      guardianEmail: profileDetails?.home?.guardianEmail ?? "",
      guardianIsdCode: profileDetails?.home?.guardianIsdCode ?? "",
      guardianIsdCountry: profileDetails?.home?.guardianIsdCountry ?? "",
      guardianPhoneNumber: profileDetails?.home?.guardianPhoneNumber ?? "",
      isGuardianApproved: profileDetails?.home?.isGuardianApproved ?? false,
      email: profileDetails?.home?.email,
      isdCode: profileDetails?.home?.isdCode,
      isdCountry: profileDetails?.home?.isdCountry,
      phoneNumber: profileDetails?.home?.phoneNumber,
    };
    navigate(ROUTES.PRIVATE.CHANGE_AGE, { state: ageDetails });
  };

  return (
    <Box>
      <Snackbar
        severity={snackbar.type}
        open={snackbar.open}
        message={snackbar.message}
        onClose={closeSnackbar}
        autoHideDuration={
          snackbar.message ===
          Resources.PAGES.ACCOUNT_PROFILE.SAVED_SUCCESSFULLY
            ? 500
            : 5000
        }
        disableClickAway
      />
      <Typography variant="body2" component="h4" className={styles.subHeading}>
        {PROFILE_INFORMATION}
      </Typography>
      <SectionLoader loading={loader} />

      <Box className={styles.profileInformation}>
        <Box className={styles.profileAvatar}>
          <ProfilePicture
            alt="Profile pic"
            imageUrl={userImage || defaultImage}
            onImageSelect={handleImageSelect}
            loading={isPhotoUploading}
          />
        </Box>

        <Box className={styles.profileTextSection}>
          <Grid container className={styles.formSection}>
            <Grid
              item
              xs={6}
              className={styles.formGroup}
              sx={{ width: "100%" }}
            >
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={FIELDS.FIRST_NAME}
                    required
                    placeholder="Enter your first name"
                    autoComplete="off"
                    {...register("firstName", {
                      pattern: {
                        value: REGEX.ALPHABETS_AND_SPACE,
                        message: VALIDATION_MESSAGES.firstName.regex,
                      },
                    })}
                    {...field}
                    error={!!errors?.firstName}
                    helperText={errors.firstName?.message}
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleSubmit(saveProfileDetails)}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={6}
              className={styles.formGroup}
              sx={{ width: "100%" }}
            >
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    label={FIELDS.LAST_NAME}
                    placeholder="Enter your last name"
                    autoComplete="off"
                    {...register("lastName", {
                      pattern: {
                        value: REGEX.ALPHABETS_AND_SPACE,
                        message: VALIDATION_MESSAGES.lastName.regex,
                      },
                    })}
                    {...field}
                    error={!!errors?.lastName}
                    helperText={errors.lastName?.message}
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleSubmit(saveProfileDetails)}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container className={styles.formSection}>
            <Grid
              item
              xs={6}
              className={`${styles.formGroup} ${styles.btnformGroup}`}
              sx={{ width: "100%" }}
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <InputField
                    disabled
                    required
                    label={FIELDS.EMAIL}
                    placeholder={FIELDS.EMAIL}
                    autoComplete="off"
                    {...register("email")}
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    isVerified={profileDetails?.isEmailVerified}
                    {...(!profileDetails?.isEmailVerified && {
                      error: true,
                      helperText: "Email verification is incomplete.",
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} className={`${styles.formGroup}`}>
              {profileDetails?.isEmailVerified ? (
                <LoadingButton
                  disabled={profileDetails?.accessCode !== null}
                  loading={isEmailOTPSending}
                  label="Change Email Address"
                  fullWidth
                  variant="contained"
                  className={`${styles.buttonStyle}`}
                  onClick={handleChangeEmail}
                />
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  className={`${styles.buttonStyle}`}
                  onClick={handleVerifyEmail}
                >
                  Verify Email
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid container className={styles.formSection}>
            <Grid
              item
              xs={6}
              className={`${styles.formGroup} ${styles.btnformGroup}`}
              sx={{ width: "100%" }}
            >
              <InputField
                required
                disabled
                isVerified
                label={FIELDS.PASSWORD}
                placeholder={FIELDS.PASSWORD}
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                value="**********"
              />
            </Grid>
            <Grid item xs={6} className={`${styles.formGroup}`}>
              <Button
                fullWidth
                variant="contained"
                onClick={navigatePage(
                  ROUTES.PRIVATE.UPDATE_PASSWORD,
                  "CHANGE_PASSWORD_PHONE_VALIDATION",
                )}
                className={`${styles.buttonStyle}`}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>

          <Grid container className={styles.formSection}>
            <Grid
              item
              xs={6}
              className={`${styles.formGroup} ${styles.btnformGroup}`}
            >
              <Controller
                control={control}
                name="isdCountry"
                render={({ field }) => (
                  <CountryPhoneBlock
                    disabled
                    isVerified
                    fieldErrors={{ isdCode: "", phoneNumber: "" }}
                    control={control}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} className={`${styles.formGroup}`}>
              <Button
                fullWidth
                variant="contained"
                className={`${styles.buttonStyle}`}
                onClick={navigatePage(
                  ROUTES.PRIVATE.UPDATE_PHONE,
                  "CHANGE_PHONE_PHONE_VALIDATION",
                )}
                sx={{
                  width: "100% !important",
                }}
              >
                Change Mobile Number
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            className={styles.formSection}
            sx={{ justifyContent: "start !important" }}
          >
            <Grid
              item
              xs={6}
              className={`${styles.formGroup} ${styles.btnformGroup}`}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2.3rem",
                    marginBottom: "1.5rem",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "1.1rem",
                      fontFamily: "var(--font-primary-500)",
                      color: "var(--list-primary)",
                      lineHeight: 1,
                    }}
                  >
                    Age
                    <Typography
                      component="span"
                      sx={{
                        color: "var(--color-primary)",
                      }}
                    >
                      *
                    </Typography>
                  </Typography>
                  <IconButton
                    onClick={handleEditAgeClick}
                    sx={{
                      padding: "0",
                      color: "var(--color-secondary)",
                      width: "1.5rem",
                      height: "1.5rem",
                      svg: {
                        width: "100%",
                        height: "100%",
                      },
                    }}
                  >
                    <SVGIcon name="edit-pencil-light" />
                  </IconButton>
                </Box>
                <Box>
                  <Typography
                    component="p"
                    sx={{
                      fontSize: "1.4rem",
                      fontFamily: "var(--font-primary-500)",
                      color: "var(--color-secondary)",
                      lineHeight: 1,
                    }}
                  >
                    {AGE[profileDetails?.home?.age ?? ""] ?? ""}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            className={styles.formSection}
            sx={{ justifyContent: "start !important" }}
          >
            <Grid item xs={6} className={`${styles.formGroup}`}>
              <Select
                disabled={false}
                required
                label={FIELDS.COUNTRY}
                initialValue={getValues("country")}
                placeholder={FIELDS.COUNTRY_PLACEHOLDER}
                placeholderDisabled
                options={countryList ?? []}
                onChange={onCountryChange}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={`${styles.formGroup} ${styles.location}`}
            >
              {/* <legend className={styles.country_code_legend_label}>
                  {Resources.PAGES.SIGN_UP.REGISTER_FORM.FIELDS.ZIP_CODE}
                  <span
                    className={`${styles.requiredSpan} ${
                      getValues("zipCode") ? styles.valid : styles.error
                    }`}
                  >
                    *
                  </span>
                </legend>
                <div
                  className={`legend4 ${styles.address_legend_label}`}
                  style={{ marginLeft: "32%" }}
                >
                  {Resources.PAGES.SIGN_UP.REGISTER_FORM.FIELDS.CITY}
                </div>
                <legend className={`legend5 ${styles.address_legend_label}`}>
                  {Resources.PAGES.SIGN_UP.REGISTER_FORM.FIELDS.STATE}
                </legend> */}

              <Box
                sx={{
                  display: "flex",
                  gap: "1.5rem",
                }}
              >
                <Box
                  sx={{
                    flexBasis: "10rem",
                    flexShrink: 0,
                  }}
                >
                  <Controller
                    name="zipCode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        variant="outlined"
                        label={
                          Resources.PAGES.SIGN_UP.REGISTER_FORM.FIELDS.ZIP_CODE
                        }
                        {...register("zipCode", {
                          onChange: (e) => cityDetailsUpdate(e),
                        })}
                        {...field}
                        error={!!errors?.zipCode}
                        InputLabelProps={{ shrink: true }}
                        onBlur={handleSubmit(saveProfileDetails)}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ flexGrow: 1, minWidth: "0" }}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <TextField
                      variant="outlined"
                      className={styles.cityDropdown}
                      id="demo-simple-select-standard"
                      value={cityDetails.city}
                      onChange={(e) => onCityChange(e)}
                      name="city"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: "off",
                      }}
                      label={Resources.PAGES.SIGN_UP.REGISTER_FORM.FIELDS.CITY}
                      disabled={!cityDetails?.cityList?.length}
                      select
                    >
                      {cityDetails?.cityList?.map((item) => (
                        <MenuItem value={item} style={{ background: "#fff" }}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Box>
                <Box sx={{ flexBasis: "7rem", flexShrink: "0" }}>
                  <TextField
                    disabled
                    type="text"
                    name="state"
                    variant="outlined"
                    label={Resources.PAGES.SIGN_UP.REGISTER_FORM.FIELDS.STATE}
                    value={getValues("state")}
                  />
                </Box>
              </Box>
              <FormHelperText error>
                {errors.zipCode?.message as string}
              </FormHelperText>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
export default ProfileForm;
