import { Box, Typography } from "@mui/material";
import InvalidImage from "../../../assets/images/alert.svg";

type InvaildViewType = {
  erroeMessage: string;
};

function InvalidView(props: InvaildViewType) {
  const { erroeMessage } = props;
  return (
    <>
      <Box sx={{ marginTop: "auto", marginBottom: "2rem" }}>
        <img src={InvalidImage} alt="Invalid" />
      </Box>
      <Box sx={{ marginBottom: "auto", textAlign: "center" }}>
        <Typography
          component="h3"
          className="app-l-heading--h3"
          sx={{ marginBottom: "0.5rem !important", fontWeight: 600 }}
        >
          {erroeMessage}
        </Typography>
      </Box>
    </>
  );
}

export default InvalidView;
