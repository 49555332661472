import { APIResponse } from "constants/sharedTypes";
import { UseQueryOptions, useQuery } from "react-query";
import { HandlerType } from "services/commonTypes";

export type QueryOptions = Omit<UseQueryOptions, "queryKey" | "queryFn">;

type Response<T> = T & APIResponse;

type Options<T> = {
  queryKey: string;
  queryFn: () => Promise<T>;
  handler?: HandlerType;
  successStatusCodes?: number[];
  queryOptions?: QueryOptions;
};

const useGETRequestHandler = <T>(options: Options<T>) => {
  const {
    queryFn,
    queryKey,
    handler,
    successStatusCodes = [200],
    queryOptions,
  } = options;

  const onRejected = () => {
    handler?.onError?.("");
  };

  const onFulfilled = (res: Response<T>) => {
    if (!successStatusCodes.includes(res?.status as number)) {
      onRejected();
      return;
    }
    handler?.onSuccess?.(res);
  };

  return useQuery<T>(queryKey, queryFn, {
    onSuccess: onFulfilled,
    onError: onRejected,
    ...(queryOptions as any),
  });
};

export default useGETRequestHandler;
