/* eslint-disable react/jsx-props-no-spreading */
import { Button, TextField } from "@mui/material";
import Resources from "assets/json/Resources";
import { LoadingButton } from "components/common";
import REGEX from "constants/regex";
import { Email } from "constants/sharedTypes";
import { Controller, useFormContext } from "react-hook-form";

function ForgotPasswordForm(props: {
  sendOTPHandler: (emailData: Email) => void;
  isCodeSendLoading: boolean;
  onBack: () => void;
}) {
  const { sendOTPHandler, isCodeSendLoading, onBack } = props;
  const {
    ERRORS: { ENTER_EMAIL_ADDRESS, ENTER_VALID_EMAIL_ADDRESS },
    COMMON: {
      BUTTONS: { SEND_VERIFICATION_CODE, BACK },
    },
  } = Resources;

  // retrieve all hook methods
  const { handleSubmit, control, formState, clearErrors } =
    useFormContext<Email>();
  const { errors } = formState;

  const onSubmit = handleSubmit((data) => {
    sendOTPHandler(data);
    clearErrors("email");
  });

  return (
    <form name="form" onSubmit={onSubmit} noValidate>
      <div className="formGroup">
        <Controller
          name="email"
          control={control}
          rules={{
            required: ENTER_EMAIL_ADDRESS,
            pattern: {
              value: REGEX.EMAIL,
              message: ENTER_VALID_EMAIL_ADDRESS,
            },
          }}
          render={({ field }) => {
            return (
              <TextField
                required
                placeholder="Enter registered email address"
                type="text"
                label="Email"
                autoComplete="off"
                className="custom_form_control"
                inputProps={{ maxLength: "50" }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                FormHelperTextProps={{ style: { color: "red" } }}
                error={!!errors.email?.message}
                helperText={errors.email?.message}
                {...field}
              />
            );
          }}
        />
      </div>
      <div className="button-block">
        <div className="app-l-primary-button-block">
          <LoadingButton
            type="submit"
            variant="contained"
            label={SEND_VERIFICATION_CODE}
            fullWidth
            loading={isCodeSendLoading}
            sx={{ fontWeight: "500" }}
          />
        </div>
        <div className="app-l-secondary-button-block">
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            fullWidth
            sx={{ textTransform: "capitalize" }}
            onClick={onBack}
          >
            {BACK}
          </Button>
        </div>
      </div>
    </form>
  );
}
export default ForgotPasswordForm;
