import { Box } from "@mui/material";
import { ROUTES, SUBSCRIPTION_PLAN_MAP } from "config/app";
import { Subscriptions } from "constants/sharedTypes";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Basic, { BasicPropTypes } from "./Basic";
import Free from "./Free";
import styles from "./styles.module.scss";

type PlanPropTypes = {
  freePlan: Subscriptions;
  basicPlan: Subscriptions;
} & Omit<BasicPropTypes, "active" | "planOption">;

function Plans(props: PlanPropTypes) {
  const { currentPlan, freePlan, basicPlan, ...baseProps } = props;

  const navigate = useNavigate();

  const handleDowngradeSubscription = useCallback(() => {
    navigate(ROUTES.PRIVATE.DOWNGRADE_SUBSCRIPTION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const membershipType = SUBSCRIPTION_PLAN_MAP[currentPlan.memberLevel]?.title;
  return (
    <Box className={styles.subscriptionItems}>
      <Free
        active={currentPlan.memberLevel === freePlan.memberLevel}
        planDetails={SUBSCRIPTION_PLAN_MAP[freePlan.memberLevel].description}
        planTitle="Free"
        onDowngradeClick={handleDowngradeSubscription}
        membershipType={membershipType}
      />
      <Basic
        active={currentPlan.memberLevel === basicPlan.memberLevel}
        planOption={basicPlan.options}
        currentPlan={currentPlan}
        {...baseProps}
      />
    </Box>
  );
}

export default Plans;
