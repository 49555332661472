import { Typography } from "@mui/material";
import { Container } from "components/common";

function Receipts() {
  return (
    <Container gradient>
      <Typography variant="body2">Receipts</Typography>
    </Container>
  );
}
export default Receipts;
