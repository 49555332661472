import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { HandlerType } from "services/commonTypes";
import { getJobOfferPDF } from "./docCenterService";
import { GetJobOfferLinkPayload } from "./types";

const useGetJobOfferDownload = (handler?: HandlerType<Uint8Array>) => {
  return useMutationWithHandler<Uint8Array, GetJobOfferLinkPayload>({
    mutationFn: getJobOfferPDF,
    resource: Resources.ERRORS,
    handler,
  });
};
export default useGetJobOfferDownload;
