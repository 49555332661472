import { Box, Typography } from "@mui/material";
import { CircularButton } from "components/ui";

type EditEmailProps = { email: string; onEdit: () => void };

function EditEmail(props: EditEmailProps) {
  const { email, onEdit } = props;

  return (
    <Box>
      <Typography
        component="p"
        sx={{
          fontSize: "1.4rem !important",
          fontFamily: "var(--font-primary-500)",
          color: "var(--colorTextSecondary)",
          fontWeight: "400",
          lineHeight: "1.2",
          marginBottom: "1.5rem",
        }}
      >
        Email
      </Typography>
      <Box
        sx={{
          maxWidth: "36.7rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1.5rem",
            marginBottom: "4rem",
          }}
        >
          <Typography
            component="p"
            sx={{
              fontSize: "2.2rem !important",
              fontFamily: "var(--font-primary-500)",
              color: "var(--colorTextPrimary)",
              fontWeight: "500",
              lineHeight: "1.2",
            }}
          >
            {email}
          </Typography>
          <CircularButton iconName="edit" onClick={onEdit} />
        </Box>
      </Box>
    </Box>
  );
}

export default EditEmail;
