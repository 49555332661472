import { TableRow } from "@mui/material";
import { Table, TableCell } from "components/common";
import { TableHeaderType } from "constants/sharedTypes";
import { useMemo } from "react";
import { InterViews } from "services/home/types";
import { openURL } from "utils";
import { formatUTCDateToLocal } from "utils/dateUtils";

type InterviewsProps = {
  documents: InterViews[];
  loading: boolean;
  timeZoneData: string;
};

const TABLE_HEADER: TableHeaderType[] = [
  { key: "interview", label: "Interview", align: "left", width: "33%" },
  { key: "date", label: "Date", width: "15%" },
  { key: "time", label: "Time", width: "22%" },
  { key: "details", label: "Details", width: "30%" },
];

function Interviews(props: InterviewsProps) {
  const { documents, loading, timeZoneData } = props;

  const getDetails = (data: InterViews) => {
    switch (data?.interviewDetails?.interviewmode) {
      case "DIGITAL_CONFERENCE_ZOOM":
        return "Zoom";
      case "DIGITAL_CONFERENCE_LINK":
        return "Digital Conference";
      case "PHONE_CALL":
        return `+${data?.interviewDetails?.isdcode} ${data?.interviewDetails?.phone}`;
      case "ONSITE":
        return `${data?.interviewDetails?.buildingname}, ${data?.interviewDetails?.streetaddress}, ${data?.interviewDetails?.city}, ${data?.interviewDetails?.landmark}, ${data?.interviewDetails?.city}, ${data?.interviewDetails?.state}, ${data?.interviewDetails?.zipcode}`;
      case "MATCH_EVENT":
        return "Match Event";
      default:
        return "";
    }
  };

  const onLinkPress = (data: InterViews) => {
    const url = data?.interviewDetails?.link;
    if (url) {
      openURL(url);
    }
  };

  const linkNeeded = (data: InterViews) => {
    switch (data?.interviewDetails?.interviewmode) {
      case "DIGITAL_CONFERENCE_ZOOM":
        return true;
      case "DIGITAL_CONFERENCE_LINK":
        return true;
      case "PHONE_CALL":
        return false;
      case "ONSITE":
        return false;
      case "MATCH_EVENT":
        return true;
      default:
        return false;
    }
  };

  const tableRows = useMemo(() => {
    return documents.map((doc) => (
      <TableRow key={timeZoneData}>
        <TableCell>
          <TableCell.Text
            fontWeight="500"
            text={`${doc?.jobName} - ${doc?.companyName}`}
          />
        </TableCell>
        <TableCell>
          <TableCell.Text
            fontWeight="500"
            text={
              formatUTCDateToLocal(doc?.interviewStartDate, "dd LLL, yyyy") ??
              ""
            }
          />
        </TableCell>
        <TableCell>
          <TableCell.Text
            fontWeight="500"
            text={`${
              formatUTCDateToLocal(doc?.interviewStartDate, "hh:mm a") ?? ""
            } ${timeZoneData}`}
          />
        </TableCell>
        <TableCell>
          <TableCell.Text
            isLink={linkNeeded(doc)}
            onClick={() => onLinkPress(doc)}
            fontWeight="500"
            text={doc ? getDetails(doc) : ""}
          />
        </TableCell>
      </TableRow>
    ));
  }, [documents, timeZoneData]);

  return (
    <Table
      headerData={TABLE_HEADER}
      loading={loading}
      noDataMessage="No records found"
    >
      {tableRows}
    </Table>
  );
}

export default Interviews;
