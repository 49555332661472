import requests from "config/api";
import API_ENDPOINTS from "constants/api";
import {
  ChangeNewEmailPayload,
  ChangePasswordPayload,
  ChangePhonePayload,
  OnBehalfPayload,
  SetCountryPayload,
  SetWelcomePayload,
  UpdateAgePayload,
  UpdateEmailPayload,
  ValidateEmailOtpPayload,
  ValidateOldEmailPayload,
  ValidatePhoneOtpPayload,
  ValidatePhonePayload,
  VerifyEmailPayload,
  VerifyPhoneOTPPayload,
  VerifyPhonePayload,
} from "./types";

export const updateProfilePic = async (payload: File) => {
  const headers = { "Content-Type": "multipart/form-data" };

  const formData = new FormData();
  formData.append("userMediaType", "display");
  formData.append("display", payload, payload?.name);

  return requests.post(API_ENDPOINTS.UPLOAD_PROFILE_PIC, formData, { headers });
};

export const getEmailOTP = async () => {
  return requests.get(API_ENDPOINTS.EMAIL_OTP);
};

export const validateOldEmail = async (payload: ValidateOldEmailPayload) => {
  return requests.post(API_ENDPOINTS.OLD_EMAIL_VERIFY, payload);
};

export const changeEmail = async (payload: ChangeNewEmailPayload) => {
  const { oldEmailOTP, ...rest } = payload;
  return requests.post(API_ENDPOINTS.NEW_EMAIL_VERIFY, {
    otp: oldEmailOTP,
    ...rest,
  });
};

export const changePassword = async (payload: ChangePasswordPayload) => {
  return requests.post(API_ENDPOINTS.CHANGE_PASSWORD, payload);
};

export const updatePhone = async (payload: ChangePhonePayload) => {
  return requests.post(API_ENDPOINTS.UPDATE_PHONE_NUMBER, payload);
};

export const getPhoneOTP = async () => {
  return requests.get(API_ENDPOINTS.TRIGGER_OTP_SMS);
};

export const verifyPhone = async (payload: VerifyPhonePayload) => {
  return requests.post(API_ENDPOINTS.VERIFY_PHONE, payload);
};

export const onBehalfOfLogin = async (payload: OnBehalfPayload) => {
  return requests.post(API_ENDPOINTS.ON_BEHALF_OF_LOGIN, payload);
};

export const getAccountProfile = async () => {
  return requests.get(API_ENDPOINTS.HOME_DETAILS);
};

export const setWelcomeOptions = async (payload: SetWelcomePayload) => {
  return requests.post(API_ENDPOINTS.SET_WELCOME, payload);
};

export const verifyEmail = async (data: VerifyEmailPayload) => {
  return requests.post(API_ENDPOINTS.VERIFY_EMAIL, data);
};

export const updateEmail = async (payload: UpdateEmailPayload) => {
  return requests.post(API_ENDPOINTS.UPDATE_EMAIL, payload);
};

export const changePhoneNumber = async (payload: ChangePhonePayload) => {
  return requests.post(API_ENDPOINTS.SEND_PHONE_OTP, payload);
};

export const verifyPhoneOTP = async (payload: VerifyPhoneOTPPayload) => {
  return requests.post(API_ENDPOINTS.CHANGE_PHONE, payload);
};

export const validatePhone = async (payload: ValidatePhonePayload) => {
  return requests.post(API_ENDPOINTS.VALIDATE_PHONE, payload);
};

export const validatePhoneOtp = async (payload: ValidatePhoneOtpPayload) => {
  return requests.post(API_ENDPOINTS.VALIDATE_PHONE_OTP, payload);
};

export const validateEmailOtp = async (payload: ValidateEmailOtpPayload) => {
  return requests.post(API_ENDPOINTS.VALIDATE_EMAIL_OTP, payload);
};

export const setCountry = async (payload: SetCountryPayload) => {
  return requests.post(API_ENDPOINTS.SET_COUNTRY, payload);
};

export const updateAge = async (payload: UpdateAgePayload) => {
  return requests.post(API_ENDPOINTS.UPDATE_AGE, payload);
};
