import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SVGIcon from "components/icons";
import { CheckoutButtons, InputField } from "components/ui";
import {
  ChangeEventHandler,
  FocusEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { debounce } from "utils";
import styles from "../style.module.scss";

const MAX_ITEMS = 99;

type AddCoachingSessionProps = {
  count: number;
  price: number;
  updateCount: (count: number, price: number) => void;
  isInCart: boolean;
  onCheckout: () => void;
  onRemoveCart: () => void;
  disabled?: boolean;
};

function AddCoachingSession(props: AddCoachingSessionProps) {
  const {
    count,
    isInCart,
    price,
    updateCount,
    onCheckout,
    onRemoveCart,
    disabled,
  } = props;
  const [sessionCount, setSessionCount] = useState(count);

  useEffect(() => {
    setSessionCount(count);
  }, [count]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce((value: number, cost: number) => {
      if (value > 0) updateCount(value, cost);
    }, 500),
    [updateCount],
  );

  const updateSessionCount = (number: number) => {
    debouncedUpdate(number, price);
  };

  const handleIncrement = () => {
    const nextCount = sessionCount + 1;
    if (nextCount <= MAX_ITEMS) {
      setSessionCount(nextCount);
      if (isInCart) {
        updateSessionCount(nextCount);
      }
    }
  };

  const handleDecrement = () => {
    const nextCount = sessionCount - 1;
    if (nextCount > 0) {
      setSessionCount(nextCount);
      if (isInCart) {
        updateSessionCount(nextCount);
      }
    }
  };

  const handleCountChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    if (value === "") {
      updateSessionCount(0);
      setSessionCount(0);
      return;
    }

    if (+value > 0 && +value <= MAX_ITEMS) {
      setSessionCount(+value);
      if (isInCart) updateSessionCount(+value);
    }
  };

  const handleInputBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    if (!+value) {
      if (isInCart) updateSessionCount(1);
      setSessionCount(1);
    }
  };

  const handleCartRemove = () => {
    setSessionCount(1);
    onRemoveCart();
  };

  return (
    <>
      <Box className={styles.sliderItemLeftBlock}>
        <Box
          className="number-stepper-block"
          sx={{ paddingRight: "0 !important", maxWidth: "21.3rem" }}
        >
          <IconButton onClick={handleDecrement}>
            <SVGIcon name="minusBlack" />
          </IconButton>
          <Box className={styles.countBox}>
            <InputField
              label="Count"
              placeholder="Total count"
              value={sessionCount}
              onChange={handleCountChange}
              onBlur={handleInputBlur}
            />
          </Box>
          <IconButton onClick={handleIncrement}>
            <SVGIcon name="plusBlack" />
          </IconButton>
        </Box>
      </Box>

      {isInCart ? (
        <CheckoutButtons
          disabled={disabled}
          onCheckout={onCheckout}
          onRemove={handleCartRemove}
        />
      ) : (
        <Button
          onClick={() => updateSessionCount(sessionCount)}
          fullWidth
          variant="contained"
        >
          Add
        </Button>
      )}
    </>
  );
}

export default AddCoachingSession;
