import { useMutationWithHandler } from "hooks";
import { HandlerType, FailedAPIResponse } from "services/commonTypes";
import Resources from "assets/json/Resources";
import { SuccessResponse, CCDetailsTypePayload } from "./types";
import { addNewCard } from "./paymentSevice";

type APIResponse = SuccessResponse & FailedAPIResponse;

const useSaveCC = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, CCDetailsTypePayload>({
    mutationFn: addNewCard,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useSaveCC;
