import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Timer from "../Timer";

const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MIN = 60;
const SECOND_IN_MS = 1000;

type CountdownProps = {
  /** time in milliseconds */
  remainingTime: number;
  onComplete: () => void;
};

function CountDown(props: CountdownProps) {
  const { remainingTime, onComplete } = props;

  const [time, setTime] = useState(remainingTime);

  useEffect(() => {
    const interval = setInterval(() => {
      // reduce 1sec from current time
      const newTime = time - SECOND_IN_MS;
      if (newTime < 0) {
        // stop countdown
        clearInterval(interval);
        onComplete();
      } else {
        setTime(newTime);
      }
    }, SECOND_IN_MS);

    return () => clearInterval(interval);
  }, [time]);

  const timeLeft = useMemo(() => {
    const totalSeconds = Math.floor(time / SECOND_IN_MS);
    const totalMinutes = Math.floor(totalSeconds / SECONDS_IN_MIN);
    const totalHours = Math.floor(totalMinutes / MINUTES_IN_HOUR);

    const seconds = totalSeconds % SECONDS_IN_MIN;
    const minutes = totalMinutes % MINUTES_IN_HOUR;
    const hours = totalHours % HOURS_IN_DAY;
    return { seconds, minutes, hours };
  }, [time]);

  return (
    <Box
      sx={{
        marginTop: { xs: "3rem", lg: "6rem" },
        maxWidth: { xs: "26.2rem", sm: "36.2rem", md: "48rem", lg: "57rem" },
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginLeft: { xs: "auto", sm: "0" },
        marginRight: { xs: "auto", sm: "0" },
      }}
    >
      <Timer
        role="progressbar"
        value={timeLeft.hours}
        label={timeLeft.hours > 1 ? "hrs" : "hr"}
        aria-valuemin={0}
        aria-valuemax={HOURS_IN_DAY}
        aria-valuenow={timeLeft.hours}
        aria-label={timeLeft.hours > 1 ? "hrs" : "hr"}
        tabIndex={0}
        maxLimit={HOURS_IN_DAY}
      />
      <Timer
        role="progressbar"
        value={timeLeft.minutes}
        label={timeLeft.minutes > 1 ? "mins" : "min"}
        aria-valuemin={0}
        aria-valuemax={MINUTES_IN_HOUR}
        aria-valuenow={timeLeft.minutes}
        aria-label="mins"
        tabIndex={0}
        maxLimit={MINUTES_IN_HOUR}
      />
      <Timer
        role="progressbar"
        value={timeLeft.seconds}
        label={timeLeft.seconds > 1 ? "secs" : "sec"}
        aria-valuemin={0}
        aria-valuemax={SECONDS_IN_MIN}
        aria-valuenow={timeLeft.seconds}
        aria-label="secs"
        tabIndex={0}
        maxLimit={SECONDS_IN_MIN}
      />
    </Box>
  );
}

export default CountDown;
