import { yupResolver } from "@hookform/resolvers/yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Resources from "assets/json/Resources";
import CountryPhoneBlock from "components/CountryPhoneBlock";
import PasswordErrorInfo from "components/PasswordErrorInfo";
import { PasswordInput } from "components/ui";
import InputField from "components/ui/InputField";
import { ChangeEvent, ChangeEventHandler, useMemo } from "react";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useGetCountryCode } from "services/common";
import * as yup from "yup";
import { ageOptions, ageRange, registerFormSchema } from "./config";
import styles from "./styles.module.scss";

type RegisterFormType = yup.InferType<typeof registerFormSchema>;
type FormField = keyof typeof registerFormSchema.fields;

type RegisterFormProps = {
  formId: string;
  onSubmit: (formData: FieldValues) => void;
  onFormError: () => void;
};

const {
  PAGES: {
    SIGN_UP: { REGISTER_FORM },
    GUARDIAN: { GUARDIAN_MOBILE_PLACEHOLDER, GUARDIAN_EMAIL_PLACEHOLDER },
  },
} = Resources;

function SignUpForm(props: RegisterFormProps) {
  const { formId, onSubmit, onFormError } = props;
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    trigger,
    resetField,
  } = useForm<RegisterFormType>({
    resolver: yupResolver(registerFormSchema),
    mode: "onTouched",
    defaultValues: {
      ageRange: "",
      email: "",
      firstName: "",
      isdCode: "",
      lastName: "",
      password: "",
      phoneNumber: "",
      isdCountry: "",
      guardianIsdCode: "",
      guardianIsdCountry: "",
      guardianPhoneNumber: "",
    },
  });

  const { data: allCountryList } = useGetCountryCode();

  const countryList = useMemo(() => {
    const list = allCountryList?.result?.countryList?.map((country) => ({
      isdCode: country.isdCode,
      countryCode: country.shortName,
    }));
    return list;
  }, [allCountryList]);

  const isMinorTalent = watch("ageRange") === ageRange.below18;
  const watchPassword = watch("password");

  const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    const field = name as FormField;
    const country = countryList?.find((code) => code.countryCode === value);
    if (name === "isdCountry") {
      if (country) {
        setValue("isdCode", country?.isdCode);
        setValue("isdCountry", value);
      }
      trigger("isdCode");
      trigger("isdCountry");
      return;
    }
    if (name === "guardianIsdCountry") {
      if (country) {
        setValue("guardianIsdCode", country?.isdCode);
        setValue("guardianIsdCountry", value);
      }
      trigger("guardianIsdCode");
      trigger("guardianIsdCountry");
      return;
    }
    trigger(field);
    setValue(field, value);
  };

  const handleAgeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    resetField("guardianEmail");
    setValue("ageRange", value);
    trigger("ageRange");
  };

  const handleFormSubmit: SubmitHandler<FieldValues> = (formData) => {
    onSubmit(formData);
  };

  return (
    <Box
      component="form"
      id={formId}
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit(handleFormSubmit, onFormError)}
    >
      <Grid className={styles.formGroup}>
        <Controller
          control={control}
          name="firstName"
          render={({ field }) => (
            <InputField
              required
              label={REGISTER_FORM.FIELDS.F_NAME}
              placeholder="Enter your first name"
              error={!!errors.firstName?.message}
              helperText={errors?.firstName?.message as string}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid className={styles.formGroup}>
        <Controller
          control={control}
          name="lastName"
          render={({ field }) => (
            <InputField
              required
              label={REGISTER_FORM.FIELDS.L_NAME}
              placeholder="Enter your last name"
              error={!!errors.lastName?.message}
              helperText={errors.lastName?.message as string}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid className={styles.formGroup}>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <InputField
              required
              label={REGISTER_FORM.FIELDS.MAIL}
              placeholder="Enter your email"
              error={!!errors.email?.message}
              helperText={errors.email?.message as string}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid className={styles.formGroup}>
        <Controller
          control={control}
          name="isdCountry"
          render={({ field }) => (
            <CountryPhoneBlock
              fieldErrors={{
                isdCode: errors.isdCode?.message,
                phoneNumber: errors.phoneNumber?.message,
              }}
              control={control}
              {...field}
              onChange={handlePhoneChange}
            />
          )}
        />
      </Grid>
      <Grid className={`${styles.formGroup} ${styles.formPassword}`}>
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <PasswordInput
              label={REGISTER_FORM.FIELDS.PASSWORD}
              placeholder="Enter your password"
              error={!!errors.password?.message}
              {...field}
            />
          )}
        />
        {watchPassword.length > 30 && (
          <FormHelperText error>This password is too long.</FormHelperText>
        )}
      </Grid>
      <PasswordErrorInfo
        password={watchPassword}
        isHighlight
        showTextDecoration={false}
      />
      <Grid className={styles.formGroup} sx={{ mt: "1.5rem" }}>
        <FormControl component="fieldset">
          <FormLabel
            error={!!errors.ageRange?.message}
            required
            component="label"
            sx={{
              fontSize: "1.1rem",
              color: "var(--color-secondary) !important",
              fontFamily: "var(--font-primary-500)",
              "&.Mui-focused": {
                color: "inherit",
              },
            }}
          >
            {REGISTER_FORM.FIELDS.AGE}
          </FormLabel>
          <Controller
            control={control}
            name="ageRange"
            render={({ field }) => (
              <RadioGroup
                aria-label="age"
                row
                {...field}
                onChange={handleAgeChange}
                sx={{ mt: "1.5rem", ml: "1.3rem", gap: "1.5rem" }}
              >
                {ageOptions.map((option) => (
                  <FormControlLabel
                    value={option.value}
                    label={option.label}
                    control={
                      <Radio
                        checkedIcon={<CheckCircleIcon fontSize="large" />}
                      />
                    }
                  />
                ))}
              </RadioGroup>
            )}
          />
          <FormHelperText error={!!errors.ageRange?.message}>
            {errors.ageRange?.message}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Fade in={isMinorTalent} unmountOnExit>
        <Box>
          <Grid className={styles.formGroup}>
            <Controller
              control={control}
              name="guardianIsdCountry"
              render={({ field }) => (
                <CountryPhoneBlock
                  fieldErrors={{
                    isdCode: errors.guardianIsdCode?.message,
                    phoneNumber: errors.guardianPhoneNumber?.message,
                  }}
                  control={control}
                  {...field}
                  isdName="guardianIsdCountry"
                  phoneNumberName="guardianPhoneNumber"
                  placeholder={GUARDIAN_MOBILE_PLACEHOLDER}
                  label={REGISTER_FORM.FIELDS.GUARDIAN_MOBILE}
                  onChange={handlePhoneChange}
                />
              )}
            />
          </Grid>
          <Grid className={styles.formGroup}>
            <Controller
              control={control}
              name="guardianEmail"
              render={({ field }) => (
                <InputField
                  required
                  label={REGISTER_FORM.FIELDS.GUARDIAN_MAIL}
                  placeholder={GUARDIAN_EMAIL_PLACEHOLDER}
                  error={!!errors.guardianEmail?.message}
                  helperText={errors.guardianEmail?.message as string}
                  {...field}
                />
              )}
            />
            <Typography
              variant="body2"
              sx={{
                fontSize: "1.1rem !important",
                mt: "1.5rem",
                color: "var(--text-input-label)",
              }}
            >
              {REGISTER_FORM.GUARDIAN_DESC}
            </Typography>
          </Grid>
        </Box>
      </Fade>
    </Box>
  );
}

export default SignUpForm;
