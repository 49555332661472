/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import {
  FormControl,
  Input,
  InputBaseProps,
  InputLabel,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { InputEventType } from "constants/sharedTypes";
import { useId } from "react";
import {
  Controller,
  FieldError,
  FieldErrors,
  Merge,
  useFormContext,
  ValidationRule,
} from "react-hook-form";

type FormInputType = {
  label?: string;
  requiredValue?: string | ValidationRule<boolean>;
  patternValue?: RegExp;
  patternMessage?: string;
  onChangeHelper?: (e: InputEventType) => boolean;
  onBlur?: (e: InputEventType) => void;
  inputId?: string;
  className?: string;
  variant?: "standard" | "filled" | "outlined" | undefined;
  isTextField: boolean;
  inputProps?: object;
  error?: boolean;
  name: string;
  formControlClassName?: string;
  customValidation?: (value: string) => undefined | string;
  isHidden?: boolean;
  customError?: FieldError | Merge<FieldError, FieldErrors<any>> | undefined;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrors<any>>;
  fullWidth?: boolean;
} & InputBaseProps &
  TextFieldProps;
function FormInput(props: FormInputType) {
  const {
    label,
    requiredValue,
    patternValue,
    patternMessage,
    onBlur,
    onChangeHelper,
    inputId,
    className,
    variant,
    isTextField,
    inputProps,
    formControlClassName,
    name,
    customError,
    errorMessage,
    isHidden,
    fullWidth,
    customValidation,
    ...rest
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const uniqueId = useId();

  const handleOnBlur = (e: InputEventType, field: any) => {
    field.onBlur();
    if (onBlur) onBlur(e);
  };
  const handleOnChange = (e: InputEventType, field: any) => {
    if (onChangeHelper) {
      if (onChangeHelper(e)) field.onChange(e);
    } else {
      field.onChange(e);
    }
  };

  return (
    <FormControl
      className={`form-control-tag ${formControlClassName} ${
        isHidden ? "d-none" : ""
      }`}
      fullWidth={fullWidth}
    >
      {!isTextField && (
        <InputLabel htmlFor={inputId ?? uniqueId}>{label}</InputLabel>
      )}
      <Controller
        name={name}
        control={control}
        rules={{
          required: requiredValue,
          pattern: patternValue
            ? {
                value: patternValue,
                message: patternMessage || "",
              }
            : undefined,
          validate: customValidation,
        }}
        render={({ field }) => {
          return !isTextField ? (
            <Input
              id={inputId ?? uniqueId}
              autoComplete="off"
              inputProps={inputProps || { maxLength: "50" }}
              {...field}
              onBlur={(e: InputEventType) => handleOnBlur(e, field)}
              onChange={(e: InputEventType) => handleOnChange(e, field)}
              {...rest}
            />
          ) : (
            <TextField
              type="text"
              label={label}
              autoComplete="off"
              className={className}
              inputProps={inputProps || { maxLength: "50" }}
              variant={variant || "outlined"}
              {...field}
              onBlur={(e: InputEventType) => handleOnBlur(e, field)}
              onChange={(e: InputEventType) => handleOnChange(e, field)}
              error={!!customError || !!errors[name]}
              InputLabelProps={{ shrink: true }}
              helperText={
                errorMessage && typeof errorMessage === "string" && errorMessage
              }
              {...rest}
            />
          );
        }}
      />
    </FormControl>
  );
}

export default FormInput;

FormInput.defaultProps = {};
