/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Breadcrumb, Snackbar } from "components/common";
import { TabPanel } from "components/ui";
import { REACT_QUERY_KEYS, ROUTES } from "config/app";
import { SnackbarType } from "constants/sharedTypes";
import { useNavigate } from "react-router-dom";
import { useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
// import { PlaidType } from "services/useGetPaymentMethods";
import { useIsMutating } from "react-query";
import { PlaidType } from "services/useGetPaymentMethods";
import CreditCardForm from "./AddCreditCard";
import styles from "./styles.module.scss";
import { PaymentTypes } from "./types";
// import useGetPaymentMethods, {
//   PlaidType,
//   PaymentMethodResponseType,
// } from "services/useGetPaymentMethods";

const breadcrumbs = {
  addPayment: [
    {
      text: " Payment Center",
      active: true,
      pageLink: ROUTES.PRIVATE.PAYMENT_CENTER,
    },
    { text: "Add Payment" },
  ],
};

// type AddPaymentProps = {
//   toggleSection: () => void;
//   plaidInfo: PlaidType;
//   defaultActiveTab: PaymentTypes;
// };

type TabItemType = {
  key: PaymentTypes;
  label: string;
  disabled: boolean;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  onCancel: () => void;
  onSave: () => void;
};

function AddPaymentMethod() {
  //   const { toggleSection, plaidInfo, defaultActiveTab } = props;
  //   const { clientName, env, productType, publicKey } = plaidInfo;

  const btnRef = useRef<HTMLButtonElement>(null);
  // const [defaultActiveTab, setDefaultActiveTab] =
  //   useState<PaymentTypes>("creditCard");
  // const [plaidInfo, setPlaidInfo] = useState<PlaidType>({} as PlaidType);

  // const [isAchVerified, setIsAchVerified] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activePayment, setActivePayment] = useState("");
  const [tabValue, setTabValue] = useState("creditCard");
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    open: false,
    message: "",
    type: "success",
  });
  const navigate = useNavigate();
  const toggleSection = () => {
    navigate(ROUTES.PRIVATE.PAYMENT_CENTER);
  };

  // form initialization
  const methods = useForm({ mode: "all" });

  // Trigger form submit
  const triggerFormSubmit = () => {
    btnRef.current?.click();
  };
  // const filterAndSavePaymentMethod = (payments: PaymentMethodResponseType) => {
  //   setPlaidInfo(payments?.result?.paymentDetails?.paymentCenter?.plaid);
  // };
  // const { isLoading: isPaymentsLoading, isFetching: isPaymentFetching } =
  //   useGetPaymentMethods({ onSuccess: filterAndSavePaymentMethod });

  const isGettingZip =
    useIsMutating({
      mutationKey: REACT_QUERY_KEYS.MUTATION.geoLocation,
    }) > 0;

  const tabItems = useMemo<TabItemType[]>(() => {
    return [
      {
        key: "creditCard",
        label: "Credit card",
        // onClick: (key: string) => handleCC(key),
        disabled: false,
        onCancel: toggleSection,
        onSave: triggerFormSubmit,
      },
      // {
      //   key: "ach",
      //   label: "ACH",
      //   icon: ACHIcon,
      //   onClick: (key: string) => handleAch(key),
      //   disabled: true,
      // },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // functionalities for ACH
  // const onAchSuccess = () => setIsAchVerified(true);

  // const onAchFailed = (errorMsg: string) => {
  //   setErrorMessage(errorMsg);
  //   setIsAchVerified(false);
  // };

  // const { mutateAsync: addAchMethod } = useAddAch({
  //   onSuccess: onAchSuccess,
  //   onError: onAchFailed,
  // });

  // const onPlaidSuccess = async (publicToken: string, metadata: any) => {
  //   toggleModalVisibility();
  //   restActivePayment();
  //   setIsVerificationProgress(true);
  //   await addAchMethod({ publicToken, bankAccountId: metadata.account_id });
  //   setIsVerificationProgress(false);
  //   queryClient.invalidateQueries(REACT_QUERY_KEYS.PAYMENT_METHODS);
  // };
  // const onPlaidExit = () => restActivePayment();

  // const { open: openAchPayment } = usePlaid({
  //   publicKey,
  //   clientName,
  //   env,
  //   product: [productType],
  //   onSuccess: onPlaidSuccess,
  //   onExit: onPlaidExit,
  // });

  // const handleAch = (key: string) => {
  //   setActivePayment(key);
  //   reset();
  //   openAchPayment();
  // };

  const onSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: PaymentTypes,
  ) => {
    setTabValue(newValue);
  };

  // const renderModalContent = () => {
  //   if (isVerificationProgress) {
  //     return <PaymentVerification title={PAYMENT_CENTER.ADD_ACH.TITLE} />;
  //   }
  //   // if (isAchVerified) {
  //   //   return (
  //   //     <PaymentSuccess
  //   //       title={PAYMENT_CENTER.ADD_ACH.TITLE}
  //   //       onClose={toggleModalVisibility}
  //   //       successMsg={PAYMENT_CENTER.VERIFICATION_SUCCESS}
  //   //     />
  //   //   );
  //   // }
  //   return (
  //     <PaymentFail
  //       errorTitle={errorMessage}
  //       errorDesc="Try another one"
  //       disabled={false}
  //       onClose={onPaymentFailClose}
  //       onRetry={toggleModalVisibility}
  //       title={PAYMENT_CENTER.ADD_ACH.TITLE}
  //     />
  //   );
  // };

  const renderPaymentForm = (paymentType: PaymentTypes) => {
    switch (paymentType) {
      case "creditCard":
        return (
          <FormProvider {...methods}>
            <CreditCardForm ref={btnRef} toggleSection={toggleSection} />
          </FormProvider>
        );
      default:
        return null;
    }
  };

  const renderSnackbar = () => (
    <Snackbar
      severity={snackbar.type}
      open={snackbar.open}
      message={snackbar.message}
      snackbarProps={{ onClose: onSnackbarClose }}
      autoHideDuration={2000}
    />
  );

  return (
    <Box
      className="app-l-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "3rem !important",
      }}
    >
      <Breadcrumb crumbs={breadcrumbs.addPayment} />
      {renderSnackbar()}
      {/* {renderModal()} */}
      <Box className={styles.tabBoxContainer}>
        <Typography
          variant="body2"
          component="h4"
          className={styles.subHeading}
        >
          Add Payment Method
        </Typography>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{ paddingLeft: "32px" }}
          >
            {tabItems.map(({ label, key, disabled }) => (
              <Tab
                label={label}
                key={key}
                value={key}
                disabled={disabled}
                sx={{ color: "var(--dark-heading) !important" }}
              />
            ))}
          </Tabs>
        </Box>
        {tabItems.map(
          ({
            key,
            onCancel,
            onSave,
            secondaryButtonLabel,
            primaryButtonLabel,
          }) => (
            <TabPanel
              value={tabValue}
              key={key}
              uniqueKey={key}
              onSecondaryBtnClick={onCancel}
              secondaryButtonLabel={secondaryButtonLabel}
              primaryButtonLabel={primaryButtonLabel}
              onPrimaryBtnClick={onSave}
              primaryButtonProps={{ disabled: isGettingZip }}
            >
              {renderPaymentForm(key)}
            </TabPanel>
          ),
        )}
      </Box>
    </Box>
  );
}

export default AddPaymentMethod;
