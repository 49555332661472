import Resources from "assets/json/Resources";
import { emailSchema } from "utils/validationSchema";
import * as yup from "yup";

const { ERRORS } = Resources;

export const deleteAccountForm = yup.object({
  email: emailSchema({
    required: ERRORS.ENTER_EMAIL_ADDRESS,
    regex: ERRORS.ENTER_VALID_EMAIL_ADDRESS,
  }),
});

export type ChangeEmailFormType = yup.InferType<typeof deleteAccountForm>;
