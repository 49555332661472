/* eslint-disable consistent-return */
import { useEffect, useState } from "react";

type TimerProps = {
  /** time in seconds */
  time: number;
  start: boolean;
  onComplete: () => void;
};

const useTimer = (props: TimerProps) => {
  const { onComplete, start, time } = props;

  const [timeLeft, setTimeLeft] = useState(time);

  useEffect(() => {
    if (!start) {
      setTimeLeft(time);
      return;
    }

    const interval = setInterval(() => {
      const remainingTime = timeLeft - 1;
      if (remainingTime < 0) {
        clearInterval(interval);
        onComplete();
      } else {
        setTimeLeft(remainingTime);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [start, timeLeft]);

  return { timeLeft };
};

export default useTimer;
