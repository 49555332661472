import { Box, SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";

type TitleProps = { title: string; sx?: SxProps };
type NodataProps = { children: ReactNode; sx?: SxProps };

type ImageProps = { image: string; sx?: SxProps };

function NoDataTitle(props: TitleProps) {
  const { title, sx } = props;
  return (
    <Typography
      component="h3"
      sx={{
        fontFamily: "var(--font-primary-500)",
        fontSize: { md: "1.5rem" },
        lineHeight: { md: "1.8rem" },
        textAlign: "center",
        ...sx,
      }}
    >
      {title}
    </Typography>
  );
}

function ImageContainer(props: ImageProps) {
  const { image, sx } = props;
  return (
    <Box
      sx={{
        margin: "auto",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: { md: "11.25rem", xs: "9.375rem" },
        width: { md: "11.25rem", xs: "9.375rem" },
        my: { md: "2.813rem", xs: "0.8rem" },
        ...sx,
      }}
    >
      <img
        src={image}
        alt="no content "
        style={{ height: "100%", width: "100%", objectFit: "contain" }}
        loading="lazy"
      />
    </Box>
  );
}

function Description(props: NodataProps) {
  const { children, sx } = props;
  return (
    <Typography
      component="p"
      sx={{
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: "1.05rem",
        textAlign: "center",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
}

function StepTitle(props: TitleProps) {
  const { sx, title } = props;
  return (
    <Typography
      sx={{
        fontWeight: "bold",
        fontSize: { md: "1.25rem" },
        lineHeight: { md: "1.8rem" },
        display: "flex",
        flexDirection: "row",
        ...sx,
      }}
    >
      {title}
    </Typography>
  );
}

function Steps(props: NodataProps) {
  const { children, sx } = props;
  return (
    <Box
      sx={{
        maxWidth: "18.75rem",
        display: "flex",
        flexDirection: "row",
        ...sx,
      }}
    >
      <Typography
        component="p"
        sx={{ fontSize: "0.875rem", fontWeight: "500", lineHeight: "1.05rem" }}
      >
        {children}
      </Typography>
    </Box>
  );
}

function NoDataItem(props: NodataProps) {
  const { sx, children } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.938rem",
        maxWidth: "41.625rem",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

NoDataItem.Title = NoDataTitle;
NoDataItem.ImageContainer = ImageContainer;
NoDataItem.Description = Description;
NoDataItem.StepTitle = StepTitle;
NoDataItem.Steps = Steps;

export default NoDataItem;
