import { createContext } from "react";
import { InitialStateType } from "./types";

export const userInitial = {
  email: "",
  firstname: "",
  lastname: "",
  middlename: "",
  userid: "",
  navigate: false, // incase useDetails has to change but navigation should be blocked
};
export const initialState: InitialStateType = {
  user: userInitial,
  login: () => {},
  logout: () => {},
  setUser: () => {},
  setGlobalAlert: () => {},
  globalAlert: {
    message: "",
    messageType: "",
  },
  globalLoader: false,
  setGlobalLoader: () => {},
  setUserPhone: () => {},
  getUserImage: () => "",
  setUserImage: () => {},
  getUserPhone: () => ({ isdCode: "", phoneNumber: "" }),
  setUserId: () => {},
  getUserId: () => "",
  setUserBalanceOTP: () => {},
};

export const AuthContext = createContext<InitialStateType>(initialState);
