import { Box, Typography } from "@mui/material";
import { ROUTES } from "config/app";
import { SubscriptionOptions } from "constants/sharedTypes";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styles from "../style.module.scss";

type DiscountInfoProps = {
  discountAmount: number;
  onLinkClick: () => void;
  preferredPlan: SubscriptionOptions[];
};

type CoolDownProps = { coolOfTime: number };

export function ButtonContainer(props: { children: ReactNode }) {
  const { children } = props;
  return (
    <Box className={styles.counterButton}>
      <Box sx={{ maxWidth: "20rem", width: "100%" }}>{children}</Box>
    </Box>
  );
}

export function DiscountInfo(props: DiscountInfoProps) {
  const { discountAmount, onLinkClick, preferredPlan } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleLinkClick = () => {
    onLinkClick();
  };

  return (
    <Typography
      sx={{
        fontSize: { sm: "1.4rem !important", xs: "1.1rem !important" },
        color: "var(--colorTextPrimary)",
        fontFamily: "var(--font-primary-700)",
        marginBottom: "1.6rem",
      }}
    >
      <Link
        to={ROUTES.PRIVATE.PURCHASE_SUBSCRIPTION}
        state={preferredPlan}
        style={{ color: "inherit", textDecoration: "underline" }}
      >
        Become a Preferred member first
      </Link>
      &nbsp;to get a discount of ${discountAmount}
    </Typography>
  );
}

export function CoolDownInfo(props: CoolDownProps) {
  const { coolOfTime } = props;
  return (
    <Typography
      component="p"
      sx={{
        fontSize: "1.1rem !important",
        fontFamily: "var(--font-primary-500)",
        color: "var(--colorTextSecondary)",
        lineHeight: "1.4rem",
      }}
    >
      Once you have used this feature, there is a cool-down period of{" "}
      <Typography
        component="span"
        sx={{
          color: "var(--colorTextPrimary)",
          fontSize: "inherit !important",
          fontFamily: "var(--font-primary-700)",
        }}
      >
        {coolOfTime} days.
      </Typography>
    </Typography>
  );
}
