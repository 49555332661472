import { Box, Button, ButtonProps, Grid } from "@mui/material";

interface TabPanelProps {
  children: React.ReactNode;
  uniqueKey: string;
  value: string;
  primaryButtonLabel?: string;
  onPrimaryBtnClick: () => void;
  secondaryButtonLabel?: string;
  onSecondaryBtnClick: () => void;
  primaryButtonProps?: ButtonProps;
}

function TabPanel(props: TabPanelProps) {
  const {
    children,
    value,
    uniqueKey,
    primaryButtonLabel,
    secondaryButtonLabel,
    onSecondaryBtnClick,
    onPrimaryBtnClick,
    primaryButtonProps,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== uniqueKey}
      id={`add-payment-tabpanel-${uniqueKey}`}
      aria-labelledby={`add-payment-tab-${uniqueKey}`}
      {...other}
    >
      {value === uniqueKey && (
        <Box
          sx={{ p: 3 }}
          flex={1}
          display="flex"
          minHeight="55vh"
          flexDirection="column"
        >
          {children}

          <Grid
            container
            sx={{
              justifyContent: "space-between",
              gap: { md: "1rem", xs: "1.5rem" },
              mt: { xs: "2rem", md: "0rem" },
            }}
          >
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                onClick={onPrimaryBtnClick}
                {...primaryButtonProps}
              >
                {primaryButtonLabel}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <Button
                color="primary"
                variant="outlined"
                onClick={onSecondaryBtnClick}
                fullWidth
                sx={{
                  border: "1px solid var(--btn-border, #9DBFBF)",
                  color: "var(--color-secondary)",
                }}
              >
                {secondaryButtonLabel}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}

export default TabPanel;

TabPanel.defaultProps = {
  primaryButtonLabel: "Proceed",
  secondaryButtonLabel: "Cancel",
};
