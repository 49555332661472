import { Box, Fade } from "@mui/material";
import CheckoutLayout from "components/CheckoutLayout";
import { REACT_QUERY_KEYS, ROUTES } from "config/app";
import { FailedAPIStatus, SubscriptionOptions } from "constants/sharedTypes";
import { useAuth } from "hooks";
import Payments from "pages/Checkout/Payments";
import {
  AnnualPlanDiscountInfo,
  SubscriptionTile,
} from "pages/Checkout/subComponents";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useIsMutating, useQueryClient } from "react-query";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useGetTaxDetails } from "services/common";
import { PaymentType } from "services/common/types";
import useGetPaymentMethods from "services/common/useGetPayments";
import { useCheckout } from "services/featureCenter";
import { CheckoutSuccessResponse } from "services/featureCenter/types";
import triggerGAEvent, { buildPurchasedAnalyticsData } from "utils/gaEvents";

const breadcrumbs = [
  { text: "People Hub", active: true },
  { text: " Feature Center" },
];

function PurchaseSubscription() {
  const { setGlobalAlert, getUserId } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [paymentMethodId, setPaymentMethodId] = useState("ADD_CARD");
  const [activeCreditCards, setActiveCreditCards] = useState<PaymentType[]>([]);
  const [isNewPaymentAdding, setIsNewPaymentAdding] = useState(false);

  const userId = getUserId();

  useLayoutEffect(() => {
    queryClient.invalidateQueries(REACT_QUERY_KEYS.PAYMENT_METHODS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preferredPlan = useMemo<SubscriptionOptions[]>(() => {
    return location.state ?? [];
  }, [location.state]);

  // Group plans
  const plans = useMemo(() => {
    const obj: Record<"annual" | "monthly", SubscriptionOptions> = {
      annual: {} as SubscriptionOptions,
      monthly: {} as SubscriptionOptions,
    };
    preferredPlan?.forEach((plan) => {
      if (plan.durationMonths === 1) {
        obj.monthly = plan;
      } else if (plan.durationMonths === 12) {
        obj.annual = plan;
      }
    });

    return obj;
  }, [preferredPlan]);

  const [selectedPlan, setSelectedPlan] = useState<SubscriptionOptions>(
    plans.monthly,
  );

  const showError = (message: string) =>
    setGlobalAlert({ message, messageType: "error" });

  const isGettingZip =
    useIsMutating({
      mutationKey: REACT_QUERY_KEYS.MUTATION.geoLocation,
    }) > 0;

  const onPaymentSuccess = (res?: CheckoutSuccessResponse) => {
    // trigger GA event on success transaction
    if (res?.result?.isSuccess && res?.result.transactionId) {
      const purchaseInfo = buildPurchasedAnalyticsData({
        transactionId: res.result.transactionId,
        plan: selectedPlan,
      });
      triggerGAEvent("purchase", userId as string, purchaseInfo);
    }
    queryClient.invalidateQueries(REACT_QUERY_KEYS.MEMBERSHIP_PLANS);
    queryClient.invalidateQueries(REACT_QUERY_KEYS.CART);
    queryClient.invalidateQueries(REACT_QUERY_KEYS.FEATURES_COUNT);
    queryClient.invalidateQueries(REACT_QUERY_KEYS.BUY_UP_FEATURES);
  };

  const onPaymentError = (error: FailedAPIStatus) => {
    queryClient.invalidateQueries(REACT_QUERY_KEYS.PAYMENT_METHODS);
    showError(error.errorMessage);
  };

  const { mutateAsync: checkoutCart, isLoading: isPaymentProcessing } =
    useCheckout({
      onSuccess: onPaymentSuccess,
      onError: onPaymentError,
    });

  const { data: paymentMethods, isLoading: isPaymentsLoading } =
    useGetPaymentMethods();

  const { mutateAsync: getTaxDetails } = useGetTaxDetails();

  useEffect(() => {
    if (!paymentMethods?.result?.paymentDetails?.payments?.length) return;

    const allPayments = paymentMethods?.result?.paymentDetails?.payments;
    const activePayments = allPayments.filter(
      (payment) =>
        payment.status === "Active" && payment.paymentMethod === "CC",
    );
    if (activePayments?.length) {
      setActiveCreditCards(activePayments);
      setPaymentMethodId(activePayments?.[0]?.paymentMethodId);
    }
  }, [paymentMethods]);

  const handleBack = () => navigate(-1);

  const proceedToPayment = async (paymentId: string) => {
    if (!paymentId || paymentId === "ADD_CARD") return;

    const taxableAmount = selectedPlan.fullPrice;

    const res = await getTaxDetails({
      paymentMethodId: paymentId,
      reference: "TALENT_PURCHASE",
      amount: taxableAmount,
    });

    if (!res?.result?.success) {
      setGlobalAlert({
        message: "Unable to get Tax details",
        messageType: "error",
      });
      return;
    }

    const checkoutRes = await checkoutCart({
      amountWithTax: res?.result?.taxDetails?.amount_total,
      isTaxCodeApplicable: res?.result?.taxDetails?.isTaxCodeApplicable,
      ...(res?.result?.taxDetails?.isTaxCodeApplicable && {
        taxCode: res?.result?.taxDetails?.id,
      }),
      isCartCheckout: false,
      paymentMethodId: paymentId,
      subscriptionId: selectedPlan.subscriptionId,
      taxRate:
        res?.result?.taxDetails?.tax_breakdown?.[0]?.tax_rate_details
          ?.percentage_decimal,
    });

    if (checkoutRes?.result?.isSuccess) {
      navigate(ROUTES.PRIVATE.PAYMENT_SUCCESS, {
        state: { amount: res?.result?.taxDetails?.amount_total },
      });
    }
  };

  const onCancel = () => {
    handleBack();
  };

  const onPlanChange = (planId: SubscriptionOptions) => {
    setSelectedPlan(planId);
  };

  const handlePaymentSelect = (paymentId: string) => {
    setPaymentMethodId(paymentId);
  };

  const onNewPaymentAdd = (status: boolean) => {
    setIsNewPaymentAdding(status);
  };

  if (!preferredPlan.length) {
    return <Navigate replace to={ROUTES.PRIVATE.PURCHASE_CENTER} />;
  }

  return (
    <CheckoutLayout
      breadCrumbs={breadcrumbs}
      onConfirm={() => proceedToPayment(paymentMethodId)}
      primaryButtonLabel="Proceed To Payment"
      loading={isPaymentProcessing || isNewPaymentAdding}
      {...(paymentMethodId === "ADD_CARD"
        ? {
            primaryButtonProps: {
              type: "submit",
              form: "addCredit-card",
              disabled: isGettingZip,
            },
          }
        : { onConfirm: () => proceedToPayment(paymentMethodId) })}
    >
      <CheckoutLayout.CheckoutHeader
        title="Proceed To Checkout"
        onButtonClick={onCancel}
        containerStyle={{ alignItems: "center" }}
      />
      <Box sx={{ mt: "3rem" }}>
        <CheckoutLayout.Title title="Subscriptions" sx={{ mb: "1.5rem" }} />
        <CheckoutLayout.ContentContainer>
          <Box>
            <CheckoutLayout.ItemContainer>
              <SubscriptionTile
                title="Preferred"
                onChange={onPlanChange}
                preferredPlan={plans}
              />
            </CheckoutLayout.ItemContainer>

            <Fade
              in={selectedPlan.subscriptionId === plans.monthly.subscriptionId}
              unmountOnExit
            >
              <Box>
                <AnnualPlanDiscountInfo discount="20%" />
              </Box>
            </Fade>
          </Box>
          <CheckoutLayout.Price
            label="Total:"
            amount={selectedPlan.fullPrice}
          />
        </CheckoutLayout.ContentContainer>
        <CheckoutLayout.Title
          title="Select Existing Payment Method"
          sx={{ mb: "1.5rem" }}
        />
        <Payments
          creditCards={activeCreditCards}
          onPaymentSelect={handlePaymentSelect}
          selectedPaymentId={paymentMethodId}
          loading={isPaymentsLoading}
          onPaymentProcessing={onNewPaymentAdd}
          stripeKey={
            paymentMethods?.result?.paymentDetails?.paymentCenter?.stripe
              ?.pApiKey as string
          }
          onCardSave={proceedToPayment}
        />
      </Box>
    </CheckoutLayout>
  );
}

export default PurchaseSubscription;
