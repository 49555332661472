import { InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import SVGIcon from "components/icons";
import { forwardRef, ReactNode } from "react";

export type InputFieldProps = {
  actionButtonLabel?: string;
  onActionClick?: () => void;
  isVerified?: boolean;
  startAdornment?: ReactNode;
  validateButtonLabel?: string;
  onValidateClick?: () => void;
} & TextFieldProps;

type RefType = React.Ref<HTMLDivElement>;

// eslint-disable-next-line react/display-name
const InputField = forwardRef((props: InputFieldProps, ref: RefType) => {
  const {
    actionButtonLabel,
    onActionClick,
    isVerified,
    startAdornment,
    validateButtonLabel,
    onValidateClick,
    ...textFieldProps
  } = props;

  return (
    <>
      <TextField
        {...textFieldProps}
        ref={ref}
        {...(startAdornment && {
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ),
          },
        })}
        {...((isVerified || validateButtonLabel) && {
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                {isVerified && (
                  <Box
                    sx={{
                      width: "1.6rem",
                      height: "1.6rem",
                      borderRadius: "50%",
                      background: "var(--color-primary)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SVGIcon name="tick" />
                  </Box>
                )}
                {validateButtonLabel && (
                  <Box>
                    <Button
                      aria-label={validateButtonLabel}
                      variant="text"
                      sx={{
                        padding: 0,
                        ml: "auto",
                        color: "var(--colorTextPrimary)",
                        textDecoration: "underline",
                      }}
                      disableRipple
                      onClick={onValidateClick && onValidateClick}
                    >
                      {validateButtonLabel}
                    </Button>
                  </Box>
                )}
              </InputAdornment>
            ),
          },
        })}
      />
      {actionButtonLabel && (
        <Box display="flex" mt="0">
          <Button
            aria-label={actionButtonLabel}
            variant="text"
            sx={{
              padding: 0,
              ml: "auto",
              color: "var(--colorTextPrimary)",
              textDecoration: "underline",
            }}
            disableRipple
            onClick={onActionClick}
          >
            {actionButtonLabel}
          </Button>
        </Box>
      )}
    </>
  );
});

export default InputField;
