import { Box, BoxProps, CircularProgress, Typography } from "@mui/material";

type TimerProps = {
  value: number;
  maxLimit: number;
  label: string;
} & BoxProps;

function Timer(props: TimerProps) {
  const { value, label, maxLimit } = props;

  // finding percentage of value with respect to maximum value
  const currentPercent = (value / maxLimit) * 100;

  return (
    <Box
      sx={{
        "&:before": {
          content: "''",
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          borderWidth: { xs: "0.45rem", sm: "0.5rem", md: "0.78rem" },
          borderStyle: "solid",
          borderColor: "#3E4B61",
          position: "absolute",
          top: "0",
          left: "0",
        },
        flexShrink: "0",
        color: "var(--success)",
        width: { xs: "6.8rem", sm: "10rem", md: "13rem", lg: "15rem" },
        height: { xs: "6.8rem", sm: "10rem", md: "13rem", lg: "15rem" },
        position: "relative",
      }}
    >
      <CircularProgress
        variant="determinate"
        value={currentPercent}
        size="100%"
        thickness={2.35}
        color="inherit"
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="caption"
          component="h5"
          color="var(--colorTextPrimary)"
          sx={{
            fontSize: {
              xs: "2.4rem !important",
              md: "4.3rem !important",
              lg: "6.4rem !important",
            },
            fontWeight: "400",
            fontFamily: {
              xs: "var(--font-primary-700)",
              sm: "var(--font-primary-800)",
            },
            lineHeight: "1",
          }}
        >
          {value}
        </Typography>
        <Typography
          component="p"
          color="var(--colorTextPrimary)"
          sx={{
            margin: 0,
            fontSize: {
              xs: "1.2rem !important",
              md: "2rem !important",
              lg: "2.4rem !important",
            },
            fontWeight: "400",
            fontFamily: {
              xs: "var(--font-primary-500)",
              sm: "var(--font-primary-700)",
            },
            lineHeight: 1,
          }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
}

export default Timer;
