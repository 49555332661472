import { Box, Button, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import SVGIcon from "components/icons";
import { APP_LINKS, ROUTES } from "config/app";
import useSidebar from "hooks/useSidebar";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

const {
  ADD_MORE_FEATURES,
  BUY_MORE_FEATURES,
  GET_BACK_TO_JOURNEY,
  CONTINUE_JOURNEY,
} = Resources.PAGES.ACCESS_CODE;

function LinkSection(): JSX.Element {
  const navigate = useNavigate();
  const { setActiveMenu } = useSidebar();

  const handleNavigateToPurchaseCenter = () => {
    setActiveMenu("featureCenter");
    navigate(ROUTES.PRIVATE.PURCHASE_CENTER);
  };

  return (
    <Box className={styles.bottomContainer}>
      <Box className={styles.addFeatures}>
        <Typography variant="h3" className={styles.containerHeading}>
          {ADD_MORE_FEATURES}
        </Typography>
        <Button
          variant="contained"
          className={styles.buyButton}
          onClick={handleNavigateToPurchaseCenter}
        >
          {BUY_MORE_FEATURES}
        </Button>
      </Box>
      <Box className={styles.addFeatures}>
        <Typography variant="h3" className={styles.containerHeading}>
          {GET_BACK_TO_JOURNEY}
        </Typography>
        <Typography variant="h4" className={styles.containerHeading2}>
          {CONTINUE_JOURNEY}
        </Typography>
        <Box className={styles.storeGroup}>
          {APP_LINKS.map((link) => (
            <Link to={link?.link} key={link?.key} target="_blank">
              <SVGIcon name={link.icon} />
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default LinkSection;
