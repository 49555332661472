import { AddOnKeys, SubscriptionOptions } from "constants/sharedTypes";
import { ADD_ON_DETAILS } from "pages/PurchaseCenter/NewBuyUpFeatures/config";
import { CartItem } from "pages/PurchaseCenter/types";

export type GAEvents =
  | "login"
  | "create_account"
  | "how_we_help"
  | "career_phase"
  | "account_deactivate"
  | "purchase"
  | "error"
  | "logout"
  | "account_delete";

const triggerGAEvent = (
  eventName: GAEvents,
  userId: string,
  options?: Record<string, any>,
) => {
  // dataLayer will not be available in all environments
  window.dataLayer?.push({
    event: eventName,
    userId,
    ...options,
  });
};

export default triggerGAEvent;

export const GA_USER_FLOW = {
  REGISTER: {
    SUCCESS: {
      SIGNUP: "sign-up",
      MOB_OTP_SENT: "mob-verification-sent", // phone otp send success
      MOBILE_VERIFICATION: "enter-phone-otp-success", // phone otp verification success
      EMAIL_VERIFICATION: "enter-email-otp-success", // email otp verification success
      SKIP_EMAIL_VERIFICATION: "skip-email-verification",
      WHY_ARE_U_HERE: "why-are-you-here",
      SIGNUP_SUCCESS: "sign-up-success", // signup process completed
      LOGOUT: "logout",
    },
    ERROR: {
      SIGNUP: "sign-up-error",
      MOB_OTP_SENT: "mob-verification-error", // phone otp send fail - DONE
      MOBILE_VERIFICATION: "enter-phone-otp-fail", // phone otp verification fail
      EMAIL_VERIFICATION: "email-verification-otp-fail", // email otp verification fail
      WHY_ARE_U_HERE: "why-are-you-here-fail",
      RESEND_MOBILE_OTP: "resend-phone-otp",
      RESEND_EMAIL_OTP: "resend-email-otp",
      SKIP_EMAIL_VERIFICATION: "skip-email-verification-fail",
    },
  },
};

const planName = new Map();
planName.set(1, "Monthly");
planName.set(12, "Yearly");

type PurchasedItem = {
  item_id: string;
  item_name: string;
  price: number;
  quantity: number;
  item_brand: "pepelwerk";
  item_category: string;
};

export const buildPurchasedAnalyticsData = ({
  addOns = [],
  plan,
  transactionId,
}: {
  addOns?: CartItem[];
  plan?: SubscriptionOptions;
  transactionId: string;
}) => {
  const purchasedAddons: PurchasedItem[] = addOns.map((feature) => ({
    item_id: "Add-ons",
    item_name: ADD_ON_DETAILS[feature.feature as AddOnKeys].title,
    item_category: "",
    price: feature.price as number,
    quantity: feature.count,
    item_brand: "pepelwerk",
  }));

  const purchasedPlan: PurchasedItem = plan
    ? {
        item_id: "upgrade sub",
        item_name: `Preferred ${planName.get(plan.durationMonths)} plan`,
        item_category: planName.get(plan.durationMonths),
        price: plan.fullPrice,
        quantity: 1,
        item_brand: "pepelwerk",
      }
    : ({} as PurchasedItem);

  const purchasedItems: PurchasedItem[] = plan
    ? [...purchasedAddons, purchasedPlan]
    : purchasedAddons;

  const totalCost = purchasedItems.reduce(
    (sum, item) => sum + item.quantity * item.price,
    0,
  );

  return Object.freeze({
    ecommerce: {
      currency: "USD",
      value: totalCost,
      transaction_id: transactionId,
      items: purchasedItems,
    },
  });
};

export const freePlanInfo = Object.freeze({
  ecommerce: {
    items: [
      {
        item_id: "downgrade sub",
        item_name: "Free plan",
        item_category: "free",
        item_brand: "pepelwerk",
      },
    ],
  },
});
