import { Box, Fade, Modal as MuiModal } from "@mui/material";
import { ReactNode } from "react";

type ModalPropType = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  disableClickAway?: boolean;
};

function Modal(props: ModalPropType) {
  const { open, onClose, children, disableClickAway } = props;

  const handleClose = (e: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (
      disableClickAway &&
      (reason === "backdropClick" || reason === "escapeKeyDown")
    ) {
      return;
    }
    onClose();
  };

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      disableEscapeKeyDown
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {children}
        </Box>
      </Fade>
    </MuiModal>
  );
}

export default Modal;

Modal.defaultProps = {
  disableClickAway: false,
};
