import { Box, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import { ConfirmDialog } from "components";
import { Breadcrumb } from "components/common";
import { useAuth } from "hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deactivateAccount } from "services/accountProfile";
import triggerGAEvent from "utils/gaEvents";
import { ROUTES } from "config/app";

const breadcrumbs = [
  {
    text: "Account Settings",
    active: true,
    pageLink: ROUTES.PRIVATE.ACCOUNT_PROFILE,
  },
  { text: "Delete Account" },
];

function DeleteAccount() {
  const [loading, setLoading] = useState(false);

  const { setGlobalAlert } = useAuth();
  const { logout, user } = useAuth();
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);

  const onSubmit = async () => {
    setLoading(true);
    // NOTE: deactivate API is intentionally used for delete account
    const response = await deactivateAccount();
    if (!response.result.success) {
      setLoading(false);
      setGlobalAlert({ message: response?.errorMessage, messageType: "error" });
    } else if (response.result.success && response.status === 200) {
      // Send delete event to GA
      triggerGAEvent("account_delete", user?.userId as string);
      setLoading(false);
      logout();
    }
  };

  return (
    <Box
      className="app-l-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: "3rem !important",
        gap: "4.5rem",
      }}
    >
      <Breadcrumb crumbs={breadcrumbs} />
      <ConfirmDialog
        heading={Resources.PAGES.ACCOUNT_PROFILE.DELETE_ACCOUNT}
        onCancel={handleBack}
        onConfirm={onSubmit}
        primaryButtonProps={{ disabled: loading }}
        primaryButtonLabel={
          Resources.PAGES.ACCOUNT_PROFILE.DELETE_ACCOUNT_BUTTON_LABEL
        }
        secondaryButtonLabel={
          Resources.PAGES.ACCOUNT_PROFILE.CANCEL_BUTTON_LABEL
        }
      >
        <Box>
          <Typography
            sx={{
              fontSize: "1.4rem",
              color: "var(--color-secondary)",
              fontFamily: "var(--font-primary-500)",
            }}
          >
            {Resources.PAGES.ACCOUNT_PROFILE.DELETE_ACCOUNT_DESCRIPTION}
          </Typography>
        </Box>
      </ConfirmDialog>
    </Box>
  );
}

export default DeleteAccount;
