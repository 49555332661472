import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { SectionLoader } from "components/common";
import Plan, { PlanProps } from "components/common/plan";

import { ReactNode } from "react";
import styles from "../style.module.scss";

type BuyUpCardProps = {
  cardTitle: ReactNode;
  description: ReactNode;
  isLoading?: boolean;
  cost?: number;
  currency?: string;
  costDescription?: string;
  children?: ReactNode;
  infoText?: ReactNode;
} & Omit<PlanProps, "children">;

function BuyUpCard(props: BuyUpCardProps) {
  const {
    isLoading,
    cardTitle,
    description,
    infoText,
    cost,
    costDescription,
    currency = "$",
    children,

    ...planProps
  } = props;
  return (
    <Plan {...planProps} className={styles.planCard}>
      <Box className={styles.sliderContent}>
        <SectionLoader loading={!!isLoading} />
        <Box className={styles.sliderTop}>
          <Box className={styles.sliderHeading}>
            <Typography variant="body2" className={styles.sliderItemTitle}>
              {cardTitle}
            </Typography>
            <Typography variant="body2" className={styles.sliderSubText}>
              {description}
            </Typography>
          </Box>
          <Box className={styles.rateWrapper}>
            <Typography component="span" className={styles.rate}>
              {cost !== undefined && `${currency}${cost}`}
            </Typography>
            {costDescription && (
              <Typography component="span" className={styles.rateText}>
                {costDescription}
              </Typography>
            )}
          </Box>
        </Box>

        <Divider className={styles.divider} />
        {infoText && <Box className={styles.getSession}>{infoText}</Box>}

        <Box className={styles.sliderItemBottom}>{children}</Box>
      </Box>
    </Plan>
  );
}

export default BuyUpCard;
