import { Box, CircularProgress } from "@mui/material";
import { LOCAL_STORAGE_KEYS, ROUTES } from "config/app";
import { ReactChildrenProps } from "constants/sharedTypes";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import signOutApiHandler from "services/signOutAPIHandler";
import useGetTalentDetails from "services/useGetTalentDetails";
import { navigateToIncompleteSession } from "utils";
import { AuthContext, userInitial } from "./authContext";
import { GlobalAlert, UserType } from "./types";

const {
  PUBLIC: { LOGIN, DELETE_ACCOUNT_TALENT },
  PRIVATE: { HOME },
} = ROUTES;

function AuthProvider(props: ReactChildrenProps) {
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<UserType>(userInitial);
  const [globalAlert, setGlobalAlert] = useState<GlobalAlert>({
    message: "",
    messageType: "",
  });
  const [loading, setLoading] = useState(true);
  const [globalLoader, setGlobalLoader] = useState(false);

  const locationRef = useRef<string>(location.pathname);
  const [searchParams] = useSearchParams();
  const pathName = searchParams.get("path");
  const token = location.pathname.split("/login/")[1];

  useEffect(() => {
    locationRef.current = location.pathname;
    return () => {
      locationRef.current = "";
    };
  }, []);

  const nextRoute =
    locationRef.current && locationRef.current !== LOGIN
      ? locationRef.current
      : HOME;

  const nextRedirectionPath =
    location.pathname.includes(LOGIN) && nextRoute === DELETE_ACCOUNT_TALENT
      ? (locationRef.current = HOME)
      : nextRoute;

  const { mutate: validateTalentSession } = useGetTalentDetails({
    setUser,
    setLoading,
    setGlobalAlert,
    nextRoute: nextRedirectionPath,
  });

  const checkLogin = async () => {
    // fetch the session from server
    validateTalentSession();
  };

  useEffect(() => {
    if (!location.pathname.includes("guardian")) {
      checkLogin();
    } else {
      setLoading(false);
      navigate(location.pathname);
    }
  }, []);

  const login = async (userData: UserType) => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.USER_DETAILS,
      JSON.stringify(userData),
    );
    setUser({ ...userData });
    navigateToIncompleteSession({
      userData,
      navigate,
      nextRoute: nextRedirectionPath,
    });
  };

  const queryClient = useQueryClient();

  const logout = async () => {
    await signOutApiHandler();
    setUser(userInitial);
    locationRef.current = "";
    queryClient.clear();
    navigate(LOGIN, { replace: true });
  };

  const setUserPhone = (phone: { isdCode: string; phoneNumber: string }) => {
    const { isdCode, phoneNumber } = phone;
    setUser((u) => ({
      ...u,
      home: { ...u.home, phoneNumber, isdCode },
    }));
  };

  const getUserImage = () => user.home?.mediaDetails?.photoUrl;

  const setUserImage = (imgUrl: string) => {
    setUser((u) => ({
      ...u,
      home: {
        ...u.home,
        mediaDetails: { ...u.home?.mediaDetails, photoUrl: imgUrl },
      },
    }));
  };

  const setUserId = (userId: string) => {
    setUser((u) => ({
      ...u,
      userId,
    }));
  };

  const setUserBalanceOTP = (balanceOtp: {
    resendPhoneOtpBalance?: number;
    resendEmailOtpBalance: number;
  }) => {
    setUser((u) => ({
      ...u,
      balanceOtp,
    }));
  };

  const getUserId = (): string | undefined => user?.userId;

  const getUserPhone = () => {
    const isdCode = user.home?.isdCode ?? "";
    const phoneNumber = user?.home?.phoneNumber ?? "";

    return { isdCode, phoneNumber };
  };

  const ssoToken = () => {
    if (location.pathname === `${LOGIN}/${token}`) {
      const tokendetails = {
        token,
        pathName,
      };
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.TOKEN_DETAILS,
        JSON.stringify(tokendetails),
      );
    }
  };

  useLayoutEffect(() => {
    ssoToken();
  }, []);

  const authContextValues = useMemo(
    () => ({
      login,
      logout,
      user,
      setUser,
      globalAlert,
      setGlobalAlert,
      globalLoader,
      setGlobalLoader,
      setUserPhone,
      getUserImage,
      setUserImage,
      getUserPhone,
      setUserId,
      getUserId,
      ssoToken,
      setUserBalanceOTP,
    }),
    [globalAlert, user, globalLoader, login],
  );

  return (
    <AuthContext.Provider value={authContextValues}>
      {loading ? (
        <Box
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={24} />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
