import { TableCell as MuiTableCell, Typography } from "@mui/material";
import { TableCellProps } from "@mui/material/TableCell";
import { ReactNode } from "react";

interface ITableCellProps extends TableCellProps {
  children: ReactNode;
}

type CellTextProps = {
  text: string;
  fontWeight?: string;
  isLink?: boolean;
  onClick?: () => void;
};

function TableCell(props: ITableCellProps) {
  const { children, ...rest } = props;
  return <MuiTableCell {...rest}>{children}</MuiTableCell>;
}

function CellText(props: CellTextProps) {
  const { text, fontWeight = "700", isLink, onClick } = props;
  return (
    <Typography
      onClick={onClick}
      sx={{
        fontWeight,
        fontSize: "inherit",
        color: "var(--colorTextPrimary)",
        cursor: isLink ? "pointer" : "text",
        whiteSpace: "pre-wrap",
        textDecoration: isLink ? "underline" : "",
      }}
    >
      {text}
    </Typography>
  );
}

TableCell.Text = CellText;

export default TableCell;
