/* eslint-disable react/jsx-props-no-spreading */
import { Box, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import FTUELayout from "components/FTUELayout";
import { AlertDialog } from "components/common";
import { PAGE_KEYS, ROUTES, coachingSessionMandatoryItems } from "config/app";
import { useAuth } from "hooks";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useGetTalentHomeDetails from "services/useGetTalentHomeDetails";
import useGuardianConsentEmail from "services/useGurianConsentEmail";
import { isValidMobileNumber, sleep } from "utils";
import GuardianForm from "./guardianForm";
import { GuardianConsentFormType } from "./config";

const { CHANGE_EMAIL, AFTER_WE_RECEIVE, CONSENT_TO, WAITING_FOR_GUARDIAN } =
  Resources.PAGES.GUARDIAN;

const GUARDIAN_CONSENT_APPROVED_STATUS = [
  "PWB_GUARDIAN_CONSENT_VERIFIED",
  "PWB_GUARDIAN_CONSENT_ALREADY_APPROVED",
];

function Guardian() {
  const { GUARDIAN } = PAGE_KEYS;
  const [currentForm, setCurrentForm] = useState(GUARDIAN);
  const [isAccountLocked, setAccountLocked] = useState(false);
  const navigate = useNavigate();
  const { setGlobalAlert } = useAuth();

  const {
    ERRORS: { ACCOUNT_LOCKED, ACCOUNT_LOCKED_DESC },
    COMMON: {
      BUTTONS: { OK },
    },
  } = Resources;

  // form initialization
  const methods = useForm({
    mode: "all",
  });

  const { data: userDetails, refetch: getUserInfo } = useGetTalentHomeDetails();

  const setErrorPopUp = (message: string) => {
    setGlobalAlert({ message, messageType: "error" });
  };

  const closeAlert = () => setAccountLocked(false);

  const onConcentVerified = async (message: string, errorCode?: string) => {
    setErrorPopUp(message);
    // wait fot the popup to show
    await sleep(2000);

    if (errorCode && GUARDIAN_CONSENT_APPROVED_STATUS.includes(errorCode)) {
      // navigate talent to dashboard
      const response = await getUserInfo();

      if (response?.data?.incompleteSection) {
        const nextSection = coachingSessionMandatoryItems.includes(
          response.data.incompleteSection,
        )
          ? "COACHING_SESSION_BLOCKER"
          : "";
        navigate(ROUTES.PUBLIC.SIGN_UP, {
          state: { section: nextSection },
        });
        return;
      }
      navigate(ROUTES.PRIVATE.HOME);
    }
  };

  const { mutate: mutateGuardianConsent, isLoading } = useGuardianConsentEmail({
    errorCallback: onConcentVerified,
    setCurrentForm,
    currentForm,
  });

  const sendEmailHandler = (userData: GuardianConsentFormType) => {
    const { email, isdCode, isdCountry, phoneNumber } = userData;
    const guardianNumber = `${isdCode} ${phoneNumber}`;
    const talentNumber = `${userDetails?.home?.isdCode} ${userDetails?.home?.phoneNumber}`;
    if (!isValidMobileNumber(guardianNumber)) {
      setGlobalAlert({
        messageType: "error",
        message: Resources.ERRORS.MOBILE_NUMBER_NOT_MATCHING,
      });
      return;
    }
    if (email === userDetails?.home?.email) {
      setGlobalAlert({
        messageType: "error",
        message: Resources.ERRORS.PWB_SAME_TALENT_AND_GUARDIAN_EMAIL,
      });
      return;
    }
    if (talentNumber === guardianNumber) {
      setGlobalAlert({
        messageType: "error",
        message: Resources.ERRORS.PWB_GUARDIAN_PHONE_SAME_AS_TALENT,
      });
      return;
    }
    const requestParams = {
      guardianEmail: email,
      guardianIsdCode: isdCode,
      guardianIsdCountry: isdCountry,
      guardianPhoneNumber: phoneNumber,
    };
    mutateGuardianConsent(requestParams);
  };

  return (
    <FTUELayout
      title1={WAITING_FOR_GUARDIAN}
      title2={CONSENT_TO}
      containerStyle={{ maxWidth: "none" }}
    >
      <Box sx={{ maxWidth: "51rem", mb: "1.5rem" }}>
        <Typography
          display="inline"
          sx={{
            fontSize: "1.4rem",
            fontWeight: "50rem !important",
            lineHeight: "1.68rem",
          }}
        >
          {AFTER_WE_RECEIVE}
          <Typography
            sx={{
              fontSize: "1.4rem",
              fontWeight: "50rem !important",
            }}
          >
            {CHANGE_EMAIL}
          </Typography>
        </Typography>
      </Box>
      <AlertDialog
        open={isAccountLocked}
        title={ACCOUNT_LOCKED}
        content={ACCOUNT_LOCKED_DESC}
        handleClose={closeAlert}
        primaryButton={OK}
        primaryButtonAction={closeAlert}
      />
      <Box sx={{ maxWidth: "36.8rem", paddingTop: "1.5em" }}>
        <FormProvider {...methods}>
          {currentForm === GUARDIAN ? (
            <GuardianForm
              sendEmailHandler={sendEmailHandler}
              isCodeSendLoading={isLoading}
              details={userDetails}
            />
          ) : null}
        </FormProvider>
      </Box>
    </FTUELayout>
  );
}

export default Guardian;
