import { Box, Paper } from "@mui/material";
import styles from "app.module.scss";
import { Snackbar } from "components/common";
import NetworkStatus from "components/NetworkStatus";
import { INCOMPLETE_SECTIONS, ROUTES } from "config/app";
import useAuth from "hooks/useAuth";
import useUserWay from "hooks/useUserWay";
import useDrift from "hooks/useDrift";
import { useEffect, useLayoutEffect } from "react";
import TagManager from "react-gtm-module";
import { useLocation, useNavigate } from "react-router-dom";
import PageRoutes from "routes";
import { navigateToIncompleteSession } from "utils";

const APP_ENV = process.env.REACT_APP_ENVIRONMENT;

if (APP_ENV === "PRODUCTION") {
  const GTM_KEY = `REACT_APP_GTM_ID_${APP_ENV}`;
  const GTM_ID = `${process.env[GTM_KEY]}`;

  const tagManagerArgs = { gtmId: GTM_ID };

  TagManager.initialize(tagManagerArgs);
}

function App() {
  useUserWay();
  useDrift();
  const { globalAlert, setGlobalAlert, user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const resetErrors = () => {
    if (globalAlert) setGlobalAlert({ message: "", messageType: "" });
  };
  // // Add theme class to body before loading
  useLayoutEffect(() => {
    document.body.classList.add("theme-light");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Direct all users to delete account page if accessing it - (logged in & not logged in users)
    if (location.pathname.includes(ROUTES.PUBLIC.DELETE_ACCOUNT_TALENT)) {
      navigate(ROUTES.PUBLIC.DELETE_ACCOUNT_TALENT, { replace: true });
      return;
    }

    let isAccessingPublicPath = Object.values(ROUTES.PUBLIC).includes(
      location.pathname,
    );

    // checks for campaign
    isAccessingPublicPath = location.pathname.includes("signup")
      ? true
      : isAccessingPublicPath;

    if (
      user &&
      isAccessingPublicPath &&
      INCOMPLETE_SECTIONS.includes(user?.incompleteSection as string)
    ) {
      navigateToIncompleteSession({
        userData: user,
        navigate,
        nextRoute: ROUTES.PRIVATE.HOME,
      });
    } else if (user?.email && isAccessingPublicPath) {
      navigate(ROUTES.PRIVATE.HOME, { replace: true });
    } else if (!user?.email && isAccessingPublicPath) {
      navigate(location.pathname, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Box>
      {globalAlert.message && (
        <Snackbar
          severity={globalAlert.messageType || "error"}
          open
          message={globalAlert.message}
          onClose={resetErrors}
        />
      )}
      <NetworkStatus />
      <Paper className={styles.wrapper}>
        <PageRoutes />
      </Paper>
    </Box>
  );
}

export default App;
