import { TableRow } from "@mui/material";
import { Table, TableCell } from "components/common";
import { SESSION_TYPE } from "config/app";
import { TableHeaderType } from "constants/sharedTypes";
import { useMemo } from "react";
import { CoachingSessions } from "services/home/types";
import { openURL } from "utils";
import { formatUTCDateToLocal } from "utils/dateUtils";

type CoachinSessionProps = {
  documents: CoachingSessions[];
  loading: boolean;
  timeZoneData: string;
};

const TABLE_HEADER: TableHeaderType[] = [
  { key: "session", label: "Session", align: "left", width: "33%" },
  { key: "date", label: "Date", width: "15%" },
  { key: "time", label: "Time", width: "22%" },
  { key: "details", label: "Details", width: "30%" },
];

function CoachingSession(props: CoachinSessionProps) {
  const { documents, loading, timeZoneData } = props;

  const onLinkPress = (url: string) => {
    if (url) {
      openURL(url);
    }
  };

  const tableRows = useMemo(() => {
    return documents.map((doc) => (
      <TableRow key={timeZoneData}>
        <TableCell>
          <TableCell.Text
            fontWeight="500 !mportant"
            text={`${SESSION_TYPE[doc?.sessionType ?? ""]} session with ${
              doc?.coachName?.firstname
            }`}
          />
        </TableCell>
        <TableCell>
          <TableCell.Text
            fontWeight="500"
            text={formatUTCDateToLocal(doc?.scheduleTime, "dd LLL, yyyy") ?? ""}
          />
        </TableCell>
        <TableCell>
          <TableCell.Text
            fontWeight="500"
            text={`${
              formatUTCDateToLocal(doc?.scheduleTime, "hh:mm a") ?? ""
            } ${timeZoneData}`}
          />
        </TableCell>
        <TableCell>
          <TableCell.Text
            isLink
            onClick={() => onLinkPress(doc?.sessionRoomLocation)}
            fontWeight="500"
            text="Zoom"
          />
        </TableCell>
      </TableRow>
    ));
  }, [documents, timeZoneData]);

  return (
    <Table
      headerData={TABLE_HEADER}
      loading={loading}
      noDataMessage="No records found"
    >
      {tableRows}
    </Table>
  );
}

export default CoachingSession;
