import Resources from "assets/json/Resources";
import { TalentRegistrationStepTypes } from "constants/sharedTypes";
import { useMutation } from "react-query";
import { resendOtp } from "./signUpServices";

type HandlerType = {
  onSuccess: (nextStep: SuccessResponseType) => void;
  onError: (errorMsg: string) => void;
};

type SuccessResponseType = {
  status: number;
  result: {
    incompleteSection: TalentRegistrationStepTypes;
    resendEmailOtpBalance: number;
    hintMessageCode: string;
    userid: string;
    session: string;
    email: string;
  };
};

type FailedResponse = {
  status: number;
  inputErrors?: {
    exists: boolean;
  };
  appErrors?: {
    exists: boolean;
    errors: string[];
  };
};

type APIResponseType = SuccessResponseType & FailedResponse;

const useResendOtp = (handlers?: HandlerType) => {
  const onResendOtpError = (error: FailedResponse) => {
    let errorMessage = Resources.ERRORS.SOMETHING;
    if (error.appErrors?.errors[0]) {
      errorMessage =
        Resources.ERRORS[error.appErrors?.errors[0]] ??
        Resources.ERRORS.REGISTER_ERROR;
    }

    handlers?.onError(errorMessage);
  };

  const onResendOtpSuccess = (data: APIResponseType) => {
    if (data?.inputErrors?.exists || data?.appErrors?.exists) {
      onResendOtpError(data);
      return;
    }
    handlers?.onSuccess(data);
  };

  return useMutation(resendOtp, {
    onSuccess: onResendOtpSuccess,
    onError: onResendOtpError,
  });
};

export default useResendOtp;
