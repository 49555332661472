import { Box, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import { Breadcrumb } from "components/common";
import { AccessCodeDetailType } from "pages/PaymentCenter/types";
import { useMemo } from "react";
import useGetAccessCodesList from "services/accessCode/useGetAccessCodesList";
import { breadcrumbs, talentAccessCodeKey } from "../config";
import AccessCodeList from "./AccessCodeList";
import AddAccessCodeForm from "./AddAccessCodeForm";
import styles from "./styles.module.scss";

function AccessCodeListing() {
  const { ACTIVE_ACCESS_CODE_TITLE, NO_ACTIVE_ACCESS_CODES } =
    Resources.PAGES.ACCESS_CODE;
  const {
    ACTIVE,
    AVAILABLE_TO_USE,
    UTILIZED,
    EXPIRED,
    IN_ACTIVE,
    REQUESTED,
    REJECTED,
  } = talentAccessCodeKey;
  const {
    isLoading,
    data,
    refetch: refetchAccessCodeList,
  } = useGetAccessCodesList();
  const { accessCodeList = [], talentActiveAccessCode = "" } = {
    ...data?.result,
  };

  const accessCodeSortedList = useMemo(() => {
    const sortedArray = accessCodeList?.sort((data1, data2) => {
      const date1 = new Date(data1?.assignedDate);
      const date2 = new Date(data2?.assignedDate);
      return date2.valueOf() - date1.valueOf();
    });
    const activeAC: AccessCodeDetailType[] = [];
    const availableAC: AccessCodeDetailType[] = [];
    const utilizedAC: AccessCodeDetailType[] = [];
    const expiredAC: AccessCodeDetailType[] = [];
    const inActiveAC: AccessCodeDetailType[] = [];
    const requestedAC: AccessCodeDetailType[] = [];
    const rejectedAC: AccessCodeDetailType[] = [];
    sortedArray.forEach((info) => {
      if (info?.talentAccessCodeStatus === ACTIVE) {
        activeAC.push(info);
      }
      if (info?.talentAccessCodeStatus === AVAILABLE_TO_USE) {
        availableAC.push(info);
      }
      if (info?.talentAccessCodeStatus === UTILIZED) {
        utilizedAC.push(info);
      }
      if (info?.talentAccessCodeStatus === EXPIRED) {
        expiredAC.push(info);
      }
      if (info?.talentAccessCodeStatus === IN_ACTIVE) {
        inActiveAC.push(info);
      }
      if (info?.talentAccessCodeStatus === REQUESTED) {
        requestedAC.push(info);
      }
      if (info?.talentAccessCodeStatus === REJECTED) {
        rejectedAC.push(info);
      }
    });
    return [
      ...activeAC,
      ...availableAC,
      ...requestedAC,
      ...utilizedAC,
      ...expiredAC,
      ...inActiveAC,
      ...rejectedAC,
    ];
  }, [data]);

  return (
    <Box className="app-l-container">
      <Breadcrumb crumbs={breadcrumbs} />
      <Box className={styles.accessCodeContainer}>
        <Box>
          <Typography variant="h3" className={styles.containerHeading}>
            {ACTIVE_ACCESS_CODE_TITLE}
          </Typography>
          <Box className={styles.addAccessCodeBlock}>
            <Box sx={{ flex: "1" }}>
              <Typography
                variant="h3"
                className={`${styles.blockHeading} ${
                  talentActiveAccessCode && styles.active
                }`}
              >
                {talentActiveAccessCode ?? NO_ACTIVE_ACCESS_CODES}
              </Typography>
            </Box>
            <AddAccessCodeForm refetchAccessCodeList={refetchAccessCodeList} />
          </Box>
        </Box>
        <AccessCodeList
          accessCodeList={accessCodeSortedList}
          loading={isLoading}
        />
      </Box>
    </Box>
  );
}

export default AccessCodeListing;
