import { REACT_QUERY_KEYS, TimeConfig } from "config/app";
import { useGETRequestHandler } from "hooks";
import { HandlerType } from "services/commonTypes";
import { getCartItems } from "./featureCenterService";
import { ViewCartResponse } from "./types";

const useGetCartItems = (handler?: HandlerType<ViewCartResponse>) => {
  return useGETRequestHandler<ViewCartResponse>({
    queryKey: REACT_QUERY_KEYS.CART,
    queryFn: getCartItems,
    handler,
    queryOptions: {
      staleTime: TimeConfig.TEN_MINUTE,
      refetchOnWindowFocus: "always",
    },
  });
};

export default useGetCartItems;
