import requests from "config/api";
import API_ENDPOINTS from "constants/api";
import { ForgetPhoneOtpPayload, VerifyPhonePayload } from "./type";

export const forgetPhoneOtp = async (payload: ForgetPhoneOtpPayload) => {
  return requests.post(API_ENDPOINTS.FORGET_PASSWORD_PHONE_OTP, payload);
};

export const verifyPhoneOtp = async (payload: VerifyPhonePayload) => {
  return requests.post(API_ENDPOINTS.FORGOT_VERIFY_PHONE, payload);
};
