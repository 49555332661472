import Resources from "assets/json/Resources";
import requests from "config/api";
import { PAGE_KEYS } from "config/app";
import API_ENDPOINTS from "constants/api";
import { ErrorKeysType } from "constants/sharedTypes";
import { useAuth } from "hooks";
import { useMutation } from "react-query";

type GuardianConsentRequestPayload = {
  guardianEmail: string;
  guardianIsdCode: string;
  guardianIsdCountry: string;
  guardianPhoneNumber: string;
};

export const guardianEmailRequest = async (
  payload: GuardianConsentRequestPayload,
) => {
  return requests.post(API_ENDPOINTS.GUARDIAN_CONSENT_RESEND_EMAIL, payload);
};

type GuardianEmail = {
  errorCallback: (message: string, errorKey?: string) => void;
  setCurrentForm: (page: string) => void;
  currentForm: string;
};

const useGuardianConsentEmail = ({
  errorCallback,
  setCurrentForm,
  currentForm,
}: GuardianEmail) => {
  const { GUARDIAN } = PAGE_KEYS;
  const { setGlobalAlert } = useAuth();

  return useMutation(
    async (payload: GuardianConsentRequestPayload) => {
      const response = await guardianEmailRequest(payload);
      if (response?.status !== 200 && !response?.result?.isSuccess) {
        // consent is already approved by guardian
        const guardianEmail = response?.result?.approvedBy ?? "";
        const errorKey: string = response?.result?.error;
        const errorMessage = Resources.ERRORS[errorKey as ErrorKeysType]
          ? Resources.ERRORS[errorKey as ErrorKeysType]?.replace(
              "{email}",
              guardianEmail,
            )
          : Resources.ERRORS.SOMETHING;
        errorCallback(errorMessage as string, errorKey);
        return response;
      }
      if (response && response.status === 200 && response.result) {
        setGlobalAlert({
          message: Resources.PAGES.GUARDIAN.MAIL_SENT,
          messageType: "success",
        });
        if (currentForm === GUARDIAN) setCurrentForm(GUARDIAN);
      } else if (response?.appErrors?.errors?.length > 0) {
        const apiErrorKey = response.appErrors.errors[0];
        const errorMessage =
          Resources.ERRORS[apiErrorKey as ErrorKeysType] ||
          Resources.ERRORS.SOMETHING;
        errorCallback(errorMessage);
      }
      return response;
    },
    {
      onError: () => {
        errorCallback(Resources.ERRORS.SOMETHING);
      },
    },
  );
};

export default useGuardianConsentEmail;
