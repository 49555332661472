import { Box, Typography } from "@mui/material";
import styles from "./styles.module.scss";

type PepelInfoCardProps = {
  label: string;
  value: string;
};
function PepelInfoCard(props: PepelInfoCardProps): JSX.Element {
  const { label, value } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",

        "& + .MuiBox-root": {
          marginTop: "1rem",
        },
      }}
    >
      <Typography component="p" className={styles.blockName}>
        {label}:
      </Typography>
      <Typography component="p" className={styles.blockDesc}>
        {value}
      </Typography>
    </Box>
  );
}

export default PepelInfoCard;
