import { Menu } from "config/app";
import { PropsWithChildren, createContext, useMemo, useState } from "react";

type SidebarOptions = {
  isVisible: boolean;
};

type InitialSidebarState = {
  open: boolean;
  activeMenu: Menu | null;
  setSidebarVisibility: (options: SidebarOptions) => void;
  setActiveMenu: (menuItem: Menu | null) => void;
};

export const initialState: InitialSidebarState = {
  open: false,
  activeMenu: null,
  setSidebarVisibility: () => {},
  setActiveMenu: () => {},
};

// Creating context for sidebar
export const SidebarContext = createContext<InitialSidebarState>(initialState);

export function SidebarProvider(props: PropsWithChildren) {
  const { children } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState<Menu | null>(null);

  const handleSidebarVisibility = (options: SidebarOptions) => {
    setIsOpen(options.isVisible);
  };

  const contextValues = useMemo(() => {
    return {
      open: isOpen,
      activeMenu,
      setSidebarVisibility: handleSidebarVisibility,
      setActiveMenu,
    };
  }, [isOpen, activeMenu]);

  return (
    <SidebarContext.Provider value={contextValues}>
      {children}
    </SidebarContext.Provider>
  );
}
