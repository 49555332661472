import { Box, Button } from "@mui/material";
import { OTPCount, OTPInput } from "components/ui";
import { DEFAULT_OTP_LENGTH, OTP_WAITING_TIME } from "config/app";
import Resources from "assets/json/Resources";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth } from "hooks";
import useTimer from "hooks/useTimer";
import { useEffect, useReducer, useState } from "react";
import { useGetDeleteAccountOTP } from "services/common";
import { DeleteAccountOTPResponse } from "services/common/types";
import { OTPReducer, initialOTPState } from "utils/otpReducer";

type OTPFormProps = {
  onValidateOTP: (otp: string) => void;
  otpAttemptsLeft: number;
  email: string;
  otpError: string;
};

const {
  COMMON: {
    MESSAGE: { INVALID_OTP, ENTER_OTP },
  },
} = Resources;

function OTPForm(props: OTPFormProps) {
  const { otpAttemptsLeft, email, onValidateOTP, otpError } = props;
  const { setGlobalAlert } = useAuth();
  const [helperText, setHelperText] = useState<string>("");
  const [attemptsLeft, setAttemptsLeft] = useState(otpAttemptsLeft);
  const [canSendOTP, setCanSendOTP] = useState(true);
  const [isFirstOTP, setIsFirstOTP] = useState(true);

  const [OTPState, dispatchOTPAction] = useReducer(OTPReducer, initialOTPState);

  useEffect(() => {
    dispatchOTPAction({ type: "SET_IS_EMAIL_OTP_RESEND", payload: true });
  }, []);

  useEffect(() => {
    setHelperText(otpError);
  }, [otpError]);

  const onTimerComplete = () => setCanSendOTP(true);

  const { timeLeft } = useTimer({
    time: OTP_WAITING_TIME,
    start: !canSendOTP,
    onComplete: onTimerComplete,
  });

  const showError = (message: string) => {
    setGlobalAlert({ messageType: "error", message });
  };

  const handleError = (error: FailedAPIStatus) => {
    showError(error.errorMessage);
  };

  const onEmailOTPResendSuccess = (res: DeleteAccountOTPResponse) => {
    dispatchOTPAction({ type: "SET_IS_EMAIL_OTP_RESEND", payload: true });

    setAttemptsLeft(res.result.resendEmailOtpBalance);
    setCanSendOTP(false);
  };

  const { mutateAsync: getEmailOTP, isLoading: isOTPResending } =
    useGetDeleteAccountOTP({
      onSuccess: onEmailOTPResendSuccess,
      onError: handleError,
    });

  const onOTPComplete = (otp: string) => {
    dispatchOTPAction({ type: "SET_EMAIL_OTP", payload: otp });
  };

  const closeResentAlert = () => {
    dispatchOTPAction({ type: "SET_IS_EMAIL_OTP_RESEND", payload: false });
  };

  const onOTPResend = async () => {
    closeResentAlert();
    await getEmailOTP({ email });
    setIsFirstOTP(false);
  };

  const onOTPFocus = () => {
    setHelperText("");
  };

  const handleOTPValidation = () => {
    if (!OTPState.emailOTP.length) {
      const errorMessage = ENTER_OTP;
      setHelperText(errorMessage);
      showError(errorMessage);
      return;
    }

    if (OTPState.emailOTP.length !== DEFAULT_OTP_LENGTH) {
      const errorMessage = INVALID_OTP;
      setHelperText(errorMessage);
      showError(errorMessage);
      return;
    }
    onValidateOTP(OTPState.emailOTP);
  };

  return (
    <Box component="form" noValidate>
      <Box className="formGroup">
        <OTPInput
          otpLength={DEFAULT_OTP_LENGTH}
          legend={`${DEFAULT_OTP_LENGTH}-digit code`}
          onComplete={onOTPComplete}
          onFocus={onOTPFocus}
          helpText={helperText ?? ""}
          error={!!helperText}
          showAlert={OTPState.isEmailOTPResend}
          alertMessage={
            isFirstOTP ? "Code sent successfully!" : "Code resent successfully"
          }
          onAlertClose={closeResentAlert}
        />
        <OTPCount
          attemptsLeft={attemptsLeft}
          onResend={onOTPResend}
          resendLabel={
            canSendOTP ? "Resend Code" : `Resend code in ${timeLeft}`
          }
          attemptsLabel="attempts left"
          sx={{ justifyContent: "flex-end", mb: "3rem" }}
          isAttemptEnabled
          disabled={isOTPResending || !canSendOTP}
          loading={isOTPResending}
        />
      </Box>
      <Box sx={{ mt: "3.8rem" }}>
        <Box className="app-l-primary-button-block">
          <Button variant="contained" fullWidth onClick={handleOTPValidation}>
            Verify OTP and Delete Account
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default OTPForm;
