import requests from "config/api";
import API_ENDPOINTS from "constants/api";
import { GetJobOfferLinkPayload, GetReceiptsLinkPayload } from "./types";

export const getGeneralDocs = async () => {
  return requests.post(API_ENDPOINTS.GET_DOCUMENT_LIST);
};

export const getReceiptsList = async () => {
  return requests.get(API_ENDPOINTS.GET_DOCUMENT_RECEIPTS);
};

export const getReceiptUrl = async (payload: GetReceiptsLinkPayload) => {
  return requests.post(API_ENDPOINTS.GET_RECEIPT_URL, payload);
};

export const getJobOffersList = () => {
  return requests.get(API_ENDPOINTS.GET_JOB_OFFERS);
};

export const getJobOfferURL = (payload: GetJobOfferLinkPayload) => {
  return requests.post(API_ENDPOINTS.JOB_OFFER_LINK, payload);
};

export const getJobOfferPDF = (payload: GetJobOfferLinkPayload) => {
  return requests.post(API_ENDPOINTS.DOWNLOAD_JOB_OFFER, payload, {
    responseType: "arraybuffer",
  });
};
