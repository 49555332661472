import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FormHelperText, TextField } from "@mui/material";
import Resources from "assets/json/Resources";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth } from "hooks";
import {
  Controller,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useAddPublicAccessCode } from "services/home";
import { addPublicAccessCodeSchema } from "utils/validationSchema";
import { Paragraph } from "../subComponents";
import { AccessCodeFormData } from "./config";

function AddAccessCode() {
  const {
    control,
    handleSubmit,
    resetField,
    watch,
    formState: { errors },
  } = useForm<AccessCodeFormData>({
    resolver: yupResolver(addPublicAccessCodeSchema),
    defaultValues: { accessCode: "" },
    mode: "onTouched",
  });

  const isAccessCode = watch("accessCode").length > 0;

  const { setGlobalAlert } = useAuth();

  const onAddAccessCodeSuccess = () => {
    setGlobalAlert({
      messageType: "success",
      message:
        Resources.PAGES.PAYMENT_CENTER.ACCESS_CODE.PUBLIC_ACCESS_CODE_SUCCESS,
    });
    resetField("accessCode");
  };

  const handleApiError = (error: FailedAPIStatus) => {
    setGlobalAlert({
      messageType: "error",
      message: error.errorMessage,
    });
  };

  const { mutateAsync: addPublicAccessCode, isLoading } =
    useAddPublicAccessCode({
      onSuccess: onAddAccessCodeSuccess,
      onError: handleApiError,
    });

  const onSubmit: SubmitHandler<FieldValues> = async (formData) => {
    await addPublicAccessCode({ accessCode: formData.accessCode });
  };

  const handleErrors: SubmitErrorHandler<FieldValues> = (error) => {
    const Errormessage = error?.accessCode?.message;
    setGlobalAlert({
      messageType: "error",
      message: Errormessage as string,
    });
  };

  return (
    <Box sx={{ marginTop: "2.5rem" }}>
      <Paragraph
        sx={{ marginBottom: "0.6rem" }}
        text="You can add your access code here"
      />
      <Box
        sx={{
          display: "flex",
          gap: { xs: "1.1rem", sm: "1.5rem" },
          flexDirection: { xs: "column", sm: "row" },
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit, handleErrors)}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <Controller
            control={control}
            name="accessCode"
            render={({ field }) => (
              <TextField
                label="Access code"
                variant="outlined"
                placeholder="Enter your access code here"
                type="text"
                fullWidth
                error={!!errors?.accessCode}
                margin="normal"
                {...field}
              />
            )}
          />
          <FormHelperText error>{errors.accessCode?.message}</FormHelperText>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            marginTop: { sm: "1.6rem", lg: "2rem" },
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading || !isAccessCode}
            fullWidth
            type="submit"
            sx={{ maxWidth: { md: "30rem", xs: "100%" } }}
          >
            Add
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AddAccessCode;
