import { Box, Typography } from "@mui/material";
// import PaymentName from "components/PaymentName";
import Resources from "assets/json/Resources";
import { PAYMENT_METHODS } from "config/app";
import { PaymentType } from "services/useGetPaymentMethods";
import { getCardNameAndImage } from "utils";
import styles from "./styles.module.scss";

type PaymentCardPropType = {
  paymentMethodInfo: PaymentType;
  onCardDelete: Function;
  viewUsage: Function;
};

function PaymentCard(props: PaymentCardPropType) {
  const { paymentMethodInfo, onCardDelete, viewUsage } = props;
  const { nickName, status, paymentMethod } = paymentMethodInfo;
  const { name, imgPath } = getCardNameAndImage(nickName);
  return (
    <Box className={styles.cardWrapper}>
      <Box className={styles.cardDetails}>
        <img src={imgPath} alt={name} className={styles.cardLogo} />
        <Box className={styles.cardInfo}>
          <Typography className={styles.cardName}>{name}</Typography>
          {/* <PaymentName nickName={nickName} /> */}
          <Box className={styles.cardStatus}>
            <Typography className={styles.cardStatusText}>
              {PAYMENT_METHODS[paymentMethod]}
            </Typography>
            <Typography className={styles.cardStatusText}>{status}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={styles.cardControls}>
        <Typography className={styles.usageText}>
          {Resources.PAGES.PAYMENT_CENTER.USAGE}
        </Typography>
        <Box className={styles.action}>
          <Typography
            className={styles.viewUsage}
            onClick={() => viewUsage(paymentMethodInfo)}
          >
            {Resources.PAGES.PAYMENT_CENTER.VIEW_USAGE}
          </Typography>
          <Typography
            className={styles.remove}
            onClick={() => onCardDelete(paymentMethodInfo)}
          >
            {Resources.PAGES.PAYMENT_CENTER.REMOVE_CARD}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentCard;
