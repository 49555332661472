import Resources from "assets/json/Resources";
import { HttpResponseStatus, REACT_QUERY_KEYS } from "config/app";
import { useAPIErrorHandler } from "hooks";
import { useQuery } from "react-query";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { getJobOffersList } from "./docCenterService";
import { JobOfferResponse } from "./types";

type APIResponse = JobOfferResponse & FailedAPIResponse;

const useGetjobOffers = (handler?: HandlerType) => {
  const { checkError, getErrorInfo } = useAPIErrorHandler(Resources.ERRORS);

  const onRejected = (error: FailedAPIResponse) => {
    const { errorMessage } = getErrorInfo(error);
    handler?.onError?.(errorMessage);
  };
  const onFulfilled = (res: APIResponse) => {
    const hasError = checkError(res);
    if (hasError || res?.status !== HttpResponseStatus.OK) {
      onRejected(res);
      return;
    }
    handler?.onSuccess?.();
  };
  return useQuery(REACT_QUERY_KEYS.JOB_OFFER, getJobOffersList, {
    onSuccess: onFulfilled,
    onError: onRejected,
    staleTime: 50000,
  });
};

export default useGetjobOffers;
