import { Box, SxProps, Typography } from "@mui/material";

type FeaturesUsagePropsType = {
  count?: string | number;
  isColorRed?: boolean;
  text: string;
};

const textStyles: SxProps = {
  fontSize: {
    xl: "2.4rem !important",
    lg: "2rem !important",
    xs: "3.4rem !important",
  },
  fontFamily: "var(--font-primary-700)",
};

const numberStyles: SxProps = {
  fontSize: {
    xl: "5rem !important",
    lg: "3.5rem !important",
    xs: "5rem !important",
  },
  fontFamily: "var(--font-primary-800)",
};

function FeatureUsage(props: FeaturesUsagePropsType) {
  const { count, isColorRed, text } = props;
  const isText = typeof count === "string";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box sx={{ flexShrink: 0, minWidth: "6.2rem", marginRight: "1.5rem" }}>
        <Typography
          component="h4"
          sx={{
            ...(isText ? textStyles : numberStyles),
            color: isColorRed ? "var(--danger)" : "var(--color-secondary)",
            margin: "0",
            textAlign: "center",
            lineHeight: "1",
          }}
        >
          {count}
        </Typography>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          minWidth: 0,
        }}
      >
        <Typography
          component="p"
          sx={{
            fontSize: {
              xl: "2rem !important",
              lg: "1.6rem !important",
              xs: "2rem !important",
            },
            fontFamily: "var(--font-primary-700)",
            color: "var(--color-secondary)",
            margin: "0",
            lineHeight: 1,
            maxWidth: isText ? "14rem" : "none",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
}
export default FeatureUsage;
