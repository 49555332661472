/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import SVGIcon from "assets/icons";
import Resources from "assets/json/Resources";
import FTUELayout from "components/FTUELayout";
import { LoadingButton } from "components/common";
import { LOCAL_STORAGE_KEYS, ROUTES } from "config/app";
import { TalentRegistrationStepTypes } from "constants/sharedTypes";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import { Link } from "react-router-dom";
import useGetTC, { TCResponseType } from "services/useGetTnC";
import useRegisterTalent from "services/useRegisterTalent";
import useSendCampaignInfo from "services/useSendCampaignInfo";
import { ageRange } from "pages/AccountProfile/config";
import {
  getCampaignInfo,
  getReferralInfo,
  isValidMobileNumber,
  removeFromLocalStorage,
} from "utils";
import triggerGAEvent, { GA_USER_FLOW } from "utils/gaEvents";
import SignUpForm from "./Form";
import styles from "./styles.module.scss";

const { REGISTER_FORM } = Resources.PAGES.SIGN_UP;

type RegisterProps = {
  onComplete: (nextStep: TalentRegistrationStepTypes) => void;
};

function RegisterForm(props: RegisterProps) {
  const { onComplete } = props;
  const { setUser, setGlobalAlert, setUserId } = useAuth();

  const [termsAndConditions, setTermsAndConditions] = useState<TCResponseType>(
    {} as TCResponseType,
  );
  const [acceptTnC, setAcceptTnC] = useState(false);

  const { mutateAsync: sendCampaignInfo } = useSendCampaignInfo();
  let userAgeGroup = "";

  const saveTermsAndConditions = (tnc: TCResponseType) => {
    setTermsAndConditions(tnc);
  };

  const onRegisterSuccess = async (
    nextStep: TalentRegistrationStepTypes,
    userId: string | undefined,
  ) => {
    // check in session for campaign id and access code
    const campaignInfo = getCampaignInfo();
    if (campaignInfo) {
      await sendCampaignInfo({
        accessCode: campaignInfo.code,
        campaignId: campaignInfo.campaign_id,
      });
    }
    const referralInfo = getReferralInfo();
    if (referralInfo) {
      removeFromLocalStorage(LOCAL_STORAGE_KEYS.REFERRAL_INFO);
    }

    // Trigger GA Event on success -
    // Get user info

    setUserId(userId as string);
    triggerGAEvent("create_account", userId as string, {
      step: GA_USER_FLOW.REGISTER.SUCCESS.SIGNUP,
      pageURL: window.location.href,
      age_selection: userAgeGroup,
      referralCode: referralInfo?.inviteCode ?? "",
    });

    onComplete(nextStep);
  };

  const onRegisterError = (errorMsg: string) => {
    // Trigger GA Event on failure
    triggerGAEvent("error", "", {
      step: GA_USER_FLOW.REGISTER.ERROR.SIGNUP,
      age_selection: userAgeGroup,
    });
    setGlobalAlert({ message: errorMsg, messageType: "error" });
  };

  useGetTC({ onSuccess: saveTermsAndConditions });

  const { mutateAsync: registerTalent, isLoading } = useRegisterTalent({
    onSuccess: onRegisterSuccess,
    onError: onRegisterError,
  });

  const onSubmit = async (formData: any) => {
    const {
      guardianIsdCode,
      guardianIsdCountry,
      guardianPhoneNumber,
      ...restPayload
    } = formData;
    if (formData.ageRange === ageRange.below18) {
      const guardianNumber = `${formData.guardianIsdCode} ${formData.guardianPhoneNumber}`;
      if (!isValidMobileNumber(guardianNumber)) {
        setGlobalAlert({
          messageType: "error",
          message: Resources.ERRORS.GUARDIAN_MOBILE_NUMBER_NOT_MATCHING,
        });
        return;
      }
    }
    const number = `${formData.isdCode} ${formData.phoneNumber}`;
    if (!acceptTnC) {
      setGlobalAlert({
        messageType: "error",
        message: REGISTER_FORM.ERRORS.ACCEPT_TNC,
      });
      return;
    }
    if (!isValidMobileNumber(number)) {
      setGlobalAlert({
        messageType: "error",
        message: Resources.ERRORS.MOBILE_NUMBER_NOT_MATCHING,
      });
      return;
    }

    // check in session for referral info
    const referralInfo = getReferralInfo();
    userAgeGroup = formData.ageRange;
    const response = await registerTalent({
      ...restPayload,
      tncVersion: termsAndConditions.tncVersion,
      isdCode: formData.isdCode,
      ...(referralInfo && { inviteCode: referralInfo.inviteCode }),
      ...(formData.ageRange === ageRange.below18 && {
        guardianIsdCode,
        guardianIsdCountry,
        guardianPhoneNumber,
      }),
    });
    if (!response.appErrors?.exists) {
      setUser({ ...response.result, email: formData.email });
    }
  };

  const onFormError = () => {
    setGlobalAlert({
      messageType: "error",
      message: REGISTER_FORM.ERRORS.INVALID_FORM,
    });
  };

  const handleTnCChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setAcceptTnC(checked);
  };

  return (
    <FTUELayout
      title1={REGISTER_FORM.CREATE_ACCOUNT}
      title2={REGISTER_FORM.SUB_TITLE}
      containerStyle={{ maxWidth: "45rem" }}
    >
      <SignUpForm
        formId="signUp"
        onSubmit={onSubmit}
        onFormError={onFormError}
      />
      <Box>
        <FormControlLabel
          sx={{ ml: 0 }}
          control={<Checkbox checked={acceptTnC} onChange={handleTnCChange} />}
          label={
            <Typography
              sx={{
                color: "var(--colorTextSecondary)",
                ml: "1rem",
                fontFamily: "var(--font-primary-500)",
                fontSize: "1.4rem !important",
              }}
            >
              I accept these&nbsp;
              <Link
                to={ROUTES.PUBLIC.TERMS_AND_CONDITIONS}
                target="_blank"
                style={{
                  fontFamily: "var(--font-primary-700)",
                  textDecoration: "underline",
                  color: "var(--colorTextPrimary)",
                  fontSize: "inherit",
                }}
              >
                {REGISTER_FORM.TNC}
              </Link>
            </Typography>
          }
        />
      </Box>
      <Box className={styles.buttonBlock}>
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          form="signUp"
          label={REGISTER_FORM.BUTTON.SIGNUP}
          loading={isLoading}
        />
      </Box>
      <Box className={styles.loginSection}>
        <SVGIcon name="account" />
        <p className={styles.alreadyAccountText}>
          {REGISTER_FORM.ALREADY_HAVE_ACCOUNT}
          <Link to={ROUTES.PUBLIC.LOGIN}> {REGISTER_FORM.LOG_IN}.</Link>
        </p>
      </Box>
    </FTUELayout>
  );
}

export default RegisterForm;
