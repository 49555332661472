import requests from "config/api";
import API_ENDPOINTS from "constants/api";
import {
  Config,
  DeleteAccountOTPPayload,
  FTUEPayload,
  GeolocationPayload,
  TaxDetailsPayload,
  TimezonesDetailsPayload,
  ValidateEmailOTPType,
} from "./types";

export const getHowCanWeHelp = async () => {
  return requests.get(API_ENDPOINTS.GETHOWCANWEHELP);
};

export const getConfiguration = async (config: Config) => {
  return requests.get(`${API_ENDPOINTS.CAREERPHASEOPTIONS}?type=${config}`);
};

export const getGeoLocation = async (payload: GeolocationPayload) => {
  return requests.post(API_ENDPOINTS.GEO_LOCATION, payload);
};

export const getPaymentMethods = async () => {
  return requests.get(API_ENDPOINTS.PAYMENT_METHODS);
};

export const SingleSignOnRequest = async (token: string) => {
  return requests.post(API_ENDPOINTS.SINGLE_SIGN_ON, { token });
};

export const validateEmailOTP = async (payload: ValidateEmailOTPType) => {
  return requests.post(API_ENDPOINTS.VALIDATE_MAIL_OTP, payload);
};

export const getDeleteAccountOTP = async (payload: DeleteAccountOTPPayload) => {
  return requests.post(API_ENDPOINTS.DELETE_ACCOUNT_OTP, payload);
};

export const deleteAccount = async (payload: ValidateEmailOTPType) => {
  return requests.post(API_ENDPOINTS.DELETE_ACCOUNT, payload);
};

export const updateFTUEStatus = async (payload: FTUEPayload) => {
  return requests.post(API_ENDPOINTS.UPDATE_TUTORIAL, payload);
};

export const getCountryList = async () => {
  return requests.get(API_ENDPOINTS.COUNTRY_LIST);
};

export const getTimeZoneDetails = async (payload: TimezonesDetailsPayload) => {
  return requests.post(API_ENDPOINTS.GET_TIMEZONE_INFO, {
    timeZoneId: payload.timeZoneId,
  });
};

export const getTaxDetails = async (payload: TaxDetailsPayload) => {
  const { reference, ...restPayload } = payload;
  const timeStamp = new Date().getTime();

  return requests.post(API_ENDPOINTS.GET_TAX_DETAILS, {
    ...restPayload,
    reference: `${reference}-${timeStamp}`,
  });
};

export const getCountryLists = async () => {
  return requests.get(API_ENDPOINTS.GET_COUNTRY_LIST);
};
