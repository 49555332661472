import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { getTaxDetails } from "./commonService";
import { TaxDetailsPayload, TaxResponse } from "./types";

type APIResponse = TaxResponse & FailedAPIResponse;

const useGetTaxDetails = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, TaxDetailsPayload>({
    mutationFn: getTaxDetails,
    handler,
    resource: Resources.API_ERRORS.TAX_DETAILS,
    defaultErrorMessage:
      Resources.API_ERRORS.TAX_DETAILS.PWB_TAX_CALCULATION_FAILED,
  });
};

export default useGetTaxDetails;
