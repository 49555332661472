import { Box, FormHelperText, Grid, MenuItem } from "@mui/material";
import InputField, { InputFieldProps } from "components/ui/InputField";
import REGEX from "constants/regex";
import { ChangeEvent, FocusEventHandler, useMemo } from "react";
import { Control, Controller } from "react-hook-form";
import { useGetCountryCode } from "services/common";
import styles from "./styles.module.scss";

export const acceptNumberOnly = (string: string): string =>
  string.replace(REGEX.DIGITS_ONLY, "");

const ISD_MAX_LENGTH = 5;

type ErrorType = {
  isdCode: string | undefined;
  phoneNumber: string | undefined;
};

type PhoneNumberProps = {
  isdName?: string;
  phoneNumberName?: string;
  fieldErrors: ErrorType;
  control: Control<any>;
  onFieldBlur?: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement | undefined
  >;
} & InputFieldProps;

function CountryPhoneBlock(props: PhoneNumberProps) {
  const {
    control,
    disabled,
    fieldErrors,
    onChange,
    onBlur,
    isVerified,
    onFieldBlur,
    isdName,
    phoneNumberName,
    placeholder,
    label,
    ...inputProps
  } = props;

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
  };

  const { data: allCountryList, isLoading: isCountryListLoading } =
    useGetCountryCode();

  const countryList = useMemo(() => {
    const list = allCountryList?.result?.countryList?.map((country) => ({
      label: `${country.isdCode} - ${country.shortName}`,
      value: country.shortName,
    }));
    return list;
  }, [allCountryList]);

  return (
    <Box>
      <Grid className={styles.countryPhoneBlock}>
        <Grid
          className={styles.countryCodeBlock}
          sx={{
            ".MuiInputAdornment-positionStart": {
              marginTop: "0.5rem",
            },
          }}
        >
          <InputField
            select
            required
            {...inputProps}
            inputProps={{
              maxLength: ISD_MAX_LENGTH,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            name={isdName ?? inputProps.name}
            aria-label="country code"
            label="Country code"
            placeholder="Select code"
            onChange={onInputChange}
            error={!!fieldErrors.isdCode}
            onBlur={onFieldBlur ?? onBlur}
            disabled={disabled || isCountryListLoading}
            SelectProps={{
              classes: {
                select: inputProps.value
                  ? styles.selectActive
                  : styles.selectDisabled,
              },
              MenuProps: {
                sx: {
                  maxHeight: 250,
                },
              },
              displayEmpty: true,
            }}
          >
            <MenuItem
              disabled
              sx={{ backgroundColor: "var(--colorWhite)" }}
              value=""
            >
              Select code
            </MenuItem>
            {countryList?.map((country) => (
              <MenuItem
                key={country.label}
                value={country.value}
                sx={{ backgroundColor: "var(--colorWhite)" }}
              >
                {country.label}
              </MenuItem>
            ))}
          </InputField>
        </Grid>
        <Grid className={styles.phoneNumberBlock}>
          <Controller
            control={control}
            name={phoneNumberName ?? "phoneNumber"}
            render={({ field }) => (
              <InputField
                required
                aria-label="Mobile Number"
                label={label ?? "Mobile number"}
                placeholder={placeholder ?? "Enter your mobile number"}
                {...field}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                {...(onFieldBlur && { onBlur: onFieldBlur })}
                onChange={onInputChange}
                isVerified={isVerified}
                error={!!fieldErrors.phoneNumber}
                disabled={disabled}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          "p:empty": {
            display: "none",
          },
        }}
      >
        <FormHelperText error={!!fieldErrors.isdCode}>
          {fieldErrors.isdCode}
        </FormHelperText>
        <FormHelperText error={!!fieldErrors.phoneNumber}>
          {fieldErrors.phoneNumber}
        </FormHelperText>
      </Box>
    </Box>
  );
}
export default CountryPhoneBlock;
