import {
  AmericanExp,
  DefaultCard,
  DinersClub,
  Discover,
  JCB,
  MasterCard,
  UnionPay,
  Visa,
} from "assets/images";
import Resources from "assets/json/Resources";
import {
  FINAL_STATUS,
  INCOMPLETE_SECTIONS,
  LOCAL_STORAGE_KEYS,
  ROUTES,
  SESSION_KEYS,
} from "config/app";
import REGEX from "constants/regex";
import { UserType } from "context/auth/types";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input/mobile";
import { autoRedeemOrEmailVerify } from "services/signUpServices";

export const getLSItem = (key: string): string | null =>
  localStorage.getItem(key);

export const getParsedLSItem = (key: string) => JSON.parse(`${getLSItem(key)}`);

const cardImages: { [key: string]: string } = {
  american: AmericanExp,
  visa: Visa,
  mastercard: MasterCard,
  discover: Discover,
  diners: DinersClub,
  jcb: JCB,
  unionpay: UnionPay,
};

const getCardImage = (cardType: string) => {
  return cardImages[cardType] ?? DefaultCard;
};

export const getCardNameAndImage = (
  nickName: string,
): { name: string; imgPath: string } => {
  const cardNameDetails = nickName.split(" ");
  const cardNameInfo = nickName.split(" ending ");
  const cardShortName = `${cardNameInfo[0]} ENDING IN *${
    cardNameInfo[cardNameInfo.length - 1]
  }`;
  const imgPath = getCardImage(cardNameDetails[0].toLowerCase());
  return { name: cardShortName, imgPath };
};

/**
 *
 * @param dateInString String - date in YYYY-MM-DD format, eg: 2018-05-12
 * @returns String - date in MM DD,YYYY format : May 12, 2018
 */
export const transformDate = (
  dateInString: string,
  options: Intl.DateTimeFormatOptions = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  },
): string => {
  const date = dateInString.split(/\D/).map(Number);

  const dt = new Date(date[0], date[1] - 1, date[2]);
  return dt.toLocaleString("en-US", options);
};

export const formatDate = (date: string) =>
  transformDate(date, { month: "short", day: "numeric", year: "numeric" });

export const compareTwoDates = (
  date1: Date,
  date2: Date,
  type: "greater" | "smaller" | "equal" | "greater&Equal",
) => {
  const date1WithoutTime = new Date(date1.getTime());
  const date2WithoutTime = new Date(date2.getTime());
  date1WithoutTime.setUTCHours(0, 0, 0, 0);
  date2WithoutTime.setUTCHours(0, 0, 0, 0);
  switch (type) {
    case "greater":
      return date1WithoutTime.getTime() > date2WithoutTime.getTime();
    case "smaller":
      return date1WithoutTime.getTime() < date2WithoutTime.getTime();
    case "equal":
      return date1WithoutTime.getTime() === date2WithoutTime.getTime();
    case "greater&Equal":
      return date1WithoutTime.getTime() >= date2WithoutTime.getTime();
    default:
      return { date1: date1WithoutTime, date2: date2WithoutTime };
  }
};

export const getResponseErrorMessage = (response: {
  appErrors: { errors: Array<string> };
  result: {
    timeOut: string;
  };
}) => {
  const { SOMETHING, SESSION_TIME_OUT } = Resources.ERRORS;
  let errorMessage: string | undefined = SOMETHING;
  if (response?.appErrors?.errors?.length > 0) {
    const apiErrorKey: string = response.appErrors.errors[0];
    errorMessage = Resources.ERRORS[apiErrorKey] || SOMETHING;
  }
  if (response?.result?.timeOut === "session") errorMessage = SESSION_TIME_OUT;

  return errorMessage;
};

export const identifyTypeOfPhone = () => {
  const {
    COMMON: {
      LINKS: { PLAY_STORE_LINK, APP_STORE_LINK },
    },
  } = Resources;
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return {
      type: "Windows",
      appStoreLink: "",
    };
  }
  if (/android/i.test(userAgent)) {
    return {
      type: "Android",
      appStoreLink: PLAY_STORE_LINK,
    };
  }
  if (
    ["iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  ) {
    return {
      type: "IOS",
      appStoreLink: APP_STORE_LINK,
    };
  }
  return {
    type: "unknown",
    appStoreLink: "",
  };
};

export const hasCapitalLetters = (text: string) => REGEX.CAPS_LETTER.test(text);

export const hasLowerCase = (text: string) => REGEX.LOWER_CASE.test(text);

export const hasSpecialCharOrNum = (text: string) =>
  REGEX.SPECIAL_CHAR.test(text) || REGEX.DIGITS.test(text);

export const hasNumber = (text: string) => REGEX.DIGITS.test(text);

export const hasMinLength = (text: string, minLength: number) =>
  text?.length >= minLength;

export const hasNoSpace = (text: string) => REGEX.SPACE.test(text);

export const hasSpace = (text: string) => !!text.match(REGEX.SPACE);

export const isOnline = () => navigator.onLine;

export const navigateToIncompleteSession = ({
  userData,
  navigate,
  nextRoute,
  sections,
}: {
  userData: UserType;
  navigate: any;
  nextRoute?: string;
  sections?: { currentSection?: string; incompleteSection: string };
}) => {
  const {
    PUBLIC: { SIGN_UP },
  } = ROUTES;
  let navigtionAllowed = true;
  switch (userData.incompleteSection) {
    // case "ENTER_ACCESSCODE":
    //   if (sections?.currentSection === "ENTER_ACCESSCODE") {
    //     navigtionAllowed = false;
    //   }
    //   if (navigtionAllowed)
    //     navigate(SIGN_UP, {
    //       state: { section: "ACCESS_CODE" },
    //     });
    //   break;
    case "SCHEDULE_COACHING_SESSION":
    case "COACHING_SESSION_CONFIRMED":
    case "ON_COACH_CONFIRMATION":
    case "TALENT_RE_PROPOSED_SLOTS":
    case "COACH_RE_PROPOSED_SLOTS":
      if (sections?.currentSection === "COACHING_SESSION_BLOCKER") {
        navigtionAllowed = false;
      }
      if (navigtionAllowed)
        navigate(SIGN_UP, {
          state: { section: "COACHING_SESSION_BLOCKER" },
        });
      break;
    case "VERIFY_PHONE":
      if (sections?.currentSection === "VERIFY_PHONE") {
        navigtionAllowed = false;
      }
      if (navigtionAllowed)
        navigate(SIGN_UP, {
          state: { section: "VERIFY_PHONE" },
        });
      break;
    case "VERIFY_EMAIL":
      if (sections?.currentSection === "VERIFY_EMAIL") {
        navigtionAllowed = false;
      }
      if (navigtionAllowed)
        navigate(SIGN_UP, {
          state: { section: "VERIFY_EMAIL" },
        });
      break;
    case "PHONE_COOL_DOWN_PERIOD":
      if (sections?.currentSection === "PHONE_COOL_DOWN_PERIOD") {
        navigtionAllowed = false;
      }
      if (navigtionAllowed)
        navigate(SIGN_UP, {
          state: { section: "PHONE_COOL_DOWN_PERIOD" },
        });
      break;
    case "EMAIL_COOL_DOWN_PERIOD":
      if (sections?.currentSection === "EMAIL_COOL_DOWN_PERIOD") {
        navigtionAllowed = false;
      }
      if (navigtionAllowed)
        navigate(SIGN_UP, {
          state: { section: "EMAIL_COOL_DOWN_PERIOD" },
        });
      break;
    // if response is ENTER_ACCESSCODE from login API, then next page
    // to show is determined by the autoRedeem API
    case "ENTER_ACCESSCODE":
      autoRedeemOrEmailVerify().then((response) => {
        if (
          response?.status === 200 &&
          response?.result?.success &&
          response?.result?.incompleteSection
        ) {
          const nextStep = response?.result?.incompleteSection;
          if (nextStep === "VERIFY_EMAIL") {
            navigate(SIGN_UP, {
              state: {
                section: "VERIFY_EMAIL",
              },
            });
          }
          if (
            nextStep === FINAL_STATUS &&
            !response?.result?.ftueVisitedStatus?.GUARDIAN_APPROVAL
          ) {
            navigate(SIGN_UP, {
              state: {
                section: "GUARDIAN_APPROVAL",
              },
            });
          }
          if (
            nextStep === FINAL_STATUS &&
            response?.result?.ftueVisitedStatus?.GUARDIAN_APPROVAL
          ) {
            navigate(ROUTES.PRIVATE.HOME, { replace: true });
          }
        }
      });
      break;
    case "WELCOME":
      if (sections?.currentSection === FINAL_STATUS) {
        navigtionAllowed = false;
      }
      if (navigtionAllowed)
        navigate(SIGN_UP, { state: { section: "WELCOME" } });

      break;
    case "GUARDIAN_APPROVAL":
      navigate(SIGN_UP, {
        state: { section: "GUARDIAN_APPROVAL" },
      });
      break;
    default:
      // Checks whether guardian approval is received
      if (INCOMPLETE_SECTIONS.includes(userData.incompleteSection as string)) {
        if (!userData?.ftueVisitedStatus?.GUARDIAN_APPROVAL) {
          navigate(SIGN_UP, {
            state: { section: "GUARDIAN_APPROVAL" },
          });
        } else if (nextRoute) navigate(nextRoute, { replace: true });
      } else if (nextRoute) navigate(nextRoute, { replace: true });
  }
};

type PasswordValidStatus = {
  upperCase: boolean;
  lowerCase: boolean;
  isNumber: boolean;
  minLength: boolean;
  specialChar: boolean;
  space: boolean;
};

export const handlePasswordChange = (password: string): PasswordValidStatus => {
  let passwordValid = {
    upperCase: false,
    lowerCase: false,
    isNumber: false,
    minLength: false,
    specialChar: false,
    space: false,
  };
  const upperCase = hasCapitalLetters(password);
  const lowerCase = hasLowerCase(password);
  const specialChar = hasSpecialCharOrNum(password);
  const isNumber = hasNumber(password);
  const minLength = hasMinLength(password, 8);
  const space = hasNoSpace(password);
  passwordValid = {
    minLength,
    specialChar,
    upperCase,
    lowerCase,
    space,
    isNumber,
  };
  return passwordValid;
};

export const zipCodeValidation = async (zipCode: any) => {
  const ind = /^[1-9][0-9]{5}$/.test(zipCode);
  const usa = /^\d{5}(-\d{4})?$/.test(zipCode);
  const cad = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(zipCode);
  const mx = /^\d{5}$/.test(zipCode);
  const ec = /^\d{5,6}$/.test(zipCode);

  let formattedZipcode = zipCode;

  if (ind || usa || cad || mx || ec) {
    if (cad && zipCode.length === 6) {
      const formattedString = `${zipCode.substr(0, 3)} ${zipCode.substr(3, 5)}`;
      formattedZipcode = formattedString.toUpperCase();
    }
  }
  return formattedZipcode;
};

export const isValidMobileNumber = (mobileNumber: string) => {
  let valid = true;
  if (
    !isValidPhoneNumber(mobileNumber) ||
    !isPossiblePhoneNumber(mobileNumber)
  ) {
    valid = false;
  }
  return valid;
};

export const setToSessionStorage = (key: string, value: string) =>
  sessionStorage.setItem(key, value);

export const getFromSessionStorage = (key: string) =>
  sessionStorage.getItem(key);

export const removeFromSessionStorage = (key: string) =>
  sessionStorage.removeItem(key);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFromLocalStorage = (key: string): any => {
  //  Handling non string values with JSON.parse
  try {
    return JSON.parse(localStorage.getItem(key) as string);
  } catch {
    return localStorage.getItem(key);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setToLocalStorage = (key: string, value: any) => {
  if (typeof value === "object") {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};

export const removeFromLocalStorage = (key: string) =>
  localStorage.removeItem(key);

export const encryptString = (data: string) => window.btoa(data);
export const decryptString = (string: string) => window.atob(string);

export const getCampaignInfo = (): null | {
  campaign_id: string;
  code: string;
} => {
  try {
    const campaignInfo = getFromSessionStorage(SESSION_KEYS.CAMPAIGN);
    if (campaignInfo) {
      const decryptedInfo = JSON.parse(decryptString(campaignInfo));

      // A valid decrypted info will contain both campaign_id and code
      if (decryptedInfo?.campaign_id && decryptedInfo?.code) {
        return decryptedInfo;
      }
      return null;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const REFERRAL_CODE_LENGTH = 6;

export const getReferralInfo = (): null | {
  inviteCode: string;
} => {
  try {
    const referralInfo = getFromLocalStorage(LOCAL_STORAGE_KEYS.REFERRAL_INFO);
    if (referralInfo) {
      const decryptedInfo = JSON.parse(decryptString(referralInfo));
      // A valid decrypted info will contain inviteCode
      if (decryptedInfo?.inviteCode) {
        return decryptedInfo;
      }
      return null;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const isReferralInfo = (token: string) => {
  if (!token) return false;
  const decryptedInfo = JSON.parse(decryptString(token));
  // A valid decrypted info will contain inviteCode of length 6
  if (
    decryptedInfo?.inviteCode &&
    decryptedInfo?.inviteCode?.length === REFERRAL_CODE_LENGTH
  ) {
    return true;
  }
  return false;
};

export function sleep(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const openURL = (
  url: string | URL,
  target = "_blank",
  features = "noopener,noreferrer",
) => window.open(url, target, features);

export const parseDate = (date: string): string => {
  return new Date(date).toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
};

type AnyFunction = (...args: any[]) => any;

export function debounce<T extends AnyFunction>(func: T, delay: number): T {
  let timeoutId: NodeJS.Timeout;

  // eslint-disable-next-line func-names
  return function (this: any, ...args: Parameters<T>): void {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  } as T;
}

export const monthsToYear = (months: number): number => months / 12;

export const getTokenDetails = () => {
  try {
    const tokenInfo = getFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN_DETAILS);
    return tokenInfo;
  } catch (error) {
    return null;
  }
};

/**
 * To get the print from url
 * @param {string} link
 */
export const printPDF = async (link: string): Promise<void> => {
  const response = await fetch(link);
  const html = await response.text();
  const printWindow = window.open("", "_blank");
  printWindow?.document.write(`<html><body>${html}</body></html>`);
  printWindow?.document.close();
  printWindow?.print();
};

/**
 * To get pdf from Blob
 * @param  {Uint8Array} Blob
 * @param {fileName} string
 */
export const downloadPDF = (pdfData: Uint8Array, fileName: string) => {
  const pdfBlob = new Blob([pdfData], { type: "application/pdf" });
  const url = window.URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = url;
  // eslint-disable-next-line prefer-template
  link.setAttribute("download", fileName + ".pdf");
  document.body.appendChild(link);
  link.click();
};

export const getElement = (selector: string) =>
  document.querySelector(selector);

export const formatCurrency = (
  amount: number,
  fraction = 0,
  currency = "USD",
) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: fraction,
  }).format(amount);
};
