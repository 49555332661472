import { SidebarContext } from "context/sidebar/SidebarProvider";
import { useContext } from "react";

const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebar must be used within a SidebarContext");
  }
  return context;
};

export default useSidebar;
