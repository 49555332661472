import { SxProps, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import { useMemo } from "react";
import { handlePasswordChange as validatePassword } from "utils";

const { REGISTER_FORM } = Resources.PAGES.SIGN_UP;

type PasswordErrorType = {
  password: string;
  isHighlight?: boolean;
  showTextDecoration?: boolean;
};

const errorStyle: SxProps = { fontWeight: "700" };

function PasswordErrorInfo(props: PasswordErrorType) {
  const { password, isHighlight, showTextDecoration } = props;

  const isValidPassword = useMemo(() => {
    return validatePassword(password);
  }, [password]);

  return (
    <Typography
      sx={{
        fontSize: "1.4rem !important",
        color: "var(--colorTextSecondary)",
        fontFamily: "var(--font-primary-500)",
        ".MuiTypography-root": {
          fontSize: "inherit !important",
        },
        fontWeight: "500",
      }}
    >
      {REGISTER_FORM.PASSWORD_ERRORS.PASSWORD_SHOULD}
      <Typography
        component="span"
        sx={{
          ".MuiTypography-root": {
            fontSize: "inherit !important",
          },

          ...errorStyle,
          color:
            isHighlight && !isValidPassword?.minLength
              ? "var(--danger)"
              : "var(--colorTextPrimary)",
          textDecoration:
            isHighlight && isValidPassword?.minLength && showTextDecoration
              ? "line-through"
              : "none",
        }}
      >
        {REGISTER_FORM.PASSWORD_ERRORS.MIN_LENGTH}&nbsp;
      </Typography>
      {REGISTER_FORM.PASSWORD_ERRORS.CHARACTERS_WITH}
      <Typography
        component="span"
        sx={{
          ".MuiTypography-root": {
            fontSize: "inherit !important",
          },

          ...errorStyle,
          color:
            isHighlight && !isValidPassword?.upperCase
              ? "var(--danger)"
              : "var(--colorTextPrimary)",
          textDecoration:
            isHighlight && isValidPassword?.upperCase && showTextDecoration
              ? "line-through"
              : "none",
        }}
      >
        {REGISTER_FORM.PASSWORD_ERRORS.UPPERCASE}
      </Typography>
      {/* {REGISTER_FORM.PASSWORD_ERRORS.LETTER},&nbsp; */}
      <Typography
        component="span"
        sx={{
          ".MuiTypography-root": {
            fontSize: "inherit !important",
          },

          ...errorStyle,
          color:
            isHighlight && !isValidPassword?.lowerCase
              ? "var(--danger)"
              : "var(--colorTextPrimary)",
          textDecoration:
            isHighlight && isValidPassword?.lowerCase && showTextDecoration
              ? "line-through"
              : "none",
        }}
      >
        {REGISTER_FORM.PASSWORD_ERRORS.LOWERCASE}&nbsp;
      </Typography>
      {`${REGISTER_FORM.PASSWORD_ERRORS.AND}`}
      <Typography
        component="span"
        sx={{
          ".MuiTypography-root": {
            fontSize: "inherit !important",
          },

          ...errorStyle,
          color:
            isHighlight && !isValidPassword?.isNumber
              ? "var(--danger)"
              : "var(--colorTextPrimary)",
          textDecoration:
            isHighlight && isValidPassword?.isNumber && showTextDecoration
              ? "line-through"
              : "none",
        }}
      >
        {REGISTER_FORM.PASSWORD_ERRORS.NUMBER}.
      </Typography>
    </Typography>
  );
}

export default PasswordErrorInfo;
