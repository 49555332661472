import { IconButton, InputAdornment } from "@mui/material";
import SVGIcon from "components/icons";
import { forwardRef, useState } from "react";
import InputField, { InputFieldProps } from "../InputField";

export type PasswordInputProps = {
  label: string;
  placeholder: string;
} & InputFieldProps;

type RefType = React.Ref<HTMLDivElement>;

// eslint-disable-next-line react/display-name
const PasswordInput = forwardRef((props: PasswordInputProps, ref: RefType) => {
  const { label, placeholder, disabled, ...inputProps } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword((visible) => !visible);
  };

  return (
    <InputField
      ref={ref}
      margin="none"
      required
      fullWidth
      name="password"
      label={label}
      type={showPassword ? "text" : "password"}
      aria-label="password"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={disabled}
              onClick={handlePasswordVisibility}
              aria-label="Show Password"
            >
              {showPassword ? (
                <SVGIcon name="eye-off" />
              ) : (
                <SVGIcon name="eye" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      autoComplete="current-password"
      placeholder={placeholder}
      disabled={disabled}
      {...inputProps}
    />
  );
});

export default PasswordInput;
