import { REACT_QUERY_KEYS, TimeConfig } from "config/app";
import { useGETRequestHandler } from "hooks";
import { HandlerType } from "services/commonTypes";
import { getPaymentMethods } from "./commonService";
import { PaymentResponse } from "./types";

const useGetPaymentMethods = (handler?: HandlerType<PaymentResponse>) => {
  return useGETRequestHandler<PaymentResponse>({
    queryKey: REACT_QUERY_KEYS.PAYMENT_METHODS,
    queryFn: getPaymentMethods,
    handler,
    queryOptions: {
      staleTime: TimeConfig.TEN_MINUTE,
    },
  });
};

export default useGetPaymentMethods;
