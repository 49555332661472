import Resources from "assets/json/Resources";
import { LOCAL_STORAGE_KEYS, ROUTES } from "config/app";
import { TalentRegistrationStepTypes } from "constants/sharedTypes";
import { userInitial } from "context/auth/authContext";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getParsedLSItem, navigateToIncompleteSession } from "utils";
import useAuth from "./useAuth";

interface CustomizedState {
  section?: TalentRegistrationStepTypes;
}
const useSessionTimeOut = () => {
  const { user, logout, setGlobalAlert, setUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const checker = (response: any) => {
    let userId = "";
    if (response?.userid) {
      userId = response.userid;
    } else if (response?.result?.userid) {
      userId = response.result.userid;
    }
    const userDetails = getParsedLSItem(LOCAL_STORAGE_KEYS.USER_DETAILS);
    let incompleteSection = "";
    if (response.incompleteSection) {
      incompleteSection = response.incompleteSection;
    } else if (response.result.incompleteSection) {
      incompleteSection = response.result.incompleteSection;
    }
    const state = location.state as CustomizedState;

    if (userId && user?.userid && userId !== user?.userid) {
      queryClient.clear();
      setUser({ ...userDetails, navigate: true });
      navigateToIncompleteSession({
        // user data update
        userData: { ...userInitial, ...userDetails, incompleteSection },
        navigate,
        nextRoute: ROUTES.PRIVATE.HOME,
      });
    } else if (location.pathname === ROUTES.PUBLIC.SIGN_UP) {
      navigateToIncompleteSession({
        // user data update
        userData: { ...userInitial, ...userDetails, incompleteSection },
        navigate,
        nextRoute: ROUTES.PRIVATE.HOME,
        sections: { currentSection: state?.section, incompleteSection },
      });
    }
    if (response?.status === 440 && response.result?.timeOut === "session") {
      setGlobalAlert({
        message: Resources.ERRORS.SESSION_TIME_OUT,
        messageType: "error",
      });
      logout();
    }
  };
  return checker;
};

export default useSessionTimeOut;
