/* eslint-disable react/require-default-props */

import { CircularProgress, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { CircularButton } from "components/ui";
import { ChangeEventHandler, useRef } from "react";

type ProfilePictureProps = {
  imageUrl: string;
  alt: string;
  onImageSelect?: (info: { error: boolean; image?: File }) => void;
  height?: number | string;
  width?: number | string;
  borderRadius?: number | string;
  loading?: boolean;
};

export const SUPPORTED_IMAGE_FORMATS = ".png, .jpg, .jpeg, .webp";

function ProfilePicture(props: ProfilePictureProps) {
  const picInputRef = useRef<HTMLDivElement>(null);

  const {
    alt,
    imageUrl,
    height = "14rem",
    width = "10rem",
    borderRadius = "1.8rem",
    onImageSelect,
    loading,
  } = props;

  const handleEditClick = () => picInputRef.current?.click();

  const handlePictureChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    try {
      const image = event.currentTarget.files?.[0];
      onImageSelect?.({ error: false, image });
    } catch (error) {
      onImageSelect?.({ error: true });
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          top: 0,
          left: 0,
          display: "inline-flex",
          boxShadow: "0px 3px 15px #00000029",
          width,
          height,
          borderRadius,
          border: "0.3rem solid #00CCCC",
          background: "#ffffff",
        }}
      >
        <Box
          overflow="hidden"
          borderRadius="inherit"
          height="100%"
          width="100%"
          display="inherit"
        >
          <Avatar
            src={imageUrl}
            alt={alt}
            variant="square"
            sx={{ width: "100%", height: "100%", color: "aqua" }}
          />
          {onImageSelect && (
            <Box sx={{ position: "absolute", bottom: "-1rem", right: "-1rem" }}>
              <CircularButton
                iconName="edit"
                onClick={handleEditClick}
                disabled={loading}
              />
            </Box>
          )}
        </Box>
        {loading && (
          <CircularProgress
            size={20}
            sx={{ position: "absolute", inset: 0, margin: "auto" }}
          />
        )}
      </Box>
      <TextField
        type="file"
        sx={{ display: "none" }}
        inputProps={{ accept: SUPPORTED_IMAGE_FORMATS }}
        inputRef={picInputRef}
        onChange={handlePictureChange}
      />
    </>
  );
}

export default ProfilePicture;
