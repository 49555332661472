import { Box, IconButton, Typography } from "@mui/material";
import SVGIcon from "components/icons";
import Switch from "components/ui/Switch";
import { PlanDetails, SubscriptionOptions } from "constants/sharedTypes";
import { useAuth } from "hooks";
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { debounce } from "utils";
import styles from "../style.module.scss";

const MAX_ITEMS = 99;

type ItemProps = {
  title: string;
  price: number;
  children?: ReactNode;
  onDelete?: () => void;
};

type SubscriptionTileProps = {
  preferredPlan: { monthly: SubscriptionOptions; annual: SubscriptionOptions };
  onChange: (selectedPlanId: SubscriptionOptions) => void;
  title: string;
  selectedPlanId?: string;
  onDelete?: () => void;
  userPlan?: PlanDetails;
};

type CartItemProps = {
  multiple?: boolean;
  count?: number;
  updateCount: (count: number) => void;
};

type DiscountProps = { discount: string };

function DeleteItem(props: Pick<ItemProps, "onDelete">) {
  const { onDelete } = props;
  return (
    <IconButton className={styles.delete} onClick={onDelete}>
      <SVGIcon name="delete" />
    </IconButton>
  );
}

function CartItemTitle(props: Pick<ItemProps, "title">) {
  const { title } = props;

  return <Typography className={styles.itemName}>{title}</Typography>;
}

function CartItemLayout(props: ItemProps) {
  const { price, title, onDelete, children } = props;

  return (
    <Box className={styles.itemBlock}>
      <Box className={styles.itemLeft}>
        <CartItemTitle title={title} />
        {children}
      </Box>
      <Box className={styles.innerItemBlock}>
        <Box className={styles.price}>
          <Typography
            component="h4"
            sx={{
              fontSize: "1.4rem !important",
              fontWeight: "400",
              fontFamily: "var(--font-primary-500)",
              color: "var(--colorTextPrimary)",
            }}
          >
            {`$${price}`}
          </Typography>
        </Box>

        {onDelete && <DeleteItem onDelete={onDelete} />}
      </Box>
    </Box>
  );
}

export function SubscriptionTile(props: SubscriptionTileProps) {
  const { title, preferredPlan, onChange, selectedPlanId, onDelete, userPlan } =
    props;
  const { setGlobalAlert } = useAuth();

  const [selectedPlan, setSelectedPlan] = useState<SubscriptionOptions>(
    {} as SubscriptionOptions,
  );

  // Set selected Plan
  useEffect(() => {
    if (selectedPlanId) {
      const currentPlan = Object.values(preferredPlan)?.find(
        (plan) => plan.subscriptionId === selectedPlanId,
      );
      if (currentPlan) setSelectedPlan(currentPlan);
    } else {
      setSelectedPlan(preferredPlan.monthly);
    }
  }, [selectedPlanId, preferredPlan]);

  useEffect(() => {
    if (Object.keys(selectedPlan).length) onChange(selectedPlan);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan]);

  const handlePlanChange = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const currentPlan = checked ? preferredPlan.annual : preferredPlan.monthly;
    if (currentPlan?.subscriptionId === userPlan?.subscriptionId) {
      setGlobalAlert({
        messageType: "error",
        message: "You have already purchased this subscription",
      });
      return;
    }
    setSelectedPlan(currentPlan);
  };

  return (
    <CartItemLayout
      title={title}
      price={selectedPlan?.fullPrice ?? 0}
      onDelete={onDelete}
    >
      <Box className={styles.itemLeft}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Typography
            component="span"
            sx={{
              fontSize: "1.4rem",
              color: "var(--colorTextPrimary)",
              fontFamily: "var(--font-primary-500),",
            }}
          >
            Monthly
          </Typography>
          <Switch
            className="app-c-switch"
            onChange={handlePlanChange}
            checked={
              selectedPlan.subscriptionId ===
              preferredPlan.annual.subscriptionId
            }
          />
          <Typography
            component="span"
            sx={{
              fontSize: "1.4rem",
              color: "var(--colorTextPrimary)",
              fontFamily: "var(--font-primary-700)",
            }}
          >
            Yearly
          </Typography>
        </Box>
      </Box>
    </CartItemLayout>
  );
}

export function CartItemTile(props: ItemProps & CartItemProps) {
  const { multiple, count = 1, updateCount, ...layoutProps } = props;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce((value: number) => {
      updateCount(value);
    }, 500),
    [updateCount],
  );
  const [sessionCount, setSessionCount] = useState(count);

  useEffect(() => {
    setSessionCount(count);
  }, [count]);

  const handleIncrement = () => {
    if (sessionCount) {
      const nextCount = sessionCount + 1;
      if (nextCount <= MAX_ITEMS) {
        setSessionCount(nextCount);
        debouncedUpdate(nextCount);
      }
    }
  };

  const handleDecrement = () => {
    if (sessionCount) {
      const nextCount = sessionCount - 1;
      if (nextCount > 0) {
        setSessionCount(nextCount);
        debouncedUpdate(nextCount);
      }
    }
  };

  return (
    <CartItemLayout {...layoutProps}>
      {multiple && (
        <Box className={styles.counter}>
          <IconButton className={styles.button} onClick={handleDecrement}>
            <SVGIcon name="remove" />
          </IconButton>
          <Box className={styles.countBox}>{sessionCount}</Box>
          <IconButton className={styles.button} onClick={handleIncrement}>
            <SVGIcon name="add" />
          </IconButton>
        </Box>
      )}
    </CartItemLayout>
  );
}

export function AnnualPlanDiscountInfo(props: DiscountProps) {
  const { discount } = props;
  return (
    <Typography
      component="p"
      sx={{
        fontSize: "1.4rem !important",
        fontFamily: "var(--font-primary-500)",
        color: "var(--colorTextPrimary)",
      }}
    >
      By choosing yearly plan, you will save{" "}
      <Typography
        component="span"
        sx={{
          fontSize: "1.8rem !important",
          fontFamily: "var(--font-primary-700)",
          color: "var(--color-primary)",
        }}
      >
        {discount}
      </Typography>{" "}
      on monthly membership and get additional savings on the add-ons after you
      check out.
    </Typography>
  );
}
