import { REACT_QUERY_KEYS } from "config/app";
import { useQuery } from "react-query";
import { FailedAPIResponse } from "services/commonTypes";
import { YourActivityPayload, YourActivityResponse } from "./types";
import { getYourActivity } from "./homeService";

type APIResponse = YourActivityResponse & FailedAPIResponse;

const useGetYourActivites = (data: YourActivityPayload) => {
  return useQuery<APIResponse, Error>(
    `${REACT_QUERY_KEYS.YOUR_ACTIVITY}-${data?.type ?? "all"}`,
    () => getYourActivity(data),
  );
};
export default useGetYourActivites;
