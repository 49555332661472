import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { getAccessCodeDetails } from "./accessCodeService";
import { AccessCodeDetailsPayload, AccessCodeDetailsResponse } from "./types";

export type AccessCodeDetailsAPIResponse = AccessCodeDetailsResponse &
  FailedAPIResponse;

const useGetAccessCodeDetails = (
  handler?: HandlerType<AccessCodeDetailsAPIResponse>,
) => {
  return useMutationWithHandler<
    AccessCodeDetailsAPIResponse,
    AccessCodeDetailsPayload
  >({
    mutationFn: getAccessCodeDetails,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useGetAccessCodeDetails;
