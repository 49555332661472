import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { updateProfilePic } from "./accountProfileService";
import { UpdatePhotoSuccessResponse } from "./types";

type APIResponse = UpdatePhotoSuccessResponse & FailedAPIResponse;

const useSetProfilePic = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, File>({
    mutationFn: updateProfilePic,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useSetProfilePic;
