import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { verifyEmail } from "./accountProfileService";
import { VerifyEmailPayload, VerifyEmailResponse } from "./types";

type APIResponse = VerifyEmailResponse & FailedAPIResponse;

const useVerifyEmail = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, VerifyEmailPayload>({
    mutationFn: verifyEmail,
    resource: Resources.ERRORS,
    handler,
    successCodes: [200, 428],
  });
};

export default useVerifyEmail;
