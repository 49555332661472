import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Breadcrumb, SectionLoader } from "components/common";
import { Card } from "components/ui";
import SVGIcon from "components/icons";
import { ChangeEvent, ChangeEventHandler, useMemo } from "react";
import { useAuth } from "hooks";
import {
  Controller,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Resources from "assets/json/Resources";
import { useLocation, useNavigate } from "react-router-dom";
import { useUpdateAge } from "services/accountProfileService";
import { ChangeAgeResponse } from "services/accountProfileService/types";
import { ROUTES } from "config/app";
import { useGetCountryCode } from "services/common";
import CountryPhoneBlock from "components/CountryPhoneBlock";
import { isValidMobileNumber } from "utils";
import {
  ChangeAgeFormType,
  ageOptions,
  ageRange,
  breadcrumbs,
  changeAgeSchema,
  FormField,
} from "./config";

type ErrorType = {
  errorMessage: string;
};

const {
  ACCOUNT_PROFILE: { FIELDS, CHANGE_AGE },
  GUARDIAN: { GUARDIAN_MOBILE_PLACEHOLDER, GUARDIAN_MOBILE_LABEL },
} = Resources.PAGES;

function ChangeAge() {
  const { setGlobalAlert, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const ageDetails = location.state;

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm<ChangeAgeFormType>({
    resolver: yupResolver(changeAgeSchema),
    mode: "all",
    defaultValues: {
      ageRange: ageDetails?.age,
      guardianEmail: ageDetails?.guardianEmail,
      isdCode: ageDetails?.guardianIsdCode ?? "",
      isdCountry: ageDetails?.guardianIsdCountry ?? "",
      phoneNumber: ageDetails?.guardianPhoneNumber ?? "",
    },
  });

  const showError = (message: string) => {
    setGlobalAlert({ messageType: "error", message });
  };

  const onUpdateAgeSuccess = async (res: ChangeAgeResponse) => {
    if (res?.result?.success) {
      if (
        ageDetails?.age === ageRange.below18 ||
        getValues("ageRange") === ageRange.below18
      ) {
        setGlobalAlert({
          messageType: "success",
          message: CHANGE_AGE.MAIL_SENT,
        });
        logout();
        return;
      }
      setGlobalAlert({
        messageType: "success",
        message: CHANGE_AGE.AGE_UPDATED,
      });
      navigate(ROUTES.PRIVATE.ACCOUNT_PROFILE);
    }
  };

  const handleAgeError = (error: ErrorType) => {
    const message = error?.errorMessage;
    showError(message as string);
  };

  const { mutateAsync: updateAge } = useUpdateAge({
    onError: handleAgeError,
    onSuccess: onUpdateAgeSuccess,
  });

  const { data: allCountryList } = useGetCountryCode();

  const countryList = useMemo(() => {
    const list = allCountryList?.result?.countryList?.map((country) => ({
      isdCode: country.isdCode,
      countryCode: country.shortName,
    }));
    return list;
  }, [allCountryList]);

  const handleErrors: SubmitErrorHandler<FieldValues> = (FieldErrors) => {
    const errorMsgs = Object.values(FieldErrors).map(
      (formField) => formField?.message,
    );
    showError(errorMsgs[0] as string);
  };

  const watchAgeRange = watch("ageRange");

  const navigateBack = () => navigate(-1);

  const onSubmit: SubmitHandler<FieldValues> = async (formData) => {
    if (formData.ageRange === ageRange.below18) {
      const { isdCode, phoneNumber } = formData;
      const guardianNumber = `${isdCode} ${phoneNumber}`;
      const talentNumber = `${ageDetails?.isdCode} ${ageDetails?.phoneNumber}`;
      if (!isValidMobileNumber(guardianNumber)) {
        setGlobalAlert({
          messageType: "error",
          message: Resources.ERRORS.MOBILE_NUMBER_NOT_MATCHING,
        });
        return;
      }
      if (formData?.guardianEmail === ageDetails?.email) {
        setGlobalAlert({
          messageType: "error",
          message: Resources.ERRORS.PWB_SAME_TALENT_AND_GUARDIAN_EMAIL,
        });
        return;
      }
      if (guardianNumber === talentNumber) {
        setGlobalAlert({
          messageType: "error",
          message: Resources.ERRORS.PWB_GUARDIAN_PHONE_SAME_AS_TALENT,
        });
        return;
      }
    }
    await updateAge({
      ageRange: formData.ageRange,
      ...(formData.ageRange === ageRange.below18 && {
        guardianEmail: formData.guardianEmail,
        guardianIsdCode: formData.isdCode,
        guardianIsdCountry: formData.isdCountry,
        guardianPhoneNumber: formData.phoneNumber,
      }),
    });
  };

  const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    const field = name as FormField;
    if (name === "isdCountry") {
      const country = countryList?.find((code) => code.countryCode === value);
      if (country) {
        setValue("isdCode", country?.isdCode);
        setValue("isdCountry", value);
      }
      trigger("isdCode");
      trigger("isdCountry");
      return;
    }
    setValue(field, value);
    trigger(field);
  };

  const handleAgeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setValue("ageRange", value);
  };

  if (!ageDetails) {
    navigate(ROUTES.PRIVATE.ACCOUNT_PROFILE);
  }

  return (
    <Box className="app-l-container">
      <SectionLoader loading={!ageDetails} />
      {ageDetails && (
        <>
          <Breadcrumb crumbs={breadcrumbs} />
          <Box
            sx={{
              marginTop: "4.8rem",
              flexGrow: "1",
              minHeight: "0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: "1",
              }}
            >
              <Card
                sx={{
                  marginBottom: "3rem",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ flexShrink: 0, marginRight: "1.5rem" }}>
                      <IconButton onClick={navigateBack} sx={{ padding: 0 }}>
                        <SVGIcon name="angle-left" />
                      </IconButton>
                    </Box>
                    <Box>
                      <Typography
                        component="h3"
                        sx={{
                          fontSize: "2rem !important",
                          fontFamily: "var(--font-primary-700)",
                          color: "var(--colorTextPrimary)",
                          margin: "0 0 0",
                          lineHeight: "1",
                        }}
                      >
                        {CHANGE_AGE.TITLE}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      padding: {
                        xs: "3.6rem 0rem 0rem",
                        md: "3.6rem 5rem 9.6rem",
                      },
                    }}
                  >
                    <Controller
                      control={control}
                      name="ageRange"
                      render={({ field }) => (
                        <RadioGroup
                          row
                          {...field}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={handleAgeChange}
                          sx={{
                            columnGap: { xs: "3rem", sm: "6rem" },
                            rowGap: "1.5rem",
                          }}
                        >
                          {ageOptions.map((option) => (
                            <FormControlLabel
                              value={option.value}
                              label={option.label}
                              control={<Radio />}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                    {watchAgeRange === ageRange.below18 && (
                      <Box
                        sx={{
                          maxWidth: "60rem",
                          paddingTop: {
                            lg: "6.6rem",
                            sm: "3.6rem",
                            xs: "2.5rem",
                          },
                        }}
                      >
                        <Typography
                          component="h3"
                          sx={{
                            fontSize: {
                              xs: "1.5rem !important",
                              sm: "1.8rem !important",
                              lg: "2rem !important",
                            },
                            fontWeight: "400",
                            fontFamily: "var(--font-primary-500)",
                            color: "var(--colorTextPrimary)",
                            lineHeight: { xs: 1.4, sm: 1.4, lg: "3rem" },
                            marginBottom: "3.4rem",
                          }}
                        >
                          {CHANGE_AGE.GUARDIAN_DESCRIPTION}
                        </Typography>
                        <Box sx={{ maxWidth: "36.7rem", marginBottom: "3rem" }}>
                          <Controller
                            control={control}
                            name="isdCountry"
                            render={({ field }) => (
                              <CountryPhoneBlock
                                fieldErrors={{
                                  isdCode: errors.isdCode?.message,
                                  phoneNumber: errors.phoneNumber?.message,
                                }}
                                control={control}
                                {...field}
                                onChange={handlePhoneChange}
                                label={GUARDIAN_MOBILE_LABEL}
                                placeholder={GUARDIAN_MOBILE_PLACEHOLDER}
                                disabled={
                                  (ageDetails?.isGuardianApproved &&
                                    ageDetails?.guardianIsdCode &&
                                    ageDetails?.guardianIsdCountry &&
                                    ageDetails?.guardianPhoneNumber) ||
                                  watchAgeRange === ageDetails?.age
                                }
                              />
                            )}
                          />
                        </Box>
                        <Box sx={{ maxWidth: "36.7rem", marginBottom: "3rem" }}>
                          <Controller
                            name="guardianEmail"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled={
                                  ageDetails?.isGuardianApproved ?? false
                                }
                                id="outlined-basic"
                                label={FIELDS.GUARDIAN_EMAIL}
                                required
                                variant="outlined"
                                placeholder={FIELDS.GUARDIAN_EMAIL_PLACEHOLDER}
                                error={!!errors.guardianEmail?.message}
                                helperText={
                                  errors.guardianEmail?.message as string
                                }
                              />
                            )}
                          />
                        </Box>
                        <Box>
                          <Typography
                            component="p"
                            sx={{
                              color: "var(--colorTextPrimary)",
                              fontFamily: "var(--font-primary-500)",
                              lineHeight: "1.4",
                            }}
                          >
                            <Typography
                              component="span"
                              sx={{
                                fontFamily: "var(--font-primary-700)",
                                color: "inherit",
                              }}
                            >
                              {CHANGE_AGE.NOTE}
                            </Typography>
                            {CHANGE_AGE.NOTE_DESCRIPTION}
                          </Typography>
                          <Typography
                            component="p"
                            sx={{
                              color: "var(--colorTextPrimary)",
                              fontFamily: "var(--font-primary-500)",
                              lineHeight: "1.4",
                              marginTop: "1.6rem",
                            }}
                          >
                            {CHANGE_AGE.NOTE_DESCRIPTION1}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    <Box sx={{ maxWidth: "36.6rem", marginTop: "3rem" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={watchAgeRange === ageDetails?.age}
                        fullWidth
                        onClick={handleSubmit(onSubmit, handleErrors)}
                      >
                        {CHANGE_AGE.SAVE}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default ChangeAge;
