import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextField } from "@mui/material";
import Resources from "assets/json/Resources";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth } from "hooks";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useAddPublicAccessCode } from "services/home";
import { addPublicAccessCodeSchema } from "utils/validationSchema";
import { AddPublicAccessCodeFormType } from "../config";
import styles from "./styles.module.scss";

interface AddAccessCodeFormProps {
  refetchAccessCodeList: () => void;
}

function AddAccessCodeForm(props: AddAccessCodeFormProps): JSX.Element {
  const { refetchAccessCodeList } = props;
  const { BUTTON, LABEL, PLACEHOLDER } = Resources.PAGES.ACCESS_CODE.FORM;
  const { setGlobalAlert } = useAuth();
  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors, isValid },
  } = useForm<AddPublicAccessCodeFormType>({
    resolver: yupResolver(addPublicAccessCodeSchema),
    defaultValues: { accessCode: "" },
    mode: "onChange",
  });

  const onAddAccessCodeSuccess = () => {
    refetchAccessCodeList();
    setGlobalAlert({
      messageType: "success",
      message:
        Resources.PAGES.PAYMENT_CENTER.ACCESS_CODE.PUBLIC_ACCESS_CODE_SUCCESS,
    });
    resetField("accessCode");
  };

  const handleApiError = (error: FailedAPIStatus) => {
    setGlobalAlert({
      messageType: "error",
      message: error.errorMessage,
    });
  };

  const { mutateAsync: addPublicAccessCode } = useAddPublicAccessCode({
    onSuccess: onAddAccessCodeSuccess,
    onError: handleApiError,
  });

  const onSubmit: SubmitHandler<FieldValues> = async (formData) => {
    await addPublicAccessCode({ accessCode: formData.accessCode });
  };

  return (
    <Box
      component="form"
      id="addPublicAccessCodeForm"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      className={styles.subBlock}
      sx={{ flex: "1" }}
    >
      <Controller
        name="accessCode"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors?.accessCode}
            helperText={errors.accessCode?.message}
            label={LABEL}
            placeholder={PLACEHOLDER}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            className={styles.inputAccessCode}
          />
        )}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        className={styles.addButton}
        disabled={!isValid}
      >
        {BUTTON}
      </Button>
    </Box>
  );
}

export default AddAccessCodeForm;
