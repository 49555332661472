import { Box, CircularProgress, Fade } from "@mui/material";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

import CheckoutLayout from "components/CheckoutLayout";
import { PREFERRED, REACT_QUERY_KEYS, ROUTES } from "config/app";
import {
  AddOnKeys,
  FailedAPIStatus,
  SubscriptionOptions,
  Subscriptions,
} from "constants/sharedTypes";
import { useAuth } from "hooks";
import { CartItem } from "pages/PurchaseCenter/types";
import { useIsMutating, useQueryClient } from "react-query";
import { Navigate, useNavigate } from "react-router-dom";
import { useGetTaxDetails } from "services/common";
import { PaymentType } from "services/common/types";
import useGetPaymentMethods from "services/common/useGetPayments";
import {
  useAddCartItem,
  useCheckout,
  useGetCartItems,
  useGetPlanInfo,
  useRemoveCartItem,
} from "services/featureCenter";
import {
  CheckoutSuccessResponse,
  ViewCartResponse,
} from "services/featureCenter/types";
import triggerGAEvent, { buildPurchasedAnalyticsData } from "utils/gaEvents";
import Payments from "./Payments";
import { addOnConfig } from "./config";
import {
  AnnualPlanDiscountInfo,
  CartItemTile,
  SubscriptionTile,
} from "./subComponents";

const breadcrumbs = [
  {
    text: " Feature Center",
    active: true,
    pageLink: ROUTES.PRIVATE.PURCHASE_CENTER,
  },
  { text: "Checkout" },
];

function Checkout() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setGlobalAlert, getUserId } = useAuth();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isNewPaymentAdding, setIsNewPaymentAdding] = useState(false);
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [activeCreditCards, setActiveCreditCards] = useState<PaymentType[]>([]);
  const [paymentMethodId, setPaymentMethodId] = useState("ADD_CARD");

  const userId = getUserId();

  useLayoutEffect(() => {
    // clear cart cache on page load
    queryClient.invalidateQueries(REACT_QUERY_KEYS.CART);
    queryClient.invalidateQueries(REACT_QUERY_KEYS.PAYMENT_METHODS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // clear cache on page leave
    return () => {
      queryClient.invalidateQueries(REACT_QUERY_KEYS.CART);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isGettingZip =
    useIsMutating({
      mutationKey: REACT_QUERY_KEYS.MUTATION.geoLocation,
    }) > 0;

  const showError = (message: string) => {
    setGlobalAlert({ messageType: "error", message });
  };

  const handleBack = () => navigate(-1);

  const { data: paymentMethods, isLoading: isPaymentsLoading } =
    useGetPaymentMethods();

  useEffect(() => {
    if (!paymentMethods?.result?.paymentDetails?.payments?.length) return;

    const allPayments = paymentMethods?.result?.paymentDetails?.payments;
    const activePayments = allPayments.filter(
      (payment) =>
        payment.status === "Active" && payment.paymentMethod === "CC",
    );
    if (activePayments?.length) {
      setActiveCreditCards(activePayments);
      setPaymentMethodId(activePayments?.[0]?.paymentMethodId);
    }
  }, [paymentMethods]);

  const setCartItemsData = (response: ViewCartResponse | undefined) => {
    if (response?.result?.success) {
      setCartItems(response?.result?.userCart?.cartItems);
    }
    setIsPageLoading(false);
  };

  const handleAPIFailure = (error: FailedAPIStatus) => {
    showError(error.errorMessage);
  };

  useGetCartItems({ onSuccess: setCartItemsData });

  const { mutateAsync: deleteCartItem } = useRemoveCartItem({
    onError: handleAPIFailure,
  });

  const { mutateAsync: addItemToCart, isLoading: isAddingCartItem } =
    useAddCartItem({
      onError: handleAPIFailure,
    });

  const { data: planData } = useGetPlanInfo();

  const { mutateAsync: getTaxDetails, isLoading: isGettingTax } =
    useGetTaxDetails({ onError: handleAPIFailure });

  const currentPlan = useMemo(
    () => planData?.result?.membershipInfo?.current,
    [planData],
  );

  const availableCoachingSessionCount = useMemo(() => {
    const coachingSessionIndex = cartItems?.find(
      (item) => item?.feature === "COACHING_SESSION",
    );
    return coachingSessionIndex ? coachingSessionIndex.count : 1;
  }, [cartItems]);

  const removeItemFromCart = async (key: AddOnKeys | string) => {
    const response = await deleteCartItem({ feature: key });
    if (response.result.success) {
      const filteredCart = cartItems?.filter((item) => item.feature !== key);
      setCartItems(filteredCart);
    }
  };

  const cartItemCount = useMemo(() => {
    return cartItems?.reduce((acc, item) => acc + item.count, 0) ?? 0;
  }, [cartItems]);

  const updateCoachingSessions = async (sessionCount: number) => {
    await addItemToCart({ feature: "COACHING_SESSION", count: sessionCount });

    const updatedCartItems = cartItems?.map((item) => {
      if (item.feature === "COACHING_SESSION") {
        return {
          ...item,
          count: sessionCount,
        };
      }
      return item;
    });

    setCartItems(updatedCartItems);
  };

  const handlePaymentSelect = (paymentId: string) => {
    setPaymentMethodId(paymentId);
  };

  const basicPlan = useMemo<Subscriptions | undefined>(() => {
    return planData?.result?.subscriptionList?.find(
      (plan) => plan.memberLevel === PREFERRED.id,
    );
  }, [planData]);

  // group plans by id
  const planIdMap = useMemo(() => {
    const id: Record<string, SubscriptionOptions> = {};
    basicPlan?.options?.forEach((option) => {
      id[option.subscriptionId] = option;
    });
    return id;
  }, [basicPlan?.options]);

  // Group plans
  const plans = useMemo(() => {
    const obj: Record<"annual" | "monthly", SubscriptionOptions> = {
      annual: {} as SubscriptionOptions,
      monthly: {} as SubscriptionOptions,
    };
    basicPlan?.options?.forEach((plan) => {
      if (plan.durationMonths === 1) {
        obj.monthly = plan;
      } else if (plan.durationMonths === 12) {
        obj.annual = plan;
      }
    });

    return obj;
  }, [basicPlan?.options]);

  const preferredPlanInCart = useMemo(() => {
    if (basicPlan?.options) {
      const planIds = Object.values(basicPlan?.options).map(
        (plan) => plan.subscriptionId,
      );
      const preferredPlan = cartItems.find((item) =>
        planIds.includes(item.feature),
      );
      return preferredPlan;
    }
    return null;
  }, [cartItems, basicPlan]);

  const totalAmount = useMemo(() => {
    const addOnPrice =
      cartItems?.reduce(
        (acc, item) => acc + (item.price ?? 0) * item.count,
        0,
      ) ?? 0;
    return (
      addOnPrice +
      (planIdMap[preferredPlanInCart?.feature as string]?.fullPrice ?? 0)
    );
  }, [cartItems, preferredPlanInCart, planIdMap]);

  const addOns = useMemo(() => {
    return cartItems?.filter((cart) => cart.price) ?? [];
  }, [cartItems]);

  const subscription = useMemo(() => {
    return cartItems?.filter((cart) => !cart.price) ?? [];
  }, [cartItems]);

  const onPlanChange = async (plan: SubscriptionOptions) => {
    // restrict user to purchase current subscription again
    if (currentPlan?.subscriptionId === plan.subscriptionId) {
      return;
    }

    // check whether subscription already in cart
    if (plan.subscriptionId === preferredPlanInCart?.feature) return;

    const response = await addItemToCart({ feature: plan.subscriptionId });
    if (!response?.result?.success) return;

    const cartItem: CartItem = {
      count: 1,
      feature: plan.subscriptionId,
    };
    // clear old ppf plan from cart if exists
    if (preferredPlanInCart) {
      const updatedCart = cartItems.filter(
        (item) => item.feature !== preferredPlanInCart.feature,
      );
      setCartItems([...updatedCart, cartItem]);
    } else {
      setCartItems((c) => [...c, cartItem]);
    }
  };

  const onPaymentSuccess = (res?: CheckoutSuccessResponse) => {
    // trigger GA event on success transaction
    if (res?.result?.isSuccess && res?.result?.transactionId) {
      const purchaseInfo = buildPurchasedAnalyticsData({
        transactionId: res?.result?.transactionId,
        addOns,
        ...(preferredPlanInCart?.feature && {
          plan:
            preferredPlanInCart?.feature === plans.annual.subscriptionId
              ? plans.annual
              : plans.monthly,
        }),
      });
      triggerGAEvent("purchase", userId as string, purchaseInfo);
    }

    queryClient.invalidateQueries(REACT_QUERY_KEYS.MEMBERSHIP_PLANS);
    queryClient.invalidateQueries(REACT_QUERY_KEYS.BUY_UP_FEATURES);
    queryClient.invalidateQueries(REACT_QUERY_KEYS.FEATURES_COUNT);
    queryClient.invalidateQueries(REACT_QUERY_KEYS.CART);
  };

  const onPaymentError = (error: FailedAPIStatus) => {
    queryClient.invalidateQueries(REACT_QUERY_KEYS.PAYMENT_METHODS);
    showError(error.errorMessage);
  };

  const { mutateAsync: checkoutCart, isLoading: isPaymentProcessing } =
    useCheckout({
      onSuccess: onPaymentSuccess,
      onError: onPaymentError,
    });

  const proceedToPayment = useCallback(
    async (paymentId: string) => {
      // check cart is empty
      if (!addOns.length && !preferredPlanInCart) {
        showError("Cart is empty");
        return;
      }

      if (!paymentId || paymentId === "ADD_CARD") return;

      // compute tax and then proceed to payment

      const response = await getTaxDetails({
        paymentMethodId: paymentId,
        amount: totalAmount,
        reference: "TALENT_PURCHASE",
      });

      if (!response?.result?.success) {
        setGlobalAlert({
          message: "Unable to get Tax details",
          messageType: "error",
        });
        return;
      }

      const buyUpFeatures = addOns?.map(({ feature, ...item }) => ({
        ...item,
        price: item.price as number,
        featureKey: feature,
      }));

      const checkoutRes = await checkoutCart({
        amountWithTax: response?.result?.taxDetails?.amount_total,
        isCartCheckout: true,
        paymentMethodId: paymentId,
        isTaxCodeApplicable: response?.result?.taxDetails?.isTaxCodeApplicable,
        ...(response?.result?.taxDetails?.isTaxCodeApplicable && {
          taxCode: response?.result?.taxDetails?.id,
        }),
        subscriptionId: preferredPlanInCart?.feature ?? "",
        buyUpFeatures,
        taxRate:
          response?.result?.taxDetails?.tax_breakdown?.[0]?.tax_rate_details
            ?.percentage_decimal,
      });

      if (checkoutRes?.result?.isSuccess) {
        navigate(ROUTES.PRIVATE.PAYMENT_SUCCESS, {
          state: { amount: response?.result?.taxDetails?.amount_total },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addOns, preferredPlanInCart, totalAmount],
  );

  const onNewPaymentAdd = (status: boolean) => {
    setIsNewPaymentAdding(status);
  };

  if (isPageLoading) {
    return (
      <Box sx={{ display: "grid", placeItems: "center", flexGrow: 1 }}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  if (!isPageLoading && !cartItems.length) {
    return <Navigate to={ROUTES.PRIVATE.PURCHASE_CENTER} replace />;
  }

  return (
    <CheckoutLayout
      breadCrumbs={breadcrumbs}
      primaryButtonLabel="Proceed To Payment"
      onConfirm={() => proceedToPayment(paymentMethodId)}
      {...(paymentMethodId === "ADD_CARD"
        ? {
            primaryButtonProps: {
              type: "submit",
              form: "addCredit-card",
              disabled: isGettingZip || isGettingTax,
            },
          }
        : { onConfirm: () => proceedToPayment(paymentMethodId) })}
      loading={isPaymentProcessing || isNewPaymentAdding || isAddingCartItem}
    >
      <CheckoutLayout.CheckoutHeader
        title="Proceed To Checkout"
        cartItemCount={cartItemCount}
        onButtonClick={handleBack}
      />
      <Box>
        {subscription[0]?.feature ? (
          <CheckoutLayout.Title title="Subscriptions" sx={{ mb: "1.5rem" }} />
        ) : null}
        <CheckoutLayout.ContentContainer>
          <Box>
            {preferredPlanInCart ? (
              <CheckoutLayout.ItemContainer
                sx={{ marginBottom: "1rem !important" }}
              >
                <SubscriptionTile
                  title="Preferred"
                  onChange={onPlanChange}
                  preferredPlan={plans}
                  selectedPlanId={preferredPlanInCart?.feature}
                  onDelete={() =>
                    removeItemFromCart(preferredPlanInCart.feature)
                  }
                  userPlan={currentPlan}
                />
              </CheckoutLayout.ItemContainer>
            ) : null}
            <Fade
              in={preferredPlanInCart?.feature === plans.monthly.subscriptionId}
              unmountOnExit
            >
              <Box>
                <AnnualPlanDiscountInfo discount="20%" />
              </Box>
            </Fade>
          </Box>
          {addOns?.length ? (
            <CheckoutLayout.Title
              title="Add-ons"
              sx={{ mt: "3.2rem", mb: "1.5rem" }}
            />
          ) : null}
          <CheckoutLayout.ItemContainer>
            {addOns?.map((item) => (
              <CartItemTile
                key={item.feature}
                title={addOnConfig[item?.feature]}
                price={
                  item.feature === "COACHING_SESSION"
                    ? (item?.price as number) * availableCoachingSessionCount
                    : (item?.price as number)
                }
                onDelete={() => removeItemFromCart(item?.feature)}
                multiple={item.feature === "COACHING_SESSION"}
                count={availableCoachingSessionCount}
                updateCount={updateCoachingSessions}
              />
            ))}
          </CheckoutLayout.ItemContainer>

          <CheckoutLayout.Price
            label="Sub Total (Excluding Tax):"
            amount={+totalAmount}
          />

          <CheckoutLayout.Title
            title="(Applicable tax will be added to the final payment)"
            sx={{
              fontSize: "1.4rem",
              textAlign: "right",
              mt: "0.5rem",
              fontFamily: "var(--font-primary-400)",
            }}
          />
        </CheckoutLayout.ContentContainer>

        <CheckoutLayout.Title
          title="Select Existing Payment Method"
          sx={{ mb: "1.5rem" }}
        />

        <Payments
          disabled={isPaymentProcessing}
          creditCards={activeCreditCards}
          onPaymentSelect={handlePaymentSelect}
          selectedPaymentId={paymentMethodId}
          loading={isPaymentsLoading}
          onCardSave={proceedToPayment}
          onPaymentProcessing={onNewPaymentAdd}
          stripeKey={
            paymentMethods?.result.paymentDetails.paymentCenter.stripe
              .pApiKey as string
          }
        />
      </Box>
    </CheckoutLayout>
  );
}

export default Checkout;
