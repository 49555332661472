import Resources from "assets/json/Resources";
import { SVGIconType } from "components/icons";
import {
  SubscriptionPlanType,
  TableHeaderType,
  TalentRegistrationStepTypes,
} from "constants/sharedTypes";

const ENVIRONMENT = `REACT_APP_API_${process.env.REACT_APP_ENVIRONMENT}`; // set while scripts are added
export const API_BASE_URL = `${process.env[ENVIRONMENT]}/`;

const {
  COMMON: {
    LINKS: { PLAY_STORE_LINK, APP_STORE_LINK, CONTACT_LINK },
  },
} = Resources;

export const ROUTES = {
  PUBLIC: {
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot-password",
    SIGN_UP: "/signup",
    TERMS_AND_CONDITIONS: "/terms-and-conditions",
    SIGN_UP_WITH_CAMPAIGN: "/signup/:campaignInfo",
    GUARDIAN: "/guardian/:token",
    GUARDIAN_SHORT: "/g/:token",
    GUARDIAN_SUCCESS: "/guardian/success",
    SINGLE_SIGN_ON: "/login/:token",
    EMAIL_VERIFICATION: "/email-verification",
    LIMIT_REACHED: "/limit",
    DELETE_ACCOUNT_TALENT: "/delete-account",
    CHOOSE_VERIFICATION: "/choose-verification",
    MOBILE_VERIFICATION: "/mobile-verification",
    FORGOT_VERIFY_PHONE: "/forget_verify",
  },
  PRIVATE: {
    ADD_PAYMENT: "/add-payment",
    DASHBOARD: "/dashboard",
    PURCHASE_CENTER: "/feature-center",
    PAYMENT_CENTER: "/payment-center",
    DOCUMENT_CENTER: "/document-center",
    RECEIPTS: "/receipts",
    ACCOUNT_PROFILE: "/account-profile",
    UPDATE_EMAIL: "/update-email",
    UPDATE_PASSWORD: "/update-password",
    UPDATE_PHONE: "/update-phone",
    DEACTIVATE_ACCOUNT: "/deactivate-account",
    DELETE_PAYMENT: "/payment-center/delete-card",
    ON_BEHALF: "/login-on-behalf",
    ACCESS_CODE: "/access-code",
    HOME: "/home",
    CHECKOUT: "/checkout",
    PAYMENT_USAGE: "/payment-usage/:paymentId",
    ACCESS_CODE_ACTIVATION: "/access-code-activate",
    ACCESS_CODE_DETAILS: "/access-code-details/:code",
    DOWNGRADE_SUBSCRIPTION: "/downgrade-subscription",
    PURCHASE_SUBSCRIPTION: "/purchase-subscription",
    PAYMENT_SUCCESS: "/payment-success",
    CHANGE_AGE: "/change-age",
    COOL_DOWN: "/cool-down",
    DELETE_ACCOUNT: "/delete-my-account",
    VERIFY_PHONE: "/verify-phone",
    VERIFY_EMAIL: "/verify-email",
    YOUR_ACTIVITY: "/your-activity",
  },
};

export const LOCAL_STORAGE_KEYS = {
  USER_DETAILS: "userDetails",
  REFERRAL_INFO: "referralInfo",
  TOKEN_DETAILS: "tokenDetils",
};

export const PAGE_KEYS = {
  CHOOSE_VERIFICATION: "choose-verification",
  FORGOT_PASSWORD_PHONE: "forgot-password-phone",
  FORGOT_PASSWORD_EMAIL: "forgot-password-email",
  RESET_PASSWORD: "reset-password",
  VERIFY_PASSWORD: "verify-password",
  GUARDIAN: "/guardian",
};

export const TABLE_HEADERS: {
  PAYMENT_CENTER: {
    CREDIT_CARD: TableHeaderType[];
    ACCESS_CODE: TableHeaderType[];
    USAGE_SUMMARY: TableHeaderType[];
  };
} = {
  PAYMENT_CENTER: {
    CREDIT_CARD: [
      { key: "method", label: "Method", align: "left" },
      { key: "type", label: "Type", align: "left" },
      { key: "status", label: "Status", align: "left" },
      { key: "usage", label: "Usage", align: "left" },
      { key: "remove", label: "Remove", align: "right" },
    ],
    ACCESS_CODE: [
      { key: "name", label: "Name", align: "left" },
      { key: "type", label: "Type", align: "left" },
      { key: "status", label: "Status", align: "left" },
      { key: "exp-date", label: "Expiration date", align: "left" },
    ],
    USAGE_SUMMARY: [
      { key: "usageType", label: "Usage Type", align: "left", width: "25%" },
      { key: "name", label: "Name", align: "left", width: "25%" },
      { key: "cycle", label: "Purchase Date", align: "left", width: "25%" },
      { key: "amount", label: "Billable Amount", align: "right" },
    ],
  },
};

export const ACCESS_CODE_STATUS = {
  ACTIVE: "Active",
  EXPIRED: "Expired",
  INACTIVE: "Inactive",
};

export const REACT_QUERY_KEYS = {
  USER_PROFILE_DETAILS: "userProfileDetails",
  PAYMENT_METHODS: "payment-methods",
  ACCESS_CODES: "access-codes",
  MEMBERSHIP_PLANS: "membershipPlans",
  BUY_UP_FEATURES: "buyUpFeatures",
  TERMS_AND_CONDITIONS: "termsAndConditions",
  USER_HOME_DETAILS: "userHomeDetails",
  RECEIPTS: "receipts",
  FEATURES_COUNT: "featureCount",
  ACCOUNT_PROFILE: "accountProfile",
  CONFIG: "config",
  CART: "cart",
  COUNTRY_LIST: "country-list",
  JOB_OFFER: "joboffers",
  YOUR_ACTIVITY: "your-activity",
  MUTATION: {
    geoLocation: "GeoLocation",
  },
};

export const TimeConfig = {
  ONE_HOUR: 360_0000,
  ONE_MINUTE: 60_000,
  TEN_MINUTE: 600_000,
};

export enum HttpResponseStatus {
  OK = 200,
  SESSION_OUT = 440,
}

export const PAYMENT_METHODS: { [key: string]: string } = {
  CC: "Card",
  ACH: "ACH",
  ACCESS_CODE: "Access Code",
};

export const FREE: SubscriptionPlanType = {
  id: "1",
  title: "FREE",
  description: [
    "As a free member, you get to set up your profile and get viewed by employers. If you want to learn more about other services and levels of membership, reach out to us on chat.",
  ],
};

export const PREFERRED: SubscriptionPlanType = {
  id: "2",
  title: "PREFERRED",
  description: [
    "- View & act on your match catalog for your today's match",
    "- Initiate interest in interviewing with an employer from your job matches",
    "- Get competitive positioning for job opportunities",
    "- Manage your interest with AML",
  ],
  subPlan: {
    month: { durationInMonths: 1 },
    year: { durationInMonths: 12 },
  },
};

export const SUBSCRIPTION_PLAN_MAP: { [x: string]: SubscriptionPlanType } = {
  "1": FREE,
  "2": PREFERRED,
};

export const coachingSessionMandatoryItems = [
  "SCHEDULE_COACHING_SESSION",
  "COACHING_SESSION_CONFIRMED",
  "ON_COACH_CONFIRMATION",
  "TALENT_RE_PROPOSED_SLOTS",
  "COACH_RE_PROPOSED_SLOTS",
];

export const INCOMPLETE_SECTIONS = [
  ...coachingSessionMandatoryItems,
  "VERIFY_PHONE",
  "ENTER_ACCESSCODE",
  "VERIFY_EMAIL",
  "WELCOME",
  "GUARDIAN_APPROVAL",
  "PHOTO",
  "AREAS_OF_INTEREST",
  "JOB",
  "SKILL",
  "PHONE_COOL_DOWN_PERIOD",
  "EMAIL_COOL_DOWN_PERIOD",
];

export type Menu =
  | "account"
  | "appStore"
  | "playStore"
  | "featureCenter"
  | "documentCenter"
  | "onBehalf"
  | "paymentCenter"
  | "contact"
  | "help"
  | "logout"
  | "accessCode"
  | "home";

export type SubmenuType = {
  key: Menu;
  icon: SVGIconType;
  name: string;
  link: string;
  submenu?: SubmenuType[];
  externalLink?: boolean;
  warning?: boolean;
  selector?: string;
};

type MainMenu = {
  label: string;
  key?: string;
};

export type SidebarList = {
  mainMenu: MainMenu;
  subMenu: SubmenuType[];
};

export const APP_LINKS: SubmenuType[] = [
  {
    key: "appStore",
    icon: "appStore",
    name: "",
    link: APP_STORE_LINK,
    externalLink: true,
  },
  {
    key: "playStore",
    icon: "googlePlay",
    name: "",
    link: PLAY_STORE_LINK,
    externalLink: true,
  },
];

export const MY_APPS: SidebarList[] = [
  {
    mainMenu: { label: "My Apps" },
    subMenu: APP_LINKS,
  },
];

export const SIDEBAR_TOP_MENUS: SidebarList[] = [
  {
    mainMenu: {
      label: "My People Hub",
      key: "peoplehubMenu",
    },
    subMenu: [
      {
        key: "home",
        icon: "homeicon",
        name: "Home",
        link: ROUTES.PRIVATE.HOME,
        selector: '[data-tutorial-item="home"]',
      },
      {
        key: "account",
        icon: "profile",
        name: "Account Settings",
        link: ROUTES.PRIVATE.ACCOUNT_PROFILE,
        selector: '[data-tutorial-item="account"]',
      },
    ],
  },
  {
    mainMenu: {
      label: "My Experiences",
      key: "appExperience",
    },
    subMenu: APP_LINKS,
  },
  {
    mainMenu: {
      label: "My Services",
      key: "services",
    },
    subMenu: [
      {
        key: "featureCenter",
        icon: "feature-center",
        name: "Feature Center",
        link: ROUTES.PRIVATE.PURCHASE_CENTER,
        selector: '[data-tutorial-item="featureCenter"]',
      },
      {
        key: "documentCenter",
        icon: "document-center",
        name: "Document Center",
        link: ROUTES.PRIVATE.DOCUMENT_CENTER,
        selector: '[data-tutorial-item="documentCenter"]',
      },
      {
        key: "onBehalf",
        icon: "on_behalf",
        name: "Login On Behalf",
        link: ROUTES.PRIVATE.ON_BEHALF,
      },
    ],
  },
  {
    mainMenu: {
      label: "My Payment",
      key: "payment",
    },
    subMenu: [
      {
        key: "paymentCenter",
        icon: "payment-center",
        name: "Payment Center",
        link: ROUTES.PRIVATE.PAYMENT_CENTER,
        selector: '[data-tutorial-item="paymentCenter"]',
      },
      {
        key: "accessCode",
        icon: "accessCode",
        name: "Access Code",
        link: ROUTES.PRIVATE.ACCESS_CODE,
        selector: '[data-tutorial-item="accessCode"]',
      },
    ],
  },
  {
    mainMenu: {
      label: "My support",
      key: "support",
    },
    subMenu: [
      {
        key: "contact",
        name: "Contact us",
        icon: "chat",
        link: CONTACT_LINK,
        externalLink: true,
        selector: '[data-tutorial-item="contact"]',
      },
      {
        key: "help",
        name: "Help",
        icon: "support",
        link: CONTACT_LINK,
        externalLink: true,
        selector: '[data-tutorial-item="help"]',
      },
      {
        key: "logout",
        icon: "logout",
        name: "Logout",
        link: "",
        selector: '[data-tutorial-item="logout"]',
      },
    ],
  },
];

export const APP_ENVIRONMENT: { [key: string]: string } = {
  PRODUCTION: "PRODUCTION",
  QA: "QA",
  STAGE: "STAGE",
  DEV: "DEV",
  LOCAL: "LOCAL",
};

export const SESSION_KEYS = {
  CAMPAIGN: "campaign",
  INCOMPLETE_POPUP: "uninterrupted_popup_visibility",
};

export const FINAL_STATUS: TalentRegistrationStepTypes = "WELCOME";

export const DEFAULT_OTP_LENGTH = 6;

export const ALLOWED_COUNTRIES = [
  "CA",
  "IN",
  "US",
  "MX",
  "EC",
  "AE",
  "DE",
  "PL",
  "NL",
];

export const POPUP_VISIBILITY = { HIDDEN: "hidden", VISIBLE: "visible" };

export const OTP_WAITING_TIME = 15;

export const OTP_COUNT = 5;

export const HINT_MESSAGE: Record<string, any> = {
  PWB_CHECK_IF_THE_MOBILE_NUMBER_IS_INCORRECT:
    "Please check if the mobile number is correct",
  PWB_TALENT_RESEND_COUNT_CHECK_SPAM:
    "Please check the spam/junk folder. Sometimes the message may end up in these folders.",
  PWB_TALENT_RESEND_COUNT_CHECK_QUARANTINE:
    "If this is a company email, please check your quarantine folder or contact your administrator.",
  PWB_TALENT_RESEND_COUNT_CLICK_CONTACT_SUPPORT:
    "Click on Contact Support and use the chat on our website for more assistance.",
};

export const INTERVIEW_TYPE: Record<string, string> = {
  DIGITAL_CONFERENCE_ZOOM: "Digital Conference",
  DIGITAL_CONFERENCE_LINK: "Digital Conference",
  PHONE_CALL: "Phone Call",
  ONSITE: "In-Person Onsite Location",
  MATCH_EVENT: "At Match Event",
};

export const INTERVIEW_TYPE_BUTTON: Record<string, string> = {
  DIGITAL_CONFERENCE_ZOOM: "Join Zoom",
  DIGITAL_CONFERENCE_LINK: "Digital Conference Link",
  MATCH_EVENT: "Event Link",
};

export const INTERVIEW: Record<string, string> = {
  "Interview One Scheduled": "Interview 1",
  "Interview Two Scheduled": "Interview 2",
  "Interview Level One Rescheduled": "Interview 1",
  "Interview Level Two Rescheduled": "Interview 2",
};

export const SESSION_TYPE: Record<string, string> = {
  COACH: "Coach",
  MENTOR: "Mentor",
  ADVISOR: "Advisor",
};

export const TAX_EXCLUDED_COUNTRIES = [""];
