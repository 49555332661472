import { REACT_QUERY_KEYS, TimeConfig } from "config/app";
import { useGETRequestHandler } from "hooks";
import { HandlerType } from "services/commonTypes";
import { getMemberShipPlan } from "./featureCenterService";
import { PlanResponse } from "./types";

const useGetPlanInfo = (handler?: HandlerType<PlanResponse>) => {
  return useGETRequestHandler<PlanResponse>({
    queryKey: REACT_QUERY_KEYS.MEMBERSHIP_PLANS,
    queryFn: getMemberShipPlan,
    handler,
    queryOptions: {
      staleTime: TimeConfig.ONE_MINUTE,
      refetchOnWindowFocus: "always",
    },
  });
};

export default useGetPlanInfo;
