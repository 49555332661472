import Resources from "assets/json/Resources";
import {
  coachingSessionMandatoryItems,
  REACT_QUERY_KEYS,
  ROUTES,
} from "config/app";
import useAuth from "hooks/useAuth";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { redeemAccessCode } from "./PaymentCenterService";

type RedeemAccessCodeType = {
  onSuccess: (msg: string) => void;
  onError: (errorMsg: string) => void;
  onSuccessNavigate?: () => void;
};

type ErrorResponseType = {
  status: number;
  appErrors: {
    exists: boolean;
    errors: string[];
  };
};

type FutureDateError = {
  status: number;
  result: {
    isSuccess: boolean;
    accessCodeEffectiveFrom: string;
    error: string;
  };
};

type Error = ErrorResponseType & FutureDateError;

export type SuccessResponseType = {
  result: {
    baseProfileStatus: string;
    incompleteSection: string;
    isEligibleForLoginAsTalent: boolean;
    isFirsttimeProfileComplete: boolean;
    success: boolean;
    userid: string;
  };
};

const useRedeemAccessCode = (handlers: RedeemAccessCodeType) => {
  const { setUser, user } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onRedeemError = (error: Error) => {
    const errorCode = error?.appErrors?.errors[0] ?? error.result?.error;
    const futureDate = error?.result?.accessCodeEffectiveFrom ?? "";
    let errorMsg =
      Resources.ERRORS[errorCode] ?? Resources.ERRORS.PWB_INVALID_ACCESS_CODE;
    errorMsg = errorMsg.replace("{date}", futureDate);
    handlers.onError(errorMsg);
  };

  const onRedeemSuccess = (data: SuccessResponseType & Error) => {
    if (data?.appErrors?.exists || data?.result?.isSuccess === false) {
      onRedeemError(data);
      return;
    }

    setUser({
      ...user,
      email: user?.email as string,
      incompleteSection: data?.result?.incompleteSection as string,
      userid: data?.result?.userid ?? "",
    });

    queryClient.invalidateQueries(REACT_QUERY_KEYS.ACCESS_CODES);

    queryClient.invalidateQueries(REACT_QUERY_KEYS.FEATURES_COUNT);
    queryClient.invalidateQueries(REACT_QUERY_KEYS.BUY_UP_FEATURES);

    if (
      coachingSessionMandatoryItems.some(
        (x) => x === data.result.incompleteSection,
      )
    )
      navigate(ROUTES.PUBLIC.SIGN_UP, {
        replace: true,
        state: { section: "COACHING_SESSION_BLOCKER" },
      });
    else {
      handlers?.onSuccessNavigate?.();
      handlers.onSuccess(data?.result?.incompleteSection);
    }
  };

  return useMutation(redeemAccessCode, {
    onSuccess: onRedeemSuccess,
    onError: onRedeemError,
  });
};

export default useRedeemAccessCode;
