import { IconButton, TableRow } from "@mui/material";
import Resources from "assets/json/Resources";
import { Table, TableCell } from "components/common";
import SVGIcon from "components/icons";
import { TableHeaderType } from "constants/sharedTypes";
import { useAuth } from "hooks";
import { useMemo } from "react";
import { JobOfferType } from "services/docCenter/types";
import useGetJobOfferDownload from "services/docCenter/useGetJobOfferDownload";
import useGetJobOfferLink from "services/docCenter/useGetJobOfferLink";
import { downloadPDF, openURL, parseDate, printPDF } from "utils";

type JobOfferProps = {
  jobOffers: JobOfferType[];
  loading: boolean;
};

const TABLE_HEADER: TableHeaderType[] = [
  { key: "jobName", label: "Job Name", align: "left", width: "30%" },
  {
    key: "purchaseDate",
    label: "Job Accepted Date",
    align: "left",
    width: "20%",
  },
  { key: "Download ", label: "Download ", align: "center", width: "20%" },
  { key: "view", label: "View", align: "center", width: "15%" },
  { key: "Print", label: "Print", align: "center", width: "15%" },
];

function JobOffer(props: JobOfferProps) {
  const { jobOffers, loading } = props;
  const { setGlobalAlert } = useAuth();

  const { mutateAsync: getJobOfferLink, isLoading: isLinkLoading } =
    useGetJobOfferLink({});
  const { mutateAsync: downloadJobOffer, isLoading: isDownloading } =
    useGetJobOfferDownload({});

  const handlePrintJobOffer = async (jobOffer: JobOfferType) => {
    const { accountId, jobId, offerId, userId } = jobOffer;
    const res = await getJobOfferLink({
      accountId,
      jobId,
      offerId,
      talentUserId: userId,
    });
    printPDF(res?.result?.jobOfferUrl);
  };

  const handleDownloadJobOffer = async (jobOffer: JobOfferType) => {
    const { accountId, jobId, offerId, userId, jobTitle } = jobOffer;
    const res = await downloadJobOffer({
      accountId,
      jobId,
      offerId,
      talentUserId: userId,
    });
    if (res) {
      downloadPDF(res, jobTitle);
    }
  };

  const handleJobOfferView = async (jobOffer: JobOfferType) => {
    const { accountId, jobId, offerId, userId } = jobOffer;

    setGlobalAlert({
      messageType: "info",
      message: Resources.PAGES.DOCUMENT_CENTER.POP_UP_INFO_JOB,
    });
    const res = await getJobOfferLink({
      accountId,
      jobId,
      offerId,
      talentUserId: userId,
    });
    if (res?.result?.jobOfferUrl) {
      openURL(res.result.jobOfferUrl);
    }
  };

  const tableRows = useMemo(() => {
    return jobOffers.map((jobOffer) => (
      <TableRow key={jobOffer.jobId}>
        <TableCell align="left">
          <TableCell.Text text={jobOffer.jobTitle} />
        </TableCell>
        <TableCell align="left">
          <TableCell.Text text={parseDate(jobOffer.offerAcceptedDate)} />
        </TableCell>
        <TableCell align="center">
          <IconButton
            sx={{ padding: "0" }}
            disabled={isDownloading}
            onClick={() => handleDownloadJobOffer(jobOffer)}
          >
            <SVGIcon name="download" />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton
            disabled={isLinkLoading}
            onClick={() => handleJobOfferView(jobOffer)}
            sx={{ padding: "0" }}
          >
            <SVGIcon name="eye" />
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton
            sx={{ padding: "0" }}
            disabled={isLinkLoading}
            onClick={() => handlePrintJobOffer(jobOffer)}
          >
            <SVGIcon name="print" />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobOffers]);
  return (
    <Table
      headerData={TABLE_HEADER}
      noDataMessage="No job offers are available"
      loading={loading}
      tableContainerProps={{ sx: { maxHeight: "80vh" } }}
    >
      {tableRows}
    </Table>
  );
}
export default JobOffer;
