module.exports = {
  PAGES: {
    LOGIN: {
      CREATE_ACCOUNT: "Create account",
      NOT_REGISTERED: "Haven't registered? ",
      FORGOT_PASSWORD: "Forgot password?",
      RESET_PASSWORD: "Reset Password",
      LOGIN_TITLE: "Helping people evolve with work",
      LOGIN: "Log In",
      SIGN_UP: "Sign Up",
      TITLE: "pepelwerk account",
      TITLE_QUOTE: "Login to your",
      HELP: "Need help?",
      CONTACT_SUPPORT: "Contact Support",
    },
    FORGOT_PASSWORD: {
      EMAIL_LABEL: "Enter email",
      RESET: "Reset your password",
      CODE_LABEL: "6-digit code",
      PASSWORD_REQUIREMENT:
        "Minimum of 8 characters, one number, one CAPS character, one lower case character",
      CODE_SEND: "Code Sent",
      RESEND_CODE: "Resend Code",
      RESEND_CODE_AGAIN: "Resend code again",
      FORGOT_PASSWORD_TITLE: "Reset Password",
      FORGOT_PASSWORD_SUBTEXT:
        "To reset your password, enter the email address you used to set up your pepelwerk account.",
      FORGOT_PASSWORD_SUBTEXT_PHONE:
        "To reset your password, enter the mobile number you used to set up your pepelwerk account.",
      RESET_PASSWORD_SUBTEXT_EMAIL:
        "Enter the 6 digit code that you received on your email.",
      RESET_PASSWORD_SUBTEXT_PHONE:
        "Enter the 6 digit code that you received on your mobile number.",
      TO_RESET: " To reset your password, enter the email address you ",
      USED_TO_SET: " used to set up your pepelwerk account.",
      CODE_SENT: "Code has been sent sucessfully.",
      RESET_SUCCESSFUL: "Password reset successfully",
      ENTER: "Enter",
      CODE: "6 digit code",
      EMAIL: "Email",
      PHONE_NUMBER: "Mobile number",
      OTP_VERIFIED: "6-digit code verified.",
      INVALID_OTP:
        "Please enter the 6-digit code which you received to your email.",
      EMAIL_VERIFICATION: "Email verification",
      PHONE_NUMBER_VERIFICATION: "Mobile verification",
      CHOOSE_THE_TYPE: "Choose the type of verification",
    },
    GUARDIAN: {
      TITLE: "The guardian consent",
      TITLE_QUOTE: "not yet",
      TITLE_QUOTE1: "received",
      SUB_TEXT:
        "If you want to change the guardian email or resend the consent mail",
      MAIL_SENT: "Consent mail was resent successfully!",
      WE_ARE_WAITING:
        " We are waiting for your guardian’s consent for you to use our app.",
      WAITING_FOR_GUARDIAN: " We are waiting for your Guardian’s",
      CONSENT_TO: "consent to use our app",
      AFTER_WE_RECEIVE:
        " After we receive it you will get a notification or text.",
      CHANGE_EMAIL:
        "If you want to change the guardian email or mobile number or resend the consent, you can do so below.",
      GUARDIAN_MOBILE_PLACEHOLDER: "Enter the guardian mobile number.",
      GUARDIAN_MOBILE_LABEL: "Guardian mobile number",
      GUARDIAN_EMAIL_PLACEHOLDER: "Enter guardian email",
    },
    HOME: {
      ACCESS_CODE_CANNOT_DEACTIVATE:
        "Sorry! You cannot deactivate an activated access code.",
      ACCESS_CODE_CANNOT_ACTIVATE:
        "Sorry! You cannot activate this access code, yet.",
      ACCESS_CODE_CONFIRMATION_DESC:
        "Once you activate this access code, you won’t be able to activate another access code until the expiry of this access code or until you have utilized all of the features provided in this access code",
    },
    PAYMENT_CENTER: {
      USAGE_TYPE: "Usage Type",
      NAME: "Name",
      COUNT: "Count",
      PURCHASE_DATE: "Purchase Date",
      BILLABLE_AMOUNT: "Billable Amount (Excluding Tax)",
      USAGE: "Usage",
      TITLE: "PAYMENT CENTER",
      SUB_TITLE:
        "Add any credit/debit card details and manage which card is to be active",
      DETAILS: "Payment details:",
      ADD_PAYMENT: "Add Payment Details",
      ADD_CREDIT_CARD_BUTTON: "Add Payment",
      ADD_ACCESS_CODE_BUTTON: "Add Access Code",
      CONFIRM_DEL_PAYMENT: "Confirm Payment Deletion",
      DELETE_PAYMENT_DESC:
        "You are deleting the following payment below, are you sure you want to do this?",
      CONFIRM: "Confirm",
      CANCEL: "Cancel",
      DELETE_SUCCESS: "Payment method removed successfully",
      VERIFICATION_SUCCESS: "Successfully verified",
      ADD_NEW_PAYMENT: "Add new payment method",
      ADD_NEW_PAYMENT_METHOD: "Add  Payment Method",
      SELECT_PAY_METHOD: "Select a payment method from below",
      VERIFICATION_PROGRESS: "Verification in progress",
      MONEY_DEDUCTION:
        "$1 will be debited from your account for validation purpose.",
      USAGE_TITLE: "Usage",
      VIEW_USAGE: "View Usage",
      REMOVE_CARD: "Remove",
      VALID_TILL: "Valid till",
      TOTAL_AMOUNT_BILLED: "Total billed",
      NO_USAGE_SUMMARY: "No data available",
      ADD_CREDIT_CARD: {
        ADD_NEW_CC_TITLE: "Add new card",
        VERIFICATION_FAILED_DESC:
          "We were not able to verify the card you have provided. Try another one.",
        PWB_DUPLICATE_CARD: "You cannot add the same card more than once.",
        ENTER_CVV_NUMBER: "Please enter CVV",
        ENTER_VALID_CVV: "Please enter valid CVV",
        ENTER_CC_NUMBER: "Please enter your card number",
        ENTER_VALID_CC_NUMBER: "Please enter a valid card number",
        ENTER_NAME_OF_CARD_HOLDER: "Please enter name of card holder",
        ENTER_BILLING_SHIP_CODE: "Please enter billing zip code",
        ENTER_EXPIRY_MONTH: "Please enter expiry month",
        ENTER_EXPIRY_YEAR: "Please enter expiry year",
        ENTER_VALID_ZIP_CODE: "Please enter valid zip code",
        INVALID_EXP_YEAR_FORMAT: "Please enter expiry year in YY format",
        ENTER_VALID_CARD_HOLDER: "Please enter valid name of card holder",
        INVALID_EXP_MONTH_FORMAT: "Please enter expiry month in MM format",
        ENTER_VALID_EXPIRY_MONTH: "Please enter valid expiry month",
        ENTER_VALID_EXPIRY_YEAR: "Please enter valid expiry year",
        SELECT_COUNTRY: "Select country",
      },
      ADD_ACH: {
        TITLE: "Add New ACH",
      },
      CARD_TYPES: {
        MASTER: "Mastercard",
        VISA: "Visa",
        AMEX: "American Express",
        DISCOVER: "Discover",
        JCB: "JCB",
        DINERS_CLUB: "Diners Club",
        UNION_PAY: "UnionPay",
      },
      ACCESS_CODE: {
        TITLE: "Add New Access Code",
        ADD_SUCCESS: "Access code has been successfully activated!",
        PUBLIC_ACCESS_CODE_SUCCESS: "Access code added successfully",
      },
    },
    ACCESS_CODE: {
      ACTIVE_ACCESS_CODE_TITLE: "Active access code",
      NO_ACTIVE_ACCESS_CODES: "No active access code",
      AVAILABLE_ACCESS_CODE: "Available Access Codes",
      AC_TOOLTIP: {
        ACTIVE: "You cannot deactivate an activated access code.",
        AVAILABLE_TO_USE:
          "You can activate another access code only after utilizing all the features of the current access code.",
        REQUESTED: "Awaiting approval from the access code provider.",
      },
      CANNOT_DEACTIVATE_AC:
        "Sorry! You cannot deactivate an activated access code.",
      CANNOT_ACTIVATE_AC: "Sorry! You cannot activate this access code, yet.",
      ADD_MORE_FEATURES: "Want to add more features?",
      BUY_MORE_FEATURES: "Buy More Features",
      GET_BACK_TO_JOURNEY: "Want to get back to your journey?",
      CONTINUE_JOURNEY: "Continue your journey here",
      NO_FEATURES: "No features available",

      ACTIVATE_AC_CONFIRMATION_DIALOG:
        "Once you activate this access code, you won’t be able to activate another access code until the expiry of this access code or until you have utilized all of the features provided in this access code.",
      CARD_LABEL: {
        SPONSOR: "Sponsor",
        ASSIGNED_DATE: "Assigned date",
        ACTIVATION_DATE: "Activation date",
        EXPIRY_DATE: "Expiry date",
      },
      NO_DATA: {
        TITLE: "Access Codes",
        THIS_IS_WHERE:
          "This is where you can manage all your access codes available for you!",
        IF_YOU_HAVE:
          "If you have any access codes available, enter them on the top and add them to the list.",
        IF_YOU_DONT_HAVE:
          "If you don’t have anything available, then don’t worry, you can add features and get back to your journey with pepelwerk",
        WHAT_DO_YOU_DO: "What do you do next?",
      },
      FORM: {
        PLACEHOLDER: "Enter your access code here",
        LABEL: "Access code",
        BUTTON: "Add",
      },
    },
    PURCHASE_CENTER: {
      DOWNGRADE_SUCCESS:
        "You will be downgraded to free plan after the expiration of subscription plan",
      TITLE: "SUBSCRIPTION",
      CURRENT_SUBSCRIPTION: " Current subscription",
      TOTAL: "Total",
      CONFIRM_PAYMENT: "Payment Confirmation",
      AMOUNT_SUMMARY: "You will be charged with a payment of",
      FOR_FOLLOWING: "for the following:",
      PAYMENT_METHOD: "Payment method",
      DOWNGRADE_SUBSCRIPTION: "Downgrade Subscription",
      DOWNGRADE_MESSAGE:
        "Are you sure want to downgrade your subscription to free?",
      SAVE_UP_TO: "By choosing annual plan, you will save",
      REVISED_TOTAL:
        "on monthly membership and get additional savings on the buy-up features after you check out. Your revised total will be",
      SELECT_PAY_METHOD: "Select a payment method",
      CHOOSE_PAY_METHOD: "Please select a payment method to proceed",
      PAYMENT_SUCCESS: "Payment was successful",
      PWB_PAYMENT_ERROR: "Payment has failed. Please try again.",
      PWB_INVALID_SUBSCRIPTION_PLAN: "The plan has already been purchased",
      PWB_INVALID_PAYMENT_METHOD:
        "Payment method is invalid. Please try another payment method",
      PLANS: {
        COST: "COST",
        CURRENT_PLAN: "Current Active Plan",
        FREE: "FREE",
        PREFERRED: "Preferred",
        EXPIRY: "Expires on:",
        EXIST_ACTIVE_PLAN:
          "You have an active preferred yearly plan valid till ",
        LATE_ACTIVATE_INFO:
          "The current plan you are purchasing will be active only after that.",
        SAVE_AMOUNT: "Save 20%",
        UNABLE_TO_PURCHASE: "You have already purchased this subscription",
      },
      BUTTONS: {
        CHECKOUT: "Proceed to Checkout",
        DOWNGRADE: "Downgrade Plan",
        CHANGE_SUBSCRIPTION: "Change Subscription",
        UPGRADE: "Upgrade Subscription",
        SAVE_AND_UPGRADE: "Save 20% and upgrade to Annual",
        BUY_MONTHLY: "Proceed with monthly plan",
        PROCEED: "Proceed",
        YES: "Yes",
        NO: "No",
      },
      CAMPAIGN_MODAL: {
        REDEEM: {
          TITLE: "Welcome to pepelwerk",
          SUB_TEXT: "If you have an access code, enter here",
          ENTER_CODE: "Enter your access code here",
          ACCESS_CODE: "Access code",
          ADD_FEATURES: "If not, visit Feature Center to add features",
          BUILD_TALENT_PROFILE:
            "or go directly to the app to start building your Talent profile.",
          ACTIVATE: "Activate",
        },
        SUCCESS: {
          ACTIVATE_SUCCESS: "Your access code has been activated successfully!",
          VISIT_PURCHASE_CENTER:
            "Visit Feature Center to add features to your profile",
          BUILD_TALENT_PROFILE:
            "Go directly to the app to start building your Talent profile",
          PURCHASE_CENTER: "Feature Center",
          OR: "OR",
        },
      },
    },
    DOCUMENT_CENTER: {
      DOCUMENT_CENTER: "Document Center",
      GENERAL_DOCUMENTS: "General Documents",
      PURCHASE_DATE: "Purchase Date",
      No_DATA: "No Data Available",
      POP_UP_INFO:
        "In case you are not able to see the receipt, make sure you have turned off the popup blockers that you might have.",
      POP_UP_INFO_JOB:
        "In case you are not able to see the job offer, make sure you have turned off the popup blockers that you might have.",
    },
    VERIFY_PHONE: {
      TITLE: "Verify Current Mobile Number",
      SUB_TITLE:
        "Verify your registered mobile number before changing any given details.",
      VERIFY_OTP_SUCCESS: "Mobile number verified successfully",
      CODE_SENT_SUCCESSFULLY: "Code sent successfully!",
      CODE_RESENT_SUCCESSFULLY: "Code resent successfully!",
    },
    SIGN_UP: {
      REGISTER_FORM: {
        TITLE: "Create Account",
        I_ACCEPT: "I Accept These",
        TNC: "Terms & Conditions",
        ALREADY_HAVE_ACCOUNT: "Already have an account?",
        LOG_IN: "Log In",
        PEPEL_ACCOUNT: "pepel account ",
        FOR_PEOPLE: "for people who are looking ",
        LIFE_GOALS: " to accomplish their work-life goals",
        CREATE_ACCOUNT: "Create your",
        SUB_TITLE: "People Hub account",
        GUARDIAN_DESC:
          "We will be sending the information to the provided email and mobile number to get your guardian's consent.",
        ERRORS: {
          ACCEPT_TNC: "Please accept our Terms & Conditions",
          INVALID_FORM: "Please fill all fields",
        },
        FIELDS: {
          F_NAME: "First name",
          L_NAME: "Last name",
          MAIL: "E-mail",
          AGE: "Age",
          GUARDIAN_MAIL: "Guardian email",
          GUARDIAN_MOBILE: "Guardian mobile number",
          COUNTRY: "Country code",
          PHONE: "Phone number",
          PASSWORD: "Password",
          STATE: "State",
          CITY: "City",
          ZIP_CODE: "Zip code",
        },
        PASSWORD_ERRORS: {
          PASSWORD_SHOULD: "Your new password should be a minimum of ",
          MIN_LENGTH: "8 alphanumeric characters",
          CHARACTERS_WITH: "with a minimum of ",
          UPPERCASE: "one uppercase letter, ",
          SPECIAL_CHAR: "one number or symbol",
          LOWERCASE: "one lowercase letter",
          LETTER: "letter",
          AND: "and ",
          NUMBER: "one number",
        },
        BUTTON: {
          SIGNUP: "Sign Up",
        },
      },
      PHONE_NUMBER_VERIFICATION: {
        TITLE: "Mobile Number",
        SUB_TITLE: "Verification",
        SUB_HEADING:
          "To complete your sign up process, verify your mobile number and enter the 6 - digit code that you will receive.",
        OTP_MESSAGE:
          "An SMS text message has been sent to validate your phone number. Please enter your received 6-digit code in the space below:",
        RESEND_CODE: "Resend code",
        FIELDS: {
          COUNTRY_CODE: "Country code",
          MOBILE_NUMBER: "Mobile number",
        },
        ERRORS: {
          CC_REQUIRED: "Please enter your phone country code",
          PHONE_REQUIRED: "Please enter valid phone number",
        },
      },
      ACCESS_CODE: {
        INVALID_ACCESS_CODE_MESSAGE:
          "Looks like you don't have an access code or your access code has expired. If you don't have an access code, you can click",
        ACCESS_CODE_NOTE: "Note: Access code is case sensitive",
        BUTTON: {
          ENTER: "Enter",
        },
        ERRORS: {
          INVALID_FORM: "Please fill all fields",
        },
      },
      VERIFY_EMAIL: {
        DESCRIPTION:
          "We have sent you an Email. Check it and enter the 6-digit code that we've included. Check your SPAM folder if you don't see it in your Inbox and mark it as Not Spam.",
        // "To complete your sign-up process, check your email to retrieve the 6-digit code.",
        NOTE: "NOTE: Check your SPAM folder if you don't see it",
        HAVING_TROUBLE: "Having Trouble? ",
        CONTACT_US: "Contact Us",
        HELP_TEXT: " for help",
        VERIFY_EMAIL_TEXT: "Verify Email",
        RESEND_CODE: "Resend Code",
        DSEC_WE_HAVE_SENT:
          "We have sent you an email. Check it and enter the 6- ",
        DESC_DIGIT_CODE: "digit code that we've included. Check your ",
        DESC_SPAM: "SPAM ",
        DESC_IF_YOU: "folder if you don't see it in your Inbox and mark it as ",
        DESC_NOTSPAM: "Not Spam. ",
        SIX_DIGIT_CODE: "6-Digit Code",
        SKIP_EMAIL_VERIFICATION: "To skip the Email Verification ",
        CLICK_HERE: "click here.",
      },
      COACHING_SESSION_BLOCKER: {
        MANDATORY: "Mandatory",
        COACHING: "Coaching",
        ACCESS_CODE:
          "Your access code requires you to complete a coaching session with one of pepelwerk’s coaches first.",
        GO_TO_MOBILE:
          "Go to your mobile application to schedule one right away",
      },
      GUARDIAN_CONSENT: {
        DESC: "Consent form ",
        GUARDIAN: "Guardian",
        CONSENT_DESC:
          "Someone who is younger than 18 years of age would like to use the pepelwerk app and gave us your email so that they could have permission from their guardian to use the app.",
        CONSENT_DESC_1:
          "Please submit the information below if you give them permission.",
        THANKS: "Thanks",
        SUCCESS_MSG: "You have successfully submitted the consent form.",
        CONSENT_DESC_2: "I give consent to",
        CONSENT_DESC_3: "for using the application",
        GUARDIAN_INFO_1:
          "wants to change their age range and would like to use the ",
        PEPELWERK_CAREER_ASSISTANT: "pepelwerk's Career Assistant",
        GUARDIAN_INFO_2:
          ". They have given your details as their guardian. This means that we need your permission for {talentName} to continue accessing career service tools and features.",
        APP_SUCCESS:
          "Our application is a tool to help them achieve career success.",
        INFO_SUBMIT:
          "Please submit the information below to give them permission.",
        FIELDS: {
          TALENT_MAIL: "Talent email ID",
          GUARDIAN_MAIL: "Guardian email",
          GUARDIAN_MOBILE: "Guardian mobile number",
          F_NAME: "First name",
          L_NAME: "Last name",
          AGE: "Age",
          TALENT_RELATION: "Relationship",
          TALENT_NAME: "Talent name",
          TALENT_MAIL_ID: "Talent Email ID",
        },
        ERRORS: {
          ACCEPT_TNC: "Please accept our Terms & Conditions",
          ACCEPT_CONSENT: "Please accept consent",
          INVALID_FORM: "Please fill all fields",
        },
      },
    },

    TERMS_AND_CONDITIONS: {
      TITLE: "Terms and Conditions",
      FAILED_TO_LOAD:
        "Failed to load Terms and Conditions. Please try after sometime.",
    },
    VERIFICATION_TIME: {
      TITLE:
        "Our verification codes are taking a breather. They should be up in about…",
      LOGOUT_AND: "Log out and come back later",
      ENTER_COOL_DOWN: "Entering cool down",
      PERIOD_VALIDATION: "period for validation attempts!",
    },
    ACCOUNT_PROFILE: {
      ZIP_CODE_NOT_APPLICABLE: "Sorry, we are not active in this location yet.",
      MINIMUM_FIVE_DIGITS: "Minimum 5 digits required",
      SAVED_SUCCESSFULLY: "Changes saved successfully",
      ACCOUNT_PROFILE: "Account Profile",
      PROFILE_INFORMATION: "Profile Information",
      HOW_CAN_WE: "What do you want pepelwerk to help you do?",
      GET_MATCHED_JOB:
        "Get matched with jobs that I can start as soon as possible",
      DISCOVER_AND:
        "Discover and prepare for future in demand careers that are a good fits for me",
      LAUNCH_MY: "Launch my work-life for the first time",
      ON_BEHALF: "On Behalf Of Login",
      DEACTIVATE_ACCOUNT: "Deactivate Account",
      DEACTIVATE_ACCOUNT_BUTTON_LABEL: "Deactivate Account",
      CANCEL_BUTTON_LABEL: "Nevermind, Just Kidding",
      DEACTIVATE_ACCOUNT_DESCRIPTION:
        "Please confirm that you would like to suspend activity but keep your profile and account settings in our database for use in future and reactivate when log into your account",
      CARRER_PHASE: "Career phase",
      CARRER_PHASE_HEADING:
        "Your career phase is how long you have been working",
      NOTIFICATION_SETTING: "Notification settings",
      DEACTIVATE_MESSAGE:
        "Please confirm that you would like to suspend activity but keep your profile and account settings in our database for use in future and reactivate when log into your account.",
      CANCEL_DEACTIVATE: "Nevermind, Just kidding",
      MARKETING_HEADER: "Marketing and community notifications",
      MARKETING_MESSAGE:
        " Disclaimer: If you disable this mode of communication, you will not receive real-time communication from pepelwerk and this will limit the results of your experience..",
      NEW_EMAIL_VERIFY:
        "To complete the process, we sent a code to your new email address ",
      NEW_EMAIL_VERIFY2: " NOTE: Check your spam folder if you don't see it.",
      OLD_EMAIL_VERIFY:
        "To change your email address, we sent a code to your old email address",
      OLD_EMAIL_HEADER: "Old Email Verification",
      NEW_EMAIL_HEADER: "New Email Verification",
      EMAIL_COMPLETED_HEADER: "Email Updated",
      PHONE_COMPLETED_MADE_IT:
        "You made it to the end. You win the prize of a brand new",
      PHONE_COMPLETED_GREAT_JOB: "validated phone number. Great Job!",

      EMAIL_CHANGE_COMPLETED:
        "You made it to the end. You win the prize of a brand new validated email. Great Job!",
      PASSWORD_SUCCESS: "Your password has been updated successfully.",
      CHANGE_PWD: "Change Password",
      PHONE_VALIDATION:
        "Your mobile number needs to be validated. You should have received an SMS text message to your new mobile number which will contain a 6-digit code. Please enter the code below.",
      ON_BEHALF_DESC: "Please enter an email address to continue.",
      DELETE_ACCOUNT: "Delete Account",
      DELETE_ACCOUNT_BUTTON_LABEL: "Delete Account",
      DELETE_ACCOUNT_DESCRIPTION:
        "Deleting your account will permanently remove your account profile. Your account will be deleted in 30 days after you confirm that you want to delete it.",
      FIELDS: {
        FIRST_NAME: "First name",
        LAST_NAME: "Last name",
        EMAIL: "Email",
        PASSWORD: "Password",
        PHONE_NUMBER: "Phone number",
        COUNTRY_CODE: "Country code",
        ZIP_CODE: "Zip code",
        CITY: "City",
        STATE: "State",
        COUNTRY: "Country",
        COUNTRY_PLACEHOLDER: "Select country",
        GUARDIAN_EMAIL: "Guardian email",
        GUARDIAN_EMAIL_PLACEHOLDER: "Enter your guardian email",
      },
      CHANGE_AGE: {
        TITLE: "Change your age",
        GUARDIAN_DESCRIPTION:
          "Pepelwerk needs a guardian consent for <18 years of old.",
        NOTE: "Note: ",
        NOTE_DESCRIPTION:
          "Once you submit the details, you will be logged out and you won’t be able to access pepelwerk until we receive the consent.",
        NOTE_DESCRIPTION1:
          "All your purchases will be available to use once we receive the consent.",
        SAVE: "Save",
        AGE_UPDATED: "Age updated",
        MAIL_SENT: "Consent mail sent successfully!",
      },
      VALIDATION_MESSAGES: {
        firstName: {
          required: "Please fill first name",
          regex: "First name cannot contain special characters",
        },
        lastName: {
          required: "Please fill last name",
          regex: "Last name cannot contain special characters",
        },
        email: {
          required: "Please fill email",
          regex:
            "Double check the email address you have entered. Looks like something is off!",
        },
        country: {
          required: "Please fill country code",
          regex: "Country code can contain numbers only",
          maxLength: "Country code cannot exceed 4 digits",
        },
        phone: {
          required: "Please fill phone number",
          regex: "Phone number can contain numbers only",
          minLength: "Minimum 8 number required",
          maxLength: "Phone number cannot exceed 10 digits",
        },
        password: {
          required: "Password is required",
          regex: "Password must have a capital letter",
          specialChar:
            "Password must contain at least a special character or number",
          isNumber: "Password must contain at least a number",
          space: "Please avoid using white space for password",
          minLength: "Password must be at least 8 letters",
          valid: "Please enter a valid password",
        },
      },
    },
    DELETE_ACCOUNT: {
      title: "Delete Your ",
      account: "Job Match, Goal & Career Tools",
      developerName: "By pepelwerk",
      enterEmail:
        "To delete your account, please enter your registered email address below.",
      verifyOTPDetails:
        "We have sent you a 6-digit code to your registered email. Please enter the 6-digit code below",
      confirmationTitle: "Are you sure you want to delete your account?",
      deleteSuccess: "Account deleted successfully",
    },
    FTUE: {
      HOME: {
        welcome: {
          title: "Welcome to your People Hub!",
          description:
            "Create your personal AI driven Career Guide to reach your work, earn, learn and connecting goals.",
        },
        sidebar: {
          title: "Your Main Navigation",
          description:
            "This panel will give you quick access to modules, features and services that you have added to your account.",
        },
        home: {
          title: "Home",
          description: "This is where you can see all the features you have. ",
        },
        account: {
          title: "Account Settings",
          description:
            "View, edit and adjust your personal settings and goals.",
        },
        appExperience: {
          title: "My Experiences",
          description: "This is your quick access to our apps.",
        },
        featureCenter: {
          title: "Feature Center",
          description:
            "This is where you can add additional features, purchase events and add services.",
        },
        documentCenter: {
          title: "Document Center",
          description:
            "You can find receipts, job offers and other documents here.",
        },
        paymentCenter: {
          title: "Payment Center ",
          description: "Add and manage payment methods.",
        },
        accessCode: {
          title: "Access Code",
          description: "This is where you can manage all your access codes.",
        },
        logout: {
          title: "Logout",
          description:
            "You can logout of the application by selecting this. Yes, it’s obvious, but you never know with some people.",
        },
      },
    },
  },
  ERRORS: {
    ERROR_TEXT: "Error",
    ENTER_PASSWORD: "Please enter password",
    ENTER_VALID_PASSWORD: "Please enter valid password",
    ENTER_EMAIL_ADDRESS: "Please enter your email address",
    ENTER_VALID_EMAIL_ADDRESS: "Please enter valid email",
    ENTER_VALID_FNAME: "Please enter valid first name",
    ENTER_VALID_LNAME: "Please enter valid last name",
    INVALID_ZIPCODE: "Invalid zip code",
    VALID_PASSWORD_ERROR:
      "Welllll, either the password is incorrect or doesn't match with the email",
    VALID_EMAIL_ADDRESS_ERROR:
      "Double check the email address you have entered. Looks like something is off!",
    PWB_INVALID_USER: "Please enter valid credentials to login",
    PWB_ACCOUNT_LOCKED:
      "Account has been locked. Go to forgot password link to reset your account.",
    PWB_INVALID_CODE: "Invalid code. Try again.",
    PWB_ALREADY_EXIST: "You have connector code already",
    ACCOUNT_LOCKED: "Account has been locked",
    ACCOUNT_LOCKED_DESC: "Go to forgot password link to reset your account.",
    SOMETHING: "Something went wrong, please try after sometime",
    ENTER_OTP: "Please enter 6-digit code",
    VALID_OTP: "Please enter valid 6-digit code",
    OTP_LIMIT_REACHED:
      "You have reached the maximum limit for 6-digit code. Try again later or contact us for help.",
    PWB_OTP_LIMIT_REACHED:
      "You have reached the maximum limit for 6-digit code.",
    BLOCKED_PHONE_NUMBER:
      "Your phone number has been blocked. Contact us for more details.",
    ENTER_CONFIRMED_PASSWORD: "Please enter confirm password",
    PASSWORDS_MISMATCH: "Passwords does not match",
    MULTIPLE_ERRORS: "Please enter all the fields to proceed",
    PASSCODES_MISMATCH: "Passcodes do not match. Try again.",
    COMPLETE_REGISTRATION:
      "Please complete your registration process in your app to proceed",
    CONNECTOR_CODE: "Please enter connector code",
    CONNECTOR_EXCEEDED:
      "Sorry, I don't think you know it. Please try again later.",
    REDEEM_CODE: "Please enter promotional code",
    REDEEM_CODE_ERROR:
      "Please check the code and your email address and try again",
    COUPON_ALREADY_USED: "Code is already used",
    PASSWORD_REQUIREMENT:
      "Password should be minimum 8 character alpha numeric with atleast one character of capital and small letter",
    INCOMPLETE: "Incomplete profile, please complete your profile to login",
    PWB_INVALID_OTP_EMAIL:
      "Please enter valid 6-digit code which we sent to your email",
    PWB_PAYMENT_DELETE_ERROR: "Failed to remove payment method",
    BANK_ALREADY_EXISTS: "This payment method is already added",
    VERIFICATION_FAILED: "Verification failed",
    PWB_INVALID_ACCESS_CODE:
      "Invalid access code. Please enter a valid access code",
    PWB_SAME_TALENT_AND_GUARDIAN_EMAIL:
      "Guardian's email cannot be the same as your email.",
    PWB_GUARDIAN_PHONE_SAME_AS_TALENT:
      "Guardian's mobile number cannot be the same as your mobile number.",
    PWB_ACCESSCODE_NOTACTIVE:
      "Invalid access code. Please enter a valid access code",
    PWB_VALIDATE_ACCESSCODE: "Your email is not verified",
    PWB_UN_AUTHORIZED: "Unauthorized. Please try again",
    PWB_USER_EXISTS: "This email is already registered",
    REGISTER_ERROR: "Failed to register talent. Please try again",
    UNABLE_TO_GET_TNC: "Failed to get Terms & Conditions",
    CONNECTION_LOST: "The network connection was lost",
    CONNECTION_RESTORE: "The network connection restored",
    PWB_INVALID_OTP: "Invalid 6 digit code",
    PWB_ACCESSCODE_FUTURE_START_DATE:
      "This access code will be activated on {date}. Please try after that.",
    SESSION_TIME_OUT: "Session Time Out",
    PASSWORD_SPACE_ERROR: "Please avoid using white space for password",
    USER_EXIST:
      "User already exists with new email, please try with another email",
    INVALID_OLD_PWD: "Please enter valid old password",
    INVALID_NEW_PWD: "Please enter valid new password",
    MAX_PHONE_LENGTH: "Phone Number cannot exceed 10 digits",
    MIN_ZIPCODE_LENGTH: "Minimum 5 digit required",
    MAX_ZIPCODE_LENGTH: "Zip code cannot exceed 6 digits",
    MIN_PHONE_LENGTH: "Minimum 8 number required",
    MAX_OTP_LENGTH: "OTP cannot exceed 6 digits",
    MIN_OTP_LENGTH: "Minimum 6 number required",
    MAX_ISDCODE_LENGTH: "Country code cannot exceed 4 digits",
    WRONG_PWD:
      "Tisk Tisk...you've entered the wrong current password. Please try again.",
    PWD_NOCHANGE: "Please choose new password as different from old password",
    NOT_MATCH: "New password and verify password do not match",
    PASSWORDS_DONT_MATCH: "New and confirm password should be same",
    PASSWORD_MISS_MATCH: "Passwords do not match.",
    CUR_PWD: "Please enter current password ",
    NEW_PWD: "Please enter new password",
    VERIFY_PWD: "Please enter verify password",
    PHONE_FORMAT: "Please provide Valid Phone Number.",
    ACCOUNT_NOT_MATCHING_GUIDELINES:
      "Your account has been blocked for not following our guidelines. Please check your email to know more.",
    PWB_IN_COMPLETE_FTUE: "Please complete the FTUE process In mobile app",
    PWB_HAVE_ACTIVE_ACCESS_CODE:
      "Oops! Something went wrong. Email cannot be updated.",
    INVALID_EMAIL_ADDRESS:
      "The email address you have entered is not that of a valid user.",
    PWB_ACCESSCODE_EXIST: "You have already an active access code.",
    ENTER_ACCESS_CODE: "Please enter access code",
    PWB_ACCESS_CODE_FEATURE_NOT_UTILIZED:
      "Sorry! You cannot activate this access code, yet.",
    // PWB_ACCESS_CODE_FEATURE_NOT_UTILIZED:
    //   "You cannot redeem this access code as there is an active access code associated with this account. You can redeem this access code after completely utilizing the existing access code features or after the expiry of the existing access code.",
    PWB_ACCESS_CODE_NOT_TAGGED:
      "Invalid access code. Please enter a valid access code",
    PWB_ACCESS_CODE_ALREADY_REDEEMED:
      "You have already redeemed this access code",
    INVALID_LINK: "The provided link has expired.",
    PWB_GUARDIAN_TOKEN_RESEND:
      "The provided link has expired. A new link will be shared with you shortly.",
    GUARDIAN_CONSENT_VERIFIED: "Consent received",
    GUARDIAN_CONSENT_ALREADY_APPROVED: "Consent already given by {email}",
    VERIFICATION_FAILED_DESC:
      "We were not able to verify the card you have provided. Try another one.",
    PWB_DUPLICATE_CARD: "You cannot add the same card more than once.",
    PWB_INVALID_ZIPCODE: "Invalid zip code",
    PWB_GUARDIAN_TOKEN_EXPIRED: "The provided link has expired.",
    // public access code messages
    PWB_ACCESS_CODE_NOT_ACTIVE: "This access code is not activated",
    PWB_NOT_A_PUBLIC_ACCESS_CODE:
      "This access code is not a public access code",
    PWB_ACCESS_CODE_EXPIRED: "This access code has been expired",
    PWB_UNABLE_TO_ADD_TALENT_DUE_TO_PAYMENT_ISSUE:
      "Unable to add access code please contact sponsor",
    PWB_TALENT_ALREADY_IN_ACCESS_CODE:
      "This access code has already been added",
    PWB_TALENT_COUNT_EXCEEDED: "This access code limit has been reached",
    PWB_TALENT_NOT_IN_ACCESS_CODE:
      "You no longer have access to this access code.",
    PWB_PHONE_NUMBER_EXISTS: "This mobile number already exists.",
    PWB_INVALID_TALENT_ACCOUNT: "This account does not exist.",
    PWB_INVALID_PHONE_NUMBER: "This mobile number does not exist.",
    PWB_PHONE_ALREADY_VALIDATED: "This mobile number is already verified.",
    PWB_UNAUTHORIZED: "Token invalid",
    PWB_FEATURE_NOT_IN_CART: "Item is not in cart",
    PWB_FEATURE_ALREADY_IN_CART: "Item already added to cart",
    PWB_CART_ITEMS_MISMATCH: "Cart item(s) doesn't match",
    PWB_INVALID_PAYMENT_METHOD:
      "Payment failed. Please try another payment option.",
    PWB_PAYMENT_ERROR: "Payment has failed. Please try again.",
    PWB_SESSION_TIMEOUT: "Session timeout",
    PWB_ACCOUNT_NOT_FOUND: "This account does not exist.",
    PWB_INCORRECT_BUY_UP_PRICE:
      "There is a feature price mismatch. Try refreshing your screen.",
    PWB_ZIP_CODE_COUNTRY_MISMATCH:
      "This zip code is not associated with the country you selected.",
    MOBILE_NUMBER_NOT_MATCHING:
      "Mobile number does not match the provided country code.",
    GUARDIAN_MOBILE_NUMBER_NOT_MATCHING:
      "Guardian mobile number does not match the provided country code.",
    NO_RESEND_LEFT: "No resend attempts left. Try again later.",
    PWB_EMAIL_EXISTS: "This email is already registered",
    PWB_TEST_CARD_ERROR:
      "Your card was declined as you are using the card in a test environment.",
    PWB_GUARDIAN_CONSENT_ALREADY_APPROVED: "Consent already given by {email}.",

    PWB_GUARDIAN_CONSENT_VERIFIED: "Consent already received",
    PWB_GUARDIAN_CONSENT_RESEND_LIMIT_REACHED:
      "Resend consent mail limit reached",
    PWB_SAME_AGE: "Please select an age range different from the existing one",
    PWB_TAX_CODE_AND_PRICE_MISMATCH:
      "We ran into some discrepancy in calculation",
    PWB_INVALID_REQUEST: "Unable to checkout",
    PWB_MULTIPLE_ACCOUNTS_EXISTS_FOR_PHONE_NUMBER:
      "Multiple account exists with this mobile number.",
    PWB_USER_NOT_FOUND_WITH_PHONE: "User not found with this mobile number.",
    PWB_ACCESS_CODE_NOT_ADDED: "This access code is not added.",
    PWB_APPROVAL_IN_REQUEST: "Awaiting approval from the access code provider.",
    PWB_ACCESS_CODE_NOT_AVAILABLE_TO_USE:
      "This access code is not available for use.",
    PWB_ACCESSCODE_AWAITING_APPROVAL:
      "Awaiting approval from the access code provider.",
    PWB_ACCESSCODE_ALREADY_REJECTED:
      "This access code has been rejected already.",
    PWB_PRIVATE_ACCESS_CODE: "You don't have access to this access code.",
  },

  API_ERRORS: {
    PASSWORD: {
      PWB_PASSWORD_NO_CHANGE:
        "Please choose new password as different from old password",
      PWB_INVALID_USER:
        "Tisk Tisk...you've entered the wrong current password. Please try again.",
      PWB_ACCOUNT_LOCKED:
        "Account has been locked. Go to forgot password link to reset your account.",
      PWB_SAME_OLD_AND_NEW_PASSWORD:
        "You cannot use your old password as the new password. Please choose a different password.",
    },
    ON_BEHALF: {
      PWB_INVALID_USER:
        "The email address you have entered is not that of a valid user.",
      PWB_ACCOUNT_LOCKED: "Account has been locked",
      PWB_ACCOUNT_SUSPENDED:
        "Your account has been blocked for not following our guidelines. Please check your email to know more.",
    },
    CHANGE_PHONE: {
      PWB_INVALID_OTP: "Invalid 6-digit code",
      PWB_INVALID_OLD_OTP: "Invalid 6-digit code",
      PWB_PHONE_ALREADY_VALIDATED: "The phone number already validated",
    },
    VERIFY_PHONE: {
      OTP_LIMIT_REACHED: "No resend attempts left. Try again later",
      PWB_OTP_LIMIT_REACHED: "No resend attempts left. Try again later",
    },
    VALIDATE_PASSWORD: {
      PWB_ACCOUNT_LOCKED:
        "The account has been locked. Try again after some time.",
      PWB_INVALID_OTP: "Invalid 6-digit code",
      PWB_INVALID_USER:
        "The email address you have entered is not that of a valid user.",
    },
    DELETE_ACCOUNT: {
      PWB_INVALID_USER: "This account does not exist",
      PWB_INVALID_OTP:
        "Please enter the 6-digit code which you received to your email.",
    },
    TAX_DETAILS: {
      PWB_TAX_CALCULATION_FAILED:
        "Tax details could not be retrieved. Please try again with another card or reach out to info@pepelwerk.com.",
      PWB_INVALID_PAYMENT_DETAILS: "Invalid payment details",
    },
  },
  LAYOUT: {
    NEED_HELP: "Need help? ",
    CONTACT_SUPPORT: "Contact Support.",
    HUB_NAME: "People ",
    HUB: "Hub",
  },
  COMMON: {
    TEXT: {
      PEPEL_ACCOUNT: "pepel account",
      FOR_PEOPLE: " for people who are looking ",
      LIFE_GOALS: " to accomplish their work-life goals",
      FORGOT: "Forgot",
      PASSWORD: "Password",
      CREATE: "Create your",
      NEW: "new password",
    },
    MESSAGE: {
      OTP_LIMIT: "No resend attempts left. Try again later.",
      VALID_CODE: "Enter valid 6-digit code",
      VALIDATE_MOBILE: "Please validate mobile number",
      ENTER_MOBILE_OTP: "Please enter mobile OTP to continue",
      INVALID_OTP: "Invalid OTP",
      ENTER_EMAIL_OTP: "Please enter email OTP to continue",
      ENTER_OTP: "Please enter OTP",
    },
    BUTTONS: {
      CANCEL: "Cancel",
      OK: "OK",
      SUBMIT: "Submit",
      SIGN_OUT: "Logout",
      PROCEED: "Proceed",
      TRY_AGAIN: "Try Again",
      CHANGE: "Change",
      VALIDATE: "Validate",
      BACK_TO_LOGIN: "Back to login",
      REQUEST_CONCERN: "Request Consent",
      SEND_VERIFICATION_CODE: "Send Verification Code",
      VERIFY: "Verify",
      BACKTOLOGIN: "Back to Login",
      BACK: "Back",
      RESET_PASSWORD: "Reset Password",
      RESEND: "Resend Code",
      ATTEMPT_LEFT: "attempts left",
      CODE_LABEL: "6-digit code",
    },
    FIELDS: {
      EMAIL: "E-mail",
      PASSWORD: "Password",
      PASSWORD_LABEL: "Password",
      CONFIRM_PASSWORD_LABEL: "Re-enter password",
      SAVE_PERCENTAGE: "20%",
      NAME: "Name",
      VIEW: "View",
      TOO_LONG: "Password is too long",
      SHOULD_DIFFERENT: "The email is already validated.",
      CODE_LABEL: "6-digit code",
    },
    LINKS: {
      FAQ_LINK: "https://www.pepelwerk.com/faq-talent-desktop/",
      CORPORATE_URL: "https://www.pepelwerk.com/",
      PEPELWERK_URL: "https://www.pepelwerk.com/",
      CONNECTORS_LINK: "https://www.pepelwerk.com/connectors/",
      CONTACT_US_LINK: "https://www.pepelwerk.com/help-center/",
      PLAY_STORE_LINK: "https://pepelwerktalent.page.link/openTalentAndroidApp",
      APP_STORE_LINK: "https://pepelwerktalent.page.link/openTalentiOSApp",
      CONTACT_LINK: "https://www.pepelwerk.com/help-center/",
      EVENTS: "https://www.pepelwerk.com/career-events/#calendar",
      AI_CAREER_ASSISTANT:
        "https://www.pepelwerk.com/build-my-career/build-my-ai-career-assistant/",
    },
    APPS: {
      APPLE_STORE: "appStore",
      PLAY_STORE: "googlePlay",
      CONTACT: "chat",
    },
  },
};
