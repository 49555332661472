import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { changeEmail } from "./accountProfileService";
import { ChangeEmailResponse, ChangeNewEmailPayload } from "./types";

type APIResponse = ChangeEmailResponse & FailedAPIResponse;

const useChangeEmail = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, ChangeNewEmailPayload>({
    mutationFn: changeEmail,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useChangeEmail;
