import { Box, Button } from "@mui/material";
import { PaymentListing } from "assets/images";
import Resources from "assets/json/Resources";
import NoDataItem from "components/ui/EmptyData";

type NoDataProps = {
  onActionClick: () => void;
};

function NoData(props: NoDataProps) {
  const { onActionClick } = props;
  return (
    <NoDataItem sx={{ display: "flex", maxWidth: "100%" }}>
      <NoDataItem.ImageContainer
        image={PaymentListing}
        sx={{
          height: { md: "18.75rem", xs: "14.75rem" },
          width: { md: "18.75rem", xs: "14.75rem" },
        }}
      />
      <NoDataItem.Title
        title="Add payment method to have an uninterrupted experience"
        sx={{ fontSize: "2.125rem !important" }}
      />
      <Box
        sx={{
          maxWidth: "40rem",
          margin: "6.8rem auto 0",
          width: "100%",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => onActionClick()}
          sx={{
            border: "1px solid var(--btn-border, #9DBFBF)",
            color: "var(--color-secondary)",
          }}
        >
          {Resources.PAGES.PAYMENT_CENTER.ADD_NEW_PAYMENT_METHOD}
        </Button>
      </Box>
    </NoDataItem>
  );
}

export default NoData;
