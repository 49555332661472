import { Box, Typography } from "@mui/material";
import { Breadcrumb, Modal } from "components/common";
import {
  FREE,
  PREFERRED,
  REACT_QUERY_KEYS,
  ROUTES,
  SESSION_KEYS,
} from "config/app";
import {
  AddOnKeys,
  FailedAPIStatus,
  PlanDetails,
  SubscriptionOptions,
  Subscriptions,
} from "constants/sharedTypes";
import { useAuth } from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  useAddCartItem,
  useGetCartItems,
  useGetPlanInfo,
  useRemoveCartItem,
} from "services/featureCenter";
import useRedeemAccessCode from "services/useRedeemAccessCode";
import { getCampaignInfo, removeFromSessionStorage } from "utils";
import CheckoutButton from "./CheckoutButton";
import RedeemCode from "./Modals/Campaign/redeemCode";
import RedeemSuccess from "./Modals/Campaign/redeemSuccess";
import NewBuyUpFeatures from "./NewBuyUpFeatures";
import Plans from "./Plans";
import styles from "./style.module.scss";
import { CartItem, PlanOptionsType } from "./types";

type RedeemStatus = "success" | "not-redeemed";

const BREAD_CRUMBS = [{ text: "Feature Center" }];

function PurchaseCenter() {
  const { setGlobalAlert } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [cartItems, setCartItems] = useState<CartItem[]>([]);

  const showError = (message: string) => {
    setGlobalAlert({ messageType: "error", message });
  };

  const [campaignModal, setCampaignModal] = useState(false);
  const [redeemStatus, setRedeemStatus] =
    useState<RedeemStatus>("not-redeemed");
  const [accessCode, setAccessCode] = useState("");

  const onRedeemSuccess = (message: string) => {
    removeFromSessionStorage(SESSION_KEYS.CAMPAIGN);
    setRedeemStatus("success");
    setGlobalAlert({ message, messageType: "success" });
  };

  const { mutateAsync: redeemAccessCode, isLoading } = useRedeemAccessCode({
    onError: showError,
    onSuccess: onRedeemSuccess,
  });

  useEffect(() => {
    const campaignInfo = getCampaignInfo();
    if (campaignInfo) {
      setCampaignModal(true);
      setAccessCode(campaignInfo.code);
    }
  }, []);

  const handleAPIFailure = (error: FailedAPIStatus) => {
    showError(error.errorMessage);
  };

  const { data: planData } = useGetPlanInfo();

  const { data: cartData } = useGetCartItems();

  const { mutateAsync: addItemToCart, isLoading: isAdding } = useAddCartItem({
    onError: handleAPIFailure,
  });

  const { mutateAsync: deleteCartItem, isLoading: isDeleting } =
    useRemoveCartItem({
      onError: handleAPIFailure,
    });

  useEffect(() => {
    if (cartData?.result.success && cartData.result?.userCart?.cartItems) {
      setCartItems(cartData.result.userCart.cartItems);
    }
  }, [cartData]);

  // CLEAR CART CACHE
  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(REACT_QUERY_KEYS.CART);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentPlan = useMemo<PlanDetails | undefined>(
    () => planData?.result?.membershipInfo?.current,
    [planData],
  );

  const disablePlanPurchase = useMemo(() => {
    let disable = false;
    if (planData?.result?.membershipInfo?.future) {
      disable =
        Object.keys(planData?.result?.membershipInfo?.future).length > 0;
    }
    return disable;
  }, [planData]);

  const freePlan = useMemo<Subscriptions | undefined>(() => {
    return planData?.result?.subscriptionList?.find(
      (plan) => plan.memberLevel === FREE.id,
    );
  }, [planData]);

  const basicPlan = useMemo<Subscriptions | undefined>(() => {
    return planData?.result?.subscriptionList?.find(
      (plan) => plan.memberLevel === PREFERRED.id,
    );
  }, [planData]);

  const cartItemCount = useMemo(() => {
    const planCount =
      cartItems?.reduce((acc, item) => acc + item.count, 0) ?? 0;
    return planCount; // + featuresCount;
  }, [cartItems]);

  const preferredPlanInCart = useMemo(() => {
    if (basicPlan?.options) {
      const planIds = Object.values(basicPlan?.options).map(
        (plan) => plan.subscriptionId,
      );
      const preferredPlan = cartItems.find((item) =>
        planIds.includes(item.feature),
      );
      return preferredPlan;
    }
    return null;
  }, [cartItems, basicPlan]);

  const handleBasicPlanChange = useCallback(
    async (newPlan: PlanOptionsType | undefined) => {
      if (!newPlan || newPlan?.subscriptionId === currentPlan?.subscriptionId)
        return;
      // add item to cart
      const res = await addItemToCart({ feature: newPlan.subscriptionId });

      if (!res?.result?.success) return;

      // create cart item
      const cartItem: CartItem = {
        feature: newPlan.subscriptionId,
        count: 1,
        price: newPlan.fullPrice,
      };

      // clear old ppf plan from cart if exists
      if (preferredPlanInCart) {
        const updatedCart = cartItems.filter(
          (item) => item.feature !== preferredPlanInCart.feature,
        );
        setCartItems([...updatedCart, cartItem]);
      } else {
        setCartItems((c) => [...c, cartItem]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartItems, currentPlan?.subscriptionId, preferredPlanInCart],
  );

  const handleCheckout = () => {
    navigate(ROUTES.PRIVATE.CHECKOUT);
  };

  const closeCampaignModal = () => {
    removeFromSessionStorage(SESSION_KEYS.CAMPAIGN);
    setCampaignModal(false);
  };

  const onActivateAccessCode = async (code: string) => {
    await redeemAccessCode(code);
  };

  const renderRedeemStatus = () => {
    switch (redeemStatus) {
      case "not-redeemed":
        return (
          <RedeemCode
            onSubmit={onActivateAccessCode}
            onClose={closeCampaignModal}
            accessCode={accessCode}
            isLoading={isLoading}
          />
        );
      case "success":
        return <RedeemSuccess onClose={closeCampaignModal} />;

      default:
        return null;
    }
  };

  const renderCampaignModal = () => (
    <Modal open={campaignModal} onClose={closeCampaignModal}>
      {renderRedeemStatus()}
    </Modal>
  );

  const addAddOnToCart = useCallback(
    async (cartItem: AddOnKeys, price: number, quantity?: number) => {
      const res = await addItemToCart({ feature: cartItem, count: quantity });

      if (!res.result.success) return;

      const cart = [...cartItems];

      if (cartItem === "COACHING_SESSION") {
        const coachingSessionIndex = cart.findIndex(
          (item) => item.feature === cartItem,
        );
        // if already existing in cart, update count
        if (coachingSessionIndex !== -1) {
          const updatedCart = [...cart];
          updatedCart[coachingSessionIndex] = {
            ...updatedCart[coachingSessionIndex],
            count: quantity as number,
          };

          setCartItems(updatedCart);
          return;
        }
      }

      const item: CartItem = { feature: cartItem, price, count: quantity ?? 1 };

      setCartItems((items) => [...items, item]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartItems],
  );

  const removeAddOnFromCart = async (cartItem: AddOnKeys | string) => {
    const resp = await deleteCartItem({ feature: cartItem });

    if (resp?.result?.success) {
      const addOns = cartItems?.filter((item) => item.feature !== cartItem);
      setCartItems(addOns);
    }
  };

  return (
    <>
      {renderCampaignModal()}

      <Box className="app-l-container app-l-purchase-center-main-section">
        <Box className={styles.pageLayout}>
          {/* for desktop screen */}
          <Box className={`${styles.purchaseCenterHeading} ${styles.desktop}`}>
            <Breadcrumb crumbs={BREAD_CRUMBS} />
            <CheckoutButton
              itemCount={cartItemCount}
              onClick={handleCheckout}
              disabled={!cartItemCount}
            />
          </Box>
          <Box className={styles.purchaseCenterContent}>
            <Box className={styles.subscriptionSection}>
              <div className={styles.totalContent}>
                <Box className={styles.subContent}>
                  <Typography className={styles.subtitleHeading}>
                    Subscriptions
                  </Typography>
                  <Typography className={styles.subDesc}>
                    Manage your subscriptions and begin building your future
                    with the tools and coaching you need to get your goal job.
                  </Typography>
                </Box>
              </div>
              <Box className={styles.subscriptionItems}>
                {currentPlan && freePlan && basicPlan && (
                  <Plans
                    cartItem={preferredPlanInCart}
                    currentPlan={currentPlan}
                    freePlan={freePlan}
                    basicPlan={basicPlan}
                    disablePlanPurchase={disablePlanPurchase}
                    disabled={isAdding || isDeleting}
                    onChangeSubscription={handleBasicPlanChange}
                    onCheckout={handleCheckout}
                    onRemoveCart={removeAddOnFromCart}
                  />
                )}
              </Box>
            </Box>

            <Box className={styles.addOnSection}>
              <Box className={styles.subContent}>
                <Typography className={styles.subtitleHeading}>
                  Add-Ons
                </Typography>
                <Typography className={styles.subDesc}>
                  Feeling a little left behind? Here are add-ons that can help
                  you get ahead of the pack
                </Typography>
              </Box>

              <NewBuyUpFeatures
                preferredPlan={basicPlan?.options as SubscriptionOptions[]}
                addItemToCart={addAddOnToCart}
                onRemoveCartItem={removeAddOnFromCart}
                cartItems={cartItems}
                isMemberShipAvailable={
                  currentPlan?.memberLevel === PREFERRED.id
                }
                disabled={isAdding || isDeleting}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* for mobile screen */}
      <Box className={`${styles.checkoutMobile}`}>
        <CheckoutButton
          itemCount={cartItemCount}
          onClick={handleCheckout}
          disabled={!cartItemCount}
        />
      </Box>
    </>
  );
}
export default PurchaseCenter;
