import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { deleteAccount } from "./commonService";
import { DeleteAccountResponse, ValidateEmailOTPType } from "./types";

type APIResponse = DeleteAccountResponse & FailedAPIResponse;

const useDeleteAccount = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, ValidateEmailOTPType>({
    mutationFn: deleteAccount,
    resource: { ...Resources.ERRORS, ...Resources.API_ERRORS.DELETE_ACCOUNT },
    handler,
  });
};

export default useDeleteAccount;
