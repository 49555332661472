import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { SSOResponse } from "constants/sharedTypes";
import { SingleSignOnRequest } from "./common/commonService";

type APIResponse = SSOResponse & FailedAPIResponse;

const useSingleSignOn = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, string>({
    mutationFn: SingleSignOnRequest,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useSingleSignOn;
