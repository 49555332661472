/* eslint-disable consistent-return */
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, CircularProgress } from "@mui/material";
import Resources from "assets/json/Resources";
import { Breadcrumb } from "components/common";
import { ROUTES } from "config/app";
import { useAuth } from "hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { accountProfile } from "services/accountProfile";
import ProfileForm from "./ProfileForm";
import ProfileSetting from "./ProfileSetting";
import styles from "./style.module.scss";

const breadcrumbs = {
  subscriptionInfo: [{ text: "Account Settings" }],
};

function AccountProfile() {
  const [profileDetails, setProfileDetails] = useState({} as any);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { setGlobalAlert } = useAuth();

  const getAccountDetails = async () => {
    setLoading(true);
    try {
      const response = await accountProfile();
      if (response.status === 428 || response.status === 200) {
        return response.result;
      }
      setGlobalAlert({
        message: Resources.ERRORS.SOMETHING,
        messageType: "error",
      });
    } catch (error: any) {
      setGlobalAlert({ message: error, messageType: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleNavigation = (path: string) => () => {
    navigate(path);
  };

  useEffect(() => {
    (async () => {
      const response = await getAccountDetails();
      setProfileDetails(response);
    })();
  }, []);

  return (
    <div
      className="app-l-container"
      style={{ paddingTop: "3rem", backgroundColor: "--color-primary4" }}
    >
      {isLoading ? (
        <div className={`${styles.loader} input-loader`}>
          <CircularProgress size={20} />
        </div>
      ) : (
        <Box className={styles.container}>
          <Breadcrumb crumbs={breadcrumbs.subscriptionInfo} />
          <Box className={styles.profileWrapper}>
            <Box className={styles.profileSection} sx={{ height: "100%" }}>
              <ProfileForm profileDetails={profileDetails} />
              <Box className={styles.deactivate}>
                <Button
                  onClick={handleNavigation(ROUTES.PRIVATE.DEACTIVATE_ACCOUNT)}
                >
                  {Resources.PAGES.ACCOUNT_PROFILE.DEACTIVATE_ACCOUNT}
                </Button>
                <Button
                  onClick={handleNavigation(ROUTES.PRIVATE.DELETE_ACCOUNT)}
                  startIcon={<DeleteIcon />}
                  color="error"
                  sx={{ textTransform: "none" }}
                >
                  {Resources.PAGES.ACCOUNT_PROFILE.DELETE_ACCOUNT}
                </Button>
              </Box>
            </Box>
            <Box className={styles.helpSection}>
              <ProfileSetting profileDetails={profileDetails} />
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
}
export default AccountProfile;
