import Resources from "assets/json/Resources";
import { REACT_QUERY_KEYS } from "config/app";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth, useCreateCardToken } from "hooks";
import { forwardRef } from "react";
import { useQueryClient } from "react-query";
import { useSaveCC } from "services/paymentService";
import { PaymentMethodResponseType } from "services/useGetPaymentMethods";

import { Box, Button } from "@mui/material";
import { SectionLoader } from "components/common";
import PaymentForm from "pages/Checkout/Payments/PaymentForm";

type Ref = HTMLButtonElement;

type CCProps = {
  toggleSection: () => void;
};

const {
  PAGES: {
    PAYMENT_CENTER: { VERIFICATION_SUCCESS },
  },
  COMMON: {
    BUTTONS: { PROCEED },
  },
} = Resources;

const CreditCardForm = forwardRef<Ref, CCProps>((props, ref) => {
  const { toggleSection } = props;
  const queryClient = useQueryClient();

  const paymentMethods: PaymentMethodResponseType | undefined =
    queryClient.getQueryData(REACT_QUERY_KEYS.PAYMENT_METHODS);

  const { setGlobalAlert } = useAuth();
  const stripeKey =
    paymentMethods?.result?.paymentDetails?.paymentCenter?.stripe?.pApiKey ??
    "";
  const { createToken } = useCreateCardToken(stripeKey);

  const onAddCardSuccess = () => {
    setGlobalAlert({
      message: VERIFICATION_SUCCESS,
      messageType: "success",
    });
    queryClient.invalidateQueries(REACT_QUERY_KEYS.PAYMENT_METHODS);
    toggleSection();
  };

  const onAddCardError = (error: FailedAPIStatus) => {
    setGlobalAlert({
      message: error.errorMessage,
      messageType: "error",
    });
    toggleSection();
  };

  const { mutateAsync: addCreditCard, isLoading } = useSaveCC({
    onSuccess: onAddCardSuccess,
    onError: onAddCardError,
  });

  const handleFormValid = async (values: any) => {
    const { cardNumber, cvv, name, zipCode, expMonth, expYear, country } =
      values;
    const payload = {
      number: cardNumber,
      expMonth,
      expYear,
      cvc: cvv,
      addressZip: zipCode,
      name,
      addressCountry: country,
    };

    try {
      const res = await createToken(payload);
      if (res?.status === "error") {
        setGlobalAlert({ message: res.errorMsg, messageType: "error" });
        return;
      }

      await addCreditCard({
        nickName: res?.nickName,
        tokenId: res?.tokenId,
      });
    } catch (error) {
      setGlobalAlert({ message: "Unable to add card", messageType: "error" });
    }
  };

  return (
    <Box sx={{ flex: 1 }}>
      <SectionLoader loading={isLoading} />
      <PaymentForm formId="add-credit-card" onFormValid={handleFormValid} />
      <Button
        ref={ref}
        type="submit"
        variant="contained"
        sx={{ display: "none" }}
        form="add-credit-card"
      >
        {PROCEED}
      </Button>
    </Box>
  );
});

export default CreditCardForm;
