import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { verifyPhoneOTP } from "./accountProfileService";
import { VerifyPhoneOTPPayload, VerifyPhoneResponse } from "./types";

type APIResponse = VerifyPhoneResponse & FailedAPIResponse;

const useVerifyPhoneOtp = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, VerifyPhoneOTPPayload>({
    mutationFn: verifyPhoneOTP,
    resource: Resources.API_ERRORS.CHANGE_PHONE,
    handler,
  });
};

export default useVerifyPhoneOtp;
