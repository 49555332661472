/* eslint-disable react/no-unescaped-entities */
import { Box, Button } from "@mui/material";
import Resources from "assets/json/Resources";
import FTUELayout from "components/FTUELayout";
import { LoadingButton } from "components/common";
import { OTPCount, OTPInput } from "components/ui";
import { DEFAULT_OTP_LENGTH, OTP_WAITING_TIME, ROUTES } from "config/app";
import {
  FailedAPIStatus,
  TalentRegistrationStepTypes,
} from "constants/sharedTypes";
import { useAuth } from "hooks";
import { useCallback, useEffect, useState } from "react";
import useGetTalentHomeDetails from "services/useGetTalentHomeDetails";
import useResendOtp from "services/useResendOtp";
// import useVerifyEmail from "services/useVerifyEmail";
import OtpResentSuccess from "components/ui/OtpResentSuccess";
import useTimer from "hooks/useTimer";
import { HINT_MESSAGE } from "pages/ForgotPassword/config";
import { useNavigate } from "react-router-dom";
import {
  useGetAccountProfile,
  useVerifyEmail,
} from "services/accountProfileService";
import triggerGAEvent, { GAEvents, GA_USER_FLOW } from "utils/gaEvents";
import { useUpdateFTUEStatus } from "services/common";
import styles from "./styles.module.scss";

const {
  PAGES: {
    SIGN_UP: {
      VERIFY_EMAIL: {
        DSEC_WE_HAVE_SENT,
        DESC_SPAM,
        DESC_IF_YOU,
        DESC_DIGIT_CODE,
        DESC_NOTSPAM,
        SKIP_EMAIL_VERIFICATION,
        CLICK_HERE,
      },
    },
  },
  COMMON: {
    BUTTONS: { RESEND, ATTEMPT_LEFT },
    FIELDS: { CODE_LABEL },
    MESSAGE: { INVALID_OTP, ENTER_EMAIL_OTP },
  },
} = Resources;

type EmailVerificationProps = {
  onComplete: (nextStep: TalentRegistrationStepTypes) => void;
  attemptLeft: number;
};

type EmailOTPResponseType = {
  status: number;
  result: {
    incompleteSection: TalentRegistrationStepTypes;
    resendEmailOtpBalance: number;
    hintMessageCode: string;
    userid: string;
    session: string;
    email: string;
  };
};

function EmailVerification(props: EmailVerificationProps) {
  const { setUserBalanceOTP } = useAuth();
  const { data: userInfo } = useGetAccountProfile();
  const balanceOTP = userInfo?.result?.balanceOtp?.resendEmailOtpBalance;
  const { onComplete, attemptLeft } = props;
  const { setGlobalAlert, logout, getUserId } = useAuth();
  const [helperText, setHelperText] = useState<string>("");
  const [OTP, setOTP] = useState("");
  const [isOTPSent, setIsOTPSent] = useState(true);
  const [email, setEmail] = useState<string>("");
  const [otpBalance, setOtpBalance] = useState(attemptLeft);
  const [isShowResentMsg, setIsShowResentMsg] = useState<Boolean>(true);
  const [resentMsg, setResentMsg] = useState<string>("");
  const [canSentOtp, setCanSentOtp] = useState<Boolean>(true);
  const [isFirstTime, setIsFirstTime] = useState(true);

  useEffect(() => {
    if (attemptLeft === 0 && balanceOTP && balanceOTP > 0) {
      setOtpBalance(balanceOTP);
    }
  }, [attemptLeft, balanceOTP]);

  const navigate = useNavigate();

  const showError = (message: string) =>
    setGlobalAlert({ message, messageType: "error" });

  const onResendOtpSuccess = (response: EmailOTPResponseType) => {
    const { resendEmailOtpBalance, hintMessageCode } = response.result;
    setIsOTPSent(true);
    setOtpBalance(resendEmailOtpBalance);
    setResentMsg(hintMessageCode);
    setUserBalanceOTP({ resendEmailOtpBalance });
  };

  const hideResentMessage = () => {
    setIsShowResentMsg(false);
  };

  const userId = getUserId();

  const { data: userDetails } = useGetTalentHomeDetails();

  const sendAnalyticsData = (event: GAEvents, step: string) => {
    try {
      triggerGAEvent(event, userId as string, {
        step,
        age_selection: userDetails?.home?.age,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleVerificationFail = (error: FailedAPIStatus) => {
    // Trigger GA event on verification fail
    sendAnalyticsData("error", GA_USER_FLOW.REGISTER.ERROR.EMAIL_VERIFICATION);
    setHelperText(error.errorMessage);
    showError(error.errorMessage);
  };

  const onVerifyEmailSuccess = () => {
    // Trigger GA Event on verification success
    sendAnalyticsData(
      "create_account",
      GA_USER_FLOW.REGISTER.SUCCESS.EMAIL_VERIFICATION,
    );

    onComplete("WELCOME");
  };

  const onSkipEmailSuccess = () => {
    // Trigger GA Event on skip email success
    sendAnalyticsData(
      "create_account",
      GA_USER_FLOW.REGISTER.SUCCESS.SKIP_EMAIL_VERIFICATION,
    );

    onComplete("WELCOME");
  };
  const handleSkipEmailFail = (error: FailedAPIStatus) => {
    setHelperText(error.errorMessage);
    showError(error.errorMessage);
    // Trigger GA event on verification fail
    sendAnalyticsData(
      "error",
      GA_USER_FLOW.REGISTER.ERROR.SKIP_EMAIL_VERIFICATION,
    );
  };

  const { mutateAsync: verifyEmail, isLoading: isEmailVerifying } =
    useVerifyEmail({
      onSuccess: onVerifyEmailSuccess,
      onError: handleVerificationFail,
    });

  const { mutateAsync: skipEmailVerification } = useUpdateFTUEStatus({
    onSuccess: onSkipEmailSuccess,
    onError: handleSkipEmailFail,
  });

  const { mutateAsync: resendOtp, isLoading: isOTPResending } = useResendOtp({
    onSuccess: onResendOtpSuccess,
    onError: showError,
  });

  const setAccountEmail = useCallback(() => {
    if (userDetails?.home) {
      const { email: emailData } = userDetails.home;
      setEmail(emailData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.home]);

  useEffect(() => {
    setAccountEmail();
  }, [setAccountEmail]);

  const handleEmailVerify = async () => {
    if (!OTP.length) {
      const errorMessage = ENTER_EMAIL_OTP;
      setHelperText(errorMessage);
      setGlobalAlert({
        messageType: "error",
        message: errorMessage,
      });
      return;
    }
    if (OTP.length !== DEFAULT_OTP_LENGTH) {
      const errorMessage = INVALID_OTP;
      setHelperText(errorMessage);
      showError(errorMessage);
      return;
    }
    await verifyEmail({ otp: OTP });
  };
  const handleClick = async () => {
    await skipEmailVerification({ page: "EMAIL" });
  };

  const onOTPComplete = async (otp: string) => {
    setOTP(otp);
    if (otp.length === DEFAULT_OTP_LENGTH) {
      await verifyEmail({ otp });
    }
  };

  const handleAlertClose = () => setIsOTPSent(false);

  const resendVerificationCode = async () => {
    setCanSentOtp(false);
    // Trigger GA event on resending OTP
    sendAnalyticsData("error", GA_USER_FLOW.REGISTER.ERROR.RESEND_EMAIL_OTP);

    await resendOtp({ email });
    setIsShowResentMsg(true);
    setIsOTPSent(true);
    setIsFirstTime(false);
    if (otpBalance === 0) {
      navigate(ROUTES.PRIVATE.COOL_DOWN);
    }
  };

  const onOtpTimerComplete = () => {
    setCanSentOtp(true);
  };

  const onOTPFocus = () => {
    setHelperText("");
  };

  const { timeLeft: otpTimer } = useTimer({
    time: OTP_WAITING_TIME,
    onComplete: onOtpTimerComplete,
    start: !canSentOtp,
  });

  return (
    <FTUELayout title1="Email" title2="Verification">
      <Box sx={{ mb: "3rem" }}>
        <p className={styles.AuthenticationDescription}>
          {`${DSEC_WE_HAVE_SENT} ${DESC_DIGIT_CODE}`}
          <span className={styles.BoldText}>{DESC_SPAM}</span>
          {DESC_IF_YOU}
          <span className={styles.BoldText}>{DESC_NOTSPAM}</span>
          {SKIP_EMAIL_VERIFICATION}
          <Button
            variant="text"
            sx={{ fontSize: "1.4rem" }}
            onClick={handleClick}
          >
            {CLICK_HERE}
          </Button>
        </p>
      </Box>

      <Box className={styles.pin_block}>
        <OTPInput
          otpLength={DEFAULT_OTP_LENGTH}
          onComplete={onOTPComplete}
          legend={CODE_LABEL}
          showAlert={isOTPSent}
          onFocus={onOTPFocus}
          helpText={helperText ?? ""}
          error={!!helperText}
          onAlertClose={handleAlertClose}
          sx={{ mb: "1.2rem" }}
          alertMessage={
            isFirstTime
              ? "Code sent successfully!"
              : "Code resent successfully!"
          }
          bgColorProp="var(--text-input-label-2)"
          disabled={isEmailVerifying}
        />
        <OTPCount
          attemptsLeft={otpBalance}
          onResend={resendVerificationCode}
          resendLabel={canSentOtp ? RESEND : `Resend code in ${otpTimer}s`}
          attemptsLabel={ATTEMPT_LEFT}
          sx={{ justifyContent: "flex-end", mb: "3rem" }}
          isAttemptEnabled
          loading={isOTPResending}
          disabled={!canSentOtp}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={handleEmailVerify}
          disabled={isOTPResending}
          loading={isEmailVerifying}
          label="Verify"
        />

        <Button variant="outlined" fullWidth onClick={logout}>
          Logout
        </Button>
        {isShowResentMsg && resentMsg && (
          <OtpResentSuccess
            message={HINT_MESSAGE[resentMsg]}
            onCancel={hideResentMessage}
          />
        )}
      </Box>
    </FTUELayout>
  );
}

export default EmailVerification;
