import { Box, Button, Typography } from "@mui/material";
import FTUELayout from "components/FTUELayout";
import Resources from "assets/json/Resources";
import SVGIcon from "components/icons";
import { Link } from "react-router-dom";

const { CONTACT_US_LINK } = Resources.COMMON.LINKS;

function Limit() {
  return (
    <FTUELayout
      title1="Limit reached"
      title2="for reset"
      containerStyle={{ maxWidth: { xl: "80%" } }}
    >
      <Box sx={{ maxWidth: "36.8rem" }}>
        <Typography
          component="p"
          sx={{
            fontSize: "1.4rem !important",
            fontFamily: "var(--font-primary-500)",
            color: "var(--colorTextSecondary)",
            fontWeight: "400",
            lineHeight: "1.2",
            marginBottom: "4.2rem",
          }}
        >
          Click on Contact Support and use the chat on our website for more
          assistance.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1.5rem",
            paddingLeft: " 1.2rem",
          }}
        >
          <Box
            sx={{
              color: "var(--colorTextSecondary)",
              display: "flex",
              alignSelf: "center",
              width: "2.4rem",
              height: "2.4rem",
              flexShrink: "0",
              svg: {
                width: "100%",
                height: "100%",
              },
            }}
          >
            <SVGIcon name="chat" />
          </Box>

          <Typography
            sx={{
              fontSize: { sm: "1.4rem !important", xs: "1.1rem !important" },
              color: "var(--colorTextSecondary)",
              fontFamily: "var(--font-primary-500)",
            }}
          >
            Need help?&nbsp;
            <Link
              target="_blank"
              to={CONTACT_US_LINK}
              rel="noreferrer"
              style={{
                color: "var(--colorTextPrimary)",
                textDecoration: "underline",
                fontFamily: "var(--font-primary-700)",
              }}
            >
              Contact Support.
            </Link>
          </Typography>
        </Box>
        <Box sx={{ marginTop: "4.2rem" }}>
          <Button variant="outlined" color="primary" fullWidth>
            Back to Login
          </Button>
        </Box>
      </Box>
    </FTUELayout>
  );
}

export default Limit;
