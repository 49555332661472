import { REACT_QUERY_KEYS, TimeConfig } from "config/app";
import { useQuery } from "react-query";
import { getFeatureCount } from "./homeService";
import { FeatureCountResponseType } from "./types";

const useGetFeaturesCount = () => {
  return useQuery<FeatureCountResponseType, Error>(
    REACT_QUERY_KEYS.FEATURES_COUNT,
    getFeatureCount,
    {
      staleTime: TimeConfig.ONE_HOUR,
      refetchOnWindowFocus: "always",
    },
  );
};

export default useGetFeaturesCount;
