import { Box, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import { ConfirmDialog } from "components";
import PaymentName from "components/PaymentName";
import { Breadcrumb } from "components/common";
import { ROUTES } from "config/app";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth } from "hooks";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDeleteCard } from "services/paymentService";
import { PaymentType } from "services/useGetPaymentMethods";

const breadcrumbs = [
  {
    text: "Payment Center",
    active: true,
    pageLink: ROUTES.PRIVATE.PAYMENT_CENTER,
  },
  { text: "Delete Payment" },
];

function DeleteCard() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setGlobalAlert } = useAuth();

  const payment = location.state as PaymentType;

  const handleBack = () => navigate(-1);

  const onPaymentDeleteSuccess = () => {
    setGlobalAlert({
      message: Resources.PAGES.PAYMENT_CENTER.DELETE_SUCCESS,
      messageType: "success",
    });
    handleBack();
  };

  const onPaymentDeleteFail = (error: FailedAPIStatus) => {
    setGlobalAlert({
      message: error.errorMessage,
      messageType: "error",
    });
    handleBack();
  };

  const { mutateAsync: deleteCard } = useDeleteCard({
    onError: onPaymentDeleteFail,
    onSuccess: onPaymentDeleteSuccess,
  });

  const deleteCCCard = async () => {
    await deleteCard({ paymentMethodId: payment.paymentMethodId });
  };

  if (!payment) {
    return <Navigate to={ROUTES.PRIVATE.PAYMENT_CENTER} />;
  }

  return (
    <Box
      className="app-l-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: "3rem !important",
        gap: "4.5rem",
      }}
    >
      <Breadcrumb crumbs={breadcrumbs} />
      <ConfirmDialog
        heading="Confirm Payment Deletion"
        onCancel={handleBack}
        onConfirm={deleteCCCard}
        primaryButtonLabel="Confirm"
        secondaryButtonLabel="Cancel"
      >
        <Typography>
          You are deleting the following payment below, are you sure you want to
          do this?
        </Typography>
        {payment?.nickName && (
          <Box sx={{ marginTop: "3rem" }}>
            <PaymentName nickName={payment?.nickName} />
          </Box>
        )}
      </ConfirmDialog>
    </Box>
  );
}

export default DeleteCard;
