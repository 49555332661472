import { Box, Button, Typography } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import { TypographyProps } from "@mui/material/Typography";
import LogoImage from "assets/images/logo-black.svg";
import { PropsWithChildren, ReactNode } from "react";

type TutorialProps = {
  onClose?: () => void;
  info: ReactNode;
  action: ReactNode;
};

function TutorialWrapper(props: PropsWithChildren) {
  const { children } = props;
  return (
    <Box
      sx={{
        p: { xs: "3rem", md: "6rem" },
        width: "100%",
        maxWidth: { md: 1200 },
        backgroundColor: "var(--color-primary4)",
      }}
    >
      <Box sx={{ width: "100%" }}>{children}</Box>
    </Box>
  );
}

function TutorialControlWrapper(props: PropsWithChildren) {
  const { children } = props;
  return (
    <Box
      sx={{
        display: "flex",
        gap: { xs: "1.5rem", md: "6rem" },
        mt: { md: "5rem", xs: "2rem" },
        flexDirection: { xs: "column-reverse", md: "row" },
      }}
    >
      {children}
    </Box>
  );
}

function TutorialHeader(props: Pick<TutorialProps, "onClose">) {
  const { onClose } = props;
  return (
    <>
      <Box
        sx={{
          maxWidth: { xs: "13.2rem", sm: "15.8rem" },
          img: { display: "block", maxWidth: "100%" },
        }}
      >
        <img src={LogoImage} alt="logo" />
      </Box>
      {onClose && (
        <Box sx={{ marginLeft: "auto" }}>
          <Button
            variant="text"
            color="primary"
            size="large"
            aria-label="Skip Tutorial"
            sx={{ padding: 0 }}
            onClick={onClose}
          >
            Skip Tutorial
          </Button>
        </Box>
      )}
    </>
  );
}

function TutorialTitle(props: TypographyProps) {
  const { children, ...typographyProps } = props;
  return (
    <Typography
      variant="h6"
      component="h6"
      sx={{
        fontWeight: 400,
        fontFamily: " var(--font-primary-700)",
        color: "var(--colorTextPrimary)",
        marginBottom: "1.5rem",
        lineHeight: 1.2,
        fontSize: {
          sm: "1.8rem !important",
          lg: "2rem !important",
          xl: "2.4rem !important",
        },
      }}
      {...(typographyProps as any)}
    >
      {children}
    </Typography>
  );
}

function TutorialDescription(props: TypographyProps) {
  const { children, sx, ...typographyProps } = props;
  return (
    <Typography
      sx={{
        fontWeight: 400,
        color: "var(--darkText)",
        fontFamily: "var(--font-primary-500)",
        lineHeight: { sm: 1.7, md: 1.2 },
        fontSize: {
          xs: "1.4rem !important",
          lg: "1.8rem !important",
          xl: "2.2rem !important",
        },
        ...sx,
      }}
      {...typographyProps}
    >
      {children}
    </Typography>
  );
}

function TutorialButton(props: ButtonProps) {
  const { children, sx, ...buttonProps } = props;
  return (
    <Button
      sx={{ width: "100%", maxWidth: { sm: "100%", md: "36.8rem" }, ...sx }}
      {...buttonProps}
    >
      {children}
    </Button>
  );
}

function Tutorial(props: TutorialProps) {
  const { action, info, onClose } = props;

  return (
    <TutorialWrapper>
      <Box sx={{ p: 0 }}>
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "3rem" }}
        >
          <TutorialHeader onClose={onClose} />
        </Box>
        {info}
      </Box>
      {action}
    </TutorialWrapper>
  );
}

Tutorial.ButtonWrapper = TutorialControlWrapper;
Tutorial.Button = TutorialButton;
Tutorial.Title = TutorialTitle;
Tutorial.Description = TutorialDescription;

export default Tutorial;
