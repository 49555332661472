/* eslint-disable no-unsafe-optional-chaining */
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Resources from "assets/json/Resources";
import { Breadcrumb } from "components/common";
import PWTable from "components/common/PWTable";
import { Switch } from "components/ui";
import { ROUTES } from "config/app";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth } from "hooks";
import useSidebar from "hooks/useSidebar";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FeatureType } from "services/accessCode/types";
import useGetAccessCodeDetails from "services/accessCode/useGetAccessCodeDetails";
import { decryptString, formatDate } from "utils";
import LinkSection from "../AccessCodeListing/LinkSection";
import {
  TalentAccessCodeStatusType,
  featureTableHeaders,
  talentAccessCodeKey,
  talentAccessCodeStatusDesc,
} from "../config";
import PepelInfoCard from "./PepelInfoCard";
import styles from "./styles.module.scss";

function AccessCodeDetails(): JSX.Element {
  const { CARD_LABEL, NO_FEATURES, CANNOT_DEACTIVATE_AC } =
    Resources.PAGES.ACCESS_CODE;
  const { ACTIVE, AVAILABLE_TO_USE } = talentAccessCodeKey;
  const { code } = useParams();
  const navigate = useNavigate();
  const { setGlobalAlert } = useAuth();

  const onFailure = (error: FailedAPIStatus) => {
    setGlobalAlert({ message: error?.errorMessage, messageType: "error" });
    navigate(ROUTES.PRIVATE.ACCESS_CODE);
  };

  const {
    mutate: getAccessCodeDetails,
    data,
    isLoading,
  } = useGetAccessCodeDetails({
    onError: onFailure,
  });
  const { result: accessCodeInfo } = { ...data };
  const location = useLocation();
  const { state } = location;
  const { setActiveMenu } = useSidebar();

  const breadCrumbs = useMemo(() => {
    return [
      {
        text: state?.text,
        active: true,
        pageLink: state?.pageLink,
      },
    ];
  }, [state?.text, state?.pageLink]);

  useEffect(() => {
    if (code) {
      getAccessCodeDetails({ accessCode: decryptString(code) });
    } else {
      navigate(ROUTES.PRIVATE.ACCESS_CODE);
    }
  }, [code]);

  const showConfirmation = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    accessCode: string,
    status: TalentAccessCodeStatusType,
  ) => {
    const breadCrumb = {
      text: "Access Code",
      accessCode,
      pageLink: ROUTES.PRIVATE.ACCESS_CODE,
    };

    if (status === ACTIVE) {
      setGlobalAlert({ message: CANNOT_DEACTIVATE_AC, messageType: "error" });
      return;
    }
    if (checked && status === AVAILABLE_TO_USE) {
      navigate(ROUTES.PRIVATE.ACCESS_CODE_ACTIVATION, { state: breadCrumb });
    }
  };

  const navigateBack = () => {
    setActiveMenu(null);
    navigate(-1);
  };

  const tableRows = useMemo(() => {
    return accessCodeInfo?.features.map((feature: FeatureType, index) => (
      <TableRow
        key={`feature${index + 1}`}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell className={styles.CellContent}>
          <Typography
            component="p"
            sx={{
              display: "flex",
              gap: "0.7rem",
              marginRight: "1rem",
              alignItems: "center",
              fontSize: "1.4rem !important",
            }}
          >
            {feature?.name}
          </Typography>
        </TableCell>
        <TableCell className={styles.CellContent}>
          {feature?.totalCount}
        </TableCell>
        <TableCell className={styles.CellContent}>
          {feature?.usedCount}
        </TableCell>
        <TableCell className={styles.CellContent}>
          {feature?.usedCount === "NA"
            ? "NA"
            : feature?.totalCount - +feature?.usedCount}
        </TableCell>
      </TableRow>
    ));
  }, [accessCodeInfo?.features]);

  const renderStatus = (
    accessCode: string,
    status: TalentAccessCodeStatusType,
  ) => {
    if (status === ACTIVE || status === AVAILABLE_TO_USE) {
      return (
        <FormControlLabel
          control={
            <Switch
              className="app-c-switch--primary"
              checked={status === ACTIVE}
              onChange={(e, checked) => {
                showConfirmation(e, checked, accessCode, status);
              }}
            />
          }
          label=""
        />
      );
    }
    return (
      <Typography variant="subtitle1" className={styles.utilised}>
        {(status && talentAccessCodeStatusDesc[status]) ?? status}
      </Typography>
    );
  };

  return (
    <Box className="app-l-container">
      <Breadcrumb
        crumbs={[...breadCrumbs, { text: accessCodeInfo?.accessCode || "" }]}
      />
      <Box className={styles.accessCodeContainer}>
        {isLoading && (
          <CircularProgress
            size={20}
            sx={{ position: "absolute", inset: 0, margin: "auto" }}
          />
        )}
        <Box className={styles.accessCodeHeader}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={navigateBack}>
              <ChevronLeftIcon className={styles.backButton} />
            </IconButton>
            <Typography variant="h3" className={styles.accessCodeHeading}>
              {accessCodeInfo?.accessCode}
            </Typography>
          </Box>
          {accessCodeInfo?.talentAccessCodeStatus && (
            <Box
              sx={{
                maxWidth: {
                  xs: "14.4rem",
                  sm: "100%",
                },
                flexShrink: "0",
              }}
            >
              {renderStatus(
                accessCodeInfo?.accessCode,
                accessCodeInfo?.talentAccessCodeStatus,
              )}
            </Box>
          )}
        </Box>
        <Box className={styles.blockContainer}>
          <Box className={styles.blockItem}>
            <PepelInfoCard
              label={CARD_LABEL.SPONSOR}
              value={accessCodeInfo?.sponsorName ?? ""}
            />
            {accessCodeInfo?.assignedDate && (
              <PepelInfoCard
                label={CARD_LABEL.ASSIGNED_DATE}
                value={formatDate(accessCodeInfo.assignedDate)}
              />
            )}
          </Box>
          <Box className={styles.blockItem}>
            {accessCodeInfo?.redeemedDate && (
              <PepelInfoCard
                label={CARD_LABEL.ACTIVATION_DATE}
                value={formatDate(accessCodeInfo?.redeemedDate)}
              />
            )}
            {accessCodeInfo?.expiryDate && (
              <PepelInfoCard
                label={CARD_LABEL.EXPIRY_DATE}
                value={formatDate(accessCodeInfo?.expiryDate)}
              />
            )}
          </Box>
        </Box>
        <PWTable
          headerData={featureTableHeaders}
          loading={isLoading}
          noDataMessage={NO_FEATURES}
        >
          {tableRows}
        </PWTable>
        <LinkSection />
      </Box>
    </Box>
  );
}

export default AccessCodeDetails;
