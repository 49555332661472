import { Box } from "@mui/material";
import { ROUTES } from "config/app";
import { AddOnKeys, SubscriptionOptions } from "constants/sharedTypes";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useGetFeatures } from "services/featureCenter";
import {
  FeaturesAndPurchaseInfo,
  isPurchasedInfo,
} from "services/featureCenter/types";
import { useGetFeaturesCount } from "services/home";
import { CartItem } from "../types";
import Events from "./Events";
import { ADD_ON_DETAILS, AVAILABLE_ADD_ONS } from "./config";
import styles from "./style.module.scss";
import { AddBuyUp, AddCoachingSession, BuyUpCard } from "./subComponents";
import { CoolDownInfo, DiscountInfo } from "./subComponents/UIComponents";

type BuyUpProps = {
  isMemberShipAvailable: boolean;
  cartItems: CartItem[];
  addItemToCart: (
    cartItem: AddOnKeys,
    price: number,
    quantity?: number,
  ) => void;
  onRemoveCartItem: (cartItem: AddOnKeys) => void;
  preferredPlan: SubscriptionOptions[];
  disabled?: boolean;
};

function NewBuyUpFeatures(props: BuyUpProps) {
  const {
    cartItems,
    isMemberShipAvailable,
    addItemToCart,
    onRemoveCartItem,
    preferredPlan,
    disabled,
  } = props;

  const { data: featureUtilizedInfo } = useGetFeaturesCount();

  const { data: features } = useGetFeatures();
  const navigate = useNavigate();

  const availableCoachingSessionCount = useMemo(() => {
    const coachingSessionIndex = cartItems.find(
      (item) => item.feature === "COACHING_SESSION",
    );

    return coachingSessionIndex ? coachingSessionIndex.count : 1;
  }, [cartItems]);

  const addOnsInCart = useMemo(() => {
    const obj: Record<string, boolean> = {};
    cartItems?.forEach((item) => {
      if (item.feature) obj[item.feature] = true;
    });

    return obj;
  }, [cartItems]);

  const onAddFeature = useCallback(
    (featureKey: AddOnKeys, price: number, quantity?: number) => () => {
      addItemToCart(featureKey, price, quantity);
    },
    [addItemToCart],
  );

  const handleRemoveItemFromCart = (featureKey: AddOnKeys) => () => {
    onRemoveCartItem(featureKey);
  };

  const handleCheckout = () => {
    navigate(ROUTES.PRIVATE.CHECKOUT);
  };

  return (
    <Box className={styles.swiperCarousalWrap}>
      <Box className={styles.carouselContainer}>
        {AVAILABLE_ADD_ONS.map((addOn) => {
          const details = ADD_ON_DETAILS[addOn];
          const addOnInfo = features?.result?.buyUpFeatures[details.apiKey];

          const hasPurchasedInfo = isPurchasedInfo(
            addOnInfo as FeaturesAndPurchaseInfo,
          );

          const { isPurchased = false } = hasPurchasedInfo
            ? (addOnInfo as FeaturesAndPurchaseInfo)
            : {};

          const itemCost = (
            isMemberShipAvailable
              ? addOnInfo?.basic?.cost
              : addOnInfo?.free?.cost
          ) as number;

          const discountAmount =
            (addOnInfo?.free?.cost as number) -
            (addOnInfo?.basic?.cost as number);

          const isIncluded =
            features?.result?.accessCodeDetails?.[details.apiKey] ?? false;
          const coolOfTime = addOnInfo?.coolOfPeriod ?? 0;

          const showCoolOfTime = (isIncluded || isPurchased) && coolOfTime;

          let unusedCount = 0;
          if (
            details.apiKey === "coachingSession" ||
            details.apiKey === "oadSurvey" ||
            details.apiKey === "faceCode"
          ) {
            unusedCount =
              featureUtilizedInfo?.result?.features?.[details.apiKey]?.count ??
              0;
          }

          return (
            <BuyUpCard
              active={unusedCount > 0}
              label={`unused: ${unusedCount}`}
              key={addOn}
              cardTitle={details.title}
              description={details.description}
              infoText={
                <>
                  {!isMemberShipAvailable && (
                    <DiscountInfo
                      discountAmount={discountAmount}
                      onLinkClick={handleCheckout}
                      preferredPlan={preferredPlan}
                    />
                  )}
                  {showCoolOfTime && <CoolDownInfo coolOfTime={coolOfTime} />}
                </>
              }
              cost={itemCost}
              costDescription={
                addOn === "COACHING_SESSION" ? "per session" : ""
              }
            >
              {addOn === "COACHING_SESSION" ? (
                <AddCoachingSession
                  price={itemCost}
                  isInCart={addOnsInCart?.COACHING_SESSION ?? false}
                  count={availableCoachingSessionCount}
                  disabled={disabled}
                  updateCount={(qty, price) =>
                    onAddFeature("COACHING_SESSION", price, qty)()
                  }
                  onCheckout={handleCheckout}
                  onRemoveCart={handleRemoveItemFromCart("COACHING_SESSION")}
                />
              ) : (
                <AddBuyUp
                  onAdd={onAddFeature(
                    addOnInfo?.featureKey as AddOnKeys,
                    itemCost,
                  )}
                  onCheckout={handleCheckout}
                  onRemove={handleRemoveItemFromCart(
                    addOnInfo?.featureKey as AddOnKeys,
                  )}
                  isInCart={
                    addOnsInCart?.[addOnInfo?.featureKey as string] ?? false
                  }
                  disabled={disabled}
                />
              )}
            </BuyUpCard>
          );
        })}
        <Events />
      </Box>
    </Box>
  );
}

export default NewBuyUpFeatures;
