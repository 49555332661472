import { Box, IconButton, Typography } from "@mui/material";
import { CloseIcon } from "assets/images";
import { ReactNode } from "react";
import styles from "../styles.module.scss";
import { AppLinks } from "./helperComponents";

type LayoutProps = {
  children: ReactNode;
  onClose: () => void;
  title: string;
};

function Layout(props: LayoutProps) {
  const { children, onClose, title } = props;
  return (
    <Box
      className={styles.modal}
      sx={{
        minWidth: { xs: "90vw", lg: "89.7rem !important" },
        padding: { xs: "3rem !important" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box className={styles.modal_title}>
        <Typography
          variant="h5"
          className={styles.modal_heading}
          sx={{
            fontSize: { xs: "1.8rem !important", lg: "2.4rem !important" },
          }}
        >
          {title}
        </Typography>
        <IconButton disableRipple onClick={onClose}>
          <CloseIcon className={styles.delete_icon} />
        </IconButton>
      </Box>
      <Box className={styles.modal_content} sx={{ mt: "3rem" }}>
        {children}
      </Box>
      <AppLinks />
    </Box>
  );
}

export default Layout;
