import { FailedAPIResponse, HandlerType } from "services/commonTypes";

import Resources from "assets/json/Resources";
import { REACT_QUERY_KEYS } from "config/app";
import { useMutationWithHandler } from "hooks";
import { getGeoLocation } from "./commonService";
import { GeoLocationResponse, GeolocationPayload } from "./types";

type APIResponse = GeoLocationResponse & FailedAPIResponse;
const useGetGeoLocation = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, GeolocationPayload>({
    mutationFn: getGeoLocation,
    resource: Resources.ERRORS,
    handler,
    queryOptions: { mutationKey: REACT_QUERY_KEYS.MUTATION.geoLocation },
  });
};

export default useGetGeoLocation;
