import { REACT_QUERY_KEYS } from "config/app";
import { useQuery } from "react-query";
import { FailedAPIResponse } from "services/commonTypes";
import { getConfiguration } from "./commonService";
import { AccountProfileConfigRes, Config } from "./types";

const ONE_HOUR_IN_MS = 360_0000;

type APIResponse = AccountProfileConfigRes & FailedAPIResponse;

const useGetConfig = (configType: Config) => {
  return useQuery<APIResponse, Error>(
    `${REACT_QUERY_KEYS.CONFIG}-${configType}`,
    () => getConfiguration(configType),
    {
      staleTime: ONE_HOUR_IN_MS,
    },
  );
};
export default useGetConfig;
