import { MenusAndNavigation, SectionLoader } from "components/common";
import { ROUTES } from "config/app";
import useAuth from "hooks/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

function RequireAuth() {
  const { user, globalLoader } = useAuth();
  const location = useLocation();

  return user?.email ? (
    <>
      <SectionLoader loading={globalLoader} />
      <MenusAndNavigation>
        <Outlet />
      </MenusAndNavigation>
    </>
  ) : (
    <Navigate to={ROUTES.PUBLIC.LOGIN} state={{ from: location }} replace />
  );
}

export default RequireAuth;
