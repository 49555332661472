/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { InputAdornment, TextField } from "@mui/material";
import { HTMLInputTypeAttribute, ReactNode, useMemo } from "react";
import {
  Control,
  Controller,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import { FormInputs } from "./types";

type FormFieldProps = {
  register: UseFormRegister<FormInputs>;
  name: keyof FormInputs;
  control: Control<FormInputs>;
  error: boolean;
  label?: string;
  rules: RegisterOptions;
  adornment?: ReactNode;
  adornmentPosition?: "start" | "end";
  type?: HTMLInputTypeAttribute;
  inputProps?: object;
  helperText?: string;
  placeHolder?: string;
  variant?: "standard" | "outlined" | undefined;
  disabled?: boolean;
};

function FormField(props: FormFieldProps) {
  const {
    register,
    name,
    control,
    error,
    label,
    rules,
    adornment,
    adornmentPosition,
    type,
    inputProps,
    helperText,
    placeHolder,
    variant,
    disabled,
  } = props;

  const inputAdornment = useMemo(() => {
    return adornmentPosition === "start"
      ? {
          startAdornment: (
            <InputAdornment position="start">{adornment}</InputAdornment>
          ),
        }
      : {
          endAdornment: (
            <InputAdornment position="end">{adornment}</InputAdornment>
          ),
        };
  }, [adornmentPosition, adornment]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules as RegisterOptions<FormInputs, keyof FormInputs>}
      render={({ field }) => (
        <TextField
          required
          {...register(name)}
          {...field}
          type={type}
          fullWidth
          disabled={disabled ?? false}
          error={error}
          label={label || ""}
          placeholder={placeHolder}
          InputLabelProps={{ shrink: true }}
          helperText={helperText}
          variant={variant || "outlined"}
          InputProps={{
            ...inputAdornment,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ ...inputProps }}
        />
      )}
    />
  );
}

export default FormField;
