import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { getReceiptUrl } from "./docCenterService";
import { GetReceiptsLinkPayload, ReceiptLinkResponse } from "./types";

export type ReceiptLinkAPIResponse = ReceiptLinkResponse & FailedAPIResponse;

const useGetReceiptLink = (handler?: HandlerType<ReceiptLinkAPIResponse>) => {
  return useMutationWithHandler<ReceiptLinkAPIResponse, GetReceiptsLinkPayload>(
    {
      mutationFn: getReceiptUrl,
      resource: Resources.ERRORS,
      handler,
    },
  );
};

export default useGetReceiptLink;
