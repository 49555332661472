import RequireAuth from "components/RequireAuth";
import { ROUTES } from "config/app";
import { useTheme } from "hooks";
import {
  AccessCodeConfirmation,
  AccessCodeDetails,
  AccessCodeList,
  AccountProfile,
  ChangeEmail,
  ChangePassword,
  ChangePhone,
  Checkout,
  DeleteCard,
  DeleteTalentAccount,
  DocumentCenter,
  ForgotPassword,
  Home,
  Login,
  OnBehalf,
  PaymentCenter,
  PaymentSuccess,
  PaymentUsage,
  PurchaseCenter,
  PurchaseSubscription,
  SignUp,
  TermsAndConditions,
  VerifyPhone,
  VerifyEmail,
} from "pages";
import ChangeAge from "pages/AccountProfile/ChangeAge";
import DeactivateAccount from "pages/AccountProfile/DeactivateAccount";
import DeleteAccount from "pages/AccountProfile/DeleteAccount";
import EmailVerification from "pages/ForgotPassword/VerificationPage/emailVerification";
import Limit from "pages/ForgotPassword/VerificationPage/limit";
import YourActivity from "pages/Home/YourActivity";
import DowngradeSubscription from "pages/PurchaseCenter/DowngradePlan";
import Guardian from "pages/SignUp/Guardian";
import GuardianSuccess from "pages/SignUp/Guardian/GuardianSuccess";
import VerificationTime from "pages/VerificationTime";
import AddPaymentMethod from "pages/PaymentCenter/addPaymentMethod";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const {
  PUBLIC: {
    LOGIN,
    FORGOT_PASSWORD,
    SIGN_UP,
    TERMS_AND_CONDITIONS,
    SIGN_UP_WITH_CAMPAIGN,
    GUARDIAN,
    GUARDIAN_SHORT,
    GUARDIAN_SUCCESS,
    SINGLE_SIGN_ON,
    EMAIL_VERIFICATION,
    LIMIT_REACHED,
    DELETE_ACCOUNT_TALENT,
  },
  PRIVATE: {
    ADD_PAYMENT,
    PURCHASE_CENTER,
    PAYMENT_CENTER,
    DOCUMENT_CENTER,
    ACCOUNT_PROFILE,
    UPDATE_EMAIL,
    UPDATE_PASSWORD,
    UPDATE_PHONE,
    DELETE_PAYMENT,
    DEACTIVATE_ACCOUNT,
    ON_BEHALF,
    ACCESS_CODE,
    ACCESS_CODE_DETAILS,
    HOME,
    CHECKOUT,
    PAYMENT_USAGE,
    ACCESS_CODE_ACTIVATION,
    DOWNGRADE_SUBSCRIPTION,
    PURCHASE_SUBSCRIPTION,
    PAYMENT_SUCCESS,
    CHANGE_AGE,
    COOL_DOWN,
    VERIFY_PHONE,
    VERIFY_EMAIL,
    DELETE_ACCOUNT,
    YOUR_ACTIVITY,
  },
} = ROUTES;

function PageRoutes() {
  const { mode } = useTheme();

  // Add theme class to body before loading
  React.useLayoutEffect(() => {
    document.body.classList.add(`theme-${mode}`);
  }, [mode]);

  return (
    <Routes>
      <Route path={LOGIN} element={<Login />} />
      <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={SIGN_UP} element={<SignUp />} />
      <Route path={SIGN_UP_WITH_CAMPAIGN} element={<SignUp />} />
      <Route path={TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
      <Route path={GUARDIAN} element={<Guardian />} />
      <Route path={GUARDIAN_SHORT} element={<Guardian />} />
      <Route path={GUARDIAN_SUCCESS} element={<GuardianSuccess />} />
      <Route path={SINGLE_SIGN_ON} element={<Login />} />
      <Route path={EMAIL_VERIFICATION} element={<EmailVerification />} />
      <Route path={LIMIT_REACHED} element={<Limit />} />
      <Route path={COOL_DOWN} element={<VerificationTime />} />
      <Route path={DELETE_ACCOUNT_TALENT} element={<DeleteTalentAccount />} />

      <Route element={<RequireAuth />}>
        <Route path={PURCHASE_CENTER} element={<PurchaseCenter />} />
        <Route path={PAYMENT_CENTER} element={<PaymentCenter />} />
        <Route path={ADD_PAYMENT} element={<AddPaymentMethod />} />
        <Route path={ACCOUNT_PROFILE} element={<AccountProfile />} />
        <Route path={DOCUMENT_CENTER} element={<DocumentCenter />} />
        <Route path={UPDATE_EMAIL} element={<ChangeEmail />} />
        <Route path={UPDATE_PASSWORD} element={<ChangePassword />} />
        <Route path={UPDATE_PHONE} element={<ChangePhone />} />
        <Route path={DEACTIVATE_ACCOUNT} element={<DeactivateAccount />} />
        <Route path={DELETE_ACCOUNT} element={<DeleteAccount />} />
        <Route path={DELETE_PAYMENT} element={<DeleteCard />} />
        <Route path={ON_BEHALF} element={<OnBehalf />} />
        <Route path={ACCESS_CODE} element={<AccessCodeList />} />
        <Route path={ACCESS_CODE_DETAILS} element={<AccessCodeDetails />} />
        <Route path={VERIFY_PHONE} element={<VerifyPhone />} />
        <Route path={VERIFY_EMAIL} element={<VerifyEmail />} />
        <Route path={YOUR_ACTIVITY} element={<YourActivity />} />
        <Route
          path={ACCESS_CODE_ACTIVATION}
          element={<AccessCodeConfirmation />}
        />
        <Route path={HOME} element={<Home />} />
        <Route path={CHECKOUT} element={<Checkout />} />
        <Route path={PAYMENT_USAGE} element={<PaymentUsage />} />
        <Route
          path={DOWNGRADE_SUBSCRIPTION}
          element={<DowngradeSubscription />}
        />
        <Route
          path={PURCHASE_SUBSCRIPTION}
          element={<PurchaseSubscription />}
        />
        <Route path={PAYMENT_SUCCESS} element={<PaymentSuccess />} />
        <Route path={CHANGE_AGE} element={<ChangeAge />} />
      </Route>
      <Route path="*" element={<Navigate to={LOGIN} replace />} />
    </Routes>
  );
}

export default PageRoutes;
