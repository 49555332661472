import { Box, SxProps, Typography } from "@mui/material";

type TitleProps = {
  title1?: string;
  title2?: string;
  sx?: SxProps;
};
function TitleField(props: TitleProps) {
  const { title1, title2, sx } = props;

  return (
    <Box sx={{ mb: { xl: "5.7rem", sm: "3.5rem", xs: "2.5rem" }, ...sx }}>
      <Typography
        sx={{
          fontSize: {
            xl: "5rem !important",
            lg: "4rem !important",
            sm: "3.3rem !important",
            xs: "2.4rem !important",
          },
          fontWeight: 400,
          fontFamily: "var(--font-primary-700)",
          color: "var(--colorTextPrimary)",
          lineHeight: "1",
        }}
      >
        {title1}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xl: "5rem !important",
            lg: "4rem !important",
            sm: "3.3rem !important",
            xs: "2.4rem !important",
          },
          fontWeight: 400,
          fontFamily: "var(--font-primary-700)",
          color: "var(--color-primary)",
          lineHeight: "1",
          marginTop: "1rem",
        }}
      >
        {title2}
      </Typography>
    </Box>
  );
}

export default TitleField;
