import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import SVGIcon from "components/icons";
import { ROUTES } from "config/app";
import useSidebar from "hooks/useSidebar";
import { TalentAccessCodeStatusType } from "pages/AccessCode/config";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { AccessCodeDetailType } from "services/accessCode/types";
import { Paragraph, Title } from "../subComponents";
import AccessCodeList from "./AccessCodeList";
import AddAccessCode from "./AddAccessCode";
import { ACCESS_CODE_TABLE_MAX_ROW_COUNT } from "./config";

type AccessCodeTypeProps = {
  accessCodeList: AccessCodeDetailType[];
};

function TooltipContent() {
  const { setActiveMenu } = useSidebar();
  return (
    <Typography sx={{ fontSize: "1.1rem !important" }}>
      Access codes can be from a school, a company, a club or organization that
      offers special features in pepelwerk for their community. If you
      don&apos;t have one,&nbsp;
      <Link
        to={ROUTES.PRIVATE.PURCHASE_CENTER}
        style={{ color: "var(--color-primary)", textDecoration: "underline" }}
        onClick={() => setActiveMenu("featureCenter")}
      >
        add the features you want.
      </Link>
    </Typography>
  );
}

function AccessCode(props: AccessCodeTypeProps) {
  const { accessCodeList } = props;
  const { setActiveMenu } = useSidebar();

  const filteredAccessCodes = useMemo(() => {
    if (!accessCodeList?.length) return [];

    const accessCodeTypes: {
      [key in TalentAccessCodeStatusType]: AccessCodeDetailType[];
    } = {
      ACTIVE: [],
      AVAILABLE_TO_USE: [],
      UTILIZED: [],
      EXPIRED: [],
      IN_ACTIVE: [],
      REQUESTED: [],
      REJECTED: [],
    };

    accessCodeList.forEach(
      (code) =>
        code?.talentAccessCodeStatus !== "REJECTED" &&
        accessCodeTypes[code.talentAccessCodeStatus]?.push(code),
    );

    accessCodeTypes.AVAILABLE_TO_USE.sort(
      (a, b) => +new Date(a.assignedDate) - +new Date(b.assignedDate),
    );
    const { ACTIVE, AVAILABLE_TO_USE, REQUESTED, REJECTED } = accessCodeTypes;
    return [...ACTIVE, ...AVAILABLE_TO_USE, ...REQUESTED, ...REJECTED];
  }, [accessCodeList]);

  return (
    <Box
      className="app-c-card--default"
      sx={{ flexBasis: { md: "100%", lg: "auto" } }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1.5rem",
        }}
      >
        <Title title="Access code" sx={{ marginBottom: "0" }} />
        <Tooltip title={<TooltipContent />}>
          <IconButton size="small" aria-label="close" sx={{ color: "inherit" }}>
            <SVGIcon name="question" />
          </IconButton>
        </Tooltip>
      </Box>
      <Paragraph
        sx={{ marginBottom: "1rem" }}
        text="These codes are assigned and available to you. Activate them here"
      />
      <AccessCodeList
        list={filteredAccessCodes?.slice(0, ACCESS_CODE_TABLE_MAX_ROW_COUNT)}
      />
      {filteredAccessCodes?.length > ACCESS_CODE_TABLE_MAX_ROW_COUNT ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Link
            to={ROUTES.PRIVATE.ACCESS_CODE}
            style={{
              fontFamily: "var(--font-primary-700)",
              textDecoration: "underline",
              color: "var(--colorTextPrimary)",
              fontSize: "1.4rem",
            }}
            onClick={() => setActiveMenu("accessCode")}
          >
            View All Access Codes
          </Link>
        </Box>
      ) : null}
      <AddAccessCode />
    </Box>
  );
}

export default AccessCode;
