import { SxProps, Typography } from "@mui/material";

export type Titile = {
  sx?: SxProps;
  title: string;
};
function Title(props: any) {
  const { sx, title } = props;
  return (
    <Typography
      component="h3"
      sx={{
        fontSize: "2.0rem !important",
        color: "var(--color-secondary)",
        lineHeight: "normal",
        margin: "0 0 1.5rem",
        fontFamily: "var(--font-primary-700)",
        wordBreak: "break-word",
        ...sx,
      }}
    >
      {title}
    </Typography>
  );
}

export default Title;
