import Resources from "assets/json/Resources";
import { useMutation } from "react-query";
import { viewUsage } from "./PaymentCenterService";

type ErrorResponse = {
  status: number;
  inputErrors: {
    exists: boolean;
  };
};

export type PurchaseDetailType = {
  purchasetype: string;
  featuretitle: string;
  amount: number;
  purchaseddate: string;
  count: number;
};

type SuccessResponse = {
  status: number;
  result: {
    isSuccess: boolean;
    purchaseDetails: PurchaseDetailType[];
  };
};

type UsageHandler = {
  onSuccess?: (summary: PurchaseDetailType[]) => void;
  onError?: (errorMsg: string) => void;
};

const usePaymentUsage = (handlers?: UsageHandler) => {
  const onError = () => {
    handlers?.onError?.(Resources.ERRORS.SOMETHING);
  };

  const onSuccess = (data: SuccessResponse & ErrorResponse) => {
    if (data.inputErrors?.exists) {
      onError();
    }
    if (data?.result?.isSuccess && data.status === 200) {
      handlers?.onSuccess?.(data.result.purchaseDetails);
    }
  };
  return useMutation(viewUsage, { onSuccess, onError });
};

export default usePaymentUsage;
