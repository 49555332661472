import Box from "@mui/material/Box";
import { Tutorial } from "components";
import { PropsWithChildren } from "react";
import { TutorialInfo } from "./HomePage/config";

type TutorialItemProps = {
  onClose?: () => void;
  onBackClick?: () => void;
  onNextClick?: () => void;
  tutorialInfo: TutorialInfo;
  primaryActionLabel?: string;
} & PropsWithChildren;

function TutorialItem(props: TutorialItemProps) {
  const {
    tutorialInfo,
    children,
    onBackClick,
    onNextClick,
    onClose,
    primaryActionLabel = "Next",
  } = props;
  return (
    <Tutorial
      onClose={onClose}
      info={
        <Box>
          <Tutorial.Title>{tutorialInfo.title}</Tutorial.Title>
          <Tutorial.Description sx={{ mt: 2 }}>
            {tutorialInfo.description}
          </Tutorial.Description>
        </Box>
      }
      action={
        <Tutorial.ButtonWrapper>
          {onBackClick && (
            <Tutorial.Button variant="outlined" onClick={onBackClick}>
              Previous
            </Tutorial.Button>
          )}
          {onNextClick && (
            <Tutorial.Button variant="contained" onClick={onNextClick}>
              {primaryActionLabel}
            </Tutorial.Button>
          )}
          {children}
        </Tutorial.ButtonWrapper>
      }
    />
  );
}

export default TutorialItem;
