import REGEX from "constants/regex";

const { ONLY_NUMBERS, CARD_TYPES, GROUP_4_DIGITS } = REGEX;

export const formatCreditCard = (cardNumber: string) => {
  const digitsOnly = cardNumber.replace(/\D/g, "");
  if (ONLY_NUMBERS.test(digitsOnly)) {
    const formattedValue = digitsOnly.replace(GROUP_4_DIGITS, "$1 ");
    return formattedValue;
  }
  return "";
};

export const getCardType = (cardNum: string) => {
  let cardType = "Credit Card";
  if (cardNum !== "") {
    const num = cardNum.replace(/[^\d]/g, "");
    const keys = Object.keys(CARD_TYPES);
    const indexOfType = keys.findIndex((type) => {
      return num.match(CARD_TYPES[type]);
    });
    if (indexOfType > -1) cardType = keys[indexOfType];
  }
  return cardType;
};
