import { Box, Button, Drawer, Grid } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Logo from "assets/images/logo.svg";
import { SidebarContent } from "components";
import SVGIcon from "components/icons";
import useSidebar from "hooks/useSidebar";

const DRAWER_WIDTH = 300;

function Header() {
  const { open, setSidebarVisibility } = useSidebar();

  const handleSidebarOpen = () => setSidebarVisibility({ isVisible: true });
  const handleSidebarClose = () => setSidebarVisibility({ isVisible: false });

  return (
    <MuiAppBar
      position="static"
      className="app-l-header"
      sx={{ display: { lg: "none" } }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          alignItems="center"
          sx={{ minHeight: "10.2rem" }}
          spacing={2}
        >
          <Grid item xs="auto">
            <IconButton component="a">
              <img src={Logo} alt="brand logo" />
            </IconButton>
          </Grid>
          <Grid item xs="auto" ml="auto">
            <Grid container spacing={0} alignItems="center">
              <Grid
                item
                xs="auto"
                sx={{ display: { sm: "block", lg: "none" } }}
              >
                <IconButton
                  onClick={handleSidebarOpen}
                  sx={{
                    color: "var(--colorWhite)",
                    ...(open && { display: "none" }),
                  }}
                  size="small"
                  component="label"
                >
                  <SVGIcon name="hamburger" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          display: { lg: "none" },
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
          },
        }}
        variant="temporary"
        anchor="left"
        open={open}
        ModalProps={{ onClose: handleSidebarClose }}
        PaperProps={{ id: "mob-sidebar-scroll-container" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "var(--color-secondary)",
            borderRight: "0.6rem solid var(--color-primary)",
          }}
        >
          <Box sx={{ height: "100%", overflow: "auto" }}>
            <SidebarContent />
          </Box>
          <Box
            sx={{
              padding: "3rem",
              position: "sticky",
              bottom: "0",
              backgroundColor: "inherit",
            }}
          >
            <Button variant="contained" fullWidth onClick={handleSidebarClose}>
              Close Menu
            </Button>
          </Box>
        </Box>
      </Drawer>
    </MuiAppBar>
  );
}

export default Header;
