/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SVGIcon from "components/icons";
import { HowCanWeHelpType } from "services/accountProfileService/types";
import { HOW_CAN_WE_HELP_OPTIONS } from "../config";

type HelpOptionProps = {
  helpOption: HowCanWeHelpType;
};

function TooltipContent() {
  return (
    <Typography sx={{ fontSize: "1.1rem !important" }}>
      The goal you choose lets us know what you are looking for. If you ever
      need to change your goal, go to account settings.
    </Typography>
  );
}

function HelpOption(props: HelpOptionProps) {
  const { helpOption } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box sx={{ alignItems: "center", gap: "0.1rem" }}>
      <Typography
        component="span"
        sx={{
          fontSize: "1.4rem !important",
          fontFamily: "var(--font-primary-500 )",
          color: "var(--color-secondary)",
          lineHeight: "normal",
        }}
      >
        Thanks for choosing pepelwerk to help you&nbsp;
        <Typography
          component="span"
          sx={{
            fontFamily: "var(--font-primary-700)",
          }}
        >
          "
          {helpOption?.option
            ? HOW_CAN_WE_HELP_OPTIONS[helpOption?.option]
            : HOW_CAN_WE_HELP_OPTIONS.OPTION1}
          "
        </Typography>
      </Typography>
      <Tooltip
        placement={isSmallScreen ? "bottom-start" : "bottom"}
        title={<TooltipContent />}
      >
        <IconButton size="small" aria-label="close" sx={{ color: "inherit" }}>
          <SVGIcon name="question" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default HelpOption;
