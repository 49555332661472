import { addPublicAccessCodeSchema } from "utils/validationSchema";
import * as yup from "yup";

export type AccessCodeFormData = yup.InferType<
  typeof addPublicAccessCodeSchema
>;

export const talentAccessCodeStatusKey = {
  ACTIVE: "ACTIVE",
  AVAILABLE_TO_USE: "AVAILABLE_TO_USE",
  UTILIZED: "UTILIZED",
  EXPIRED: "EXPIRED",
  IN_ACTIVE: "IN_ACTIVE",
  REQUESTED: "REQUESTED",
  REJECTED: "REJECTED",
};

export const ACCESS_CODE_TABLE_MAX_ROW_COUNT = 2;
