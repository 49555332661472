import { IconButton, TableRow } from "@mui/material";
import Resources from "assets/json/Resources";
import { Table, TableCell } from "components/common";
import SVGIcon from "components/icons";
import { PaymentReceiptType, TableHeaderType } from "constants/sharedTypes";
import { useAuth } from "hooks";
import { useMemo } from "react";
import { useGetReceiptLink } from "services/docCenter";
import { ReceiptLinkAPIResponse } from "services/docCenter/useGetReceiptLink";
import { openURL, parseDate } from "utils";

type ReceiptsProps = {
  receipts: PaymentReceiptType[];
  loading: boolean;
};

const TABLE_HEADER: TableHeaderType[] = [
  { key: "name", label: "Name", align: "left", width: "40%" },
  { key: "purchaseDate", label: "Purchase Date" },
  { key: "view", label: "View", align: "right" },
];

function Receipts(props: ReceiptsProps) {
  const { setGlobalAlert } = useAuth();

  const { receipts, loading } = props;

  const onReceiptLinkSuccess = (data?: ReceiptLinkAPIResponse) => {
    if (data?.result?.receiptUrl) {
      openURL(data.result.receiptUrl);
    }
  };

  const { mutateAsync: getReceiptLink } = useGetReceiptLink({
    onSuccess: onReceiptLinkSuccess,
  });

  const handleReceiptView = (receipt: PaymentReceiptType) => async () => {
    const { paymentMethodId, purchaseId, receiptNumber } = receipt;
    setGlobalAlert({
      messageType: "info",
      message: Resources.PAGES.DOCUMENT_CENTER.POP_UP_INFO,
    });
    await getReceiptLink({ paymentMethodId, purchaseId, receiptNumber });
  };

  const tableRows = useMemo(() => {
    return receipts.map((receipt) => (
      <TableRow key={receipt.purchaseId}>
        <TableCell>
          <TableCell.Text text={receipt.receiptNumber} />
        </TableCell>
        <TableCell>
          <TableCell.Text text={parseDate(receipt.purchasedDate)} />
        </TableCell>
        <TableCell align="right">
          <IconButton
            onClick={handleReceiptView(receipt)}
            sx={{ padding: "0" }}
          >
            <SVGIcon name="eye" />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receipts]);

  return (
    <Table
      loading={loading}
      headerData={TABLE_HEADER}
      noDataMessage="No receipts are available"
      tableContainerProps={{ sx: { maxHeight: "80vh" } }}
    >
      {tableRows}
    </Table>
  );
}

export default Receipts;
