import requests from "config/api";
import API_ENDPOINTS from "constants/api";
import { CartItemPayload, CheckoutPayload, PaymentPayload } from "./types";

export const getFeatures = async () => {
  return requests.get(API_ENDPOINTS.BUY_UP_FEATURES);
};

export const getMemberShipPlan = () => {
  return requests.get(API_ENDPOINTS.MEMBERSHIP_PLANS);
};

export const downgradeSubscription = () => {
  return requests.post(API_ENDPOINTS.DOWNGRADE_SUBSCRIPTION);
};

export const makePayment = (paymentData: PaymentPayload) => {
  const { subscriptionId, ...paymentInfo } = paymentData;
  return requests.post(API_ENDPOINTS.MAKE_PAYMENT, {
    ...paymentInfo,
    ...(subscriptionId ? { subscriptionId } : {}),
  });
};

export const getCartItems = () => {
  return requests.get(API_ENDPOINTS.GET_CART);
};

export const addItemToCart = (payload: CartItemPayload) => {
  const data: CartItemPayload = {
    feature: payload.feature,
    ...(payload?.count && { count: payload.count }),
  };
  return requests.post(API_ENDPOINTS.ADD_CART_ITEM, data);
};

export const removeCartItem = (payload: Pick<CartItemPayload, "feature">) => {
  return requests.post(API_ENDPOINTS.REMOVE_CART_ITEM, payload);
};

export const checkoutItems = (payload: CheckoutPayload) => {
  const data: CheckoutPayload = {
    isCartCheckout: payload.isCartCheckout,
    paymentMethodId: payload.paymentMethodId,
    amountWithTax: payload.amountWithTax,
    taxRate: payload.taxRate,
    isTaxCodeApplicable: payload.isTaxCodeApplicable,
    ...(payload?.taxCode && { taxCode: payload.taxCode }),
    ...(payload?.subscriptionId && { subscriptionId: payload.subscriptionId }),
    ...(payload.buyUpFeatures?.length && {
      buyUpFeatures: payload.buyUpFeatures,
    }),
  };
  return requests.post(API_ENDPOINTS.CHECKOUT, data);
};
