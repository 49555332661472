import { Button, Fade } from "@mui/material";
import Box from "@mui/material/Box";

export type CheckoutProps = {
  onCheckout: () => void;
  onRemove: () => void;
  disabled?: boolean;
};

function CheckoutButtons(props: CheckoutProps) {
  const { onCheckout, onRemove, disabled } = props;
  return (
    <Fade in>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Button
          variant="outlined"
          onClick={onCheckout}
          fullWidth
          disabled={disabled}
        >
          Proceed to Checkout
        </Button>
        <Button
          variant="text"
          sx={{ mt: "1.5rem", fontFamily: "var(--font-primary-700)" }}
          onClick={onRemove}
          fullWidth
          disabled={disabled}
        >
          Remove From Cart
        </Button>
      </Box>
    </Fade>
  );
}

export default CheckoutButtons;
