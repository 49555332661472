const API_ENDPOINTS = {
  LOGIN: "v1.0/auth/talent/signin",
  HOME_DETAILS: "v1.0/api/talent/home",
  SIGN_OUT: "v1.0/auth/talent/signout",
  FORGOT_PASSWORD_OTP: "v1.0/auth/talent/forgotpasswordotp",
  RESET_PASSWORD: "v1.0/auth/talent/resetforgotpassword",
  PAYMENT_METHODS: "v1.0/auth/talent/paymentcenter/paymentmethods",
  ACCESS_CODES: "v1.0/auth/talent/paymentcenter/accesscodes",
  PROFILE_DETAILS: "v1.0/api/talent/job/viewhome",
  ADD_NEW_CC: "v1.0/auth/talent/paymentcenter/addcard",
  DELETE_PAYMENT: "v1.0/auth/talent/paymentcenter/deletepaymentmethod",
  ADD_ACH: "v1.0/auth/talent/paymentcenter/addach",
  REDEEM_ACCESS_CODE: "v1.0/api/talent/redeemaccesscode",
  MEMBERSHIP_PLANS: "v1.0/api/talent/membership/subscriptionplans",
  BUY_UP_FEATURES: "v1.0/auth/talent/buyup/features",
  DOWNGRADE_SUBSCRIPTION: "v1.0/api/talent/membership/preventautorenew",
  TERMS_AND_CONDITIONS: "v1.0/unsecure/gettalenttncversion",
  VIEW_USAGE: "v1.0/auth/talent/paymentcenter/purchasedetails",
  MAKE_PAYMENT: "v1.0/auth/talent/buyup/checkout",
  UPDATE_PHONE_NUMBER: "v1.0/api/talent/account/setphone",
  TRIGGER_OTP_SMS: "v1.0/api/talent/account/sendphoneotp",
  VERIFY_PHONE: "v1.0/api/talent/account/verifyphone",
  VALIDATE_ACCESS_CODE: "v1.0/auth/talent/validateaccesscode",
  RESEND_OTP: "v1.0/auth/talent/registerresendotp",
  SET_EMAIL_VERIFICATION: "v1.0/auth/talent/whyareyouhere",
  AUTO_REDEEM_ACCESS_CODE: "v1.0/api/talent/account/autoredeemoremailverify",
  GET_DOCUMENT_LIST: "v1.0/api/documentcenter/talent/getgeneraldocuments",
  GET_DOCUMENT_RECEIPTS: "v1.0/api/documentcenter/talent/receipts",
  GET_RECEIPT_URL: "v1.0/api/documentcenter/talent/receipturl",
  ACCOUNT_PROFILE_HOME: "v1.0/api/talent/account/home",
  UPDATE_NAME: "v1.0/api/talent/account/updatename",
  GEO_LOCATION: "v1.0/api/mdm/geolocation",
  SET_LOCATION: "v1.0/api/talent/account/setlocation",
  ON_BEHALF_OF_LOGIN: "v1.0/auth/talent/signinastalent",
  DEACTIVATE_ACCOUNT: "v1.0/api/talent/membership/deactivateaccount",
  GETHOWCANWEHELP: "v1.0/auth/talent/howcanwehelpoptions",
  SAVEHELPOPTION: "v1.0/auth/talent/howcanwehelpoptions ",
  CAREERPHASEOPTIONS: "v1.0/api/common/getconfiguration",
  SAVECAREEROPTIONS: "v1.0/api/talent/account/updatecareerphaseoption",
  EMAIL_OTP: "v1.0/api/talent/account/changeemailotp",
  OLD_EMAIL_VERIFY: "v1.0/api/talent/account/validateoldemailotp",
  NEW_EMAIL_VERIFY: "v1.0/api/talent/account/changeemail",
  CHANGE_PASSWORD: "v1.0/api/talent/account/changepassword",
  CAMPAIGN_DETAILS: "v1.0/api/talent/setcampaignaccesscode",
  UPDATE_TUTORIAL: "v1.0/auth/talent/updateftuepageasvisited",

  // 2.0 Endpoints
  REGISTER_TALENT: "v2.0/auth/talent/registerwithtnc",
  VALIDATE_GUARDIAN_TOKEN: "/v2.0/auth/talent/guardian/validatetoken",
  VALIDATE_GUARDIAN_SHORT_TOKEN: "v2.0/auth/talent/guardian/:token",
  GUARDIAN_CONSENT: "v2.0/auth/talent/guardianconsent",
  GUARDIAN_CONSENT_RESEND_EMAIL: "v2.0/auth/talent/guardianconsent/resend",
  UPLOAD_PROFILE_PIC: "v1.0/api/talent/job/settalentmedia",
  ADD_PUBLIC_ACCESS_CODE: "v2.0/api/talent/accesscode/addaccesscode",
  FEATURES_COUNT: "v2.0/api/talent/account/featurescount",
  VERIFY_EMAIL: "v2.0/auth/talent/verifyemail",
  UPDATE_EMAIL: "v2.0/api/talent/account/setemail",
  ACCESS_CODE_DETAIL: "v2.0/api/talent/accesscode/accesscodedetails",
  SET_WELCOME: "/v2.0/api/talent/account/setwelcome",
  SEND_PHONE_OTP: "v2.0/api/talent/account/sendphoneotp",
  UPDATE_AGE: "/v2.0/api/talent/account/updateagegroup",
  CHANGE_PHONE: "v2.0/api/talent/account/verifyphone",
  SINGLE_SIGN_ON: "v2.0/auth/talent/singlesignon",
  GET_CART: "/v2.0/api/talent/cart/view",
  ADD_CART_ITEM: "/v2.0/api/talent/cart/update",
  REMOVE_CART_ITEM: "/v2.0/api/talent/cart/remove",
  CHECKOUT: "/v2.0/api/talent/buyup/checkout",
  VALIDATE_MAIL_OTP: "/v2.0/auth/talent/validateemailotp",
  DELETE_ACCOUNT_OTP: "/v2.0/auth/talent/generatedeactivateotp",
  DELETE_ACCOUNT: "/v2.0/auth/talent/deactivateaccount",
  VALIDATE_PHONE: "v2.0/api/talent/account/sendphonevalidationotp",
  VALIDATE_PHONE_OTP: "v2.0/api/talent/account/validatephoneotp",
  VALIDATE_EMAIL_OTP: "v2.0/api/talent/account/sendemailotp",
  COUNTRY_LIST: "/v2.0/api/common/getvalidcountrieslist",
  SET_COUNTRY: "/v2.0/api/talent/account/setcountry",
  GET_JOB_OFFERS: "/v2.0/api/talent/documentcenter/joboffers",
  JOB_OFFER: "v2.0/api/talent/documentcenter/urltoken/joboffer",
  JOB_OFFER_LINK: "v2.0/api/talent/documentcenter/urltoken/joboffer",
  DOWNLOAD_JOB_OFFER: "/v2.0/api/talent/documentcenter/joboffer/download",
  YOUR_ACTIVITY: "/v2.0/api/talent/account/talentactivites",
  GET_TIMEZONE_INFO: "/v2.0/api/common/gettimezoneiddetails",
  GET_TAX_DETAILS: "/v2.0/api/common/gettaxdetails",
  GET_COUNTRY_LIST: "/v2.0/unsecure/getvalidcountrieslist",
  FORGET_PASSWORD_PHONE_OTP: "/v2.0/auth/talent/forgotpasswordphoneotp",
  FORGOT_VERIFY_PHONE: "/v2.0/auth/talent/validatephoneotp",
};

export default API_ENDPOINTS;
