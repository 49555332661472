import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid } from "@mui/material";
import Resources from "assets/json/Resources";
import CountryPhoneBlock from "components/CountryPhoneBlock";
import FTUELayout from "components/FTUELayout";
import { LoadingButton } from "components/common";
import { OTPCount, OTPInput } from "components/ui";
import OtpResentSuccess from "components/ui/OtpResentSuccess";
import { DEFAULT_OTP_LENGTH, OTP_WAITING_TIME, ROUTES } from "config/app";
import REGEX from "constants/regex";
import {
  FailedAPIStatus,
  TalentRegistrationStepTypes,
} from "constants/sharedTypes";
import { useAuth } from "hooks";
import useTimer from "hooks/useTimer";
import { HINT_MESSAGE } from "pages/ForgotPassword/config";
import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  useChangePhone,
  useGetPhoneOTP,
  useVerifyPhone,
} from "services/accountProfileService";
import {
  PhoneOTPSendResponse,
  VerifyPhoneResponse,
} from "services/accountProfileService/types";
import useGetTalentHomeDetails from "services/useGetTalentHomeDetails";
import { isValidMobileNumber } from "utils";
import triggerGAEvent, { GAEvents, GA_USER_FLOW } from "utils/gaEvents";
import { changePhoneNumber } from "utils/validationSchema";
import * as yup from "yup";

import { useGetCountryCode } from "services/common";
import EditPhone from "./EditPhone";
import styles from "./styles.module.scss";

type ChangePhoneFormType = yup.InferType<typeof changePhoneNumber>;
type FormField = keyof typeof changePhoneNumber.fields;

type PhoneNumberVerificationProps = {
  onComplete: (nextStep: TalentRegistrationStepTypes) => void;
  handleAttemptsLeft: (attemptsLeftCount: number) => void;
};

export type PhoneType = {
  isdCode: string;
  phoneNumber: string;
  isdCountry: string;
};

const {
  PAGES: {
    SIGN_UP: {
      PHONE_NUMBER_VERIFICATION: { TITLE, SUB_TITLE, SUB_HEADING },
    },
  },
  COMMON: {
    BUTTONS: { RESEND, ATTEMPT_LEFT },
    MESSAGE: { INVALID_OTP, ENTER_MOBILE_OTP },
  },
} = Resources;
const { SPECIAL_CHARACTERS } = REGEX;

function PhoneNumberVerification(props: PhoneNumberVerificationProps) {
  const { onComplete, handleAttemptsLeft } = props;
  const [savedPhone, setSavedPhone] = useState<PhoneType>();
  const [helperText, setHelperText] = useState<string>("");
  const [showPinBlock, setShowPinBlock] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [OTP, setOTP] = useState("");
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [otpBalance, setOtpBalance] = useState(0);
  const [isShowResentMsg, setIsShowResentMsg] = useState<Boolean>(true);
  const [resentMsg, setResentMsg] = useState<string>("");
  const [canSentOtp, setCanSentOtp] = useState<Boolean>(true);
  const { logout, setGlobalAlert, getUserId } = useAuth();
  const navigate = useNavigate();

  const userId = getUserId();

  const { data: userDetails, isLoading: homeDetailsLoading } =
    useGetTalentHomeDetails();

  // form initialization
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
    trigger,
    getValues,
  } = useForm<ChangePhoneFormType>({
    resolver: yupResolver(changePhoneNumber),
    defaultValues: { isdCode: "", phoneNumber: "", isdCountry: "" },
    mode: "all",
  });

  useEffect(() => {
    if (userDetails?.home) {
      const isdCode = userDetails.home.isdCode?.replace(SPECIAL_CHARACTERS, "");
      const phone = {
        isdCode,
        phoneNumber: userDetails.home.phoneNumber,
        isdCountry: userDetails.home.isdCountry,
      };
      setSavedPhone(phone);
      reset(phone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.home]);

  const sendAnalyticsData = (event: GAEvents, step: string) => {
    try {
      triggerGAEvent(event, userId as string, {
        step,
        age_selection: userDetails?.home?.age,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const showError = (message: string) => {
    setGlobalAlert({ message, messageType: "error" });
  };

  const handleAPIError = (error: FailedAPIStatus) => {
    showError(error.errorMessage);
  };

  const { data: allCountryList } = useGetCountryCode();

  const countryList = useMemo(() => {
    const list = allCountryList?.result?.countryList?.map((country) => ({
      isdCode: country.isdCode,
      countryCode: country.shortName,
    }));
    return list;
  }, [allCountryList]);

  const onPhoneUpdated = () => {
    const newIsd = getValues("isdCode");
    const newPhone = getValues("phoneNumber");
    const isdCodeCountry = getValues("isdCountry");
    const country = countryList?.find(
      (code) => code.countryCode === isdCodeCountry,
    );

    setValue("isdCode", country?.isdCode?.substring(1).toString() ?? "USA");
    setSavedPhone({
      isdCode: newIsd,
      phoneNumber: newPhone,
      isdCountry: isdCodeCountry,
    });
    setGlobalAlert({
      messageType: "success",
      message: "Mobile number updated",
    });
  };

  const onOtpTimerComplete = () => {
    setCanSentOtp(true);
  };

  const { timeLeft: otpTimer } = useTimer({
    time: OTP_WAITING_TIME,
    onComplete: onOtpTimerComplete,
    start: !canSentOtp,
  });

  const onOTPSent = (response: PhoneOTPSendResponse) => {
    if (response && response.result) {
      const { resendPhoneOtpBalance, hintMessageCode } = response.result;

      setIsOTPSent(true);
      setOtpBalance(resendPhoneOtpBalance as number);
      setResentMsg(hintMessageCode as string);

      if (!showPinBlock) setShowPinBlock(true);
    }
  };

  const handleOTPSendError = (error: FailedAPIStatus) => {
    showError(error.errorMessage);

    // Trigger GA Event on OTP sent failure
    sendAnalyticsData("error", GA_USER_FLOW.REGISTER.ERROR.MOB_OTP_SENT);
  };

  const handlePhoneVerifyError = (error: FailedAPIStatus) => {
    setHelperText(error.errorMessage);
    showError(error.errorMessage);

    // Trigger GA event on phone verification failure
    sendAnalyticsData("error", GA_USER_FLOW.REGISTER.ERROR.MOBILE_VERIFICATION);
  };

  const onPhoneVerified = (response: VerifyPhoneResponse) => {
    const { resendEmailOtpBalance } = response.result;
    // Trigger GA event on phone verification success
    sendAnalyticsData(
      "create_account",
      GA_USER_FLOW.REGISTER.SUCCESS.MOBILE_VERIFICATION,
    );
    handleAttemptsLeft(resendEmailOtpBalance as number);
    onComplete("VERIFY_EMAIL");
  };

  // APIs
  const { mutateAsync: updatePhone, isLoading: isPhoneUpdating } =
    useChangePhone({ onSuccess: onPhoneUpdated, onError: handleAPIError });

  const { refetch: getPhoneOTP, isLoading: isOTPSending } = useGetPhoneOTP({
    onSuccess: onOTPSent,
    onError: handleOTPSendError,
  });

  const { mutateAsync: verifyPhone, isLoading: isPhoneVerifying } =
    useVerifyPhone({
      onSuccess: onPhoneVerified,
      onError: handlePhoneVerifyError,
    });

  const handlePhoneChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setShowPinBlock(false);
    const { name, value } = event.target;
    const field = name as FormField;
    setValue(field, value);
    trigger(field);
  };

  const handlePhoneSubmit: SubmitHandler<FieldValues> = async (formData) => {
    const { phoneNumber, isdCountry } = formData;
    const country = countryList?.find(
      (code) => code.countryCode === isdCountry,
    );
    const countryCode = country?.isdCode.substring(1).replace("+", "") ?? "";
    setValue("isdCode", countryCode);

    const number = `${country?.isdCode}${phoneNumber}`;
    if (!isValidMobileNumber(number)) {
      setGlobalAlert({
        messageType: "error",
        message: Resources.ERRORS.MOBILE_NUMBER_NOT_MATCHING,
      });
      return;
    }

    if (
      countryCode === savedPhone?.isdCode &&
      phoneNumber === savedPhone?.phoneNumber
    ) {
      const res = await getPhoneOTP();
      if (res?.data?.result.isOTPSent) {
        // Trigger GA Event on OTP sent success
        sendAnalyticsData(
          "create_account",
          GA_USER_FLOW.REGISTER.SUCCESS.MOB_OTP_SENT,
        );
      }
      return;
    }

    const res = await updatePhone({
      isdCode: country?.isdCode ?? "+1",
      phoneNumber,
      isdCountry,
    });
    if (res?.result?.success) {
      setSavedPhone({
        isdCode: country?.isdCode ?? "+1",
        phoneNumber,
        isdCountry,
      });
      const response = await getPhoneOTP();
      if (response?.data?.result.isOTPSent) {
        // Trigger GA Event on OTP sent success
        sendAnalyticsData(
          "create_account",
          GA_USER_FLOW.REGISTER.SUCCESS.MOB_OTP_SENT,
        );
      }
    }
  };

  const handleOTPSend = async () => {
    // update phone number if any changes
    await getPhoneOTP();
    setIsShowResentMsg(true);
    setCanSentOtp(false);
    setIsFirstTime(false);
    setIsOTPSent(true);
    if (otpBalance === 0) {
      navigate(ROUTES.PRIVATE.COOL_DOWN);
    }
  };

  const handleResendOTP = () => {
    setIsFirstTime(false);
    // Trigger GA event for OTP Resend
    sendAnalyticsData("error", GA_USER_FLOW.REGISTER.ERROR.RESEND_MOBILE_OTP);

    handleOTPSend();
  };

  const onOTPComplete = async (otp: string) => {
    setOTP(otp);
    if (otp.length === DEFAULT_OTP_LENGTH) {
      await verifyPhone({ otp, isRegistration: true });
    }
  };

  const hideResentMessage = () => {
    setIsShowResentMsg(false);
  };

  const handlePhoneVerify = async () => {
    if (!OTP.length) {
      const errorMessage = ENTER_MOBILE_OTP;
      setHelperText(errorMessage);
      setGlobalAlert({
        messageType: "error",
        message: errorMessage,
      });
      return;
    }
    if (OTP.length !== DEFAULT_OTP_LENGTH) {
      const errorMessage = INVALID_OTP;
      setHelperText(errorMessage);
      setGlobalAlert({ messageType: "error", message: errorMessage });
      return;
    }
    await verifyPhone({ otp: OTP, isRegistration: true });
  };

  const onOTPFocus = () => {
    setHelperText("");
  };

  const onEditPhone = () => {
    setIsFirstTime(true);
    setShowPinBlock(false);
  };
  const handleAlertClose = () => setIsOTPSent(false);

  return (
    <FTUELayout title1={TITLE} title2={SUB_TITLE}>
      <Grid className="info-section">
        <p className={styles.AuthenticationDescription}>{SUB_HEADING}</p>
      </Grid>
      <Grid className="formGroup phone-no-field" sx={{ mb: "3rem !important" }}>
        {showPinBlock ? (
          <EditPhone
            phoneNumber={`+${savedPhone?.isdCode.replace("+", "")} - ${
              savedPhone?.phoneNumber
            }`}
            onEdit={onEditPhone}
          />
        ) : (
          <Controller
            control={control}
            name="isdCountry"
            render={({ field }) => (
              <CountryPhoneBlock
                control={control}
                disabled={isPhoneUpdating || homeDetailsLoading}
                fieldErrors={{
                  isdCode: errors.isdCode?.message,
                  phoneNumber: errors.phoneNumber?.message,
                }}
                {...field}
                onChange={handlePhoneChange}
              />
            )}
          />
        )}
      </Grid>

      {showPinBlock && (
        <Grid>
          <Box className={styles.pin_block}>
            <OTPInput
              otpLength={DEFAULT_OTP_LENGTH}
              legend="6-digit code"
              onComplete={onOTPComplete}
              sx={{ mb: "1.2rem" }}
              showAlert={isOTPSent}
              onFocus={onOTPFocus}
              helpText={helperText ?? ""}
              error={!!helperText}
              onAlertClose={handleAlertClose}
              alertMessage={
                isFirstTime
                  ? "Code sent successfully!"
                  : "Code resent successfully!"
              }
              disabled={isPhoneVerifying}
              bgColorProp="var(--text-input-label-2)"
            />
            <OTPCount
              attemptsLeft={otpBalance}
              onResend={handleResendOTP}
              resendLabel={canSentOtp ? RESEND : `Resend code in ${otpTimer}s`}
              attemptsLabel={ATTEMPT_LEFT}
              sx={{ justifyContent: "flex-end", mb: "3rem" }}
              isAttemptEnabled
              loading={isOTPSending}
              disabled={!canSentOtp}
            />
          </Box>
        </Grid>
      )}

      {showPinBlock ? (
        <LoadingButton
          variant="contained"
          fullWidth
          sx={{ mb: "1.5rem" }}
          onClick={handlePhoneVerify}
          loading={isPhoneVerifying}
          disabled={isOTPSending}
          label="Verify"
        />
      ) : (
        <LoadingButton
          variant="contained"
          fullWidth
          sx={{ mb: "1.5rem" }}
          loading={isOTPSending}
          disabled={
            isPhoneUpdating ||
            !!errors.isdCode?.message ||
            !!errors.phoneNumber?.message
          }
          onClick={handleSubmit(handlePhoneSubmit)}
          label="Validate"
        />
      )}

      <Button variant="outlined" fullWidth onClick={logout}>
        Logout
      </Button>
      {isShowResentMsg && resentMsg && showPinBlock && !isFirstTime && (
        <OtpResentSuccess
          message={HINT_MESSAGE[resentMsg]}
          onCancel={hideResentMessage}
        />
      )}
    </FTUELayout>
  );
}

export default PhoneNumberVerification;
