import REGEX from "constants/regex";
import * as yup from "yup";

const ISD_MAX_LENGTH = 5;

const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 30;
export const ACCESS_CODE_MAX_CHARACTER = 30;

type ErrorMessages = {
  required: string;
  regex: string;
};

export const isdSchema = yup
  .string()
  .required("Please select a valid country code")
  .max(ISD_MAX_LENGTH, "Country code can't exceed 3 digits");

export const phoneNumberSchema = yup
  .string()
  .required("Please enter a valid mobile number")
  .matches(REGEX.ONLY_DIGITS, "Mobile number must be digits");

export const isdCountrySchema = yup
  .string()
  .required("Please enter valid country code");

export const emailSchema = (
  messages: ErrorMessages = {
    regex: "Please enter valid email",
    required: "Email is required",
  },
) =>
  yup
    .string()
    .required(messages.required)
    .matches(REGEX.EMAIL, messages.regex)
    .lowercase();

export const nameSchema = (messages: ErrorMessages) =>
  yup
    .string()
    .required(messages.required)
    .matches(REGEX.ALPHABETS_AND_SPACE, messages.regex);

export const passwordSchema = yup
  .string()
  .required("Password required")
  .min(MIN_PASSWORD_LENGTH)
  .max(MAX_PASSWORD_LENGTH, "Password is too long.")
  .matches(REGEX.CAPS_LETTER, "CAPS_LETTER")
  .matches(REGEX.LOWER_CASE, "LOWER_CASE")
  .matches(REGEX.DIGITS, "DIGITS")
  .matches(REGEX.NOT_WHITESPACE, "WHITESPACE");

export const changeEmail = yup.object({
  email: emailSchema(),
});

export const changePhoneNumber = yup.object({
  isdCode: isdSchema,
  phoneNumber: phoneNumberSchema,
  isdCountry: isdCountrySchema,
});

export const addPublicAccessCodeSchema = yup.object({
  accessCode: yup
    .string()
    .trim()
    .required("Enter access code")
    .matches(REGEX.ACCESS_CODE_NAME, "Enter a valid access code")
    .max(
      ACCESS_CODE_MAX_CHARACTER,
      "The access code name should only be a maximum of 30 characters",
    ),
});

export const otpSchema = yup.object({
  otp: yup.string().required("Invalid OTP"),
});
