export const FEATURES_AVAILABLE = [
  "Get job matches",
  "Manage your interests with CML",
];

export const FEATURES_UNAVAILABLE = [
  "Show employers your interests",
  "Priority matching to job opportunities",
  "Discounts on Assessments, Paid features and Guidance Sessions",
];
