import { Box, IconButton } from "@mui/material";
import { Breadcrumb } from "components/common";
import SVGIcon from "components/icons";
import { Card } from "components/ui";
import { useLocation, useNavigate } from "react-router-dom";
import useGetYourActivites from "services/home/useGetYourActivites";
import Interviews from "./Interviews";
import CoachingSession from "./coachingSession";

const breadcrumbs = [{ text: "People Hub", active: true }, { text: "Home" }];

function YourActivity() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetYourActivites({
    type: state?.type,
  });

  const handleBack = () => navigate(-1);

  return (
    <Box className="app-l-container">
      <Breadcrumb crumbs={breadcrumbs} />
      <Box
        sx={{
          padding: "4.5rem 0 3rem 0",
          flexGrow: "1",
        }}
      >
        <Card>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              columnGap: "2.5rem",
            }}
          >
            <Box sx={{ marginTop: "-0.5rem" }}>
              <IconButton onClick={handleBack}>
                <SVGIcon name="back" />
              </IconButton>
            </Box>
            <Card.Title
              sx={{
                mb: "3rem",
              }}
            >
              {state?.type === "interview" ? "Interviews" : "Sessions"}
            </Card.Title>
          </Box>
          <Box>
            {state?.type === "interview" ? (
              <Interviews
                loading={isLoading}
                documents={data?.result?.interviews ?? []}
                timeZoneData={state?.timeZoneData}
              />
            ) : (
              <CoachingSession
                loading={isLoading}
                documents={data?.result?.coachingSessions ?? []}
                timeZoneData={state?.timeZoneData}
              />
            )}
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
export default YourActivity;
