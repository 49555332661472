import { OtpReasonType } from "services/accountProfileService/types";

export type ChangeUserDetailsType = {
  page: string;
  phoneNumber: string;
  isdCode: string;
  otpReason: OtpReasonType;
};

export const BREADCRUMBS_CONFIG: Record<string, any> = {
  CHANGE_PHONE_PHONE_VALIDATION: "Change Mobile Number",
  CHANGE_EMAIL_PHONE_VALIDATION: "Change Email",
  CHANGE_PASSWORD_PHONE_VALIDATION: "Change Password",
};
