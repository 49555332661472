import Resources from "assets/json/Resources";
import { ErrorKeysType } from "constants/sharedTypes";
import { useMutation } from "react-query";
import {
  validateGuardianShortToken,
  validateGuardianToken,
} from "./signUpServices";

type HandlerType = {
  onSuccess?: (data: ConsentRecord) => void;
  onError: (errorMsg: string) => void;
};

export type ConsentRecord = {
  talentEmail: string;
  expiryDate: string;
  talentId: string;
  consentId: string;
  grantedStatus: string;
  guardianEmail: string;
  guardianIsdCode: string;
  guardianIsdCountry: string;
  guardianPhoneNumber: string;
  talentFirstName: string;
  talentLastName: string;
  guardianFirstName?: string;
  guardianLastName?: string;
  guardianAge?: number;
  guardianRelation?: string;
};

type SuccessResponseType = {
  status: number;
  result: {
    success: boolean;
    guardianConsentRecord: ConsentRecord;
  };
};

type FailedResponse = {
  status: number;
  inputErrors?: {
    exists: boolean;
  };
  appErrors?: {
    exists: boolean;
    errors: string[];
  };
};

export type TokenType = "sms" | "email";

type APIResponseType = SuccessResponseType & FailedResponse;

const useValidateGuardianToken = (type: TokenType, handlers?: HandlerType) => {
  const handleError = (error: FailedResponse) => {
    let errorMessage = Resources.ERRORS.SOMETHING;
    if (error.appErrors?.errors[0]) {
      errorMessage =
        Resources.ERRORS[error.appErrors?.errors[0] as ErrorKeysType] ??
        Resources.ERRORS.INVALID_LINK;
    }

    handlers?.onError(errorMessage);
  };

  const handleSuccess = (data: APIResponseType) => {
    if (data?.inputErrors?.exists || data?.appErrors?.exists) {
      handleError(data);
      return;
    }
    handlers?.onSuccess?.(data?.result?.guardianConsentRecord);
  };

  return useMutation(
    type === "sms" ? validateGuardianShortToken : validateGuardianToken,
    {
      onSuccess: handleSuccess,
      onError: handleError,
    },
  );
};

export default useValidateGuardianToken;
