import { Box, SxProps, Typography } from "@mui/material";

type LabelProps = {
  label: string;
  subText: string;
  sx?: SxProps;
};

function CheckBoxLabel(props: LabelProps) {
  const { label, subText, sx } = props;

  return (
    <Box sx={{ ...sx }}>
      <Typography
        component="span"
        sx={{
          display: "block",
          lineHeight: 1,
          marginBottom: "0.25rem",
        }}
      >
        {label}
      </Typography>
      <Typography
        component="span"
        sx={{
          display: "block",
          fontSize: "0.688rem",
          lineHeight: "1.5rem",
          fontWeight: 500,
          color: "var(--colorTextSecondary)",
        }}
      >
        {subText}
      </Typography>
    </Box>
  );
}

export default CheckBoxLabel;
