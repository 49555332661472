import { Box, Button, FormHelperText, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import { ConfirmDialog } from "components";
import { Breadcrumb } from "components/common";
import { OTPCount, OTPInput } from "components/ui";
import OtpResentSuccess from "components/ui/OtpResentSuccess";
import {
  DEFAULT_OTP_LENGTH,
  HINT_MESSAGE,
  OTP_WAITING_TIME,
  ROUTES,
} from "config/app";
import { FailedAPIStatus } from "constants/sharedTypes";
import { useAuth } from "hooks";
import useTimer from "hooks/useTimer";
import { useReducer, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ValidatePhoneResponse } from "services/accountProfileService/types";
import useValidatePhone from "services/accountProfileService/useValidatePhone";
import useValidPhoneOtp from "services/accountProfileService/useValidPhoneOtp";
import { initialOTPState, OTPReducer } from "utils/otpReducer";
import { BREADCRUMBS_CONFIG, ChangeUserDetailsType } from "./config";

const {
  COMMON: {
    BUTTONS: { CODE_LABEL },
    MESSAGE: { VALID_CODE, VALIDATE_MOBILE },
  },
} = Resources;

function VerifyPhone() {
  const location = useLocation();
  const { setGlobalAlert } = useAuth();
  const navigate = useNavigate();

  const [helperText, setHelperText] = useState<string>("");
  const [canSentOtp, setCanSentOtp] = useState<Boolean>(true);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [isShowResentMsg, setIsShowResentMsg] = useState<Boolean>(false);
  const [resentMsg, setResentMsg] = useState<string>("");
  const [otpBalance, setOtpBalance] = useState(""); // reducer
  const [isFirstTime, setIsFirstTime] = useState<Boolean>(true);
  const [OTPState, dispatchOTPAction] = useReducer(OTPReducer, initialOTPState);

  const changeDetails = location.state as ChangeUserDetailsType;

  const showError = (message: string) => {
    setGlobalAlert({ messageType: "error", message });
  };

  const showSuccess = (message: string) => {
    setGlobalAlert({ messageType: "success", message });
  };

  const closeOTPAlert = () => {
    dispatchOTPAction({ type: "SET_IS_PHONE_OTP_RESEND", payload: false });
  };

  const handlePhoneError = (error: FailedAPIStatus) => {
    const message = error?.errorMessage;
    showError(message as string);
  };

  const hideResentMessage = () => {
    setIsShowResentMsg(false);
  };

  const onPhoneOtpSuccess = () => {
    const changeDetailsData = {
      isdCode: changeDetails.isdCode,
      phoneNumber: changeDetails.phoneNumber,
      oldOtp: OTPState.phoneOTP,
    };
    showSuccess(Resources.PAGES.VERIFY_PHONE.VERIFY_OTP_SUCCESS);
    navigate(changeDetails?.page, { state: changeDetailsData });
  };

  const handlePhoneOtpError = (error: FailedAPIStatus) => {
    const message = error?.errorMessage;
    setHelperText(message);
    showError(message as string);
  };

  const onPhoneVerifySuccess = (response: ValidatePhoneResponse) => {
    dispatchOTPAction({ type: "SET_IS_PHONE_OTP_RESEND", payload: true });
    setOtpBalance(response?.result?.resendPhoneOtpBalance);
    setShowOTPInput(true);
    if (!isFirstTime) {
      setResentMsg(response?.result?.hintMessageCode);
    }
  };

  const { mutateAsync: validatePhoneNumber, isLoading } = useValidatePhone({
    onError: handlePhoneError,
    onSuccess: onPhoneVerifySuccess,
  });

  const { mutateAsync: validateOtp, isLoading: isOtpLoading } =
    useValidPhoneOtp({
      onError: handlePhoneOtpError,
      onSuccess: onPhoneOtpSuccess,
    });

  const handleSubmit = () => {
    if (!OTPState.phoneOTP.length && !showOTPInput) {
      const errorMessage = VALIDATE_MOBILE;
      setHelperText(errorMessage);
      showError(errorMessage);
      return;
    }

    if (OTPState.phoneOTP.length !== DEFAULT_OTP_LENGTH) {
      const errorMessage = VALID_CODE;
      setHelperText(errorMessage);
      showError(errorMessage);
      return;
    }
    validateOtp({
      otp: OTPState.phoneOTP,
      OTPReason: changeDetails?.otpReason,
    });
  };
  const handleCancel = () => {
    navigate(ROUTES.PRIVATE.ACCOUNT_PROFILE);
  };

  const onValidatePhone = async () => {
    await validatePhoneNumber({
      OTPReason: changeDetails?.otpReason,
    });
  };

  const onOtpTimerComplete = () => {
    setCanSentOtp(true);
  };

  const { timeLeft: otpTimer } = useTimer({
    time: OTP_WAITING_TIME,
    onComplete: onOtpTimerComplete,
    start: !canSentOtp,
  });

  const onResentOtp = async () => {
    if (+otpBalance === 0) {
      showError("No resend attempts left. Try again later.");
      return;
    }
    setIsFirstTime(false);
    setIsShowResentMsg(true);
    setCanSentOtp(false);
    const response = await validatePhoneNumber({
      OTPReason: changeDetails?.otpReason,
    });
    if (response.result.hintMessageCode) {
      setResentMsg(response.result.hintMessageCode);
    }
  };

  const onOTPFocus = () => {
    setHelperText("");
  };

  const onOTPComplete = (otp: string) => {
    dispatchOTPAction({ type: "SET_PHONE_OTP", payload: otp });
  };
  const breadcrumbs = [
    {
      text: "Account Settings",
      active: true,
      pageLink: ROUTES.PRIVATE.ACCOUNT_PROFILE,
    },
    {
      text: BREADCRUMBS_CONFIG[changeDetails?.otpReason],
    },
  ];

  if (!changeDetails?.isdCode || !changeDetails?.phoneNumber) {
    return <Navigate to={ROUTES.PRIVATE.ACCOUNT_PROFILE} />;
  }

  const validatePhoneDisabled = isLoading || isOtpLoading || !showOTPInput;

  return (
    <Box
      className="app-l-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: "3rem !important",
        gap: "4.5rem",
      }}
    >
      <Breadcrumb crumbs={breadcrumbs} />
      <Box sx={{ display: "flex", flex: 1 }}>
        <ConfirmDialog
          heading={Resources.PAGES.VERIFY_PHONE.TITLE}
          subHeading={Resources.PAGES.VERIFY_PHONE.SUB_TITLE}
          onCancel={handleCancel}
          onConfirm={handleSubmit}
          primaryButtonLabel="Proceed"
          primaryButtonProps={{
            disabled: validatePhoneDisabled,
          }}
        >
          <Box sx={{ maxWidth: "40rem", paddingTop: "2.5rem" }}>
            <Box>
              <Typography>Mobile Number</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box>
                <Typography
                  sx={{ fontSize: "2.2rem !important", fontWeight: 500 }}
                >
                  +{changeDetails?.isdCode.replace("+", "")}-
                  {changeDetails?.phoneNumber}
                </Typography>
              </Box>
              <Box>
                {!showOTPInput && (
                  <Button onClick={onValidatePhone}>Verify</Button>
                )}
              </Box>
            </Box>
            {!showOTPInput && (
              <Box>
                <FormHelperText sx={{ fontSize: "1.4rem" }}>
                  Please verify mobile number
                </FormHelperText>
              </Box>
            )}

            {showOTPInput && (
              <>
                <Box sx={{ maxWidth: "64rem", mt: "3rem" }}>
                  <Typography>
                    Your mobile number needs to be verified. You should have
                    received an SMS text message to your new mobile number which
                    will contain a 6-digit code. Please enter the code below.
                  </Typography>
                </Box>
                <Box sx={{ maxWidth: "40rem", mt: "3.2rem" }}>
                  <OTPInput
                    otpLength={DEFAULT_OTP_LENGTH}
                    legend={CODE_LABEL}
                    onComplete={onOTPComplete}
                    onFocus={onOTPFocus}
                    helpText={helperText ?? ""}
                    error={!!helperText}
                    showAlert={OTPState.isPhoneOTPResend}
                    alertMessage={
                      isFirstTime
                        ? Resources.PAGES.VERIFY_PHONE.CODE_SENT_SUCCESSFULLY
                        : Resources.PAGES.VERIFY_PHONE.CODE_RESENT_SUCCESSFULLY
                    }
                    onAlertClose={closeOTPAlert}
                    sx={{ mb: "1.2rem" }}
                  />
                  <OTPCount
                    sx={{
                      justifyContent: "flex-end",
                      mb: "3rem",
                      marginTop: "1rem",
                    }}
                    resendLabel={
                      canSentOtp ? "Resend code" : `Resend code in ${otpTimer}s`
                    }
                    onResend={onResentOtp}
                    loading={isLoading}
                    attemptsLeft={otpBalance}
                    attemptsLabel={Resources.COMMON.BUTTONS.ATTEMPT_LEFT}
                    isAttemptEnabled
                    disabled={!canSentOtp}
                  />
                  {isShowResentMsg && resentMsg && (
                    <OtpResentSuccess
                      message={HINT_MESSAGE[resentMsg]}
                      onCancel={hideResentMessage}
                    />
                  )}
                </Box>
              </>
            )}
          </Box>
        </ConfirmDialog>
      </Box>
    </Box>
  );
}

export default VerifyPhone;
