import resources from "assets/json/Resources";
import { useAPIErrorHandler } from "hooks";
import { useQuery } from "react-query";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { getEmailOTP } from "./accountProfileService";
import { EmailOTPSendResponse } from "./types";

type APIResponse = EmailOTPSendResponse & FailedAPIResponse;

const useGetEmailOTP = (handler?: HandlerType) => {
  const { getErrorInfo, checkError } = useAPIErrorHandler(resources.ERRORS);

  const onRejected = (error: FailedAPIResponse) => {
    const { errorMessage } = getErrorInfo(error);
    handler?.onError?.(errorMessage);
  };

  const onFulfilled = (res: APIResponse) => {
    const hasError = checkError(res);

    if (hasError || !res?.result?.isOTPSent) {
      onRejected(res);
      return;
    }
    handler?.onSuccess?.();
  };

  return useQuery("emailOTP", getEmailOTP, {
    onSuccess: onFulfilled,
    onError: onRejected,
    enabled: false,
  });
};

export default useGetEmailOTP;
