import { Box } from "@mui/material";
import { Breadcrumb } from "components/common";
import PaymentSuccessful from "components/ui/PaymentSuccessful";
import { ROUTES } from "config/app";
import { Navigate, useLocation } from "react-router-dom";

const breadCrumbs = [
  { text: "Feature Center", pageLink: ROUTES.PRIVATE.PURCHASE_CENTER },
];

function PaymentSuccess() {
  const location = useLocation();

  // don't skip 0 amount
  if (location?.state?.amount === undefined) {
    return <Navigate replace to={ROUTES.PRIVATE.PURCHASE_CENTER} />;
  }

  return (
    <Box
      className="app-l-container"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Breadcrumb crumbs={breadCrumbs} />
      <Box
        sx={{
          marginTop: "4.8rem",
          flexGrow: "1",
          minHeight: "0",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PaymentSuccessful amount={location.state.amount} />
      </Box>
    </Box>
  );
}

export default PaymentSuccess;
