import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { validateEmailOtp } from "./accountProfileService";
import { ValidateEmailOTPPayload, ValidateEmailResponse } from "./types";

type APIResponse = ValidateEmailResponse & FailedAPIResponse;

const useValidateEmail = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, ValidateEmailOTPPayload>({
    mutationFn: validateEmailOtp,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useValidateEmail;
