import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { updateEmail } from "./accountProfileService";
import { UpdateEmailResponse, UpdateEmailPayload } from "./types";

type APIResponse = UpdateEmailResponse & FailedAPIResponse;

const useUpdateEmail = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, UpdateEmailPayload>({
    mutationFn: updateEmail,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useUpdateEmail;
