import { Box, TextField } from "@mui/material";
import Resources from "assets/json/Resources";
import { LoadingButton } from "components/common";
import { ChangeEventHandler, useEffect, useState } from "react";
import { Title } from "./helperComponents";
import Layout from "./layout";

type RedeemCodeProps = {
  accessCode: string;
  onSubmit: (accessCode: string) => void;
  onClose: () => void;
  isLoading: boolean;
};

const labels = Resources.PAGES.PURCHASE_CENTER.CAMPAIGN_MODAL.REDEEM;

function RedeemCode(props: RedeemCodeProps) {
  const { accessCode, onSubmit, onClose, isLoading } = props;
  const [code, setAccessCode] = useState("");

  useEffect(() => {
    setAccessCode(accessCode);
  }, [accessCode]);

  const onAccessCodeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setAccessCode(value);
  };

  const handleActivateClick = () => {
    onSubmit(code.trim());
  };

  return (
    <Layout onClose={onClose} title={labels.TITLE}>
      <Title text={labels.SUB_TEXT} />
      <Box
        sx={{
          mt: "3rem",
          display: "flex",
          gap: { xs: "1.5rem", md: "3rem" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <TextField
          label={labels.ACCESS_CODE}
          placeholder={labels.ENTER_CODE}
          InputLabelProps={{
            shrink: true,
          }}
          autoComplete="off"
          onChange={onAccessCodeChange}
          value={code}
          autoFocus
          fullWidth
        />
        <LoadingButton
          variant="contained"
          onClick={handleActivateClick}
          label={labels.ACTIVATE}
          loading={isLoading}
          disabled={!code}
          fullWidth
        />
      </Box>
      <Box sx={{ mt: "4.5rem", mb: "1.5rem" }}>
        <Title text={labels.ADD_FEATURES} />
        <Title text={labels.BUILD_TALENT_PROFILE} />
      </Box>
    </Layout>
  );
}

export default RedeemCode;
