import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { deletePaymentCard } from "./paymentSevice";
import { SuccessResponse, DeleteCardPayload } from "./types";

type APIResponse = SuccessResponse & FailedAPIResponse;

const useDeleteCard = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, DeleteCardPayload>({
    mutationFn: deletePaymentCard,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useDeleteCard;
