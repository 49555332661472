import Resources from "assets/json/Resources";
import { REACT_QUERY_KEYS } from "config/app";
import { useQuery } from "react-query";
import { getTermsAndConditions } from "./signUpServices";

type ResponseType = {
  status: number;
  result: {
    success: boolean;
    tnC: {
      version: string;
      body: {
        type: string;
        data: number[];
      };
    };
  };
};

export type TCResponseType = {
  tncVersion: string;
  tncDetails: string;
};

type QueryOptions = {
  onSuccess?: (data: TCResponseType) => void;
  onError?: (msg: string) => void;
  enabled?: boolean;
};

const useGetTC = (options?: QueryOptions) => {
  const sendError = () => {
    options?.onError?.(Resources.ERRORS.UNABLE_TO_GET_TNC);
  };

  const onError = () => {
    sendError();
  };

  const onSuccess = (data: ResponseType) => {
    if (data.result?.success && data.result?.tnC?.body?.data) {
      const response: TCResponseType = {
        tncVersion: data?.result?.tnC?.version,
        tncDetails: String.fromCharCode(...data.result.tnC.body.data),
      };
      options?.onSuccess?.(response);
      return;
    }
    sendError();
  };

  return useQuery<ResponseType, Error>(
    REACT_QUERY_KEYS.TERMS_AND_CONDITIONS,
    getTermsAndConditions,
    {
      onSuccess,
      onError,
      enabled: options?.enabled,
    },
  );
};
export default useGetTC;
