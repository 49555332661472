/* eslint-disable react/require-default-props */
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FormHelperText } from "@mui/material";
import Resources from "assets/json/Resources";
import { LoadingButton } from "components/common";
import { PasswordInput } from "components/ui";
import {
  Controller,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { Link } from "react-router-dom";
import useResetPassword from "services/useResetPassword";
import * as yup from "yup";
import PasswordErrorInfo from "components/PasswordErrorInfo";
import { MAX_PASSWORD_LENGTH } from "utils/validationSchema";
import { ROUTES } from "config/app";
import { useEffect, useState } from "react";
import { resetPasswordSchema } from "./config";

type ResetFormType = yup.InferType<typeof resetPasswordSchema>;

const {
  ERRORS: { MULTIPLE_ERRORS, PASSWORDS_MISMATCH },
  COMMON: {
    BUTTONS: { RESET_PASSWORD, BACKTOLOGIN },
    FIELDS: { PASSWORD_LABEL, CONFIRM_PASSWORD_LABEL, TOO_LONG },
  },
} = Resources;

function VerifyPassword(props: {
  setErrorPopUp: (message: string) => void;
  setAccountLocked: (isLocked: boolean) => void;
  currentEmail: string;
  otp: string;
  isPhoneValidated: boolean;
  phoneEmail?: string;
}) {
  const {
    setErrorPopUp,
    setAccountLocked,
    currentEmail,
    otp,
    phoneEmail,
    isPhoneValidated,
  } = props;

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    watch,
    clearErrors,
  } = useForm<ResetFormType>({
    resolver: yupResolver(resetPasswordSchema),
    mode: "all",
    defaultValues: {
      confirmPassword: "",
      password: "",
    },
  });

  const [isHighlight, setIsHighlight] = useState(false);
  const watchPassword = watch("password");
  const watchConfirmPassword = watch("confirmPassword");

  useEffect(() => {
    if (watchPassword === watchConfirmPassword) {
      clearErrors("password");
      clearErrors("confirmPassword");
    }
  }, [watchPassword, watchConfirmPassword, watch, clearErrors]);

  const { mutateAsync: resetPassword, isLoading } = useResetPassword({
    setErrorPopUp,
    setAccountLocked,
  });

  const onFormError: SubmitErrorHandler<FieldValues> = () => {
    setErrorPopUp(MULTIPLE_ERRORS);
  };

  const onFormSubmit: SubmitHandler<FieldValues> = async (formData) => {
    const { password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      setError("password", { message: PASSWORDS_MISMATCH });
      setError("confirmPassword", { message: PASSWORDS_MISMATCH });
      setErrorPopUp(PASSWORDS_MISMATCH);

      return;
    }
    await resetPassword({
      email: isPhoneValidated
        ? (phoneEmail as string)
        : (currentEmail as string),
      otp,
      password,
      confirmPassword,
      isPhoneValidated,
    });
  };

  return (
    <form
      name="form"
      onSubmit={handleSubmit(onFormSubmit, onFormError)}
      noValidate
    >
      <Box
        className="confirm-pwd-info-block"
        sx={{
          fontSize: "1.4rem !important",
          fontFamily: "var(--font-primary-500)",
          color: "var(--colorTextSecondary)",
          fontWeight: "400",
          lineHeight: "1.2",
          marginBottom: "3rem",
        }}
      >
        <PasswordErrorInfo
          password={watchPassword}
          isHighlight={isHighlight}
          showTextDecoration
        />
      </Box>

      <div className="formGroup">
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <PasswordInput
              label={PASSWORD_LABEL}
              placeholder="Enter your Password"
              error={!!errors.password?.message}
              onFocus={() => setIsHighlight(true)}
              {...field}
            />
          )}
        />
        {watchPassword.length > MAX_PASSWORD_LENGTH && (
          <FormHelperText error>{TOO_LONG}</FormHelperText>
        )}
      </div>
      <div className="formGroup app-l-confirm-pwd-block">
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field }) => (
            <PasswordInput
              label={CONFIRM_PASSWORD_LABEL}
              placeholder="Re-enter your password"
              error={!!errors.confirmPassword?.message}
              {...field}
            />
          )}
        />
      </div>

      <div className="button-block">
        <div className="app-l-primary-button-block">
          <LoadingButton
            label={RESET_PASSWORD}
            loading={isLoading}
            type="submit"
            variant="contained"
            fullWidth
          />
        </div>
        <div className="app-l-secondary-button-block">
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            component={Link}
            to={ROUTES.PUBLIC.LOGIN}
            fullWidth
            sx={{ textTransform: "capitalize" }}
          >
            {BACKTOLOGIN}
          </Button>
        </div>
      </div>
    </form>
  );
}
export default VerifyPassword;
