import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Badge, Box, Button, IconButton } from "@mui/material";
import Resources from "assets/json/Resources";
import styles from "./style.module.scss";

const { PURCHASE_CENTER } = Resources.PAGES;

type CheckoutButtonProps = {
  disabled: boolean;
  itemCount: number;
  onClick: () => void;
};

function CheckoutButton(props: CheckoutButtonProps) {
  const { disabled, itemCount, onClick } = props;
  return (
    <div className={styles.subscription}>
      <Box className={styles.top}>
        <IconButton
          className={styles.headings}
          onClick={onClick}
          disabled={disabled}
        >
          <Badge badgeContent={itemCount}>
            <ShoppingCartOutlinedIcon
              sx={{
                fontSize: "2.4rem",
                color: "var(--color-secondary)",
              }}
            />
          </Badge>
        </IconButton>
        <div className={styles.info}>
          <Button
            variant="contained"
            onClick={onClick}
            sx={{ minWidth: "24rem" }}
            disabled={disabled}
          >
            {PURCHASE_CENTER.BUTTONS.CHECKOUT}
          </Button>
        </div>
      </Box>
    </div>
  );
}

export default CheckoutButton;
