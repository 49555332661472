import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Resources from "assets/json/Resources";
import { Plan } from "components/common";
import { FeatureListItem } from "./SubComponents";
import { FEATURES_AVAILABLE, FEATURES_UNAVAILABLE } from "./config";
import styles from "./styles.module.scss";

type FreePropTypes = {
  planTitle: string;
  planDetails: string[];
  active: boolean;
  membershipType: string;
  onDowngradeClick: () => void;
};

const { PURCHASE_CENTER } = Resources.PAGES;

function Free(props: FreePropTypes) {
  const { planTitle, planDetails, active, onDowngradeClick, membershipType } =
    props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Plan
      label="My Current Plan"
      active={membershipType === "FREE"}
      activeColor="#00cccc"
      className={styles.plan}
    >
      <Box className={styles.cardContent}>
        <Box className={styles.topContent}>
          <Typography
            variant="body2"
            component="h3"
            className={`${styles.title} ${
              membershipType === "FREE"
                ? styles.activeText
                : styles.inActiveText
            }`}
          >
            {planTitle}
          </Typography>
          <Box className={styles.description}>
            <Typography
              variant="body2"
              component="h4"
              className={styles.subTitle}
            >
              Build your profile and grow your network
            </Typography>
            <Typography
              variant="caption"
              component="h3"
              className={styles.caption}
            >
              {planDetails}
            </Typography>
          </Box>
        </Box>
        <Divider className={styles.divider} />
        <Grid
          container
          className={styles.midContent}
          alignItems="center"
          sx={{ justifyContent: "center" }}
        >
          {active ? (
            <Grid item xs={7}>
              <Typography
                variant="body2"
                component="h5"
                align="center"
                className={styles.text}
              >
                Current Active Plan
              </Typography>
            </Grid>
          ) : (
            <Grid item className={styles.btnContainer}>
              <Button
                color="inherit"
                variant="outlined"
                onClick={onDowngradeClick}
                className={styles.downGradeButton}
                fullWidth
              >
                {PURCHASE_CENTER.BUTTONS.DOWNGRADE}
              </Button>
            </Grid>
          )}
        </Grid>
        <Divider className={styles.divider} />

        <Box
          sx={{
            display: "flex",
            padding: "2.5rem 0.9rem 0",
            gap: "1rem",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ flex: 1 }}>
            {FEATURES_AVAILABLE.map((feature) => (
              <FeatureListItem name={feature} key={feature} available />
            ))}
          </Box>

          <Divider
            className={styles.dividerSeperate}
            orientation={isSmallScreen ? "horizontal" : "vertical"}
            flexItem
          />

          <Box sx={{ flex: 1 }}>
            {FEATURES_UNAVAILABLE.map((feature) => (
              <FeatureListItem name={feature} key={feature} available={false} />
            ))}
          </Box>
        </Box>
      </Box>
    </Plan>
  );
}

export default Free;
