import { Box, SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";

type CommonProps = { children: ReactNode; sx?: SxProps };

function Title(props: CommonProps) {
  const { children, sx } = props;

  return (
    <Typography
      component="h4"
      sx={{
        fontSize: { xs: "1.6rem !important", md: "2rem !important" },
        marginBottom: { md: "3rem", xs: "1.5rem" },
        fontWeight: "700",
        color: "var(--colorTextPrimary)",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
}

function Card(props: CommonProps) {
  const { children, sx } = props;
  return (
    <Box
      sx={{
        boxShadow: "0px 3px 20px rgba(0,0,0,0.1)",
        borderRadius: "1.8rem",
        backgroundColor: "var(--colorWhite)",
        ...sx,
      }}
    >
      <Box
        sx={{
          padding: "3rem",
          height: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

Card.Title = Title;

export default Card;
