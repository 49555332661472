import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
  Typography,
} from "@mui/material";
import Resources from "assets/json/Resources";
import { Snackbar } from "components/common";
import { REACT_QUERY_KEYS } from "config/app";
import {
  CareerPhase,
  CareerPhaseOptionsText,
  HelpOption,
  HowCanWeHelpOptions,
  SnackbarType,
} from "constants/sharedTypes";
import { useAuth } from "hooks";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import {
  saveCareerPhaseOptions,
  saveHelpOption,
} from "services/accountProfile";
import { useGetConfig } from "services/common";
import triggerGAEvent, { GAEvents } from "utils/gaEvents";
import CheckBoxLabel from "components/ui/CheckBoxLabel";
import styles from "./style.module.scss";

const { CARRER_PHASE, HOW_CAN_WE, CARRER_PHASE_HEADING } =
  Resources.PAGES.ACCOUNT_PROFILE;

type ProfileDetailsProps = {
  profileDetails: any;
};

function ProfileSetting(props: ProfileDetailsProps) {
  const { profileDetails } = props;
  const [selectedCareer, setSelectedCareer] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    open: false,
    message: "",
    type: "error",
  });

  const { data, isLoading } = useGetConfig("ACCOUNT_PROFILE");

  const helpOptions: HelpOption =
    data?.result?.configurations?.HOW_CAN_WE_HELP_OPTIONS ?? ({} as HelpOption);

  const careerPhaseOptions: CareerPhaseOptionsText =
    data?.result?.configurations?.CAREER_PHASE_OPTIONS_TEXT ??
    ({} as CareerPhaseOptionsText);

  const { user } = useAuth();
  const queryClient = useQueryClient();

  useEffect(() => {
    setSelectedCareer(profileDetails?.home?.careerPhase?.option);
    setSelectedOption(profileDetails?.home?.howCanWeHelp?.option);
  }, [profileDetails]);

  const saveOption = async (option: any, optionText: "help" | "career") => {
    let response: any = null;

    if (optionText === "help") {
      response = await saveHelpOption({ option, from: "ACCOUNT_SETTINGS" });
    } else if (optionText === "career") {
      response = await saveCareerPhaseOptions({ option });
    }

    if (response?.status === 200 && response?.result?.success) {
      setSnackbar({
        ...snackbar,
        open: true,
        message: Resources.PAGES.ACCOUNT_PROFILE.SAVED_SUCCESSFULLY,
        type: "success",
      });
      queryClient.invalidateQueries(REACT_QUERY_KEYS.ACCOUNT_PROFILE);
      if (optionText === "help") {
        setSelectedOption(option);
      } else {
        setSelectedCareer(option);
      }

      // triggering GA event after successful change

      const eventName: GAEvents =
        optionText === "help" ? "how_we_help" : "career_phase";
      const eventParams = {
        selection:
          optionText === "help"
            ? `${option} - ${helpOptions[option as HowCanWeHelpOptions]}`
            : option,
      };

      triggerGAEvent(eventName, user?.userId as string, eventParams);
    } else {
      const apiErrorKey: string = response.appErrors.errors[0];
      setSnackbar({
        ...snackbar,
        open: true,
        message: Resources.ERRORS[apiErrorKey] || Resources.ERRORS.SOMETHING,
      });
    }
  };

  const closeSnackbar = () =>
    setSnackbar({ ...snackbar, open: false, message: "" });

  return (
    <Box>
      <Snackbar
        severity={snackbar.type}
        open={snackbar.open}
        message={snackbar.message}
        onClose={closeSnackbar}
        autoHideDuration={5000}
        disableClickAway
      />
      <Box className={styles.settingsSubBlock}>
        <Typography
          variant="body2"
          component="h4"
          className={styles.subHeading}
        >
          {HOW_CAN_WE}
        </Typography>
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <FormControl className="app-l-radio-button-wrap">
            <MuiRadioGroup
              aria-labelledby="radio-buttons-group-label"
              name="radio-buttons-group"
            >
              {Object.keys(helpOptions).map((key) => (
                <FormControlLabel
                  key={key}
                  labelPlacement="end"
                  value={key}
                  className={styles.settingsSubBlockLabel}
                  control={<Radio size="small" />}
                  label={helpOptions[key as HowCanWeHelpOptions]}
                  checked={selectedOption === key}
                  onChange={() => saveOption(key, "help")}
                />
              ))}
            </MuiRadioGroup>
          </FormControl>
        )}
      </Box>
      <Divider className={(styles.divider, styles.dividerAccount)} />
      <Box className={styles.settingsSubBlock}>
        <Typography
          variant="body2"
          component="h4"
          className={styles.subHeading}
        >
          {CARRER_PHASE}
        </Typography>
        <Typography className={styles.carrierPurchaseLabel}>
          {CARRER_PHASE_HEADING}
        </Typography>
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <FormControl className="app-l-radio-button-wrap">
            <MuiRadioGroup
              aria-labelledby="radio-buttons-group-label"
              name="radio-buttons-group"
            >
              {Object.keys(careerPhaseOptions).map((key) => (
                <FormControlLabel
                  key={key}
                  labelPlacement="end"
                  value={key}
                  className={styles.settingsSubBlockLabel}
                  control={<Radio size="small" />}
                  label={
                    <CheckBoxLabel
                      label={careerPhaseOptions[key as CareerPhase].value}
                      subText={
                        careerPhaseOptions[key as CareerPhase].talentHelperText
                      }
                      sx={{ marginBottom: "0", alignItems: "flex-start" }}
                    />
                  }
                  onChange={() => saveOption(key, "career")}
                  checked={selectedCareer === key}
                />
              ))}
            </MuiRadioGroup>
          </FormControl>
        )}
      </Box>
      {/* <Divider className={(styles.divider, styles.dividerAccount)} /> */}
      {/* <Box className={styles.mentorWrapper}>
        <Box className={styles.mentorContent}>
          <Box className={(styles.becomeMentorBox, styles.settingsSubBlock)}>
            <Typography
              variant="body2"
              component="h4"
              className={styles.subHeading}
            >
              Become a Mentor
            </Typography>
            <Typography className={styles.becomeMentorLabel}>
              Are you a mentor? We have an option for you to connect with other
              Talents so you can mentor
            </Typography>
          </Box>
          <img alt="Mentor" src={Mentor} />
        </Box>
        <Box>
          <Button
            fullWidth
            variant="contained"
            className={`${styles.mentorButton}`}
          >
            Become a mentor
          </Button>
        </Box>
      </Box>
      <Divider className={(styles.divider, styles.dividerAccount)} /> */}
      {/* <Box className={styles.settingsSubBlock}>
        <Typography
          variant="body2"
          component="h4"
          className={styles.subHeading}
        >
          {NOTIFICATION_SETTING}
        </Typography>
        <Box>
          <FormGroup>
            <FormControlLabel
              className={styles.settingsSubCheckboxLabel}
              control={<Checkbox defaultChecked />}
              label="Label"
            />
            <FormControlLabel
              disabled
              className={styles.settingsSubCheckboxLabel}
              control={<Checkbox />}
              label="Disabled"
            />
            <FormControlLabel
              disabled
              className={styles.settingsSubCheckboxLabel}
              control={<Checkbox />}
              label="Disabled"
            />
          </FormGroup>
        </Box>
      </Box> */}
    </Box>
  );
}
export default ProfileSetting;
