import { Box } from "@mui/material";
import { ReactNode } from "react";

function InvalidOuter({ children }: { children: ReactNode }) {
  return (
    <Box className="app-l-content__wrap">
      <Box
        className="app-l-content__box"
        sx={{
          padding: "1.875rem",
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          className="app-l-content__box-body"
          sx={{
            flexGrow: "1",
            minHeight: "0",
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column" },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default InvalidOuter;
