import Resources from "assets/json/Resources";
import { TalentRegistrationStepTypes } from "constants/sharedTypes";
import { useMutation } from "react-query";
import { registerTalent } from "./signUpServices";

type HandlerType = {
  onSuccess: (nextStep: TalentRegistrationStepTypes, userId?: string) => void;
  onError: (errorMsg: string) => void;
};

type SuccessResponseType = {
  status: number;
  result: {
    firstname: string;
    lastname: string;
    baseProfileStatus: string;
    isdCode: string;
    phoneNumber: string;
    isEligibleForLoginAsTalent: boolean;
    incompleteSection: TalentRegistrationStepTypes;
    isFirsttimeProfileComplete: boolean;
    userId: string;
  };
};

type FailedResponse = {
  status: number;
  inputErrors?: {
    exists: boolean;
  };
  appErrors?: {
    exists: boolean;
    errors: string[];
  };
};

type APIResponseType = SuccessResponseType & FailedResponse;

const useRegisterTalent = (handlers?: HandlerType) => {
  const onRegisterError = (error: FailedResponse) => {
    let errorMessage = Resources.ERRORS.SOMETHING;
    if (error.appErrors?.errors[0]) {
      errorMessage =
        Resources.ERRORS[error.appErrors?.errors[0]] ??
        Resources.ERRORS.REGISTER_ERROR;
    }

    handlers?.onError(errorMessage);
  };

  const onRegisterSuccess = (data: APIResponseType) => {
    if (data?.inputErrors?.exists || data?.appErrors?.exists) {
      onRegisterError(data);
      return;
    }

    handlers?.onSuccess(data?.result?.incompleteSection, data.result?.userId);
  };

  return useMutation(registerTalent, {
    onSuccess: onRegisterSuccess,
    onError: onRegisterError,
  });
};

export default useRegisterTalent;
