import { useState, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import Resources from "assets/json/Resources";
import FTUELayout from "components/FTUELayout";
import { LOCAL_STORAGE_KEYS, ROUTES } from "config/app";
import REGEX from "constants/regex";
import { useAuth } from "hooks";
import useSendCampaignInfo from "services/useSendCampaignInfo";
import useSingleSignOn from "services/useSingleSignon";
import {
  getCampaignInfo,
  getTokenDetails,
  isOnline,
  removeFromLocalStorage,
} from "utils";
import { SSOResponse } from "constants/sharedTypes";
import SVGIcon from "../../assets/icons";
import "../../assets/style/index.scss";
import useUserLogin from "../../services/useUserLogin";
import styles from "./styles.module.scss";

type LoginForm = {
  username: string;
  password: string;
};

type ErrorType = {
  errorField?: string;
  errorKey?: string;
  errorMessage: string;
};

const {
  ERRORS: {
    SOMETHING,
    ENTER_EMAIL_ADDRESS,
    ENTER_PASSWORD,
    VALID_PASSWORD_ERROR,
    VALID_EMAIL_ADDRESS_ERROR,
    MULTIPLE_ERRORS,
    CONNECTION_LOST,
  },
  PAGES: {
    LOGIN: { NOT_REGISTERED, FORGOT_PASSWORD, LOGIN, TITLE_QUOTE },
  },
  COMMON: {
    FIELDS: { EMAIL, PASSWORD },
  },
} = Resources;
const {
  PUBLIC: { FORGOT_PASSWORD: FORGOT_PASSWORD_ROUTE, LOGIN: LOGIN_ROUTE },
  PRIVATE: { HOME: HOME_ROUTE },
} = ROUTES;

function Login() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { setGlobalAlert, login } = useAuth();
  const navigate = useNavigate();
  const tokenDetails = getTokenDetails();

  const showError = (message: string) => {
    setGlobalAlert({ messageType: "error", message });
  };

  const handleError = (error: ErrorType) => {
    showError(error?.errorMessage);
    navigate(LOGIN_ROUTE);
    removeFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN_DETAILS);
  };

  const onHandleSuccess = async (response: SSOResponse) => {
    if (response?.status === 200 && response?.result?.firstname) {
      login({
        ...response.result,
      });
      navigate(tokenDetails?.pathName ?? HOME_ROUTE);
      removeFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN_DETAILS);
    } else {
      const error = {
        errorMessage: Resources.ERRORS.PWB_UNAUTHORIZED,
      };
      handleError(error);
    }
  };

  const { mutateAsync: validateSignInToken, isLoading: loading } =
    useSingleSignOn({
      onError: handleError,
      onSuccess: onHandleSuccess,
    });

  useLayoutEffect(() => {
    if (tokenDetails?.token) {
      validateSignInToken(tokenDetails?.token);
    } else {
      navigate(LOGIN_ROUTE);
    }
  }, []);

  // form initialization
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<LoginForm>({
    defaultValues: { username: "", password: "" },
    mode: "all",
  });

  const onInputBlur = (name: "username" | "password") => {
    if (errors?.[name]?.message) {
      // const message = errors[name]?.message || "";
      // setSnackbar({ open: true, message, type: "error" });
    }
  };

  const { mutate: mutateLogin, isLoading } = useUserLogin();

  const { mutate: sendCampaignInfo } = useSendCampaignInfo();

  const onSubmit = handleSubmit(
    (data) => {
      const { username, password } = data;
      mutateLogin(
        { loginDetails: { email: username, password } },
        {
          onSettled: (responseData, error) => {
            if (responseData?.appErrors?.errors?.length > 0) {
              const apiErrorKey: string = responseData.appErrors.errors[0];
              const errorMessage = !isOnline()
                ? CONNECTION_LOST
                : Resources.ERRORS[apiErrorKey] ?? SOMETHING;
              showError(errorMessage);
              return;
            }
            if (error) {
              const errorMessage = !isOnline() ? CONNECTION_LOST : SOMETHING;
              showError(errorMessage);
            } else {
              // check for campaign info
              const campaignInfo = getCampaignInfo();
              if (campaignInfo) {
                sendCampaignInfo({
                  accessCode: campaignInfo.code,
                  campaignId: campaignInfo.campaign_id,
                });
              }
            }
          },
        },
      );
    },
    (error) => {
      const { username, password } = getValues();
      if (!username && !password) {
        showError(MULTIPLE_ERRORS);
        return;
      }
      const messages = Object.values(error).map((x) => x.message);
      if (messages?.length > 0) {
        showError(messages[0] || "");
      }
    },
  );

  const { username, password } = getValues();

  return (
    <>
      {loading && (
        <CircularProgress
          size={20}
          sx={{ position: "absolute", inset: 0, margin: "auto" }}
        />
      )}
      {!tokenDetails?.token && (
        <FTUELayout
          title1={TITLE_QUOTE}
          title2="People Hub account"
          containerStyle={{
            maxWidth: { xl: "45rem", md: "40rem", sm: "48rem", xs: "34rem" },
          }}
        >
          <form
            name="form"
            className="form_tag login_form"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <Grid className={styles.formGroup}>
              <Box className={styles.texFieldBlock}>
                <Controller
                  name="username"
                  control={control}
                  rules={{
                    required: ENTER_EMAIL_ADDRESS,
                    pattern: {
                      value: REGEX.EMAIL,
                      message: VALID_EMAIL_ADDRESS_ERROR,
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <TextField
                        required
                        id="login-username"
                        label={EMAIL}
                        placeholder="Enter your email"
                        autoComplete="off"
                        focused={!!username && !errors.username}
                        inputProps={{ maxLength: "50" }}
                        {...field}
                        onBlur={() => {
                          field.onBlur();
                          onInputBlur("username");
                        }}
                        error={!!errors.username}
                        helperText={errors.username?.message}
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              </Box>
            </Grid>
            <Grid className={styles.formGroup}>
              <Box className={`${styles.texFieldBlock} passwordInputField`}>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: ENTER_PASSWORD,
                    pattern: {
                      value: REGEX.PASSWORD,
                      message: VALID_PASSWORD_ERROR,
                    },
                    maxLength: {
                      value: 30,
                      message: "This password is too long.",
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <TextField
                        required
                        id="adornment-password"
                        type={showPassword ? "text" : "password"}
                        label={PASSWORD}
                        placeholder="Enter your password"
                        autoComplete="off"
                        focused={!!password && !errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <SVGIcon
                                  name={showPassword ? "eye-cross" : "eye"}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        {...field}
                        onBlur={() => {
                          field.onBlur();
                          onInputBlur("password");
                        }}
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              </Box>
              <Grid className={(styles.loginSection, styles.forgotPassword)}>
                <p className={styles.alreadyAccountText}>
                  <Link to={FORGOT_PASSWORD_ROUTE}>{FORGOT_PASSWORD}</Link>
                </p>
              </Grid>
            </Grid>
            <Grid className={styles.buttonBlock}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                disabled={isLoading}
                color="primary"
              >
                {LOGIN}{" "}
                {isLoading && (
                  <div className="loader input-loader">
                    <CircularProgress size={24} />
                  </div>
                )}
              </Button>
            </Grid>
            <Grid className={styles.loginSection}>
              <SVGIcon name="account" />
              <p className={styles.alreadyAccountText}>
                {`${NOT_REGISTERED} `}
                <Link to={ROUTES.PUBLIC.SIGN_UP} className="link">
                  Create your account
                </Link>
              </p>
            </Grid>
          </form>
        </FTUELayout>
      )}
    </>
  );
}

export default Login;
