import { Box, Button, Typography } from "@mui/material";
import Resources from "assets/json/Resources";
import FTUELayout from "components/FTUELayout";
import SVGIcon from "components/icons";
import { APP_LINKS } from "config/app";
import { useAuth } from "hooks";
import { Link } from "react-router-dom";

const { COACHING_SESSION_BLOCKER } = Resources.PAGES.SIGN_UP;

function CoachingSessionMandatory() {
  const { logout } = useAuth();
  return (
    <FTUELayout
      title1={COACHING_SESSION_BLOCKER.MANDATORY}
      title2={COACHING_SESSION_BLOCKER.COACHING}
      containerStyle={{ maxWidth: "none" }}
    >
      <Box sx={{ maxWidth: "51rem" }}>
        <Typography
          sx={{
            fontSize: "1.4rem",
            fontWeight: "400 !important",
            lineHeight: "1.68rem",
          }}
        >
          {COACHING_SESSION_BLOCKER.ACCESS_CODE}
        </Typography>
      </Box>

      <Box sx={{ maxWidth: "51rem", pt: { xs: "1.5rem", sm: "3rem" } }}>
        <Typography
          sx={{
            fontSize: "1.4rem",
            fontWeight: "400 !important",
            lineHeight: "1.68rem",
          }}
        >
          {COACHING_SESSION_BLOCKER.GO_TO_MOBILE}
        </Typography>
      </Box>
      <Box sx={{ maxWidth: "36rem", mt: "3rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1.5rem",
            justifyContent: "space-between",
          }}
        >
          {APP_LINKS.map((link) => (
            <Box>
              <Link to={link.link} key={link.key} target="_blank">
                <SVGIcon name={link.icon} />
              </Link>
            </Box>
          ))}
        </Box>
        <Box sx={{ pt: { xs: "1.5rem", sm: "3rem" } }}>
          <Button fullWidth variant="outlined" onClick={logout}>
            Logout
          </Button>
        </Box>
      </Box>
    </FTUELayout>
  );
}

export default CoachingSessionMandatory;
