import { Box, TextField, Typography } from "@mui/material";
import { Breadcrumb } from "components/common";
import Resources from "assets/json/Resources";
import { OTPCount, OTPInput } from "components/ui";
import OtpResentSuccess from "components/ui/OtpResentSuccess";
import {
  DEFAULT_OTP_LENGTH,
  OTP_WAITING_TIME,
  REACT_QUERY_KEYS,
  ROUTES,
} from "config/app";
import REGEX from "constants/regex";
import { ConfirmDialog } from "components";
import { useAuth } from "hooks";
import useTimer from "hooks/useTimer";
import { HINT_MESSAGE } from "pages/ForgotPassword/config";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import {
  FailedAPIStatus,
  TalentRegistrationStepTypes,
} from "constants/sharedTypes";
import {
  useGetAccountProfile,
  useValidateEmail,
  useUpdateEmail,
} from "services/accountProfileService";
import { useQueryClient } from "react-query";

type EmailFormValues = {
  email: string;
};

type EmailOTPResponseType = {
  status: number;
  result: {
    incompleteSection: TalentRegistrationStepTypes;
    resendEmailOtpBalance: number;
    hintMessageCode: string;
    userid: string;
    session: string;
    email: string;
  };
};

const breadcrumbs = [
  { text: "People Hub", active: true },
  { text: "Account Settings", active: true },
  { text: "Verify Email" },
];
const {
  COMMON: {
    BUTTONS: { RESEND, ATTEMPT_LEFT, CODE_LABEL },
    MESSAGE: { VALID_CODE },
  },
} = Resources;
function VerifyEmail() {
  const navigate = useNavigate();
  const { setGlobalAlert } = useAuth();
  const { data: userInfo } = useGetAccountProfile();
  const [helperText, setHelperText] = useState<string>("");
  const [otpBalance, setOtpBalance] = useState(0);
  const [canSentOtp, setCanSentOtp] = useState<Boolean>(true);
  const [isShowResentMsg, setIsShowResentMsg] = useState<Boolean>(true);
  const [resentMsg, setResentMsg] = useState<string>("");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isOTPSent, setIsOTPSent] = useState(true);
  const [OTP, setOTP] = useState("");
  const [showOTPInput, setShowOTPInput] = useState(false);
  const queryClient = useQueryClient();

  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { errors, isValid },
  } = useForm<{ email: string }>({
    mode: "all",
    shouldFocusError: true,
    defaultValues: { email: userInfo?.result?.home?.email ?? "" },
  });
  const email = getValues("email");

  useEffect(() => {
    reset({ email: userInfo?.result.home?.email });
  }, [reset, userInfo]);

  const showError = (message: string) => {
    setGlobalAlert({ messageType: "error", message });
  };

  const handleBack = () => navigate(ROUTES.PRIVATE.ACCOUNT_PROFILE);

  const onOtpTimerComplete = () => {
    setCanSentOtp(true);
  };

  const { timeLeft: otpTimer } = useTimer({
    time: OTP_WAITING_TIME,
    onComplete: onOtpTimerComplete,
    start: !canSentOtp,
  });

  const hideResentMessage = () => {
    setIsShowResentMsg(false);
  };

  const handleupdateEmailSuccess = () => {
    setGlobalAlert({
      message: "Email verified successfully",
      messageType: "success",
    });
    queryClient.invalidateQueries(REACT_QUERY_KEYS.ACCOUNT_PROFILE);
    navigate(ROUTES.PRIVATE.ACCOUNT_PROFILE);
  };

  const handleUpdateEmailAPIError = (error: FailedAPIStatus) => {
    if (error?.errorKey === "PWB_INVALID_OLD_OTP") {
      navigate(-1);
    }
    setHelperText(error?.errorMessage);
    showError(error?.errorMessage);
  };

  const handleResendOtpError = (error: FailedAPIStatus) => {
    setHelperText(error?.errorMessage);
    showError(error?.errorMessage);

    setIsOTPSent(false);
  };

  const { mutateAsync: updateEmail, isLoading } = useUpdateEmail({
    onSuccess: handleupdateEmailSuccess,
    onError: handleUpdateEmailAPIError,
  });

  const onResendOtpSuccess = (response: EmailOTPResponseType) => {
    const { resendEmailOtpBalance, hintMessageCode } = response.result;
    setIsOTPSent(true);
    setResentMsg(hintMessageCode);
    setOtpBalance(resendEmailOtpBalance);

    if (resendEmailOtpBalance > 0) {
      setIsShowResentMsg(true);
    } else {
      setIsShowResentMsg(false);
      setIsFirstTime(false);
    }
  };

  const { mutateAsync: resendOtp, isLoading: isOTPResending } =
    useValidateEmail({
      onSuccess: onResendOtpSuccess,
      onError: handleResendOtpError,
    });

  const handleOTPResend = async () => {
    if (+otpBalance === 0) {
      showError(Resources.ERRORS.NO_RESEND_LEFT);
    } else {
      await resendOtp({ email, reason: "REGISTRATION" });
      setIsShowResentMsg(true);
      setCanSentOtp(false);
      setIsFirstTime(false);
      setIsOTPSent(true);
    }
  };

  const handleValidate = async () => {
    setIsFirstTime(true);
    const res = await resendOtp({
      email,
      reason: "REGISTRATION",
    });
    if (res?.appErrors) {
      setShowOTPInput(false);
    } else {
      setShowOTPInput(true);
    }
  };

  const handleAlertClose = () => setIsOTPSent(false);

  const onSubmit = async () => {
    if (!OTP.length) {
      const errorMessage = VALID_CODE;
      setHelperText(errorMessage);
      setGlobalAlert({
        messageType: "error",
        message: errorMessage,
      });
      return;
    }

    if (OTP?.length !== DEFAULT_OTP_LENGTH) {
      const errorMessage = VALID_CODE;
      setHelperText(errorMessage);
      showError(errorMessage);
      return;
    }

    await updateEmail({
      otp: OTP,
      email,
    });
  };

  const handleEmailChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: keyof EmailFormValues,
  ) => {
    setShowOTPInput(false);
    const { value } = event.target;

    setValue(fieldName, value);
    trigger(fieldName);
  };

  const onOTPFocus = () => {
    setHelperText("");
  };

  const validatePhoneDisabled = isLoading || !showOTPInput;

  return (
    <Box
      className="app-l-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: "3rem !important",
        gap: "4.5rem",
      }}
    >
      <Breadcrumb crumbs={breadcrumbs} />
      <ConfirmDialog
        heading="Email Verification"
        onCancel={handleBack}
        onConfirm={handleSubmit(onSubmit)}
        primaryButtonProps={{
          disabled: validatePhoneDisabled,
        }}
      >
        <Box sx={{ alignItems: "center" }}>
          <Typography
            component="span"
            sx={{
              fontSize: "1.4rem !important",
              fontFamily: "var(--font-primary-500 )",
              color: "var(--color-secondary)",
              lineHeight: "normal",
            }}
          >
            You can change the email address if required.
          </Typography>
        </Box>
        <Box sx={{ maxWidth: "40rem", marginTop: "1.476rem" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  label="Email"
                  placeholder="Enter your email"
                  autoComplete="off"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Please enter email",
                    },
                    pattern: {
                      value: REGEX.EMAIL,
                      message: "Please enter valid email",
                    },
                  })}
                  {...field}
                  error={!!errors?.email}
                  helperText={errors.email?.message}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => handleEmailChange(event, "email")}
                />
              )}
            />
            <Box sx={{ alignSelf: "flex-end" }}>
              {!showOTPInput && isValid && (
                <OTPCount
                  sx={{
                    justifyContent: "flex-end",
                    mb: "3rem",
                    marginTop: "1rem",
                  }}
                  resendLabel="Verify"
                  onResend={handleValidate}
                />
              )}
            </Box>
          </Box>
          {showOTPInput && isValid && (
            <Box
              sx={{
                maxWidth: "40rem",
                mt: "3.2rem",
              }}
            >
              <>
                <OTPInput
                  otpLength={DEFAULT_OTP_LENGTH}
                  legend={CODE_LABEL}
                  onComplete={setOTP}
                  showAlert={isOTPSent}
                  onFocus={onOTPFocus}
                  helpText={helperText ?? ""}
                  error={!!helperText}
                  onAlertClose={handleAlertClose}
                  sx={{ justifyContent: "flex-end", mb: "1rem" }}
                  alertMessage={
                    isFirstTime
                      ? "Code sent successfully!"
                      : "Code resent successfully!"
                  }
                />
                <OTPCount
                  attemptsLeft={otpBalance}
                  onResend={handleOTPResend}
                  resendLabel={
                    canSentOtp ? RESEND : `Resend code in ${otpTimer}s`
                  }
                  attemptsLabel={ATTEMPT_LEFT}
                  sx={{ justifyContent: "flex-end", mb: "3rem" }}
                  isAttemptEnabled
                  loading={isOTPResending}
                  disabled={!canSentOtp}
                />
              </>
            </Box>
          )}
          {isShowResentMsg && resentMsg && showOTPInput && !isFirstTime && (
            <OtpResentSuccess
              message={HINT_MESSAGE[resentMsg]}
              onCancel={hideResentMessage}
            />
          )}
        </Box>
      </ConfirmDialog>
    </Box>
  );
}

export default VerifyEmail;
