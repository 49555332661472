import { AddOnKeys } from "constants/sharedTypes";

export type AddOnAPIKeys =
  | "coachingSession"
  | "oadSurvey"
  | "faceCode"
  | "skillsAssessment"
  | "identityCheck"
  | "identityBackgroundCheck";

type FeatureDetailsType = {
  title: string;
  description: string;
  apiKey: AddOnAPIKeys;
  featureKey: AddOnKeys;
};

export const AVAILABLE_ADD_ONS: AddOnKeys[] = [
  "COACHING_SESSION",
  "FACE_CODE",
  "OAD_SURVEY",
  // "BACKGROUND_CHECK",
];

// data object for buy up section
export const ADD_ON_DETAILS: Record<AddOnKeys, FeatureDetailsType> = {
  COACHING_SESSION: {
    title: "Guidance Session",
    description:
      "Get realistic advice about how to achieve your job goals by talking to experienced HR Professionals, Career Coaches or Mentors.",
    featureKey: "COACHING_SESSION",
    apiKey: "coachingSession",
  },
  OAD_SURVEY: {
    title: "AI Attribute Assessment By Survey",
    description:
      "Identify your natural attributes to help you pick the kind of work that best compliments you by taking a survey.",
    apiKey: "oadSurvey",
    featureKey: "OAD_SURVEY",
  },
  SKILL_ASSESSMENT: {
    title: "Skills Assessment",
    description:
      "These are tests, games and assessment tools that help define your level of proficiency for a specific skill or subject.",
    apiKey: "skillsAssessment",
    featureKey: "SKILL_ASSESSMENT",
  },
  DRIVING_LICENSE_VALIDATION: {
    title: "Identity Check",
    description:
      "Become a valid profile by confirming your identity and profile using a government issued form of identification with your picture for validation.",
    apiKey: "identityCheck",
    featureKey: "DRIVING_LICENSE_VALIDATION",
  },
  BACKGROUND_CHECK: {
    title: "Background Check ",
    description:
      "Increase your credibility score by completing a criminal background check to confirm that you have not committed murder, crimes of fraud, or hate crimes.",
    apiKey: "identityBackgroundCheck",
    featureKey: "BACKGROUND_CHECK",
  },
  FACE_CODE: {
    title: "AI Attribute Assessment By Photo",
    description:
      "Identify your natural attributes to help you pick the kind of work that best compliments you by using a photo.",
    apiKey: "faceCode",
    featureKey: "FACE_CODE",
  },
};
