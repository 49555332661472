import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SxProps,
  Theme,
} from "@mui/material";
import { ReactNode } from "react";

type DialogProps = {
  open: boolean;
  title?: string;
  content: string | ReactNode;
  handleClose: () => void;
  primaryButton?: string;
  secondaryButton?: string;
  primaryButtonAction?: () => void;
  secondaryButtonAction?: () => void;
  contentSXProp?: SxProps<Theme>;
};
function AlertDialog(props: DialogProps) {
  const {
    open,
    title,
    content,
    handleClose,
    primaryButton,
    secondaryButton,
    primaryButtonAction,
    secondaryButtonAction,
    contentSXProp,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { backgroundColor: "var(--colorWhite)" } }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          fontSize: "2.4rem !important",
          color: "var(--colorTextPrimary) !important",
        }}
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ ...contentSXProp, fontSize: "14px", color: "#5F6969" }}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {primaryButton && (
          <Button onClick={primaryButtonAction}>{primaryButton}</Button>
        )}
        {secondaryButton && (
          <Button onClick={secondaryButtonAction} autoFocus>
            {secondaryButton}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
