import { SxProps, Typography } from "@mui/material";

type ParagraphProps = {
  sx?: SxProps;
  text: string;
};

function Paragraph(props: ParagraphProps) {
  // eslint-disable-next-line react/destructuring-assignment
  const { sx, text } = props;
  return (
    <Typography
      component="p"
      sx={{
        fontSize: "1.4rem !important",
        fontFamily: "var(--font-primary-500 )",
        color: "var(--color-secondary)",
        lineHeight: "normal",
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
}

export default Paragraph;
