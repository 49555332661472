/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Resources from "assets/json/Resources";
import { Plan } from "components/common";
import { CheckoutButtons, Switch } from "components/ui";
import { PlanDetails } from "constants/sharedTypes";
import { useEffect, useMemo, useState } from "react";
import { CartItem, PlanOptionsType } from "../types";
import { CurrentPlanInfo, FeatureListItem } from "./SubComponents";
import { FEATURES_AVAILABLE, FEATURES_UNAVAILABLE } from "./config";

import styles from "./styles.module.scss";

const MONTHS_IN_YEAR = 12;

const DURATION: { [key: number]: string } = {
  1: "month",
  12: "year",
};

const { PURCHASE_CENTER } = Resources.PAGES;

export type BasicPropTypes = {
  active: boolean;
  planOption: PlanOptionsType[];
  cartItem: CartItem | null | undefined;

  currentPlan: PlanDetails;
  disablePlanPurchase: boolean;
  onChangeSubscription: (plan: PlanOptionsType | undefined) => void;
  onRemoveCart: (planId: string) => void;
  onCheckout: () => void;
  disabled?: boolean;
};

function Basic(props: BasicPropTypes) {
  const {
    planOption,
    currentPlan,
    active,
    disablePlanPurchase,
    cartItem,
    onChangeSubscription,
    onRemoveCart,
    onCheckout,
    disabled,
  } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [chosenPlanId, setChosenPlanId] = useState("");

  useEffect(() => {
    const currentPlanId = active
      ? currentPlan.subscriptionId
      : planOption[0].subscriptionId;
    setChosenPlanId(currentPlanId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan]);

  const chosenPlan = useMemo(() => {
    return planOption.find((plan) => plan.subscriptionId === chosenPlanId);
  }, [chosenPlanId, planOption]);

  const annualPlan = useMemo(() => {
    return planOption.find((plan) => plan.durationMonths === MONTHS_IN_YEAR);
  }, [planOption]);

  const monthlyPlan = useMemo(() => {
    return planOption.find((plan) => plan.durationMonths === 1);
  }, [planOption]);

  const isDowngrading =
    currentPlan.durationMonths === MONTHS_IN_YEAR &&
    chosenPlan?.durationMonths === 1;

  const handlePlanTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const planId = checked
      ? annualPlan?.subscriptionId
      : monthlyPlan?.subscriptionId;
    setChosenPlanId(planId as string);
  };

  const handleChangeSubscription = () => {
    onChangeSubscription(chosenPlan);
  };

  const onRemoveCartItem = () => {
    onRemoveCart(cartItem?.feature as string);
  };

  const isCardActive = currentPlan.subscriptionId === chosenPlanId;

  const isUpgradingToYearly =
    currentPlan.subscriptionId === monthlyPlan?.subscriptionId &&
    chosenPlanId === annualPlan?.subscriptionId;

  return (
    <Plan
      label="My Current Plan"
      active={isCardActive}
      activeColor="var(--color-primary)"
      className={styles.plan}
    >
      <Box className={styles.cardContent}>
        <Box className={styles.topContent}>
          <Box className={styles.contentHead}>
            <Typography
              variant="body2"
              component="h3"
              className={`${styles.title} ${
                isCardActive ? styles.activeText : styles.inActiveText
              }`}
            >
              Preferred
            </Typography>
            <Typography
              variant="body2"
              component="h3"
              className={`${styles.title} ${
                isCardActive ? styles.activeText : styles.inActiveText
              }`}
            >
              {`$${chosenPlan?.fullPrice}/${
                DURATION[chosenPlan?.durationMonths as number]
              }`}
            </Typography>
          </Box>
          <Box className={styles.description}>
            <Typography
              variant="body2"
              component="h4"
              className={styles.subTitle}
            >
              Match with employers and build your future
            </Typography>
            <Typography
              variant="caption"
              component="h3"
              className={styles.caption}
            >
              As a preferred member, you get priority job matches and better
              discounts on paid features.
            </Typography>
          </Box>
        </Box>
        <Divider className={styles.divider} />
        <Box className={styles.midContent}>
          <Box className={styles.toggleSection}>
            <Typography sx={{ color: "var(--colorTextPrimary)" }}>
              Monthly
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  className="app-c-switch"
                  onChange={handlePlanTypeChange}
                  checked={chosenPlanId === annualPlan?.subscriptionId}
                />
              }
              label=""
            />
            <Box className={styles.selectSwitch}>
              <Typography className={styles.optionSwitch}>Yearly</Typography>
              <Typography className={styles.optionSwitch}>Save 20%</Typography>
            </Box>
          </Box>
          <Box className={styles.upgradeSubscription}>
            {chosenPlanId === cartItem?.feature && (
              <CheckoutButtons
                onCheckout={onCheckout}
                onRemove={onRemoveCartItem}
                disabled={disabled}
              />
            )}

            {active &&
              chosenPlanId !== currentPlan.subscriptionId &&
              chosenPlanId !== cartItem?.feature &&
              (disablePlanPurchase ? (
                <Typography
                  variant="body2"
                  sx={{
                    color: "var(--color-secondary)",
                    textAlign: "center",
                  }}
                >
                  You have already purchased this subscription
                </Typography>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  className={styles.upgradeButton}
                  onClick={handleChangeSubscription}
                  disabled={disablePlanPurchase}
                >
                  {isUpgradingToYearly
                    ? "Upgrade Subscription"
                    : "Change Subscription"}
                </Button>
              ))}

            {!active && cartItem?.feature !== chosenPlanId && (
              <Button
                fullWidth
                variant="contained"
                className={styles.upgradeButton}
                onClick={handleChangeSubscription}
              >
                Upgrade Subscription
              </Button>
            )}

            {active &&
              currentPlan.expiryDate &&
              chosenPlanId === currentPlan.subscriptionId && (
                <CurrentPlanInfo expiryDate={currentPlan.expiryDate} />
              )}
          </Box>
        </Box>

        <Divider className={styles.divider} />
        <Box
          sx={{
            display: "flex",
            padding: "2.5rem 0.9rem 0",
            gap: "1rem",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ flex: 1 }}>
            {FEATURES_AVAILABLE.map((feature) => (
              <FeatureListItem available name={feature} key={feature} />
            ))}
          </Box>

          <Divider
            className={styles.dividerSeperate}
            orientation={isSmallScreen ? "horizontal" : "vertical"}
            flexItem
          />

          <Box sx={{ flex: 1 }}>
            {FEATURES_UNAVAILABLE.map((feature) => (
              <FeatureListItem available name={feature} key={feature} />
            ))}
          </Box>
        </Box>
      </Box>
    </Plan>
  );
}

export default Basic;
