import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { getJobOfferURL } from "./docCenterService";
import { GetJobOfferLinkPayload, JobOfferLinkResponse } from "./types";

export type JobOfferLinkAPIResponse = JobOfferLinkResponse & FailedAPIResponse;

const useGetJobOfferLink = (handler?: HandlerType<JobOfferLinkAPIResponse>) => {
  return useMutationWithHandler<JobOfferLinkResponse, GetJobOfferLinkPayload>({
    mutationFn: getJobOfferURL,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useGetJobOfferLink;
