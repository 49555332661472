import { Box, Typography } from "@mui/material";
import { ActionControl, Card } from "components/ui";
import { ActionControlProps } from "components/ui/ActionControl";
import { ReactNode } from "react";

export type ConfirmProps = {
  onConfirm: () => void;
  onCancel?: () => void;
  heading?: string;
  subHeading?: string;
  children?: ReactNode;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
} & Partial<ActionControlProps>;

function ConfirmDialog(props: ConfirmProps) {
  const {
    onCancel,
    onConfirm,
    heading,
    children,
    subHeading,
    primaryButtonLabel = "Submit",
    secondaryButtonLabel = "Cancel",
    ...actionControlProps
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
      }}
    >
      <Card
        sx={{
          marginBottom: "3rem",
        }}
      >
        {heading && (
          <Box sx={{ mb: { xs: "1.5rem", md: "3rem" } }}>
            <Typography
              component="h4"
              sx={{
                flexShrink: { lg: 0, mb: "0.8rem !important" },
                fontSize: { xs: "1.6rem !important", md: "2.4rem !important" },
                fontFamily: "var(--font-primary-800 )",
              }}
            >
              {heading}
            </Typography>
            {subHeading && <Typography>{subHeading}</Typography>}
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }}>{children}</Box>
      </Card>

      <ActionControl
        {...(onCancel && secondaryButtonLabel
          ? {
              onSecondaryButtonClick: onCancel,
              secondaryButtonLabel,
            }
          : {
              onSecondaryButtonClick: () => {},
              secondaryButtonLabel: "",
            })}
        primaryButtonLabel={primaryButtonLabel}
        onPrimaryButtonClick={onConfirm}
        {...actionControlProps}
      />
    </Box>
  );
}

export default ConfirmDialog;
