import Resources from "assets/json/Resources";

const {
  PAGES: {
    PAYMENT_CENTER: {
      CARD_TYPES: { MASTER, VISA, AMEX, DISCOVER, JCB, DINERS_CLUB, UNION_PAY },
    },
  },
} = Resources;

const REGEX = {
  EMAIL:
    /^[\w][-.\w]*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,30}$/,
  ONLY_NUMBERS: /^[0-9]*$/,
  OTP: /^[0-9]{6}$/,
  CARD_TYPES: {
    [MASTER]: /^5[1-5]\d{14}$/,
    [VISA]: /^4\d{15}|4\d{12}/,
    [AMEX]: /^3[47]\d{13}/,
    [DISCOVER]: /^6011\d{12}/,
    [JCB]: /^(?:2131|1800|35\d{3})\d{11}$/,
    [DINERS_CLUB]: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    [UNION_PAY]: /^(62[0-9]{14,17})$/,
  },
  CC_NUMBER: /^[0-9]{13,19}$/,
  CVV: /^[0-9]{3,4}$/,
  ALPHABETS_AND_SPACE: /^(?!.*\d)[A-Za-z]+(\s[A-Za-z]+)*$/,
  ALPHABETS_NUMBERS_AND_SPACE: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
  ZIP_CODE:
    /^[1-9][0-9]{5}$|^\d{5}(-\d{4})?$|^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
  CANADA_ZIP_CODE: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
  MONTH_OR_YEAR: /^[0-9]{2}$/,
  CAPS_LETTER: /[A-Z]/,
  LOWER_CASE: /[a-z]/,
  SPECIAL_CHAR: /[!@#$%^&*]/,
  ONLY_DIGITS: /^\d+$/,
  DIGITS: /[0-9]/,
  ALL_DIGITS: /^[0-9]*$/,
  ALPHABETS: /^[a-zA-Z]+$/,
  PHONE_NUMBER_VALIDATION: /^\d{8,10}$/,
  PHONE_NUMBER_SPLIT: /^(\d{3})(\d{3})(\d{4})$/,
  SPECIAL_CHARACTERS: /[^\w\s]/gi,
  SPACE: /\s/g,
  CARD_NAME: /^[a-zA-Z ]*$/,
  ACCESS_CODE_NAME: /^[a-zA-Z][\s\S]*[a-zA-Z0-9\W]$/,
  MONTH: /0[1-9]|1[0-2]/,
  RELATION_TO_TALENT: /\d/g,
  NUMBERS_ONLY: /^\d+$/,
  WHITESPACE: /\s/g,
  DIGITS_ONLY: /\D/g,
  NOT_WHITESPACE: /^\S*$/,
  GROUP_4_DIGITS: /(\d{4})(?=\d)/g,
};

export default REGEX;
