import { useCallback, useEffect, useRef } from "react";

const useTimeout = (callback: React.EffectCallback, delay: number) => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<null | number>(null);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const setTo = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clearTo = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => {
    setTo();
    return clearTo;
  }, [delay, clearTo, setTo]);

  const reset = useCallback(() => {
    clearTo();
    setTo();
  }, [clearTo, setTo]);
  return { reset, clearTo };
};

export default useTimeout;
