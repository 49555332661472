import { REACT_QUERY_KEYS } from "config/app";
import { useGETRequestHandler } from "hooks";
import { HandlerType } from "services/commonTypes";
import { getCountryLists } from "./commonService";
import { CountryListResponse } from "./types";

const useGetCountryCode = (handler?: HandlerType<CountryListResponse>) => {
  return useGETRequestHandler<CountryListResponse>({
    queryKey: REACT_QUERY_KEYS.COUNTRY_LIST,
    queryFn: getCountryLists,
    handler,
  });
};

export default useGetCountryCode;
