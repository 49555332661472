/* eslint-disable react/no-array-index-key */
import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { RoutesValuesType } from "../../../constants/sharedTypes";
import styles from "./styles.module.scss";

export type BreadcrumbProps = {
  crumbs: {
    text: string;
    active?: boolean;
    pageLink?: RoutesValuesType;
  }[];
  separator?: string;
};

function Breadcrumb(props: BreadcrumbProps) {
  const { crumbs, separator } = props;
  return (
    <Box className={styles.breadcrumbs}>
      {crumbs.map((crumb, index) => (
        <Fragment key={`${crumb.text} - ${index}`}>
          <Typography
            className={`${styles.text} ${
              crumb.active ? styles.activeText : ""
            }`}
          >
            {crumb?.pageLink ? (
              <Link
                key={crumb.text}
                to={crumb?.pageLink}
                className={styles.link}
              >
                {crumb.text}
              </Link>
            ) : (
              crumb.text
            )}
          </Typography>

          {index !== crumbs.length - 1 && (
            <Typography className={styles.textSeperator}>
              {separator}
            </Typography>
          )}
        </Fragment>
      ))}
    </Box>
  );
}

export default Breadcrumb;

Breadcrumb.defaultProps = {
  separator: "/",
};
