/* eslint-disable react/require-default-props */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import Resources from "assets/json/Resources";
import { LoadingButton } from "components/common";
import { OTPCount, OTPInput } from "components/ui";
import OtpResentSuccess from "components/ui/OtpResentSuccess";
import {
  DEFAULT_OTP_LENGTH,
  HINT_MESSAGE,
  OTP_WAITING_TIME,
  ROUTES,
} from "config/app";
import { AlertSeverityType, MobileNumber } from "constants/sharedTypes";
import { useAuth } from "hooks";
import useTimer from "hooks/useTimer";
import { useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import useForgotPassword, {
  ForgotPasswordResponseType,
} from "services/useForgotPassword";
import useValidateEmailOTP from "services/useValidateEmailOTP";
import * as yup from "yup";
import useVerifyForgotPhone from "services/authServices/useVerifyForgetPhone";
import {
  ForgotPasswordPhoneResponseType,
  ForgotPhoneVerifyResponseType,
} from "services/authServices/type";
import { useForgotPhoneOtp } from "services/authServices";
import { otpValidation } from "./config";

type ResetFormType = yup.InferType<typeof otpValidation>;

type ErrorType = {
  errorField?: string;
  errorKey?: string;
  errorMessage: string;
};

const {
  PAGES: {
    FORGOT_PASSWORD: { CODE_LABEL, INVALID_OTP },
  },
  COMMON: {
    BUTTONS: { VERIFY, BACKTOLOGIN, RESEND, ATTEMPT_LEFT },
  },
} = Resources;

function ResetPasswordForm(props: {
  setErrorPopUp: (message: string) => void;
  onVerifiedOtp: (otp: string) => void;
  setAccountLocked: (isLocked: boolean) => void;
  setCurrentForm: (page: string) => void;
  currentEmail: string;
  currentForm: string;
  resendAttemptsLeft?: number;
  resendAttemptsLeftPhone?: number;
  setPhoneEmail?: (email: string) => void;
  setIsPhoneValidate?: (isPhoneValidate: boolean) => void;
  currentPhone?: MobileNumber;
}) {
  const {
    setErrorPopUp,
    currentEmail,
    onVerifiedOtp,
    setAccountLocked,
    setPhoneEmail,
    setCurrentForm,
    currentForm,
    resendAttemptsLeft,
    resendAttemptsLeftPhone,
    setIsPhoneValidate,
    currentPhone,
  } = props;

  const [helperText, setHelperText] = useState<string>("");
  const [OTP, setOTP] = useState("");
  const [otpBalance, setOtpBalance] = useState(resendAttemptsLeft);
  const [otpPhoneBalance, setOtpPhoneBalance] = useState(
    resendAttemptsLeftPhone,
  );
  const [isShowResentMsg, setIsShowResentMsg] = useState<Boolean>(true);
  const [resentMsg, setResentMsg] = useState("");
  const [canSentOtp, setCanSentOtp] = useState<Boolean>(true);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isOTPSent, setIsOTPSent] = useState(true);

  const { handleSubmit } = useForm<ResetFormType>({
    resolver: yupResolver(otpValidation),
    mode: "all",
  });

  const { setGlobalAlert } = useAuth();

  const navigate = useNavigate();

  const showAlert = (message: string, messageType: AlertSeverityType) => {
    setGlobalAlert({ messageType, message });
  };

  const handleError = (error: ErrorType) => {
    setHelperText(error?.errorMessage);
    showAlert(error?.errorMessage, "error");
  };

  const onHandleSuccess = async () => {
    onVerifiedOtp(OTP);
  };

  const onHandleSuccessPhone = async (res: ForgotPhoneVerifyResponseType) => {
    onVerifiedOtp(OTP);
    setPhoneEmail?.(res?.result?.email);
  };

  const { mutateAsync: validateEmailOtp, isLoading } = useValidateEmailOTP({
    onError: handleError,
    onSuccess: onHandleSuccess,
  });

  const validateOTP = (): boolean => {
    if (OTP.length !== DEFAULT_OTP_LENGTH) {
      setHelperText(INVALID_OTP);
      setErrorPopUp(INVALID_OTP);
      return false;
    }
    return true;
  };

  const handleOTPBalance = (otpBalanceCount: number) => {
    setOtpBalance(otpBalanceCount);
  };

  const resentOtpSuccess = (result: ForgotPasswordResponseType) => {
    setResentMsg(result?.hintMessageCode);
  };

  const resentOtpError = (error: string) => {
    if (error === "OTP_LIMIT_REACHED") {
      navigate(ROUTES.PRIVATE.COOL_DOWN);
    }
  };

  const onOtpTimerComplete = () => {
    setCanSentOtp(true);
  };

  const { timeLeft: otpTimer } = useTimer({
    time: OTP_WAITING_TIME,
    onComplete: onOtpTimerComplete,
    start: !canSentOtp,
  });

  const { mutateAsync: validatePhoneOtp } = useVerifyForgotPhone({
    onError: handleError,
    onSuccess: onHandleSuccessPhone,
  });

  const { mutate: sendOTPToEmail } = useForgotPassword({
    setErrorPopUp,
    setAccountLocked,
    setCurrentForm,
    currentForm,
    resendCountUpdate: handleOTPBalance,
    onSuccess: resentOtpSuccess,
    onError: resentOtpError,
  });

  const forgotPasswordPhoneOnSuccess = (
    response: ForgotPasswordPhoneResponseType,
  ) => {
    setOtpPhoneBalance(response?.result?.resendPhoneOtpBalance);
    setResentMsg(response?.result?.hintMessageCode);
  };

  const forgotPassWordOnError = (error: string) => {
    if (error === "OTP_LIMIT_REACHED") {
      navigate(ROUTES.PRIVATE.COOL_DOWN);
    }
  };

  const { mutateAsync: forgotPhoneOtp } = useForgotPhoneOtp({
    onSuccess: forgotPasswordPhoneOnSuccess,
    onError: forgotPassWordOnError,
  });

  const handleResendOTP = async () => {
    if (currentEmail) {
      await sendOTPToEmail({ email: currentEmail });
      if (otpBalance === 0) {
        navigate(ROUTES.PRIVATE.COOL_DOWN);
      }
    } else if (currentPhone?.phoneNumber) {
      await forgotPhoneOtp({
        isdCode: currentPhone?.isdCode,
        isdCountry: currentPhone?.isdCountry,
        phoneNumber: currentPhone?.phoneNumber,
      });
      if (otpPhoneBalance === 0) {
        navigate(ROUTES.PRIVATE.COOL_DOWN);
      }
    }

    setIsShowResentMsg(true);
    setCanSentOtp(false);
    setIsFirstTime(false);
    setIsOTPSent(true);
  };

  const onFormSubmit: SubmitHandler<FieldValues> = async () => {
    const isValidOTP = validateOTP();
    if (!isValidOTP) return;
    if (currentEmail) {
      setIsPhoneValidate?.(false);
      await validateEmailOtp({
        email: currentEmail.toLowerCase(),
        otp: OTP,
      });
    } else if (currentPhone?.phoneNumber) {
      setIsPhoneValidate?.(true);
      await validatePhoneOtp({
        isdCode: currentPhone?.isdCode,
        otp: OTP,
        phoneNumber: currentPhone?.phoneNumber,
      });
    }
  };

  const onOTPFocus = () => {
    setHelperText("");
  };

  const hideResentMessage = () => {
    setIsShowResentMsg(false);
  };

  const handleAlertClose = () => setIsOTPSent(false);

  return (
    <form name="form" onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <div className="formGroup">
        <OTPInput
          otpLength={DEFAULT_OTP_LENGTH}
          legend={CODE_LABEL}
          onComplete={setOTP}
          showAlert={isOTPSent}
          onFocus={onOTPFocus}
          helpText={helperText ?? ""}
          error={!!helperText}
          onAlertClose={handleAlertClose}
          alertMessage={
            isFirstTime
              ? "Code sent successfully!"
              : "Code resent successfully!"
          }
        />
        <OTPCount
          attemptsLeft={currentEmail ? otpBalance : otpPhoneBalance}
          onResend={handleResendOTP}
          resendLabel={canSentOtp ? RESEND : `Resend code in ${otpTimer}s`}
          attemptsLabel={ATTEMPT_LEFT}
          sx={{ justifyContent: "flex-end", mb: "3rem" }}
          isAttemptEnabled
          loading={isLoading}
          disabled={!canSentOtp}
        />
      </div>

      <div className="button-block">
        <div className="app-l-primary-button-block">
          <LoadingButton
            label={VERIFY}
            loading={isLoading}
            variant="contained"
            fullWidth
            onClick={onFormSubmit}
          />
        </div>
        <div className="app-l-secondary-button-block">
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            component={Link}
            to={ROUTES.PUBLIC.LOGIN}
            fullWidth
            sx={{ textTransform: "capitalize" }}
          >
            {BACKTOLOGIN}
          </Button>
        </div>
      </div>
      {isShowResentMsg && resentMsg && (
        <OtpResentSuccess
          message={HINT_MESSAGE[resentMsg]}
          onCancel={hideResentMessage}
        />
      )}
    </form>
  );
}
export default ResetPasswordForm;
