import { useEffect } from "react";
import { APP_ENVIRONMENT } from "config/app";

function useUserWay() {
  useEffect(() => {
    const appEnvKey = process.env.REACT_APP_ENVIRONMENT;
    if (appEnvKey && APP_ENVIRONMENT[appEnvKey] !== null) {
      const attributeCode = process.env[`REACT_APP_USERWAY_${appEnvKey}`];
      const body: HTMLBodyElement | null = document.querySelector("body");
      if (attributeCode) {
        const script = document.createElement("script");
        script.setAttribute("data-account", attributeCode);
        script.setAttribute("src", "https://cdn.userway.org/widget.js");
        body?.appendChild(script);
      }
    }
  }, []);
}
export default useUserWay;
