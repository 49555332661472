import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { RadioGroupProps } from "@mui/material/RadioGroup";
import { ReactNode } from "react";
import styles from "./styles.module.scss";

interface IRadioProps extends RadioGroupProps {
  items: {
    label: ReactNode | string;
    value: string | number;
  }[];
  labelPlacement?: "bottom" | "top" | "end" | "start" | undefined;
}

function RadioGroup(props: IRadioProps) {
  const { items, labelPlacement, ...rest } = props;
  return (
    <FormControl className="app-l-radio-button-wrap">
      <MuiRadioGroup
        aria-labelledby="radio-buttons-group-label"
        name="radio-buttons-group"
        {...rest}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.value}
            sx={{ mb: "5px" }}
            labelPlacement={labelPlacement}
            value={item.value}
            control={
              <Radio checkedIcon={<CheckCircleIcon fontSize="large" />} />
            }
            label={item.label}
            classes={{ root: styles.align }}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}

export default RadioGroup;
RadioGroup.defaultProps = {
  labelPlacement: "end",
};
