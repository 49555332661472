import { Box, IconButton, Typography } from "@mui/material";
import SVGIcon from "components/icons";

type OtpResentSuccessType = {
  message: string;
  onCancel: () => void;
};

function OtpResentSuccess(props: OtpResentSuccessType) {
  const { message, onCancel } = props;
  return (
    <Box
      sx={{
        background: "#E5F6FD",
        padding: "1.6rem",
        borderRadius: "0.4rem",
        display: "flex",
        alignItems: "center",
        marginTop: "3rem",
      }}
    >
      <Box sx={{ color: "#03989E", flexShrink: "0", marginRight: "1rem" }}>
        <SVGIcon name="exclamation" />
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: "1.4rem !important",
            fontWeight: "400",
            fontFamily: "var(--font-primary-500)",
            lineHeight: "1.3",
            color: "#014361",
          }}
        >
          {message}
        </Typography>
      </Box>
      <Box
        sx={{
          color: "#03989E",
          flexShrink: "0",
          marginLeft: "2rem",
          marginRight: "1rem",
        }}
      >
        <IconButton
          aria-label="delete"
          size="small"
          color="secondary"
          sx={{
            padding: "0",
            width: " 1.7rem",
            height: "1.7rem",
          }}
          onClick={onCancel}
        >
          <SVGIcon name="cross-circle" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default OtpResentSuccess;
