import { REACT_QUERY_KEYS, TimeConfig } from "config/app";
import { useGETRequestHandler } from "hooks";
import { HandlerType } from "services/commonTypes";
import { getCountryList } from "./commonService";
import { CountryListResponse } from "./types";

const useGetCountryList = (handler?: HandlerType<CountryListResponse>) => {
  return useGETRequestHandler<CountryListResponse>({
    queryKey: REACT_QUERY_KEYS.COUNTRY_LIST,
    queryFn: getCountryList,
    handler,
    queryOptions: { staleTime: TimeConfig.ONE_HOUR },
  });
};

export default useGetCountryList;
