import requests from "config/api";
import { LOCAL_STORAGE_KEYS } from "config/app";
import API_ENDPOINTS from "constants/api";

const signOutApiHandler = async () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_DETAILS);
  return requests.get(API_ENDPOINTS.SIGN_OUT);
};

export default signOutApiHandler;
