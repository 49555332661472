import { Box, Tab, Tabs } from "@mui/material";
import { Breadcrumb } from "components/common";
import { Card } from "components/ui";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetGeneralDocs, useGetReceipts } from "services/docCenter";
import useGetjobOffers from "services/docCenter/useGetjobOffers";
import GeneralDocs from "./GeneralDocs";
import JobOffer from "./JobOffer";
import Receipts from "./Receipts";
import { DOCUMENT_CENTER_TABS } from "./config";

const breadcrumbs = [{ text: "Document Center" }];
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            padding: { sm: "1.875rem", xs: " 0.938rem" },
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

function DocumentCenter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get("section") ?? DOCUMENT_CENTER_TABS[0].key;
  const savedTabIndex = DOCUMENT_CENTER_TABS.findIndex(
    (tab) => tab.key === currentTab,
  );

  const [tabIndex, setTabIndex] = useState(
    savedTabIndex !== -1 ? savedTabIndex : 0,
  );
  const {
    mutateAsync: fetchGeneralDocs,
    data: generalDocs,
    isLoading: isGeneralDocsLoading,
  } = useGetGeneralDocs();

  const { isLoading: isReceiptsLoading, data: receiptsList } = useGetReceipts();

  const { isLoading: isJobOfferLoading, data: jobOffer } = useGetjobOffers();

  useEffect(() => {
    (async () => {
      await fetchGeneralDocs({});
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedReceipts = useMemo(() => {
    if (!receiptsList?.result?.success) return [];

    return receiptsList?.result?.records?.sort(
      (a, b) => Date.parse(b.purchasedDate) - Date.parse(a.purchasedDate),
    );
  }, [receiptsList]);

  const sortedJobOffers = useMemo(() => {
    if (!jobOffer?.result?.offers.length) return [];
    return jobOffer?.result?.offers?.sort(
      (a, b) =>
        Date.parse(b.offerAcceptedDate) - Date.parse(a.offerAcceptedDate),
    );
  }, [jobOffer]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    const tabKey = DOCUMENT_CENTER_TABS[newValue].key;
    setSearchParams({ section: tabKey });
  };

  return (
    <Box className="app-l-container">
      <Breadcrumb crumbs={breadcrumbs} />
      <Box
        sx={{
          padding: "4.5rem 0 3rem 0",
          flexGrow: "1",
        }}
      >
        <Card>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="document-center-tabs"
            scrollButtons="auto"
            variant="scrollable"
          >
            {DOCUMENT_CENTER_TABS.map((tabs) => (
              <Tab label={tabs.label} />
            ))}
          </Tabs>
          <CustomTabPanel value={tabIndex} index={0}>
            <GeneralDocs
              loading={isGeneralDocsLoading}
              documents={generalDocs?.result.records ?? []}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={1}>
            <JobOffer loading={isJobOfferLoading} jobOffers={sortedJobOffers} />
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={2}>
            <Receipts loading={isReceiptsLoading} receipts={sortedReceipts} />
          </CustomTabPanel>
        </Card>
      </Box>
    </Box>
  );
}
export default DocumentCenter;
