import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { setCountry } from "./accountProfileService";
import { SetCountryPayload, CountryResponse } from "./types";

type APIResponse = CountryResponse & FailedAPIResponse;

const useSetCountry = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, SetCountryPayload>({
    mutationFn: setCountry,
    resource: Resources.ERRORS,
    handler,
  });
};

export default useSetCountry;
