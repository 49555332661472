import requests from "config/api";
import API_ENDPOINTS from "constants/api";

export const getPaymentMethods = async () => {
  return requests.get(API_ENDPOINTS.PAYMENT_METHODS);
};

export const removePaymentMethod = async (paymentId: string) => {
  return requests.post(API_ENDPOINTS.DELETE_PAYMENT, {
    paymentMethodId: paymentId,
  });
};

export const addACh = async (payload: {
  publicToken: string;
  bankAccountId: string;
}) => {
  return requests.post(API_ENDPOINTS.ADD_ACH, payload);
};

export const redeemAccessCode = async (accessCode: string) => {
  return requests.post(API_ENDPOINTS.REDEEM_ACCESS_CODE, { accessCode });
};

export const viewUsage = async (paymentMethodId: string) => {
  return requests.post(API_ENDPOINTS.VIEW_USAGE, { paymentMethodId });
};
