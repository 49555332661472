import requests from "config/api";
import API_ENDPOINTS from "constants/api";

export type BuyUpType = {
  featureKey: string;
  count: number;
  price: number;
};

export type PaymentDataType = {
  paymentMethodId: string;
  subscriptionId?: string;
  buyUpFeatures?: BuyUpType[];
};

export type CampaignInfo = { campaignId: string; accessCode: string };

export const getMemberShipPlan = () => {
  return requests.get(API_ENDPOINTS.MEMBERSHIP_PLANS);
};

export const downgradeSubscription = () => {
  return requests.post(API_ENDPOINTS.DOWNGRADE_SUBSCRIPTION);
};

export const makePayment = (paymentData: PaymentDataType) => {
  const { subscriptionId, ...paymentInfo } = paymentData;
  return requests.post(API_ENDPOINTS.MAKE_PAYMENT, {
    ...paymentInfo,
    ...(subscriptionId ? { subscriptionId } : {}),
  });
};

export const sendCampaignInfo = (payload: CampaignInfo) => {
  return requests.post(API_ENDPOINTS.CAMPAIGN_DETAILS, payload);
};
