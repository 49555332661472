import { Box, SxProps, Typography } from "@mui/material";
import { LogoBlack } from "assets/images";
import Resources from "assets/json/Resources";
import SVGIcon from "components/icons";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

const { LAYOUT, COMMON } = Resources;
const { CONTACT_US_LINK } = COMMON.LINKS;

type FTUEProps = {
  children: ReactNode;
  title1?: string;
  title2?: string;
  containerStyle?: SxProps;
  ftueWrapperStyle?: SxProps;
};

function FTUELayout(props: FTUEProps) {
  const { children, title1, title2, containerStyle, ftueWrapperStyle } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "space-between",
        backgroundColor: "var(--text-input-label-2)",
        ...ftueWrapperStyle,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          padding: {
            xl: "9rem 9rem 3rem 9rem",
            lg: "6rem 6rem",
            md: "4.5rem",
            sm: "5.7rem",
            xs: "5.7rem 3rem",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              mb: { xl: "6rem", xs: "4.5rem" },
              width: "100%",
              maxWidth: { xl: "27rem", lg: "20rem", md: " 17rem", xs: "15rem" },
            }}
          >
            <img
              src={LogoBlack}
              alt="Logo"
              loading="lazy"
              style={{ maxWidth: "100%", display: "block" }}
            />
          </Box>
          <Box sx={{ mb: { xl: "5.7rem", sm: "3.5rem", xs: "2.5rem" } }}>
            <Typography
              sx={{
                fontSize: {
                  xl: "5rem !important",
                  lg: "4rem !important",
                  sm: "3.3rem !important",
                  xs: "2.4rem !important",
                },
                fontWeight: 400,
                fontFamily: "var(--font-primary-700)",
                color: "var(--colorTextPrimary)",
                lineHeight: "1",
              }}
            >
              {title1}
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xl: "5rem !important",
                  lg: "4rem !important",
                  sm: "3.3rem !important",
                  xs: "2.4rem !important",
                },
                fontWeight: 400,
                fontFamily: "var(--font-primary-700)",
                color: "var(--color-primary)",
                lineHeight: "1",
                marginTop: "1rem",
              }}
            >
              {title2}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: "36rem",
            ...containerStyle,
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1.5rem",
            mt: "auto",
            paddingTop: "3rem",
          }}
        >
          <Box
            sx={{
              color: "var(--colorTextSecondary)",
              display: "flex",
              alignSelf: "center",
              width: "2.4rem",
              height: "2.4rem",
              flexShrink: "0",
              svg: {
                width: "100%",
                height: "100%",
              },
            }}
          >
            <SVGIcon name="chat" />
          </Box>

          <Typography
            sx={{
              fontSize: { sm: "1.4rem !important", xs: "1.1rem !important" },
              color: "var(--colorTextSecondary)",
              fontFamily: "var(--font-primary-500)",
            }}
          >
            {LAYOUT.NEED_HELP}
            <Link
              target="_blank"
              to={CONTACT_US_LINK}
              rel="noreferrer"
              style={{
                color: "var(--colorTextPrimary)",
                textDecoration: "underline",
                fontFamily: "var(--font-primary-700)",
              }}
            >
              {LAYOUT.CONTACT_SUPPORT}
            </Link>
          </Typography>
        </Box>
      </Box>
      <Box className={styles.bgfill}>
        <Box className="app-l-hub_name">
          <Typography className="hub-name">{LAYOUT.HUB_NAME}</Typography>
          <Typography className="hub-name">{LAYOUT.HUB}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default FTUELayout;
