import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Fade } from "@mui/material";
import Resources from "assets/json/Resources";
import { ConfirmDialog } from "components";
import { Breadcrumb } from "components/common";
import PasswordErrorInfo from "components/PasswordErrorInfo";
import { PasswordInput } from "components/ui";
import { ROUTES } from "config/app";
import { useAuth } from "hooks";
import { resetPasswordSchema } from "pages/ForgotPassword/config";
import { ChangeEventHandler, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useChangePassword } from "services/accountProfileService";
import { ChangePasswordErrorInfo } from "services/accountProfileService/types";
import { handlePasswordChange as validatePassword } from "utils";
import * as yup from "yup";
import { ChangePhoneType } from "../ChangePhone/config";

const breadcrumbs = [
  {
    text: "Account Settings",
    active: true,
    pageLink: ROUTES.PRIVATE.ACCOUNT_PROFILE,
  },
  { text: "Change Password" },
];

type Passwords = {
  newPassword: string;
  confirmPassword: string;
};
type ResetFormType = yup.InferType<typeof resetPasswordSchema>;

function ChangePassword() {
  const { setGlobalAlert, logout } = useAuth();
  const [password, setPassword] = useState<Passwords>({
    confirmPassword: "",
    newPassword: "",
  });

  const [abortPhoneVerification, setAbortPhoneVerification] = useState(false);

  const continuePhoneVerification = () => setAbortPhoneVerification(false);
  const terminatePhoneVerification = () => setAbortPhoneVerification(true);

  useForm<ResetFormType>({
    resolver: yupResolver(resetPasswordSchema),
    mode: "all",
  });

  const navigate = useNavigate();
  const [isHighlight, setIsHighlight] = useState(false);
  const location = useLocation();

  const changeDetails = location.state as ChangePhoneType;

  const showError = (message: string) =>
    setGlobalAlert({ message, messageType: "error" });

  const handleBack = () => {
    terminatePhoneVerification();
  };

  const handleConfirmPopupBack = () => navigate(ROUTES.PRIVATE.ACCOUNT_PROFILE);

  const isValidNewPassword = useMemo(() => {
    const { lowerCase, isNumber, minLength, upperCase } = validatePassword(
      password?.newPassword,
    );
    return lowerCase && isNumber && minLength && upperCase;
  }, [password?.newPassword]);

  const isValidConfirmPassword = useMemo(() => {
    const { lowerCase, isNumber, minLength, upperCase } = validatePassword(
      password?.confirmPassword,
    );
    return lowerCase && isNumber && minLength && upperCase;
  }, [password?.confirmPassword]);

  const handleChangePasswordError = (error: ChangePasswordErrorInfo) => {
    if (error.errorKey === "PWB_ACCOUNT_LOCKED") {
      showError(error.errorMessage);
      logout();
      return;
    }

    showError(error.errorMessage);
  };

  const onPasswordChangeSuccess = () => {
    setGlobalAlert({
      message: "Your password has been updated successfully.",
      messageType: "success",
    });
    navigate(ROUTES.PRIVATE.ACCOUNT_PROFILE);
  };

  const { mutateAsync: changePassword, isLoading } = useChangePassword({
    onError: handleChangePasswordError,
    onSuccess: onPasswordChangeSuccess,
  });

  const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const { name, value } = event.target;
    setPassword((prev) => ({ ...prev, [name]: value }));
  };

  const checkPasswords = (): boolean => {
    const { newPassword, confirmPassword } = password;

    if (!newPassword.length || !confirmPassword.length) {
      showError("Please enter password");
      return false;
    }
    if (!isValidNewPassword) {
      showError("Enter valid new password");
      return false;
    }

    if (newPassword !== confirmPassword) {
      showError(Resources.ERRORS.PASSWORD_MISS_MATCH);
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    const { newPassword } = password;
    const isValidPasswords = checkPasswords();

    if (isValidPasswords) {
      await changePassword({
        newPassword,
        otp: changeDetails?.oldOtp,
      });
    }
  };

  if (!changeDetails) {
    navigate(-1);
  }

  return (
    <Box
      className="app-l-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: "3rem !important",
        gap: "4.5rem",
      }}
    >
      <Breadcrumb crumbs={breadcrumbs} />
      {abortPhoneVerification && (
        <ConfirmDialog
          heading="Are you sure you want to cancel?"
          onCancel={continuePhoneVerification}
          onConfirm={handleConfirmPopupBack}
          primaryButtonLabel="Yes"
          secondaryButtonLabel="No"
        />
      )}
      <Fade in={!abortPhoneVerification} appear={false} exit={false} easing="0">
        <Box
          sx={{ display: abortPhoneVerification ? "none" : "flex", flex: 1 }}
        >
          <ConfirmDialog
            heading="Change Password"
            onCancel={handleBack}
            onConfirm={onSubmit}
            primaryButtonProps={{
              disabled: isLoading,
            }}
            primaryButtonLabel="Change Password"
            secondaryButtonLabel="Cancel"
          >
            <Box className="passwordInputField" sx={{ maxWidth: "78rem" }}>
              <Box
                className="confirm-pwd-info-block"
                sx={{
                  fontSize: "1.4rem !important",
                  fontFamily: "var(--font-primary-500)",
                  color: "var(--colorTextSecondary)",
                  fontWeight: "500",
                  lineHeight: "1.2",
                  marginBottom: "3rem",
                  width: "49.8rem",
                }}
              >
                <PasswordErrorInfo
                  password={password?.newPassword}
                  isHighlight={isHighlight}
                  showTextDecoration
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                  columnGap: "2.813rem",
                  rowGap: "2.313rem",
                  marginBottom: "2.313rem",
                }}
              >
                <Box>
                  <PasswordInput
                    aria-label="new password"
                    label="Password"
                    placeholder="Enter your new password"
                    name="newPassword"
                    onChange={handlePasswordChange}
                    onFocus={() => setIsHighlight(true)}
                    error={
                      (password.newPassword && !isValidNewPassword) ||
                      (isValidNewPassword &&
                        isValidConfirmPassword &&
                        password.newPassword !== password.confirmPassword)
                    }
                  />
                </Box>
                <Box>
                  <PasswordInput
                    aria-label="Confirm password"
                    label="Confirm password"
                    placeholder="Enter your confirm password"
                    name="confirmPassword"
                    onChange={handlePasswordChange}
                    error={
                      (password.confirmPassword && !isValidConfirmPassword) ||
                      (isValidNewPassword &&
                        isValidConfirmPassword &&
                        password.newPassword !== password.confirmPassword)
                    }
                  />
                </Box>
              </Box>
            </Box>
          </ConfirmDialog>
        </Box>
      </Fade>
    </Box>
  );
}

export default ChangePassword;
