import requests from "config/api";
import API_ENDPOINTS from "constants/api";
import { AddAccessCodePayload, YourActivityPayload } from "./types";

export const addPublicAccessCode = async (payload: AddAccessCodePayload) => {
  return requests.post(API_ENDPOINTS.ADD_PUBLIC_ACCESS_CODE, payload);
};

export const getFeatureCount = async () => {
  return requests.get(API_ENDPOINTS.FEATURES_COUNT);
};

export const getYourActivity = async (data: YourActivityPayload) => {
  let queryParams = "?";
  if (data?.limit) {
    queryParams += `limit=${data?.limit}`;
    if (data?.type) {
      queryParams += "&";
    }
  }
  if (data?.type) {
    queryParams += `type=${data?.type}`;
  }
  return requests.get(API_ENDPOINTS.YOUR_ACTIVITY + queryParams);
};
