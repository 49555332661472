import { FailedAPIResponse } from "services/commonTypes";
import useAuth from "./useAuth";

const useAPIErrorHandler = (
  resourcePath: Record<string, any>,
  defaultErrorMessage?: string,
) => {
  const { logout } = useAuth();

  const getErrorInfo = (error: FailedAPIResponse) => {
    const { inputErrors, appErrors } = error;
    let errorField = "";
    let errorKey = "";
    let errorMessage = "Something went wrong";

    if (error?.status === 440 && error?.result?.timeOut === "session") {
      errorMessage = "";
      logout();
      return { errorField, errorMessage, errorKey };
    }

    if (inputErrors?.exists) {
      errorField = inputErrors.errors[0].param?.[0];
      errorMessage = `${errorField} required`;
    }

    if (appErrors?.exists) {
      errorKey = appErrors?.errors?.[0];

      errorMessage = defaultErrorMessage || errorMessage;

      errorMessage = resourcePath[errorKey] ?? errorMessage;
    }

    return { errorField, errorMessage, errorKey };
  };

  const checkError = (error: FailedAPIResponse) => {
    const { inputErrors, appErrors } = error;

    return inputErrors?.exists || appErrors?.exists;
  };

  return { getErrorInfo, checkError };
};

export default useAPIErrorHandler;
