import Resources from "assets/json/Resources";
import { useMutationWithHandler } from "hooks";
import { FailedAPIResponse, HandlerType } from "services/commonTypes";
import { ValidateEmailOTPResponse } from "constants/sharedTypes";
import { validateEmailOTP } from "./common/commonService";
import { ValidateEmailOTPType } from "./common/types";

type APIResponse = ValidateEmailOTPResponse & FailedAPIResponse;

const useValidateEmailOTP = (handler?: HandlerType) => {
  return useMutationWithHandler<APIResponse, ValidateEmailOTPType>({
    mutationFn: validateEmailOTP,
    resource: Resources.API_ERRORS.VALIDATE_PASSWORD,
    handler,
  });
};

export default useValidateEmailOTP;
