export const addOnConfig: Record<string, string> = {
  COACHING_SESSION: "Guidance Session",
  OAD_SURVEY: "AI Attribute Assessment By Survey",
  SKILL_ASSESSMENT: "Skills Assessment",
  DRIVING_LICENSE_VALIDATION: "Identity Check",
  BACKGROUND_CHECK: "Background Check ",
  FACE_CODE: "AI Attribute Assessment By Photo",
};

export const LIMIT = 5;
