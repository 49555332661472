import { Box, Button, Typography } from "@mui/material";
import { NoAccessCode } from "assets/images";
import Resources from "assets/json/Resources";
import SVGIcon from "components/icons";
import NoDataItem from "components/ui/EmptyData";
import { APP_LINKS, ROUTES } from "config/app";
import useSidebar from "hooks/useSidebar";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

function NoDataComponent(): JSX.Element {
  const lineHeightProps = { lineHeight: "3rem" };
  const navigate = useNavigate();
  const { setActiveMenu } = useSidebar();

  const titleProps = {
    fontSize: "2.4rem !important",
    fontWeight: 400,
    fontFamily: "metropolisbold",
    ...lineHeightProps,
  };
  const {
    NO_DATA: { TITLE, THIS_IS_WHERE, IF_YOU_HAVE, IF_YOU_DONT_HAVE },
    BUY_MORE_FEATURES,
    CONTINUE_JOURNEY,
  } = Resources.PAGES.ACCESS_CODE;

  const handleNavigateToPurchaseCenter = () => {
    setActiveMenu("featureCenter");
    navigate(ROUTES.PRIVATE.PURCHASE_CENTER);
  };

  return (
    <NoDataItem sx={{ display: "contents" }}>
      <NoDataItem.Title title={TITLE} sx={titleProps} />
      <NoDataItem.Description sx={{ lineHeight: "4rem !important" }}>
        {THIS_IS_WHERE}
      </NoDataItem.Description>
      <NoDataItem.ImageContainer
        image={NoAccessCode}
        sx={{
          height: { md: "18.75rem", xs: "14.75rem" },
          width: { md: "18.75rem", xs: "14.75rem" },
        }}
      />
      <NoDataItem.Title title="What do you do next?" sx={titleProps} />
      <NoDataItem.Description sx={lineHeightProps}>
        {IF_YOU_HAVE}
      </NoDataItem.Description>
      <NoDataItem.Description sx={lineHeightProps}>
        {IF_YOU_DONT_HAVE}
      </NoDataItem.Description>
      <Box
        sx={{
          maxWidth: "36.8rem",
          margin: "3rem auto auto auto",
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          className={styles.buyButton}
          onClick={handleNavigateToPurchaseCenter}
        >
          {BUY_MORE_FEATURES}
        </Button>
        <Box sx={{ marginTop: "3rem" }}>
          <Typography variant="h4" className={styles.containerHeading2}>
            {CONTINUE_JOURNEY}
          </Typography>
          <Box className={styles.storeGroup}>
            {APP_LINKS.map((link) => (
              <Link to={link?.link} key={link?.key} target="_blank">
                <SVGIcon name={link.icon} />
              </Link>
            ))}
          </Box>
        </Box>
      </Box>
    </NoDataItem>
  );
}
export default NoDataComponent;
