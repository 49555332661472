import {
  CircularProgress,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TableProps as MuiTableProps } from "@mui/material/Table";
import { TableContainerProps as MuiTableContainerProps } from "@mui/material/TableContainer";
import { TableHeaderType } from "constants/sharedTypes";
import { Children, memo, ReactNode } from "react";
import TableCell from "./TableCell";

type TableProps = {
  headerData: TableHeaderType[];
  children: ReactNode;
  loading?: boolean;
  noDataMessage?: ReactNode;
  tableContainerProps?: MuiTableContainerProps;
} & MuiTableProps;

function Table(props: TableProps) {
  const {
    headerData,
    children,
    loading,
    noDataMessage,
    tableContainerProps,
    ...tableProps
  } = props;

  const renderLoadingRow = () => (
    <TableRow>
      <TableCell colSpan={headerData.length} align="center">
        <CircularProgress size={20} />
      </TableCell>
    </TableRow>
  );

  const renderNoContent = () => (
    <TableRow>
      <TableCell colSpan={headerData.length} align="center">
        <Typography variant="body2">{noDataMessage}</Typography>
      </TableCell>
    </TableRow>
  );

  const renderChildren = () => {
    if (Children.count(children)) {
      return children;
    }
    return renderNoContent();
  };

  return (
    <TableContainer {...tableContainerProps}>
      <MuiTable sx={{ minWidth: 650, ...tableProps.sx }} {...tableProps}>
        <TableHead>
          <TableRow>
            {headerData.map((header) => (
              <TableCell
                width={header.width}
                key={header.key}
                align={header.align ?? "left"}
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{loading ? renderLoadingRow() : renderChildren()}</TableBody>
      </MuiTable>
    </TableContainer>
  );
}

export default memo(Table);

Table.defaultProps = {
  loading: false,
  noDataMessage: "No data available",
};
