import { IconButton, TableRow } from "@mui/material";
import { Table, TableCell } from "components/common";
import SVGIcon from "components/icons";
import { GeneralDocType, TableHeaderType } from "constants/sharedTypes";
import { useMemo } from "react";
import { Link } from "react-router-dom";

type GeneralDocsProps = {
  documents: GeneralDocType[];
  loading: boolean;
};

const TABLE_HEADER: TableHeaderType[] = [
  { key: "name", label: "Name", align: "left" },
  { key: "view", label: "View", align: "right" },
];

function GeneralDocs(props: GeneralDocsProps) {
  const { documents, loading } = props;

  const tableRows = useMemo(() => {
    return documents.map((doc) => (
      <TableRow key={doc.key}>
        <TableCell>
          <TableCell.Text text={doc.name} />
        </TableCell>
        <TableCell align="right">
          <IconButton
            component={Link}
            to={doc.link}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ padding: "0" }}
          >
            <SVGIcon name="eye" />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  }, [documents]);

  return (
    <Table
      headerData={TABLE_HEADER}
      loading={loading}
      noDataMessage="Unable to find general documents"
    >
      {tableRows}
    </Table>
  );
}

export default GeneralDocs;
